import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { OverflownText } from 'Components/components.js';
import {
  CostLabel,
  JobTagsTableCell,
} from 'Pages/Pipelines/components/components.js';
import {
  bytesToSize,
  getFullTableName,
  highlightText,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';

const {
  DESTINATION_TABLE_NAME_WIDTH,
  RESERVATION_WIDTH,
  PRINCIPAL_EMAIL_WIDTH,
} = PipelineTableCellStyles;

const ResourceTableSourceRaw = ({ sourceData }) => {
  const {
    tables,
    sourceUri,
    principalEmail,
    jobTags,
    reservation,
    sourceProject,
    totalBytes,
    totalSlotsMs,
    costs,
    paymentType,
  } = sourceData;

  const totalData =
    paymentType === BQ_BILLING_TYPE.ON_DEMANDS
      ? bytesToSize(totalBytes)
      : millisecondsToTimeSlots(totalSlotsMs);

  return (
    <TableRow style={{ cursor: 'default' }}>
      <TableCell>
        {tables.map((table, i) => {
          const tableName = getFullTableName(table.table, table.dataset);

          return (
            <OverflownText
              title={tableName}
              maxWidth={DESTINATION_TABLE_NAME_WIDTH}
              key={tableName + i}
            >
              {tableName}
            </OverflownText>
          );
        })}

        {Boolean(sourceUri) && (
          <OverflownText
            title={sourceUri}
            maxWidth={DESTINATION_TABLE_NAME_WIDTH}
          >
            {highlightText(sourceUri)}
          </OverflownText>
        )}
      </TableCell>

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      <TableCell align='left' className='nowrapText'>
        {reservation?.length ? (
          <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
            {reservation}
          </OverflownText>
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {sourceProject}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        {totalData.length ? (
          totalData
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
      </TableCell>
    </TableRow>
  );
};

export { ResourceTableSourceRaw };
