const secondsInMinute = 60;
const minutesInHour = 60;
const hoursInDay = 24;
const daysInWeek = 7;
const secondsInHour = secondsInMinute * minutesInHour;
const secondsInDay = hoursInDay * secondsInHour;
const secondsInWeek = daysInWeek * secondsInDay;

const frequencyBySeconds = (seconds) => {
  const minutes = Math.floor(seconds / secondsInMinute);
  if (minutes === 0) {
    return `Every ${seconds} seconds`;
  }
  if (minutes === 1) {
    return 'Every minute';
  }
  if (minutes < minutesInHour) {
    return `Every ${minutes} minutes`;
  }

  const hours = Math.floor(seconds / secondsInHour);
  if (hours === 1) {
    return 'Hourly';
  }
  if (hours < hoursInDay) {
    return `Every ${hours} hours`;
  }

  const days = Math.floor(seconds / secondsInDay);
  if (days === 1) {
    return 'Daily';
  }
  if (days < daysInWeek) {
    return `Every ${days} days`;
  }

  const weeks = Math.floor(seconds / secondsInWeek);
  if (weeks === 1) {
    return 'Weekly';
  }

  return `Every ${weeks} weeks`;
};

const shortFrequencyBySeconds = (seconds) => {
  const minutes = Math.floor(seconds / secondsInMinute);
  if (minutes === 0) {
    return `${seconds} sec`;
  }
  if (minutes < minutesInHour) {
    return `${minutes} min`;
  }

  const hours = Math.floor(seconds / secondsInHour);
  if (hours === 1) {
    return 'hourly';
  }
  if (hours < hoursInDay) {
    return `${hours} h`;
  }

  const days = Math.floor(seconds / secondsInDay);
  if (days === 1) {
    return 'daily';
  }
  if (days < daysInWeek) {
    return `${days} days`;
  }

  const weeks = Math.floor(seconds / secondsInWeek);
  if (weeks === 1) {
    return 'weekly';
  }

  return `${weeks} weeks`;
};

export { frequencyBySeconds, shortFrequencyBySeconds };
