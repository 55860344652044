import React from 'react';
import { usePipelineLastRuns, useEffect } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { makeStyles } from '@mui/styles';
import { Table, TableBody, TableHead } from '@mui/material';
import {
  IconButton,
  OverflownText,
  PositionLoader,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import {
  StartTime,
  JobName,
  Duration,
  SlotsHours,
  Bytes,
  PipelineWidgets,
} from '../libs/constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { DATE_FORMAT } from 'constants/constants.js';
import {
  bytesToSize,
  getWidgetLabel,
  handleCopyText,
  millisecondsToHoursTime,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

const HEAD_CELLS = [StartTime, JobName, Duration, SlotsHours, Bytes];
const JOB_NAME_MAX_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  cellWithIcon: {
    display: 'flex',
    gap: theme.spacing(2),
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
}));

const LastRunsWidget = ({
  jobHash,
  currentBqBillingData,
  onChangeLoadingWidget,
}) => {
  const { lastRuns } = getWidgetLabel();
  const { paymentType = BQ_BILLING_TYPE.FLAT_RATE } =
    currentBqBillingData || {};
  const { pipelineLastRuns, isFetching } = usePipelineLastRuns(jobHash);

  const isSlotsCellExists = paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  const isBytesCellExists = paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  SlotsHours.IS_SHOW = paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
  Bytes.IS_SHOW = paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(PipelineWidgets.LAST_RUNS, isFetching);
    }
  }, [isFetching, onChangeLoadingWidget]);

  return (
    <Widget
      label={lastRuns.title}
      secondaryLabel={lastRuns.subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <Table>
        <LastRunsTableHead headCells={HEAD_CELLS} />
        <TableBody>
          {isFetching && (
            <div className='loaderContainer'>
              <PositionLoader />
            </div>
          )}
          {pipelineLastRuns?.map((item) => (
            <LastRunsTableBody
              key={`${item.jobName}/${item.bytes}`}
              rawData={item}
              isSlotsCellExists={isSlotsCellExists}
              isBytesCellExists={isBytesCellExists}
            />
          ))}
        </TableBody>
      </Table>
    </Widget>
  );
};

const LastRunsTableHead = ({ headCells }) => {
  return (
    <TableHead>
      <StyledTableRow>
        {headCells.map((headCell) => {
          return (
            headCell.IS_SHOW && (
              <StyledTableCell key={headCell.ID} align={headCell.ALIGN}>
                <span className='txt-grey-13-500 nowrapText'>
                  {headCell.LABEL}
                </span>
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
};

const LastRunsTableBody = ({
  rawData,
  isSlotsCellExists,
  isBytesCellExists,
}) => {
  const classes = useStyles();

  return (
    <StyledTableRow>
      <StyledTableCell>
        {!rawData.startTime.length ? (
          <span className='txt-grey-13-500'>N/A</span>
        ) : (
          moment(rawData.startTime).utc().format(DATE_FORMAT.monthYearTime)
        )}
      </StyledTableCell>

      <StyledTableCell>
        <div className={classes.cellWithIcon}>
          <OverflownText title={rawData.jobName} maxWidth={JOB_NAME_MAX_WIDTH}>
            {rawData.jobName}
          </OverflownText>

          <IconButton
            icon={<CopyIcon />}
            onClick={() => handleCopyText(rawData.jobName)}
          />
        </div>
      </StyledTableCell>

      <StyledTableCell align='right'>
        {rawData.duration ? (
          millisecondsToHoursTime(rawData.duration)
        ) : (
          <span className='txt-grey-13-500'>&mdash;</span>
        )}
      </StyledTableCell>

      {isBytesCellExists && (
        <>
          <StyledTableCell align='right'>
            {rawData.bytes ? (
              bytesToSize(rawData.bytes)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </StyledTableCell>
        </>
      )}

      {isSlotsCellExists && (
        <>
          <StyledTableCell align='right'>
            {rawData.slotsMs ? (
              millisecondsToTimeSlots(rawData.slotsMs)
            ) : (
              <span className='txt-grey-13-500'>&mdash;</span>
            )}
          </StyledTableCell>
        </>
      )}
    </StyledTableRow>
  );
};

export { LastRunsWidget };
