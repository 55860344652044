import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 800,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 24,
    background: theme.palette.common.white,
    borderRadius: 16,
  },
  modalDesc: {
    maxHeight: 600,
    overflow: 'scroll',
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'column',
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 4,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select:focus': {
      background: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root': {
      minHeight: 54,
      lineHeight: '22px',
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .menuItemSelect': {
      fontSize: theme.typography.fontSize,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.text.primary,
      '&--disabled': {
        color: theme.palette.text.secondary,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 14px',
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.mainDarkText,
      fontSize: theme.typography.fontSize,
      boxSizing: 'border-box',
    },
    '& .MuiAutocomplete-root': {
      '& .MuiOutlinedInput-root': {
        padding: '8px 14px',
        '& .MuiAutocomplete-input': {
          padding: 0,
        },
      },
      '& .MuiAutocomplete-tag': {
        height: 'auto',
        margin: 1,
        padding: 0,
        backgroundColor: theme.palette.common.white,
        borderRadius: 4,
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  },
  listItems: {
    padding: '8px 16px',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mainDarkText,
    fontSize: theme.typography.fontSize,
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    '&.selected': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  requiredIcon: {
    color: theme.palette.error.main,
  },
  fieldContainer: {
    width: 350,
  },
  textareaField: {
    width: '100%',
    padding: '8px 14px',
    borderColor: theme.palette.divider,
    borderRadius: 4,
    fontSize: theme.typography.fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.primary,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
    },
    '&:focus-visible': {
      outline: `${theme.palette.primary.main} 1px`,
    },
  },
  buttonsContainer: {
    marginTop: 32,
    display: 'flex',
    gap: 12,
    justifyContent: 'end',
    '& .containedButton': {
      width: 140,
    },
  },
  jiraAvatar: {
    width: 18,
    marginRight: 8,
    borderRadius: 2,
    '&--user': {
      borderRadius: '50%',
    },
  },
}));
