import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { DictionaryTabs } from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import { ReportsMemo } from './Reports.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import lookerIcon from 'assets/img/looker.svg';

const ReportsWidget = ({
  table,
  dataset,
  toDictionaryPage,
  onChangeExistingData,
}) => {
  const history = useHistory();
  const { dependentReports } = getWidgetLabel();

  return (
    <Widget
      label={dependentReports.title}
      secondaryLabel={dependentReports.subtitle}
      toPage={toDictionaryPage(
        AppRoutes.Dictionary.path,
        {
          ...history.location.state,
        },
        'View in Dictionary',
        dependentReports.title,
        DictionaryTabs.LOOKER
      )}
      initialSettings={{ size: 'L', height: 'auto' }}
      iconPath={lookerIcon}
    >
      <ReportsMemo
        table={table}
        dataset={dataset}
        onChangeExistingData={onChangeExistingData}
      />
    </Widget>
  );
};

export { ReportsWidget };
