export const columnNameId = 'column';
export const statusId = 'passed';
export const ruleTypeId = 'typeLabel';
export const dimensionId = 'dimensionLabel';
export const failedRowsId = 'failedRows';
export const parametersId = 'parameters';
export const thresholdId = 'threshold';
export const queryId = 'query';
const copyQueryId = 'copyQuery';

const ColumnName = {
  id: columnNameId,
  label: 'Column',
  sort: true,
};

const Status = {
  id: statusId,
  label: 'Status',
  sort: true,
};

const RuleType = {
  id: ruleTypeId,
  label: 'Rule type',
  sort: true,
};

const Dimension = {
  id: dimensionId,
  label: 'Dimension',
  sort: true,
};

const FailedRows = {
  id: failedRowsId,
  label: 'Failed rows',
  sort: true,
};

const Parameters = {
  id: parametersId,
  label: 'Parameters',
  sort: false,
};

const Threshold = {
  id: thresholdId,
  label: 'Threshold',
  sort: true,
};

const Query = {
  id: queryId,
  label: 'Query',
  sort: false,
};

const CopyQuery = {
  id: copyQueryId,
  label: '',
  sort: false,
};

export {
  ColumnName,
  Status,
  RuleType,
  Dimension,
  FailedRows,
  Parameters,
  Threshold,
  Query,
  CopyQuery,
};
