import React from 'react';
import {
  useCallback,
  useEffect,
  useGetBigQueryIncidentGroups,
  useLocation,
  useMemo,
  useState,
  useSyncTableToSearchParam,
} from 'hooks/hooks.js';
import { Col, Row } from 'react-bootstrap';
import { useStyles } from '../../Incidents.styles.js';
import { SourcesButton, TableDatasetSearch } from 'Components/components.js';
import { Dropdowns } from '../GlobalPageComponents/components.js';
import { BQIncidentCard } from './components/components.js';
import { useProjectTree } from 'context/context.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  IncidentTabs,
  INCIDENT_FILTER_OPTIONS,
  STATUS_FILTER_OPTIONS,
  INCIDENT_TYPES,
  INCIDENT_TYPE,
} from 'constants/constants.js';
import { formatIncidents } from 'Pages/Incidents/libs/utils/utils.js';
import {
  INCIDENT_STATUS_FILTER,
  BigQueryTabDropdowns,
  TABLE_ALERT_FILTER,
  INCIDENT_TYPE_FILTER,
  EMPTY_SEARCH_MESSAGE,
} from 'Pages/Incidents/libs/constants/constants.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';

const TAB_EVENT = `${AMPL_PAGE_EVENT.incidents} -> tab: ${IncidentTabs.GBQ} ->`;

const BQTab = ({
  startDate,
  endDate,
  handleChangeTotalIncidents,
  handleChangeTitleTotalIncidents,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeOpenBackdrop,
  onChangeExistingDatePicker,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [tableInfo, setTableInfo] = useState(null);
  const [dataset, setDataset] = useState(null);
  const [searchTable, setSearchTable] = useState(null);
  const { tableFromURL, tableInfoFromUrl, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      tableInfo?.table,
      tableInfo?.dataset || dataset,
      searchTable,
      IncidentTabs.GBQ
    );
  const {
    setIsOpen,
    setSelectTable,
    setOnSelectTable,
    setSelectDataset,
    setOnSelectDataset,
  } = useProjectTree();

  const [dropdownsValue, setDropdownsValue] = useState({
    [INCIDENT_TYPE_FILTER]: [STATUS_FILTER_OPTIONS[0]],
    [TABLE_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[1], ALERT_FILTER_OPTIONS[2]],
    [INCIDENT_STATUS_FILTER]: [INCIDENT_FILTER_OPTIONS[0]],
  });

  const incidentIdFromUrl = searchParams.get(SEARCH_PARAMS.ID);
  let incidentTypeFromUrl = searchParams.get(SEARCH_PARAMS.TYPE);

  if (incidentTypeFromUrl) {
    if (
      incidentTypeFromUrl === INCIDENT_TYPES.FRESHNESS.toString() ||
      incidentTypeFromUrl === INCIDENT_TYPES.VOLUME.toString()
    ) {
      incidentTypeFromUrl = INCIDENT_TYPE.BQ;
    }
    if (incidentTypeFromUrl === INCIDENT_TYPES.OTHER_ERROR.toString()) {
      incidentTypeFromUrl = INCIDENT_TYPE.LOG;
    }
  }

  const defaultIncidentIdAndType = useMemo(() => {
    return incidentIdFromUrl && incidentTypeFromUrl
      ? {
          id: incidentIdFromUrl,
          type: incidentTypeFromUrl,
        }
      : {
          id: null,
          type: null,
        };
  }, [incidentIdFromUrl, incidentTypeFromUrl]);

  const [incidentIdAndType, setIncidentIdAndType] = useState(
    defaultIncidentIdAndType
  );

  const tableInfoByIncident = (incident) => {
    return {
      uuid: incident.tableUuid,
      dataset: incident.dataset,
      table: incident.table,
      type: incident.tableType,
      alertType: incident.alertType,
    };
  };

  useEffect(() => {
    const id = searchParams.get(SEARCH_PARAMS.ID);
    let type = searchParams.get(SEARCH_PARAMS.TYPE);

    if (id && type) {
      if (
        type === INCIDENT_TYPES.FRESHNESS.toString() ||
        type === INCIDENT_TYPES.VOLUME.toString()
      ) {
        type = INCIDENT_TYPE.BQ;
      }
      if (type === INCIDENT_TYPES.OTHER_ERROR.toString()) {
        type = INCIDENT_TYPE.LOG;
      }

      setIncidentIdAndType({ id, type });
      onChangeExistingDatePicker(false);
    } else {
      setIncidentIdAndType({ id: null, type: null });
      onChangeExistingDatePicker(true);
    }
  }, [searchParams, onChangeExistingDatePicker]);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [tableInfo, dataset, searchTable, handleChangePage, startDate, endDate]);

  const {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
  } = useGetBigQueryIncidentGroups({
    startDate,
    endDate,
    page,
    rowsPerPage,
    dataset: tableInfo?.dataset || tableInfoFromUrl?.dataset,
    table: tableInfo?.table || tableInfoFromUrl?.table,
    searchTable: searchTable || searchFromUrl,
    incidentGroupsTypes: dropdownsValue[INCIDENT_TYPE_FILTER],
    incidentGroupsAlert: dropdownsValue[TABLE_ALERT_FILTER],
    incidentGroupsStatuses: dropdownsValue[INCIDENT_STATUS_FILTER],
    incidentIdAndType,
  });

  useEffect(() => {
    handleChangeTitleTotalIncidents(incidentsTotal);
    handleChangeTotalIncidents(incidentsGroupPagination);
  }, [
    handleChangeTitleTotalIncidents,
    handleChangeTotalIncidents,
    incidentsGroupPagination,
    incidentsTotal,
  ]);

  const formattedData = useMemo(() => {
    if (!incidentGroups.length) {
      return {};
    }
    return formatIncidents(incidentGroups);
  }, [incidentGroups]);

  const emptyResultMessage = useMemo(() => {
    if (!incidentGroups.length && !isIncidentGroupsFetching) {
      if (tableInfoFromUrl?.dataset) {
        return `${EMPTY_SEARCH_MESSAGE} ${
          tableInfoFromUrl.table ? 'table' : 'dataset'
        }`;
      }
      return `${EMPTY_SEARCH_MESSAGE} ${
        dataset && !searchTable ? 'dataset' : 'table'
      }`;
    }

    return '';
  }, [
    dataset,
    incidentGroups.length,
    isIncidentGroupsFetching,
    searchTable,
    tableInfoFromUrl?.dataset,
    tableInfoFromUrl?.table,
  ]);

  useEffect(() => {
    if (tableFromURL === null) {
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setDataset(null);
    }
  }, [
    tableFromURL,
    setTableInfo,
    setSelectTable,
    setDataset,
    setSelectDataset,
  ]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchTable(null);
    }
  }, [searchFromUrl]);

  useEffect(() => {
    onChangeOpenBackdrop(isIncidentGroupsFetching);
  }, [isIncidentGroupsFetching, onChangeOpenBackdrop]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const onTableChange = useCallback(
    (value) => {
      setTableInfo(value);
      setSelectTable(value);
      setDataset(null);
      setSelectDataset(null);

      if (value !== null) {
        amplEvent(
          `${TAB_EVENT} selected table: ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onDatasetChange = useCallback(
    (value) => {
      setDataset(value);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(value);

      if (value !== null) {
        amplEvent(`${TAB_EVENT} selected dataset: ${value}`);
      }

      if (value === null) clearSearchParams();
    },
    [setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  const onTableSearch = useCallback(
    (value) => {
      setSearchTable(value);
      setDataset(null);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);

      if (value.length) {
        amplEvent(`${TAB_EVENT} search for: ${value}`);
      }

      if (!value) clearSearchParams();
    },
    [setSearchTable, setTableInfo, setSelectTable, setDataset, setSelectDataset]
  );

  useEffect(() => {
    if (onTableChange !== undefined) {
      setOnSelectTable({ onChange: onTableChange });
    }
  }, [onTableChange, setOnSelectTable]);

  useEffect(() => {
    if (onDatasetChange !== undefined) {
      setOnSelectDataset({ onChange: onDatasetChange });
    }
  }, [onDatasetChange, setOnSelectDataset]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  return (
    <>
      {(!incidentIdAndType.id || !incidentIdAndType.type) && (
        <>
          <Dropdowns
            existingDropdown={BigQueryTabDropdowns}
            onChangeDropdownsValue={handleChangeDropdownsValue}
          />

          <div className={classes.searchContainer}>
            <SourcesButton onClick={handleProjectTree} />
            <TableDatasetSearch
              optionLabel='Incidents for:'
              onTableChange={onTableChange}
              onDatasetChange={onDatasetChange}
              onSearch={onTableSearch}
              defaultDataset={tableInfoFromUrl?.dataset}
              defaultTable={tableInfoFromUrl?.table}
              defaultSearchValue={searchFromUrl}
              className='tableSearch'
            />
          </div>
        </>
      )}

      {emptyResultMessage.length > 0 && (
        <p className='txt-mainDark-16-500 text-center'>{emptyResultMessage}</p>
      )}

      {Object.keys(formattedData).map((date) => (
        <Row key={date}>
          <Col xs={12}>
            <p className='txt-mainDark-20-700 mb-3'>{date}</p>
          </Col>
          {formattedData[date].map((incident) => (
            <Col key={incident.groupKey} xs={12} className='mb-3'>
              <BQIncidentCard
                groupIncident={incident}
                startDate={startDate}
                endDate={endDate}
                incidentIdAndType={incidentIdAndType}
                incidentStatuses={dropdownsValue[INCIDENT_STATUS_FILTER]}
                tableInfo={tableInfoByIncident(incident)}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export { BQTab };
