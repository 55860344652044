import React from 'react';
import { useMemo, useDispatch, useEffect, useSelector } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { PositionLoader } from 'Components/components.js';
import { JobItem } from './components/components.js';
import { JOBS_LIMIT } from 'Pages/DataQuality/libs/constants/constants.js';
import { DATA_STATUS } from 'constants/constants.js';
import { getDataScanJobs } from 'slices/actions.js';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    minHeight: 184,
    padding: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
  },
  itemsContainer: {
    display: 'flex',
    gap: 16,
  },
  item: {
    flex: '1 1 0',
    paddingRight: 16,
    borderRight: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  statusContainer: {
    marginBottom: 16,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    borderRadius: 4,
    backgroundColor: theme.palette.secondary.light,
  },
  emptyContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const JobsBlock = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentScan, dataScanJobs, dataScanJobsStatus, dataScanRunStatus } =
    useSelector((state) => ({
      currentScan: state.dataScans.dataScan,
      dataScanJobs: state.dataScans.dataScanJobs,
      dataScanJobsStatus: state.dataScans.dataScanJobsStatus,
      dataScanRunStatus: state.dataScans.dataScanRunStatus,
    }));

  const isLoadingJobs = useMemo(() => {
    return dataScanJobsStatus === DATA_STATUS.pending;
  }, [dataScanJobsStatus]);

  useEffect(() => {
    if (
      (currentScan && dataScanJobsStatus === DATA_STATUS.idle) ||
      dataScanRunStatus === DATA_STATUS.fulfilled
    ) {
      dispatch(
        getDataScanJobs({
          location: currentScan.location,
          dataPlexId: currentScan.dataPlexId,
        })
      );
    }
  }, [currentScan, dataScanJobsStatus, dispatch, dataScanRunStatus]);

  const limitDataScanJobs = useMemo(() => {
    if (dataScanJobs?.length < JOBS_LIMIT) {
      const emptyData = new Array(JOBS_LIMIT - dataScanJobs?.length).fill({});

      return [...dataScanJobs, ...emptyData];
    }

    return dataScanJobs;
  }, [dataScanJobs]);

  return (
    <>
      <div className={classes.container}>
        <div className='txt-mainDark-16-700 mb-4'>Last 7 job results</div>

        {isLoadingJobs ? (
          <PositionLoader />
        ) : dataScanJobs.length ? (
          <div className={classes.itemsContainer}>
            {limitDataScanJobs.map((job) =>
              job.id ? (
                <JobItem job={job} key={job.id} />
              ) : (
                <div className={clsx(classes.item, 'txt-grey-13-500')}>
                  <div className={classes.emptyContainer}>&mdash;</div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className='txt-mainDark-13-500'>No data to display</div>
        )}
      </div>
    </>
  );
};

export { JobsBlock };
