const letterNumberHyphenInput = (input) => {
  const validRegex = /^[0-9a-z-]+$/;

  return input.match(validRegex);
};

const startWithLowercaseLetter = (input) => {
  const firstElement = Array.from(input)[0];

  return (
    firstElement === firstElement.toLowerCase() &&
    isNaN(+firstElement) &&
    firstElement.toLowerCase() !== firstElement.toUpperCase()
  );
};

const endWithLetterOrNumber = (input) => {
  const validRegex = /.*[0-9a-z]$/;

  return input.match(validRegex);
};

const numberInput = (input) => {
  const validRegex = /^\d+(\.\d{1,3})?$/;
  return input.match(validRegex);
};

export {
  letterNumberHyphenInput,
  numberInput,
  startWithLowercaseLetter,
  endWithLetterOrNumber,
};
