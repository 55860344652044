import React from 'react';
import { Paper, TableContainer, Table, TableBody, Box } from '@mui/material';
import {
  PositionLoader,
  TableHeadWithCheckbox,
} from 'Components/components.js';
import { useStyles } from 'Pages/Dictionary/Dictionary.styles.js';
import { DictionaryColumnDetails } from 'Pages/Dictionary/components/DictionaryColumn/components/components.js';

const ColumnTable = ({
  tableColumns = [],
  headCells = [],
  handleRequestSort = () => {},
  onMonitors = () => {},
  hasPadding = true,
  startDate,
  endDate,
  isLoadingData = false,
}) => {
  const classes = useStyles();

  return (
    <Box sx={{ height: '100%' }}>
      <Paper sx={{ borderRadius: 4.5, boxShadow: 'none', height: '100%' }}>
        <TableContainer sx={{ padding: hasPadding && 6 }}>
          <Table>
            <TableHeadWithCheckbox
              onRequestSort={handleRequestSort}
              headCells={headCells}
              selectedColumn={false}
            />
            <TableBody className={classes.tableBody}>
              {isLoadingData && (
                <div className='loaderContainer'>
                  <PositionLoader />
                </div>
              )}
              {tableColumns?.map((row) => {
                return (
                  <DictionaryColumnDetails
                    key={row.uuid}
                    columnInfo={row}
                    onMonitors={onMonitors}
                    headCells={headCells}
                    startDate={startDate}
                    endDate={endDate}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ColumnTable;
