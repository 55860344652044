import { SUGGESTED_CRITICAL_TIP } from 'constants/constants.js';

const tableNameId = 'DATASET';
const alertStatusId = 'ALERT_TYPE';
const scoreTableId = 'PRIORITY_SCORE';
const generalTypeId = 'TYPE';
const createdTableId = 'CREATION_TIME';
const modifiedTableId = 'LAST_MODIFIED_TIME';
const sizeTableId = 'NUM_BYTES';
const dependingTableId = 'dependingTables';
const descriptionId = 'description';

const TableNameHead = {
  id: tableNameId,
  label: 'Table name',
  sort: true,
  isVisible: true,
};

const AlertStatusHead = {
  id: alertStatusId,
  label: 'Status',
  sort: true,
  isVisible: true,
};

const ScoreTableHead = {
  id: scoreTableId,
  label: 'Score',
  sort: true,
  isVisible: false,
  tip: SUGGESTED_CRITICAL_TIP,
};

const TableTypeHead = {
  id: generalTypeId,
  label: 'Type',
  sort: true,
  isVisible: true,
};

const CreatedTablesHead = {
  id: createdTableId,
  label: 'Created date',
  sort: true,
  isVisible: true,
};

const ModifiedTableHead = {
  id: modifiedTableId,
  label: 'Last modified',
  sort: true,
  isVisible: true,
};

const SizeHead = {
  id: sizeTableId,
  label: 'Size',
  sort: true,
  isVisible: true,
};

const DependingTableHead = {
  id: dependingTableId,
  label: 'Depending tables',
  sort: false,
  isVisible: true,
};

const DescriptionHead = {
  id: descriptionId,
  label: 'Description',
  isVisible: true,
};

export {
  TableNameHead,
  AlertStatusHead,
  ScoreTableHead,
  TableTypeHead,
  CreatedTablesHead,
  ModifiedTableHead,
  SizeHead,
  DependingTableHead,
  DescriptionHead,
};
