import React from 'react';
import { makeStyles } from '@mui/styles';
import ImageStartPage from 'assets/img/icons/latestTableIcon.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    gap: 8,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
  },
  img: {
    width: 40,
    height: 40,
  },
}));

const EmptyData = ({ title = '', subtitle = '' }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <img className={classes.img} src={ImageStartPage} alt='icon' />
      <h1 className='txt-mainDark-20-700'>{title}</h1>
      <p className='txt-grey-13-500'>{subtitle}</p>
    </div>
  );
};

export { EmptyData };
