import axios from 'axios';

const ACK_DEADLINE_SECONDS = 60;
const MESSAGE_RETENTION_DURATION = 86400; // 24h

const gcpPost = (url, token, data, params) =>
  axios
    .post(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const gcpPut = (url, token, data, params) =>
  axios
    .put(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const gcpCreator = {
  async enableServices(projectNumber, token) {
    return gcpPost(
      `https://serviceusage.googleapis.com/v1/projects/${projectNumber}/services:batchEnable`,
      token,
      {
        serviceIds: ['pubsub.googleapis.com', 'iam.googleapis.com'],
      }
    );
  },

  async addRoleToMastheadSA(project, token) {
    return await this.addRoles(
      project,
      token,
      'serviceAccount:masthead-data@masthead-prod.iam.gserviceaccount.com',
      [
        'roles/pubsub.subscriber',
        `projects/${project}/roles/masthead_schema_reader`,
      ]
    );
  },

  async addRoleToRetroSA(project, token) {
    return await this.addRoles(
      project,
      token,
      'serviceAccount:retro-data@masthead-prod.iam.gserviceaccount.com',
      ['roles/logging.privateLogViewer']
    );
  },

  async addRolesToDefaultServiceAccount(project, token, projectNumber) {
    let serviceAccount = `serviceAccount:${projectNumber}@cloudservices.gserviceaccount.com`;
    const roles = ['roles/iam.serviceAccountAdmin', 'roles/logging.admin'];

    let iam = await this.readIAM(project, token);
    for (let i in iam.bindings) {
      let b = iam.bindings[i];
      if (roles.includes(b.role)) {
        if (!b.members.includes(serviceAccount)) {
          b.members = b.members.concat(serviceAccount);
        }
      }
    }

    return await this.writeIAM(project, token, iam);
  },

  async addRoles(project, token, serviceAccount, roles) {
    const iam = await this.readIAM(project, token);
    const bRoles = iam.bindings.map((b) => b.role);
    for (const role of roles) {
      if (!bRoles.includes(role)) {
        iam.bindings.push({
          role: role,
          members: [serviceAccount],
        });
      } else {
        const currentB = iam.bindings.find((b) => b.role === role);
        currentB.members = currentB.members.concat(serviceAccount);
      }
    }

    return await this.writeIAM(project, token, iam);
  },

  async readIAM(project, token) {
    return gcpPost(
      `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:getIamPolicy`,
      token,
      {
        options: {
          requestedPolicyVersion: 3,
        },
      }
    );
  },

  async writeIAM(
    project,
    token,
    policy,
    currentRequest = 1,
    countRequests = 20
  ) {
    try {
      return await gcpPost(
        `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:setIamPolicy`,
        token,
        {
          policy: policy,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'IAM exist';
      }
      if (currentRequest <= countRequests) {
        return this.writeIAM(project, token, policy, currentRequest + 1);
      } else {
        throw e;
      }
    }
  },

  async createRole(project, token) {
    try {
      return await gcpPost(
        `https://iam.googleapis.com/v1/projects/${project}/roles`,
        token,
        {
          roleId: 'masthead_schema_reader',
          role: {
            title: 'masthead_bq_schema_reader',
            description: 'Masthead table structure reader',
            includedPermissions: [
              'bigquery.datasets.get',
              'bigquery.tables.get',
              'bigquery.tables.list',
              'bigquery.routines.get',
              'bigquery.routines.list',
            ],
            stage: 'GA',
          },
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Role exist';
      }
      throw e;
    }
  },

  async createTopic(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/topics/masthead-topic`,
        token,
        {}
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Topic exist';
      }
      throw e;
    }
  },

  async createSink(project, token) {
    try {
      const sink = await gcpPost(
        `https://logging.googleapis.com/v2/projects/${project}/sinks`,
        token,
        {
          name: 'masthead-agent-sink',
          destination: `pubsub.googleapis.com/projects/${project}/topics/masthead-topic`,
          description: `Masthead Agent log sink`,
          filter: `protoPayload.methodName="google.cloud.bigquery.storage.v1.BigQueryWrite.AppendRows" OR "google.cloud.bigquery.v2.JobService.InsertJob" OR "google.cloud.bigquery.v2.TableService.InsertTable" OR "google.cloud.bigquery.v2.JobService.Query" resource.type ="bigquery_table" OR resource.type ="bigquery_dataset" OR resource.type ="bigquery_project"`,
        }
      );

      await this.addRoles(project, token, sink.writerIdentity, [
        'roles/pubsub.publisher',
      ]);

      return sink;
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Sink exist';
      }
      throw e;
    }
  },

  async createSubscription(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/subscriptions/masthead-agent-subscription`,
        token,
        {
          topic: `projects/${project}/topics/masthead-topic`,
          messageRetentionDuration: `${MESSAGE_RETENTION_DURATION}s`,
          ackDeadlineSeconds: ACK_DEADLINE_SECONDS,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Subscription exist';
      }
      throw e;
    }
  },

  async create(project, token, projectNumber) {
    const results = [];
    results.push(
      await this.enableServices(projectNumber, token),
      await this.createRole(project, token),
      await this.createTopic(project, token),
      await this.createSink(project, token),
      await this.createSubscription(project, token),
      await this.addRoleToMastheadSA(project, token),
      await this.addRoleToRetroSA(project, token)
    );

    return results;
  },
};

export { gcpCreator };
