import { useState, useEffect } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { handleCopyText } from 'utils/helpers/helpers.js';
import {
  Button,
  BUTTON_VARIANT,
  Typography,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

export const useQueryWidgetStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(3),
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,

    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: theme.palette.common.white,
  },

  copyBtn: {
    '&.MuiButton-root': {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },

  queryContainer: {
    maxHeight: '100%',
    borderRadius: 8,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    overflow: 'scroll',
  },

  lineContainer: {
    whiteSpace: 'nowrap',
    fontWeight: 400,
    '&:first-child span': {
      color: theme.palette.text.primary,
      backgroundColor: '#BEC5E7',
    },
  },

  lineNumber: (props) => ({
    width: `${props.maxDigits + 2}ch`,
    marginRight: theme.spacing(5),
    display: 'inline-block',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.secondary.light,
    userSelect: 'none',
  }),

  lineText: {
    display: 'inline',
    margin: 0,
    marginRight: 8,
    fontWeight: 400,
    fontFamily: 'monospace',
  },
}));

const QueryWidget = ({ query }) => {
  const classes = useQueryWidgetStyles();

  return (
    <>
      {query.length > 0 && (
        <section className={classes.container}>
          <div className={classes.header}>
            <Typography
              variant={TYPOGRAPHY_VARIANT.h3}
              component={TYPOGRAPHY_VARIANT.h3}
            >
              Query
            </Typography>

            <Button
              text='Copy query'
              startIcon={<CopyIcon />}
              onClick={() => handleCopyText(query)}
              variant={BUTTON_VARIANT.text}
              fullWidth={false}
              className={classes.copyBtn}
            />
          </div>

          <Query query={query} />
        </section>
      )}
    </>
  );
};

const Query = ({ query }) => {
  const [maxDigits, setMaxDigits] = useState(1);
  const classes = useQueryWidgetStyles({ maxDigits });

  useEffect(() => {
    const lines = query.split('\n');
    const maxLength = lines.length.toString().length;
    setMaxDigits(maxLength);
  }, [query]);

  return (
    <section className={classes.queryContainer}>
      {query.split('\n').map((line, index) => (
        <div key={index} className={classes.lineContainer}>
          <span className={classes.lineNumber}>{index + 1}</span>
          <pre className={clsx(classes.lineText, 'txt-mainDark-13-500')}>
            {line}
          </pre>
        </div>
      ))}
    </section>
  );
};

export { QueryWidget, Query };
