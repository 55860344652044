import React from 'react';
import {
  useCallback,
  useMemo,
  useState,
  useDispatch,
  useEffect,
  useSelector,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';
import { Button, BUTTON_VARIANT, Select } from 'Components/components.js';
import {
  DescriptionInput,
  DimensionSelect,
  SqlBlock,
  ThresholdInput,
} from 'Pages/DataQuality/components/components.js';
import {
  CONTROL_ELEMENTS_DATA,
  SQL,
  SQL_LABEL,
  SQL_TEXT_INFO,
} from 'Pages/DataQuality/libs/constants/constants.js';
import { getUniqueColumns } from 'slices/actions.js';

const useStyles = makeStyles((theme) => ({
  modalBodyContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    height: '100%',
    margin: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflow: 'scroll',
  },
  buttonContainer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));

const MAX_PERCENT = 100;

const SqlRowCheckModalBody = ({
  closeModal,
  addNewRule,
  table = '',
  dataset = '',
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [dimensionValue, setDimensionValue] = useState(null);
  const [thresholdValue, setThresholdValue] = useState(MAX_PERCENT.toString());
  const [columnName, setColumnName] = useState(null);
  const [description, setDescription] = useState('');
  const [sqlValue, setSqlValue] = useState('');

  const { columnSelect } = CONTROL_ELEMENTS_DATA;

  const { uniqueColumns } = useSelector((state) => ({
    uniqueColumns: state.columns.uniqueColumns,
  }));

  const isDisabledAddButton = useMemo(() => {
    return (
      !dimensionValue ||
      !thresholdValue.length ||
      !columnName ||
      !sqlValue.length
    );
  }, [dimensionValue, thresholdValue.length, columnName, sqlValue.length]);

  const newRule = useMemo(() => {
    return {
      id: uuid(),
      isNew: true,
      dimension: dimensionValue?.value || '',
      dimensionLabel: dimensionValue?.label || '',
      threshold: +thresholdValue / MAX_PERCENT,
      column: columnName?.value || '',
      description,
      typeLabel: SQL_LABEL,
      data: {
        type: SQL,
        sqlExpression: sqlValue,
      },
    };
  }, [
    columnName?.value,
    description,
    dimensionValue?.label,
    dimensionValue?.value,
    sqlValue,
    thresholdValue,
  ]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getUniqueColumns({
          dataset,
          table,
        })
      );
    }
  }, [dispatch, table, dataset]);

  const columnOptions = useMemo(() => {
    return uniqueColumns.map((column) => {
      return { label: column, value: column };
    });
  }, [uniqueColumns]);

  const handleChangeDimensionValue = useCallback((newValue) => {
    setDimensionValue(newValue);
  }, []);

  const handleChangeThresholdValue = useCallback((newValue) => {
    setThresholdValue(newValue);
  }, []);

  const handleChangeColumnName = useCallback((newValue) => {
    setColumnName(newValue);
  }, []);

  const handleChangeDescription = useCallback((newValue) => {
    setDescription(newValue);
  }, []);

  const handleChangeSql = useCallback((newValue) => {
    setSqlValue(newValue);
  }, []);

  const onAddButtonClick = useCallback(() => {
    addNewRule(newRule);
    closeModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newRule]);

  return (
    <div className={classes.modalBodyContainer}>
      <div className={classes.contentContainer}>
        <DimensionSelect
          value={dimensionValue}
          onChange={handleChangeDimensionValue}
        />

        <ThresholdInput
          onChange={handleChangeThresholdValue}
          defaultValue={thresholdValue}
        />

        <Select
          value={columnName || ''}
          onChange={handleChangeColumnName}
          options={columnOptions}
          label={columnSelect.label}
          id={columnSelect.id}
          isRequired={columnSelect.isRequired}
        />

        <DescriptionInput
          value={description}
          onChange={handleChangeDescription}
        />

        <SqlBlock
          textInfo={SQL_TEXT_INFO}
          value={sqlValue}
          onChangeSqlData={handleChangeSql}
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button
          text='Add'
          fullWidth={false}
          className='button'
          onClick={onAddButtonClick}
          isDisabled={isDisabledAddButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { SqlRowCheckModalBody };
