import {
  RANGE,
  REGEX,
  SET,
  SQL,
  STATISTIC,
} from '../constants/create-rule-types';

const createRuleParamsByType = (data) => {
  const responses = [];
  switch (data.type) {
    case RANGE:
      responses.push({ key: 'min', value: data.minValue || 'Empty' });
      responses.push({ key: 'max', value: data.maxValue || 'Empty' });
      break;
    case SET:
      responses.push({ key: 'set of', value: data.values.join(', ') });
      break;
    case STATISTIC:
      responses.push({ key: 'statistic', value: data.statistic });
      responses.push({ key: 'min', value: data.minValue || 'Empty' });
      responses.push({ key: 'max', value: data.maxValue || 'Empty' });
      break;
    case REGEX:
      responses.push({ key: 'regex', value: data.regex || 'Empty' });
      break;
    case SQL:
      responses.push({ key: 'sql', value: data.sqlExpression || 'Empty' });
      break;
    default:
      break;
  }

  return responses;
};

export { createRuleParamsByType };
