import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tableBody: {
    position: 'relative',
    '& .MuiTableCell-root': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& .tableCell': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
      overflowWrap: 'break-word',
      '&--grey': {
        color: theme.palette.common.secondaryDark,
      },
    },
  },
}));
