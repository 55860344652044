import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginBottom: 24,
    display: 'flex',
    alignItems: 'center',
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 24,
  },
}));
