import React from 'react';
import { TableRow, TableHead, TableCell, styled } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

const SchemaHead = ({ headCells }) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      padding: '0 0 12px 0',
      borderBottom: '1px solid #BEC5E7',
    },
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell key={headCell.id}>
            <div className='txt-grey-13-500'>{headCell.label}</div>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { SchemaHead };
