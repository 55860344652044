import React from 'react';
import { useMemo, useState, useCallback, useEffect } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import PipelineTable from './PipelineTable.js';
import {
  ReportDownloadModal,
  LoadingButton,
  StyledDropdown,
} from 'Components/components.js';
import { Dropdowns, Search } from '../components.js';
import { PIPELINE_TYPE } from 'utils/constants.js';
import { amplEvent } from 'service/services.js';
import { useModal, useMessages } from 'context/context.js';
import { fetcherPost } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { ALERT_SEVERITY } from 'constants/constants.js';
import {
  CostsDropdowns,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
  SELECTED_DESTINATION_TABLE,
  SEARCH_DESTINATION_TABLE,
} from 'Pages/Pipelines/libs/constants/constants.js';
import { ReactComponent as SendEmailIcon } from 'assets/img/icons/attach-email.svg';
import { ReactComponent as PipelineIcon } from 'assets/img/sidebarIcons/pipeline.svg';

const DOWNLOAD_EVENT = 'Costs Tab -> Download report ->';

const CostsTable = ({
  destinationTables,
  searchValues,
  onChangeSearchValues,
  startDate,
  endDate,
  isFetchingDestinationTables,
  pipelines,
  alertTypes,
  selectedPipelineType,
  pipelineSubtypes,
  isDeadEndTablesSelected,
  currentBqBillingData,
  filterPipelineSubtypeOptions,
  filterPipelineSubtypeSelected,
  onFilterPipelineSubtypeSelect,
  onChangeDropdownsValue,
  onChangeOpenBackdrop,
}) => {
  const globalClasses = useStyles();
  const [isLoadingDownloadFile, setIsLoadingDownloadFile] = useState(false);
  const { setModal } = useModal();
  const { setMessage } = useMessages();

  const isShowPipelineSubtypeFilter = useMemo(() => {
    return selectedPipelineType === PIPELINE_TYPE.BQ_DTS;
  }, [selectedPipelineType]);

  const onDownloadFile = () => {
    setModal(() => (
      <ReportDownloadModal
        onAgree={downloadFile}
        title='Get the Costs Insights Report'
        text={
          'It will take 5 minutes to generate your report. Once it is ready, we will send it to the email' +
          ' you use to log in to Masthead. You can also choose the dates for your report.'
        }
        agreeButtonText='Generate'
      />
    ));
  };

  const downloadFile = useCallback(
    (startDate, endDate) => {
      setIsLoadingDownloadFile(true);
      const newAlertTypes = [];
      if (
        alertTypes.length > 1 ||
        (alertTypes.length && alertTypes[0].value !== '')
      ) {
        newAlertTypes.push(...alertTypes.map((item) => item.value));
      }
      const postData = {
        table: searchValues[SELECTED_TABLE]?.table || '',
        dataset: searchValues[SELECTED_TABLE]?.dataset || '',
        search: searchValues[SEARCH_TABLE] || '',
        destinationTable: searchValues[SELECTED_DESTINATION_TABLE]?.table || '',
        destinationDataset:
          searchValues[SELECTED_DESTINATION_TABLE]?.dataset || '',
        destinationSearch: searchValues[SEARCH_DESTINATION_TABLE] || '',
        principalEmail: searchValues[SELECTED_EMAIL] || '',
        searchPrincipalEmail: searchValues[SEARCH_EMAIL] || '',
        jobTagNames: searchValues[SELECTED_TAGS] || [],
        tableLabelKey: searchValues[SELECTED_LABEL]?.key || '',
        tableLabelValue: searchValues[SELECTED_LABEL]?.value || '',
        searchTableLabel: searchValues[SEARCH_LABEL] || '',
        pipelineType: selectedPipelineType,
        pipelineSubtypes: pipelineSubtypes,
        alertTypes: newAlertTypes,
        onlyDeadEnd: isDeadEndTablesSelected,
      };
      const from = moment(convertLocalToUTCDate(startDate)).utc().format();
      const to = moment(convertLocalToUTCDate(endDate)).utc().format();
      fetcherPost(`/api/v1/costs/report?from=${from}&to=${to}`, postData)
        .then((data) => {
          if (!data?.success) {
            setMessage('Got an error while generating report');
            amplEvent(`${DOWNLOAD_EVENT} ${ALERT_SEVERITY.error}`);
          } else {
            setMessage(
              'Success. Once it is ready, we will send it to the email you use to log in to Masthead',
              ALERT_SEVERITY.success
            );
            amplEvent(`${DOWNLOAD_EVENT} ${ALERT_SEVERITY.success}`);
          }
        })
        .catch((e) => {
          setMessage('Got an error while downloading file');
          amplEvent(
            `${DOWNLOAD_EVENT} ${ALERT_SEVERITY.error}; err: ${e.message}`
          );
        })
        .finally(() => setIsLoadingDownloadFile(false));
    },
    [
      alertTypes,
      isDeadEndTablesSelected,
      pipelineSubtypes,
      searchValues,
      selectedPipelineType,
      setMessage,
    ]
  );

  useEffect(() => {
    onChangeOpenBackdrop(isFetchingDestinationTables);
  }, [isFetchingDestinationTables, onChangeOpenBackdrop]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Dropdowns
          pipelineStats={pipelines}
          onChangeDropdownsValue={onChangeDropdownsValue}
          existingDropdown={CostsDropdowns}
        />

        {isShowPipelineSubtypeFilter && (
          <StyledDropdown
            options={filterPipelineSubtypeOptions}
            defaultValue={filterPipelineSubtypeSelected}
            onSelect={onFilterPipelineSubtypeSelect}
            selectAllOption={filterPipelineSubtypeOptions[0]}
            Icon={PipelineIcon}
            beforeLabel='Show '
          />
        )}
      </div>

      <div className={globalClasses.searchContainer}>
        <Search
          searchValues={searchValues}
          onChangeSearchValues={onChangeSearchValues}
        />
        <LoadingButton
          startIcon={<SendEmailIcon />}
          children='Get Report'
          isLoading={isLoadingDownloadFile}
          onClick={onDownloadFile}
        />
      </div>

      <PipelineTable
        destinationTables={destinationTables}
        isFetchingDestinationTables={isFetchingDestinationTables}
        startDate={startDate}
        endDate={endDate}
        searchValues={searchValues}
        selectedPipelineType={selectedPipelineType}
        pipelineSubtypes={pipelineSubtypes}
        currentBqBillingData={currentBqBillingData}
      />
    </>
  );
};

export { CostsTable };
