import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { CollapsedChips } from 'Components/components.js';

const MAX_WIDTH = 280;
const VISIBLE_ITEMS = 3;

const PipelineTagsChips = ({
  pipelineTags = [],
  maxWidth = MAX_WIDTH,
  visibleItems = VISIBLE_ITEMS,
}) => {
  const data = useMemo(() => {
    return pipelineTags.map((tag) => tag.name);
  }, [pipelineTags]);

  return (
    <CollapsedChips
      data={data}
      maxWidth={maxWidth}
      visibleItems={visibleItems}
    />
  );
};

export { PipelineTagsChips };
