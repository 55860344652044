import { getImageByXMLSvg } from 'utils/helpers/helpers.js';
import { PIPELINE_TYPE } from 'utils/constants.js';

const getSvgCustomImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0833 3.33329C11.7612 3.33329 11.5 3.59446 11.5 3.91663C11.5 4.23879 11.7612 4.49996 12.0833 4.49996C12.4055 4.49996 12.6667 4.23879 12.6667 3.91663C12.6667 3.59446 12.4055 3.33329 12.0833 3.33329ZM13.8333 3.91663C13.8333 4.88312 13.0498 5.66663 12.0833 5.66663C11.2638 5.66663 10.5759 5.10333 10.3856 4.3428C10.3751 4.34336 10.3645 4.34365 10.3539 4.34365H9.18725C8.86508 4.34365 8.60392 4.60482 8.60392 4.92698V7.99995H10.3539C10.3801 7.99995 10.4059 8.00167 10.4311 8.00502C10.6699 7.32268 11.3195 6.83329 12.0833 6.83329C13.0498 6.83329 13.8333 7.61679 13.8333 8.58329C13.8333 9.54979 13.0498 10.3333 12.0833 10.3333C11.3195 10.3333 10.6699 9.8439 10.4311 9.16155C10.4059 9.16489 10.3801 9.16662 10.3539 9.16662H8.6005L8.59015 10.9269C8.58449 11.8894 7.80266 12.6666 6.84018 12.6666H5.66667C5.6337 12.6666 5.60137 12.6639 5.5699 12.6586C5.33193 13.3425 4.68162 13.8333 3.91667 13.8333C2.95017 13.8333 2.16667 13.0498 2.16667 12.0833C2.16667 11.1168 2.95017 10.3333 3.91667 10.3333C4.68161 10.3333 5.33192 10.8241 5.56989 11.5079C5.60137 11.5027 5.6337 11.5 5.66667 11.5H6.84018C7.16101 11.5 7.42162 11.2409 7.4235 10.92L7.43725 8.58156V4.92698C7.43725 3.96048 8.22075 3.17698 9.18725 3.17698H10.3539C10.4006 3.17698 10.446 3.18247 10.4896 3.19284C10.7649 2.5875 11.375 2.16663 12.0833 2.16663C13.0498 2.16663 13.8333 2.95013 13.8333 3.91663ZM3.33334 12.0833C3.33334 11.7611 3.59451 11.5 3.91667 11.5C4.23884 11.5 4.50001 11.7611 4.50001 12.0833C4.50001 12.4055 4.23884 12.6666 3.91667 12.6666C3.59451 12.6666 3.33334 12.4055 3.33334 12.0833ZM11.5 8.58329C11.5 8.26113 11.7612 7.99996 12.0833 7.99996C12.4055 7.99996 12.6667 8.26113 12.6667 8.58329C12.6667 8.90546 12.4055 9.16663 12.0833 9.16663C11.7612 9.16663 11.5 8.90546 11.5 8.58329Z" fill="${color}"/>
    </svg>
  `;
};

const getSvgDBTImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.4901 2.4991C13.7721 2.77034 13.9566 3.12839 14 3.51899C14 3.68173 13.9566 3.79024 13.8589 3.99639C13.7613 4.20253 12.557 6.28572 12.1989 6.86076C11.9928 7.19711 11.8843 7.59856 11.8843 7.98915C11.8843 8.3906 11.9928 8.7812 12.1989 9.11755C12.557 9.69259 13.7613 11.7866 13.8589 11.9928C13.9566 12.1989 14 12.2966 14 12.4593C13.9566 12.8499 13.783 13.208 13.5009 13.4684C13.2297 13.7505 12.8716 13.9349 12.4919 13.9675C12.3291 13.9675 12.2206 13.9241 12.0253 13.8264C11.83 13.7287 9.70344 12.557 9.12839 12.1989C9.08499 12.1772 9.04159 12.1447 8.98735 12.123L6.14467 10.4412C6.20976 10.9837 6.44847 11.5045 6.83905 11.8843C6.91501 11.9602 6.99096 12.0253 7.07776 12.0904C7.01265 12.123 6.93671 12.1555 6.87161 12.1989C6.29656 12.557 4.20253 13.7613 3.99639 13.8589C3.79024 13.9566 3.69259 14 3.51899 14C3.12839 13.9566 2.77034 13.783 2.50995 13.5009C2.22785 13.2297 2.0434 12.8716 2 12.481C2.01085 12.3183 2.05425 12.1555 2.14105 12.0145C2.2387 11.8083 3.44304 9.71428 3.80108 9.13924C4.00724 8.80289 4.11573 8.41229 4.11573 8.01085C4.11573 7.6094 4.00724 7.2188 3.80108 6.88245C3.44304 6.28572 2.22785 4.19168 2.14105 3.98553C2.05425 3.84448 2.01085 3.68173 2 3.51899C2.0434 3.12839 2.217 2.77034 2.4991 2.4991C2.77034 2.217 3.12839 2.0434 3.51899 2C3.68173 2.01085 3.84448 2.05425 3.99639 2.14105C4.16999 2.217 5.69981 3.10669 6.51356 3.58409L6.69801 3.69259C6.76311 3.73599 6.81736 3.76853 6.86076 3.79024L6.94756 3.84448L9.84448 5.55877C9.77939 4.90777 9.44304 4.31103 8.92224 3.90959C8.98735 3.87704 9.06329 3.84448 9.12839 3.80108C9.70344 3.44304 11.7975 2.22785 12.0036 2.14105C12.1447 2.05425 12.3074 2.01085 12.481 2C12.8608 2.0434 13.2188 2.217 13.4901 2.4991ZM8.15189 8.77035L8.77035 8.15189C8.85715 8.06509 8.85715 7.93491 8.77035 7.84811L8.15189 7.22965C8.06509 7.14285 7.93491 7.14285 7.84811 7.22965L7.22965 7.84811C7.14285 7.93491 7.14285 8.06509 7.22965 8.15189L7.84811 8.77035C7.92405 8.84629 8.06509 8.84629 8.15189 8.77035Z" fill="${color}"/>
    </svg>
  `;
};

const getSvgFivetranImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.95621 8H11.3562C11.4656 8 11.575 7.9125 11.575 7.78125C11.575 7.75938 11.575 7.71563 11.5531 7.69375L9.14683 1.13125C9.10308 1.06563 9.03746 1 8.94996 1H7.54996C7.44058 1 7.33121 1.0875 7.33121 1.21875C7.33121 1.24062 7.33121 1.2625 7.35308 1.28437L9.78121 7.89062C9.80308 7.95625 9.86871 8 9.95621 8Z" fill="${color}"/>
      <path d="M9.7156 15H11.1156C11.225 15 11.3344 14.9125 11.3344 14.7812C11.3344 14.7594 11.3344 14.7375 11.3125 14.7156L6.32498 1.13125C6.3031 1.06563 6.2156 1 6.1281 1H4.7281C4.61873 1 4.50935 1.0875 4.50935 1.21875C4.50935 1.24062 4.50935 1.2625 4.53123 1.28437L9.51873 14.8688C9.5406 14.9563 9.6281 15 9.7156 15Z" fill="${color}"/>
      <path d="M6.93748 15H8.33748C8.44685 15 8.53435 14.9125 8.55623 14.8031C8.55623 14.7812 8.55623 14.7375 8.53435 14.7156L6.10623 8.13125C6.08435 8.04375 5.99685 8 5.90935 8H4.48748C4.3781 8 4.2906 8.10938 4.2906 8.21875C4.2906 8.24062 4.2906 8.2625 4.31248 8.28437L6.71873 14.8688C6.78435 14.9563 6.84998 15 6.93748 15Z" fill="${color}"/>
      <path d="M10.1312 1.28437L11.2687 4.36875C11.2906 4.45625 11.3781 4.5 11.4656 4.5H12.8438C12.9531 4.5 13.0625 4.4125 13.0625 4.28125C13.0625 4.25938 13.0625 4.2375 13.0406 4.21563L11.9031 1.13125C11.8813 1.06563 11.8156 1 11.7281 1H10.3281C10.2188 1 10.1094 1.0875 10.1094 1.21875C10.1094 1.24062 10.1094 1.2625 10.1312 1.28437Z" fill="${color}"/>
      <path d="M4.35625 15H5.75625C5.86563 15 5.975 14.9125 5.975 14.7812C5.975 14.7594 5.975 14.7375 5.95312 14.7156L4.81562 11.6312C4.79375 11.5437 4.70625 11.5 4.61875 11.5H3.21875C3.10938 11.5 3 11.5875 3 11.7188C3 11.7406 3 11.7625 3.02188 11.7844L4.15938 14.8688C4.18125 14.9563 4.26875 15 4.35625 15Z" fill="${color}"/>
    </svg>
  `;
};

const getSvgDataformImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11687 14C11.4163 14 14.0909 11.3137 14.0909 8C14.0909 4.68629 11.4163 2 8.11687 2H1V8V14H3.72727V12.3636H4.81818V9.63636H3.18182V6.90909H4.27273V4.18182H7H9.72727V6.90909H7H5.90909V9.63636H8.63636V12.3636H6.45455V14H8.11687Z" fill="${color}"/>
    </svg>
  `;
};

const getSvgDTSImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 7.83692L12.0421 10.8021L11.281 10.0338L12.9467 8.37538H7.4759V7.29846H12.9467L11.281 5.63282L12.0421 4.87179L15 7.83692Z" fill="${color}"/>
      <path d="M7.84205 2L8.05744 2.21538V2.89026L7.84205 3.10564C7.09915 3.13979 6.38846 3.41893 5.82089 3.89948C5.25333 4.38003 4.86081 5.03496 4.70462 5.76205L4.61128 6.12821L4.23795 6.1641C3.67731 6.19661 3.14816 6.43397 2.75106 6.83106C2.35397 7.22816 2.11661 7.75731 2.0841 8.31795C2.07699 8.41594 2.07699 8.51432 2.0841 8.61231L1.20103 9.35179C1.06897 9.01306 1.00081 8.65279 1 8.28923C1.02771 7.5268 1.31796 6.79736 1.82165 6.22433C2.32534 5.65131 3.01153 5.26989 3.7641 5.14462C4.02654 4.25806 4.56195 3.47689 5.29412 2.91229C6.02629 2.34769 6.91791 2.02845 7.84205 2Z" fill="${color}"/>
      <path d="M9.45026 11.1108H3.97231L5.63795 12.7764L4.87692 13.5374L1.91179 10.5723L4.87692 7.60718L5.63795 8.37538L3.97231 10.0338H9.45026V11.1108Z" fill="${color}"/>
      <path d="M11.3313 3.96L10.5415 4.67795C10.271 4.196 9.88002 3.79249 9.40687 3.50683C8.93371 3.22118 8.39455 3.06315 7.84205 3.0482V2C8.5404 2.01255 9.22472 2.19818 9.83368 2.54025C10.4426 2.88233 10.9572 3.37015 11.3313 3.96Z" fill="${color}"/>
    </svg>
  `;
};

const getSvgLookerImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_422_136)">
      <path d="M7.94767 1.23734e-06C7.69426 0.000111778 7.44564 0.0691067 7.22841 0.199606C7.01118 0.330106 6.83351 0.517201 6.71441 0.74088C6.5953 0.96456 6.53924 1.21641 6.55222 1.46949C6.5652 1.72257 6.64673 1.96736 6.78809 2.17769L7.38514 1.58147C7.36541 1.522 7.35546 1.45972 7.35567 1.39705C7.35567 1.28047 7.39024 1.1665 7.45501 1.06956C7.51979 0.972621 7.61185 0.897067 7.71956 0.852451C7.82727 0.807835 7.9458 0.796162 8.06014 0.818907C8.17449 0.841652 8.27953 0.897793 8.36196 0.980233C8.4444 1.06267 8.50055 1.16771 8.52329 1.28205C8.54604 1.3964 8.53436 1.51492 8.48975 1.62264C8.44513 1.73035 8.36958 1.82241 8.27264 1.88718C8.1757 1.95196 8.06173 1.98653 7.94514 1.98653C7.88276 1.98666 7.82077 1.97671 7.76156 1.95705L7.16535 2.55326C7.3463 2.67599 7.55353 2.75453 7.77036 2.78257C7.98719 2.81061 8.20758 2.78736 8.41379 2.71471C8.62 2.64205 8.80629 2.52201 8.95765 2.36424C9.10901 2.20647 9.22124 2.01537 9.28529 1.80633C9.34934 1.59728 9.36343 1.37612 9.32643 1.16063C9.28943 0.94515 9.20237 0.741357 9.07226 0.565649C8.94215 0.389942 8.77261 0.247227 8.57729 0.148983C8.38196 0.0507391 8.16631 -0.000289727 7.94767 1.23734e-06Z" fill="${color}"/>
      <path d="M7.39189 4.07157C7.3928 3.59964 7.23997 3.14026 6.95652 2.76294L6.18178 3.53683C6.2996 3.75232 6.34267 4.00077 6.30428 4.24333C6.26588 4.4859 6.14818 4.7089 5.96957 4.87747L6.39063 5.90652C6.69765 5.70879 6.95018 5.43721 7.1251 5.11664C7.30002 4.79608 7.39175 4.43676 7.39189 4.07157Z" fill="${color}"/>
      <path d="M5.22683 5.1823H5.20662C5.03339 5.18232 4.86256 5.14185 4.70775 5.06412C4.55294 4.98639 4.41844 4.87356 4.31498 4.73462C4.21152 4.59568 4.14196 4.43449 4.11186 4.2639C4.08175 4.0933 4.09194 3.91804 4.14159 3.75208C4.19125 3.58612 4.27901 3.43407 4.39786 3.30805C4.51672 3.18203 4.66338 3.08553 4.82615 3.02625C4.98892 2.96697 5.16329 2.94656 5.33535 2.96664C5.50741 2.98671 5.6724 3.04672 5.81715 3.14188L6.5843 2.37472C6.3128 2.15461 5.99243 2.00286 5.65012 1.93222C5.30781 1.86158 4.95355 1.87412 4.61709 1.96877C4.28062 2.06343 3.97179 2.23744 3.71651 2.47619C3.46124 2.71495 3.26699 3.01147 3.15007 3.34086C3.03315 3.67025 2.99698 4.02288 3.0446 4.36915C3.09221 4.71541 3.22222 5.0452 3.42371 5.3308C3.62519 5.6164 3.89227 5.84949 4.20251 6.01048C4.51275 6.17147 4.8571 6.25566 5.20662 6.25599C5.3548 6.25606 5.50261 6.24139 5.64788 6.2122L5.22683 5.1823Z" fill="${color}"/>
      <path d="M7.97715 6.07244C7.49227 6.07195 7.00994 6.14259 6.54557 6.28212L7.15946 7.78191C7.42669 7.71569 7.701 7.68232 7.9763 7.68254C8.74992 7.68277 9.49952 7.95121 10.0974 8.44214C10.6953 8.93307 11.1045 9.61612 11.2552 10.3749C11.406 11.1337 11.289 11.9213 10.9242 12.6035C10.5594 13.2857 9.9693 13.8203 9.25453 14.1162C8.53976 14.4121 7.74449 14.4511 7.00421 14.2264C6.26394 14.0018 5.62446 13.5274 5.19472 12.8841C4.76498 12.2408 4.57158 11.4685 4.64745 10.6986C4.72333 9.92868 5.06379 9.20892 5.61083 8.66191C5.84621 8.42726 6.11493 8.22863 6.4083 8.07244L5.80115 6.57686C4.70792 7.11226 3.8499 8.03117 3.39058 9.15848C2.93126 10.2858 2.90277 11.5427 3.31052 12.6897C3.71827 13.8366 4.53377 14.7935 5.6016 15.3779C6.66944 15.9623 7.91497 16.1334 9.10083 15.8585C10.2867 15.5837 11.33 14.8822 12.0319 13.8877C12.7338 12.8931 13.0453 11.6751 12.907 10.4657C12.7686 9.25628 12.1901 8.14005 11.2818 7.3297C10.3734 6.51935 9.19865 6.07154 7.98136 6.07159L7.97715 6.07244Z" fill="${color}"/>
      </g>
      <defs>
      <clipPath id="clip0_422_136">
      <rect width="9.93684" height="16" fill="white" transform="translate(3.0222)"/>
      </clipPath>
      </defs>
    </svg>
  `;
};

const getSvgDomoImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 2C15 1.44772 14.5523 1 14 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2Z" fill="${color}"/>
      <path d="M3.63424 9.23987C3.85061 8.99753 4.00471 8.69846 4.07162 8.36776C4.24425 9.22182 4.99898 9.86483 5.90394 9.86483C6.80899 9.86483 7.56381 9.22167 7.73633 8.36746V9.86483H8.35588V7.629L9.60582 8.87888L10.8503 7.629V9.86482H11.4806V8.36745C11.6532 9.22166 12.408 9.86482 13.313 9.86482C14.0567 9.86482 14.6989 9.43057 15 8.80183V7.18884C14.6989 6.5601 14.0567 6.12587 13.313 6.12587C12.408 6.12587 11.6532 6.76901 11.4806 7.62324V6.12587L9.60582 7.99531L7.73633 6.12587V7.62324C7.56381 6.76903 6.80899 6.12587 5.90394 6.12587C4.999 6.12587 4.24427 6.76888 4.07162 7.62293C4.00473 7.29223 3.85065 6.99317 3.63427 6.75083C3.2919 6.36737 2.79387 6.12587 2.23939 6.12587H1V9.86482H2.23939C2.79385 9.86482 3.29187 9.62332 3.63424 9.23987ZM13.313 6.74789C14.0019 6.74789 14.5605 7.30639 14.5605 7.99533C14.5605 8.68429 14.0019 9.24278 13.313 9.24278C12.6241 9.24278 12.0656 8.68429 12.0656 7.99533C12.0656 7.30641 12.6241 6.74789 13.313 6.74789ZM5.90394 6.74789C6.59288 6.74789 7.15141 7.30639 7.15141 7.99533C7.15141 8.68429 6.5929 9.24278 5.90394 9.24278C5.215 9.24278 4.65649 8.68429 4.65649 7.99533C4.65649 7.30641 5.21498 6.74789 5.90394 6.74789ZM1.61955 6.75085H2.32102C2.97187 6.79295 3.48682 7.33386 3.48682 7.99535C3.48682 8.657 2.97162 9.19802 2.32055 9.23987H1.61955V6.75085Z" fill="white"/>
    </svg>
  `;
};

const getSvgTableauImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_422_139)">
      <path d="M7.56219 10.7074H8.43781V8.3102H10.6401V7.45787H8.43781V5.0607H7.56219V7.45787H5.3864V8.3102H7.56219V10.7074Z" fill="${color}"/>
      <path d="M3.50249 14.17H4.24544V12.0391H6.20896V11.3733H4.24544V9.2158H3.50249V11.3733H1.53897V12.0391H3.50249V14.17Z" fill="${color}"/>
      <path d="M11.7546 6.47237H12.4975V4.34155H14.4876V3.7023H12.4975V1.54485H11.7546V3.7023H9.79105V4.34155H11.7546V6.47237Z" fill="${color}"/>
      <path d="M7.69486 15.848H8.33168V14.3298H9.68491V13.7704H8.33168V12.2522H7.69486V13.7704H6.36816V14.3298H7.69486V15.848Z" fill="${color}"/>
      <path d="M3.52902 6.47237H4.21891V4.31491H6.20896V3.7023H4.21891V1.54485H3.52902V3.7023H1.53897V4.31491H3.52902V6.47237Z" fill="${color}"/>
      <path d="M14.01 9.6686H14.6468V8.17702H16V7.59105H14.6468V6.09947H14.01V7.59105H12.6833V8.17702H14.01V9.6686Z" fill="${color}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.7546 14.17H12.4975V12.0391H14.4876V11.3733H12.4975V9.2158H11.7546V11.3733H9.79105V12.0391H11.7546V14.17Z" fill="${color}"/>
      <path d="M9.57877 1.91774V1.46494H8.25207V0H7.77446V1.46494H6.44776V1.91774H7.77446V3.38268H8.25207V1.91774H9.57877ZM1.3267 9.56206H1.80431V8.09712H3.13101V7.64432H1.80431V6.20602H1.3267V7.64432H0V8.12376L1.3267 8.09712V9.56206Z" fill="${color}"/>
      </g>
      <defs>
      <clipPath id="clip0_422_139">
      <rect width="16" height="16" rx="2" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  `;
};

const getSvgDataddoImageContent = (color) => {
  return `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M2.02513 2.02513C1 3.05025 1 4.70017 1 8C1 11.2998 1 12.9497 2.02513 13.9749C3.05025 15 4.70017 15 8 15C11.2998 15 12.9497 15 13.9749 13.9749C15 12.9497 15 11.2998 15 8C15 4.70017 15 3.05025 13.9749 2.02513C12.9497 1 11.2998 1 8 1C4.70017 1 3.05025 1 2.02513 2.02513ZM8.83999 5.27083C8.83999 6.08314 8.1818 6.74165 7.36996 6.74165C7.34024 6.74165 7.31074 6.74077 7.28146 6.73903L6.82925 8.21628C7.17121 8.38529 7.46053 8.64469 7.666 8.9633L9.68471 8.11092C9.68155 8.07462 9.67993 8.03784 9.67993 8.00071C9.67993 7.30444 10.2441 6.74001 10.94 6.74001C11.6359 6.74001 12.2 7.30444 12.2 8.00071C12.2 8.69702 11.6359 9.26145 10.94 9.26145C10.5309 9.26145 10.1673 9.06634 9.93721 8.76419L7.9417 9.60673C7.97978 9.76491 7.99996 9.93013 7.99996 10.1C7.99996 11.2598 7.05975 12.2 5.89995 12.2C4.74016 12.2 3.79995 11.2598 3.79995 10.1C3.79995 8.94023 4.74016 8 5.89995 8C5.98751 8 6.07382 8.00536 6.15856 8.01577L6.61283 6.53182C6.18568 6.2745 5.89995 5.80605 5.89995 5.27083C5.89995 4.45851 6.5581 3.8 7.36996 3.8C8.1818 3.8 8.83999 4.45851 8.83999 5.27083Z" fill="${color}"/>
    </svg>
  `;
};

const getPipelineTypeImage = (pipelineType, nodeImageColor) => {
  const getImageContent = () => {
    switch (pipelineType) {
      case PIPELINE_TYPE.CUSTOM:
        return getSvgCustomImageContent(nodeImageColor);
      case PIPELINE_TYPE.DBT:
        return getSvgDBTImageContent(nodeImageColor);
      case PIPELINE_TYPE.FIVETRAN:
        return getSvgFivetranImageContent(nodeImageColor);
      case PIPELINE_TYPE.DATAFORM:
        return getSvgDataformImageContent(nodeImageColor);
      case PIPELINE_TYPE.BQ_DTS:
        return getSvgDTSImageContent(nodeImageColor);
      case PIPELINE_TYPE.LOOKER:
        return getSvgLookerImageContent(nodeImageColor);
      case PIPELINE_TYPE.DOMO:
        return getSvgDomoImageContent(nodeImageColor);
      case PIPELINE_TYPE.TABLEAU:
        return getSvgTableauImageContent(nodeImageColor);
      case PIPELINE_TYPE.DATADDO:
        return getSvgDataddoImageContent(nodeImageColor);
      default:
        return getSvgCustomImageContent(nodeImageColor);
    }
  };

  const datasetXmlImage = getImageContent();

  return getImageByXMLSvg(datasetXmlImage);
};

export { getPipelineTypeImage };
