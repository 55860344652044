import { createSlice } from '@reduxjs/toolkit';
import { SLICE_NAME } from 'constants/constants.js';
import {
  getProjectTreeDatasets,
  getProjectTreeTables,
  selectProjectTreeTable,
} from './actions.js';
import {
  treeDataPrepare,
  treeDataAddTables,
  treeDataSelectTable,
} from './utils.js';

const initialState = {
  treeData: [],
};

export const projectTreeSlice = createSlice({
  name: SLICE_NAME.projectTree,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjectTreeDatasets.fulfilled, (state, action) => {
      state.treeData = treeDataPrepare(action.payload.values);
    });

    builder.addCase(getProjectTreeTables.fulfilled, (state, action) => {
      state.treeData = treeDataAddTables(
        state.treeData,
        action.meta.arg.dataset,
        action.meta.arg.tableType,
        action.payload.values,
        action.meta.arg.limit,
        action.meta.arg.selectTable
      );
    });

    builder.addCase(selectProjectTreeTable, (state, action) => {
      state.treeData = treeDataSelectTable(
        state.treeData,
        action.payload.selectTable
      );
    });
  },
});

export default projectTreeSlice.reducer;
