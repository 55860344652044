import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Input,
  IconButton,
  Button,
  BUTTON_COLOR,
} from 'Components/components.js';
import { ReactComponent as DeleteInputIcon } from 'assets/img/icons/trash.svg';
import { ReactComponent as AddInputIcon } from 'assets/img/icons/plus-icon.svg';

const useStyles = makeStyles((theme) => ({
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    '& .deleteIcon': {
      height: 54,
      alignSelf: 'end',
    },
  },
}));

const DynamicInputs = ({
  inputFields = [{ id: '', value: '' }],
  handleChangeInput,
  handleRemoveFields,
  handleAddFields,
  dynamicInputLabel = '',
}) => {
  const classes = useStyles();

  return (
    <>
      {inputFields.map((inputField, index) => {
        const isDisabledRemoveButton = inputFields.length === 1;
        const label = `${dynamicInputLabel} ${index + 1}`;
        const showedAddFieldsButton = inputFields.length - 1 === index;

        return (
          <div key={inputField.id}>
            <div className={classes.inputContainer}>
              <Input
                id={inputField.id}
                value={inputField.value}
                onChange={(event) => handleChangeInput(inputField.id, event)}
                label={label}
                withEventData={true}
              />
              <IconButton
                onClick={() => handleRemoveFields(inputField.id)}
                icon={<DeleteInputIcon />}
                isDisabled={isDisabledRemoveButton}
                className='deleteIcon'
              />
            </div>

            {showedAddFieldsButton && (
              <Button
                onClick={handleAddFields}
                color={BUTTON_COLOR.secondary}
                text='Add value'
                fullWidth={false}
                startIcon={<AddInputIcon />}
                className='mt-3'
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export { DynamicInputs };
