import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: 76,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
    padding: '16px 24px',
    background: '#181818',
    borderRadius: 4,
    '& .whiteText': {
      color: theme.palette.common.white,
      zIndex: 1,
    },
  },
  btnContainer: {
    '& .btn-link': {
      position: 'relative',
      marginRight: 16,
      padding: 0,
      zIndex: 1,
    },
    '& .btn-blue': {
      position: 'relative',
      padding: '11px 22px',
      color: theme.palette.common.mainDarkText,
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
      '&:hover': {
        color: theme.palette.common.mainDarkText,
        backgroundColor: theme.palette.common.white,
      },
      '&:focus': {
        color: theme.palette.common.mainDarkText,
        backgroundColor: theme.palette.common.white,
      },
    },
  },
  blurContainer1: {
    position: 'absolute',
    top: -64,
    left: 0,
    width: 528,
    height: 182,
    background: '#F43CB6',
    filter: 'blur(150px)',
    mixBlendMode: 'lighten',
  },
  blurContainer2: {
    position: 'absolute',
    top: -200,
    left: -200,
    width: 288,
    height: 615,
    background: '#1E99FE',
    mixBlendMode: 'overlay',
    filter: 'blur(150px)',
    transform: 'rotate(-90deg)',
  },
  blurContainer3: {
    position: 'absolute',
    top: -90,
    left: 244,
    width: 287,
    height: 287,
    background: '#7BC3FD',
    mixBlendMode: 'overlay',
    filter: 'blur(152.449px)',
    transform: 'rotate(-90deg)',
  },
  blurContainer4: {
    position: 'absolute',
    top: -230,
    right: 30,
    width: 288,
    height: 592,
    background: '#1E99FE',
    mixBlendMode: 'overlay',
    filter: 'blur(37.5px)',
    transform: 'rotate(-90deg)',
  },
  blurContainer5: {
    position: 'absolute',
    top: -64,
    right: 30,
    width: 98,
    height: 202,
    background: '#1E99FE',
    mixBlendMode: 'overlay',
    filter: 'blur(12.7604px)',
    transform: 'rotate(-90deg)',
  },
}));
