import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useGetVolumeData = ({
  table,
  dataset,
  startDate,
  endDate,
  isEnabled = true,
}) =>
  useQuery(
    [QUERY_TYPES.volume, table, dataset, startDate, endDate],
    ({ queryKey }) => {
      const [url, table, dataset, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
        from: moment(convertLocalToUTCDate(startDate)).utc().format(),
        to: moment(convertLocalToUTCDate(endDate)).utc().format(),
      });
    },
    { enabled: Boolean(table && dataset && isEnabled), cacheTime: 0 }
  );

const useGetVolumeRawData = ({
  dataset,
  table,
  startDate,
  endDate,
  isEnabled,
}) =>
  useQuery(
    [QUERY_TYPES.metrics, dataset, table, startDate, endDate],
    ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        from: moment(convertLocalToUTCDate(startDate)).utc().format(),
        to: moment(convertLocalToUTCDate(endDate)).utc().format(),
      });
    },
    { enabled: Boolean(dataset && table && isEnabled), cacheTime: 0 }
  );

const useGetVolumeThreshold = ({
  dataset,
  table,
  startDate,
  endDate,
  isEnabled = true,
}) => {
  const { isFetching: isFetchingVolumeThreshold, data } = useQuery(
    [QUERY_TYPES.volumeThreshold, dataset, table, startDate, endDate],
    ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        from: moment(convertLocalToUTCDate(startDate)).utc().format(),
        to: moment(convertLocalToUTCDate(endDate)).utc().format(),
      });
    },
    { enabled: Boolean(dataset && table && isEnabled) }
  );

  const metricVolumeThreshold = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { metricVolumeThreshold, isFetchingVolumeThreshold };
};

export { useGetVolumeData, useGetVolumeRawData, useGetVolumeThreshold };
