import { Typography as MuiTypography } from '@mui/material';

const TYPOGRAPHY_VARIANT = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body1: 'body1',
  body2: 'body2',
};

const TYPOGRAPHY_COLOR = {
  primary: 'primary',
  // secondary: 'secondary', TODO change default color to white
  textPrimary: 'textPrimary',
  textSecondary: 'textSecondary',
  error: 'error',
};

const Typography = ({
  children = null,
  variant = TYPOGRAPHY_VARIANT.body1,
  color = TYPOGRAPHY_COLOR.textPrimary,
  component = 'p',
  className = '',
  sx = {},
}) => {
  return (
    <MuiTypography
      variant={variant}
      color={color}
      className={className}
      component={component}
      sx={sx}
    >
      {children}
    </MuiTypography>
  );
};

export { Typography, TYPOGRAPHY_VARIANT, TYPOGRAPHY_COLOR };
