import {
  SEARCH_DESTINATION_TABLE,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_DESTINATION_TABLE,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from '../constants/constants.js';

const generateEmptyResultMessage = (searchValues) => {
  const messages = {
    [SELECTED_EMAIL]: 'email',
    [SEARCH_EMAIL]: 'email',
    [SELECTED_TABLE]: 'table',
    [SEARCH_TABLE]: 'table',
    [SELECTED_DESTINATION_TABLE]: 'destination table',
    [SEARCH_DESTINATION_TABLE]: 'destination table',
    [SELECTED_TAGS]: 'tag',
    [SELECTED_LABEL]: 'label',
    [SEARCH_LABEL]: 'label',
  };

  for (const [key, value] of Object.entries(searchValues)) {
    if (
      value !== null &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      return messages[key];
    }
  }

  return 'No costs found';
};

export { generateEmptyResultMessage };
