import React from 'react';
import { useEffect, useLocation } from 'hooks/hooks.js';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorComponent, Layout } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { useConfiguration, useUserInfo } from 'context/context.js';

function PrivateRoute({ component: Component, subComponent = null, ...rest }) {
  const { user } = useUserInfo();
  const { setIsPrivateRoute } = useConfiguration();
  const location = useLocation();

  useEffect(() => setIsPrivateRoute(true));

  return (
    <Route
      {...rest}
      render={(props) =>
        !!user ? (
          <Layout>
            <ErrorBoundary
              FallbackComponent={ErrorComponent}
              resetKeys={[location.pathname]}
            >
              <Component subComponent={subComponent} {...props} />
            </ErrorBoundary>
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: AppRoutes.Login.path,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export { PrivateRoute };
