import React from 'react';
import { usePipelineQuery } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  LineageWidget,
  PipelineTagsWidget,
  QueryWidget,
} from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}));

const QueryModalBody = ({
  jobHash,
  jobTags = [],
  destinationTable,
  sourceTables,
  sourceUri,
  destinationUri,
  pipeline,
}) => {
  const classes = useStyles();
  const { query } = usePipelineQuery(jobHash);

  return (
    <div className={classes.container}>
      {jobTags.length > 0 && <PipelineTagsWidget jobTags={jobTags} />}

      <LineageWidget
        destinationTable={destinationTable}
        sourceTables={sourceTables}
        sourceUri={sourceUri}
        destinationUri={destinationUri}
        pipeline={pipeline}
      />

      <QueryWidget query={query} />
    </div>
  );
};

export default QueryModalBody;
