import { getSearchLabel } from './get-search-label.js';
import theme from 'theme.js';

const getCustomStyles = (label, searchWithDropdown, withSelect = true) => ({
  menu: (provided) => ({
    ...provided,
    display: !withSelect && 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? theme.palette.primary.main
      : theme.palette.text.primary,
    backgroundColor: state.isSelected
      ? theme.palette.secondary.main
      : theme.palette.common.white,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    padding: '8px 16px',
    paddingLeft: 30,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      svg: {
        path: {
          fill: theme.palette.primary.main,
        },
      },
    },
    '&[aria-disabled="true"]': {
      cursor: 'default',
      color: theme.palette.text.secondary,
      svg: {
        path: {
          fill: theme.palette.text.secondary,
        },
      },
    },
    span: {
      display: 'flex',
      '&.isTable': {
        marginLeft: 20,
      },
    },
  }),
  groupHeading: (base) => ({
    ...base,
    fontWeight: 700,
    fontFamily: theme.typography.fontFamily,
    padding: '8px 16px',
  }),
  control: (provided) => {
    return {
      ...provided,
      minHeight: 46,
      paddingLeft: '8px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: searchWithDropdown ? '0 4px 4px 0' : '4px',
      cursor: 'text',
      boxShadow: 'none',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '& svg': {
        cursor: 'pointer',
      },
    };
  },
  valueContainer: (provided) => {
    return { ...provided, display: 'flex' };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      padding: 0,
      border: '1px solid rgba(5, 12, 46, 0.10)',
      borderRadius: '100px',
      backgroundColor: theme.palette.secondary.main,

      '& > div': {
        fontSize: '100%',

        '&:hover': {
          backgroundColor: 'transparent',
          color: 'inherit',
        },
      },
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      maxWidth: '95%',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.primary,
      alignItems: 'center',
      display: 'flex',
      padding: '3px 5px',
      borderRadius: '4px',
      ...(label && getSearchLabel(label)),
    };
  },
});

export { getCustomStyles };
