import React from 'react';
import { styled } from '@mui/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableHead, TableRow } from '@mui/material';

const UsersTableHead = ({ headCells }) => {
  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      padding: '10px 0 12px',
      borderBottom: `1px solid #BEC5E7`,
    },
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            style={{ minWidth: headCell.style.minWidth }}
          >
            <span className='txt-grey-13-500'>{headCell.label}</span>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export { UsersTableHead };
