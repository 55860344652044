import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { FormControl, FormGroup } from '@mui/material';
import { useStyles } from '../../Deployment.js';
import { signUpAmplEvent } from 'service/services.js';
import { useSignUpInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import HowTo from 'assets/img/howto.svg';

const ManualDeployment = () => {
  const classes = useStyles();
  const history = useHistory();
  const eventButton = 'Deployment -> Click -> Manual Deployment';
  const { signUpInfo } = useSignUpInfo();

  const toNextStep = () => {
    signUpAmplEvent(
      `${eventButton}`,
      signUpInfo.accessToken,
      signUpInfo.name,
      signUpInfo.company,
      signUpInfo.companyUseCase
    );
    history.push(AppRoutes.DeploymentAddProject.path);
  };

  return (
    <div>
      <div className='txt-mainDark-16-700 mb-3'>Manual deployment</div>
      <div className={clsx(classes.badgeContainer, 'mb-3')}>
        <div className='badgeItem txt-mainDark-13-700'>
          Deployment time: 10 min
        </div>
        <div className='badgeItem txt-mainDark-13-700'>
          Your permission: GCP Admin
        </div>
      </div>

      <div className='txt-mainDark-13-500 mb-4'>
        Manually create all the necessary resources. This setup does not
        <br />
        require granting Masthead session-based deployment permissions.
      </div>

      <a
        href='https://docs.mastheadata.com/masthead-agent/saas-deployment/saas-manual-resource-creation-google-cloud'
        target='_blank'
        rel='noreferrer'
        className='mb-4'
        style={{ display: 'flex', gap: 6, alignItems: 'center' }}
      >
        <Image src={HowTo} width={16} height={16} />
        <span className='txt-blue-13-500'>Deployment Guidelines</span>
      </a>

      <form onSubmit={(e) => e.preventDefault()}>
        <FormGroup className={classes.formField}>
          <FormControl>
            <button
              className={clsx(classes.googleBtn, 'btn-my')}
              onClick={toNextStep}
            >
              <span>Manual Deployment</span>
            </button>
          </FormControl>
        </FormGroup>
      </form>
    </div>
  );
};

export { ManualDeployment };
