const getCostsDiffByPaymentType = (costItemWithPaymentType, statsPrevious) => {
  const findCostsBefore = statsPrevious.find(
    (item) =>
      item.paymentType === costItemWithPaymentType.paymentType &&
      item.editionPlan === costItemWithPaymentType.editionPlan
  );

  if (findCostsBefore) {
    const beforeCosts = findCostsBefore.costs;
    if (beforeCosts === 0) {
      return null;
    }
    const costs = costItemWithPaymentType.costs;
    const perc = (costs / beforeCosts) * 100;
    return Math.round(100 - perc) * -1;
  }

  return null;
};

export { getCostsDiffByPaymentType };
