import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Alert } from 'Components/components.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: 608,
    '& .logo': {
      marginBottom: 32,
    },
    '& .form': {
      display: 'flex',
      flexDirection: 'column',
      '& .formBlock': {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
      },
      '& .formButton': {
        width: 280,
        marginTop: 24,
        alignSelf: 'center',
      },
    },
  },
}));

const SetUpProject = ({
  title,
  error,
  TextBlockComponent,
  FormComponent,
  ...props
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.wrapper}>
      <div className='headerContainer'>
        <Logo className='logo' />

        <h1 className='txt-mainDark-32-700 textWhiteColor mb-1'>{title}</h1>

        <div className='txt-mainDark-13-500 textWhiteColor'>
          <TextBlockComponent {...props} />
        </div>
      </div>

      <div className='mainContainer'>
        <Alert alertComponent={error} withTopMargin={false} className='mb-4' />

        <FormComponent {...props} />
      </div>

      <div
        className='bottomText txt-blue-13-700'
        style={{ cursor: 'pointer' }}
        onClick={history.goBack}
      >
        Go back
      </div>
    </div>
  );
};

export { SetUpProject };
