import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useTableLabels = (table, dataset) => {
  const { data, isFetching: isLoadingTableLabels } = useQuery(
    [QUERY_TYPES.tableLabels, table, dataset],
    ({ queryKey }) => {
      const [url, table, dataset] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
      });
    },
    { enabled: Boolean(table && dataset) }
  );

  const tableLabels = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { tableLabels, isLoadingTableLabels };
};

export { useTableLabels };
