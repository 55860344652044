import React from 'react';
import {
  usePipelineSourceTables,
  useOpenTableRawBySearch,
  useTableLabels,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { TableCell, TableRow } from '@mui/material';
import { Chip, CHIP_COLOR, TableLabelsChips } from 'Components/components.js';
import {
  DestinationTableCell,
  LoadingTableRaw,
} from 'Pages/Pipelines/components/components.js';
import { PipelineTableSourceRaw } from './PipelineTableSourceRaw.js';
import { getAlertColorType, getAlertLabel } from 'utils/helpers/helpers.js';
import { PIPELINE_TYPE_DATA } from 'utils/constants.js';

const getDestinationTableIcon = (pipelineType) =>
  PIPELINE_TYPE_DATA[pipelineType]?.icon;

const PipelineTableRows = ({
  destinationTable,
  searchValue,
  selectedTable,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  startDate,
  endDate,
}) => {
  const { isOpenTableRaw, handleChangeOpen } = useOpenTableRawBySearch(
    searchValue,
    selectedTable,
    destinationTable
  );
  const { sources, isFetchingSources } = usePipelineSourceTables({
    dataset: destinationTable?.dataset,
    table: destinationTable?.table,
    startDate,
    endDate,
    sourceTable: selectedTable?.table,
    sourceDataset: selectedTable?.dataset,
    searchValue,
    principalEmail,
    searchPrincipalEmail,
    jobTagNames,
    pipelineType: destinationTable?.pipelineType,
    enabled: isOpenTableRaw,
  });

  const { tableLabels } = useTableLabels(
    destinationTable?.table,
    destinationTable?.dataset
  );

  return (
    <>
      <TableRow
        onClick={handleChangeOpen}
        className={clsx(
          isOpenTableRaw && 'isHiddenBottomBorder',
          'styledTableRow'
        )}
      >
        <DestinationTableCell
          isOpen={isOpenTableRaw}
          tableInfo={destinationTable}
          searchTable={selectedTable}
          searchValue={searchValue}
          icon={getDestinationTableIcon(destinationTable?.pipelineType)}
        />
        <TableCell align='left' sx={{ alignContent: 'baseline' }} colSpan={7}>
          <div className='cellContentWithIcon withLargeGap'>
            <Chip
              label={getAlertLabel(destinationTable?.alertType)}
              color={getAlertColorType(destinationTable?.alertType)}
            />

            {destinationTable?.isDeadEnd && (
              <Chip label='Dead-end' color={CHIP_COLOR.error} />
            )}

            {tableLabels.length > 0 && (
              <TableLabelsChips tableLabels={tableLabels} />
            )}
          </div>
        </TableCell>
        <TableCell />
      </TableRow>

      {isOpenTableRaw &&
        (isFetchingSources ? (
          <LoadingTableRaw />
        ) : (
          sources.length > 0 &&
          sources.map((item) => {
            return (
              <PipelineTableSourceRaw
                key={item.jobHash}
                destinationTable={destinationTable}
                sourceData={item}
                selectedTable={selectedTable}
                searchValue={searchValue}
              />
            );
          })
        ))}
    </>
  );
};

export { PipelineTableRows };
