import React from 'react';
import { useBooleanToggle, useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  StyledTableRow,
  StyledTableCell,
  IconButton,
} from 'Components/components.js';
import { handleCopyText } from 'utils/helpers/helpers.js';
import { createRuleParamsByType } from 'Pages/DataQuality/libs/helpers/helper.js';
import errorIcon from 'assets/img/icons/checkIcons/check-error.svg';
import successIcon from 'assets/img/icons/checkIcons/complete.svg';
import { ReactComponent as CopyText } from 'assets/img/icons/copyIcon.svg';

const COPY_BUTTON_CLASS_NAME = 'copyIcon';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
  },
  truncateCell: {
    maxWidth: 300,
    '& .query': {
      padding: '0 4px',
      backgroundColor: theme.palette.secondary.light,
      borderRadius: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .nowrapQuery': {
      whiteSpace: 'nowrap',
    },
  },
}));

const JobDetailsTable = ({ rowData }) => {
  const {
    column,
    passed,
    typeLabel,
    dimensionLabel,
    failedRows,
    data,
    thresholdLabel,
    query,
  } = rowData;
  const classes = useStyles();
  const params = createRuleParamsByType(data);
  const { booleanState, handleChangeBooleanState } = useBooleanToggle(true);

  const jobRuleStatus = useMemo(() => {
    return passed
      ? { icon: successIcon, content: 'Success' }
      : { icon: errorIcon, content: 'Failed' };
  }, [passed]);

  const onRowClick = (e) => {
    if (e.target.parentElement.classList.contains(COPY_BUTTON_CLASS_NAME)) {
      return;
    }

    handleChangeBooleanState();
  };

  return (
    <StyledTableRow onClick={onRowClick} className={classes.tableRow}>
      <StyledTableCell className='darkBold nowrapText'>
        {column}
      </StyledTableCell>
      <StyledTableCell className='nowrapText'>
        <img
          src={jobRuleStatus.icon}
          alt={jobRuleStatus.content}
          className='me-2'
        />
        {jobRuleStatus.content}
      </StyledTableCell>
      <StyledTableCell className='nowrapText'>{typeLabel}</StyledTableCell>
      <StyledTableCell className='nowrapText'>{dimensionLabel}</StyledTableCell>
      <StyledTableCell className='nowrapText'>{failedRows}%</StyledTableCell>
      <StyledTableCell className='nowrapText'>
        {params.length
          ? params.map(({ key, value }) => (
              <div key={key}>{`${key}: ${value}`}</div>
            ))
          : '-'}
      </StyledTableCell>
      <StyledTableCell className='nowrapText'>{thresholdLabel}</StyledTableCell>
      <StyledTableCell className={classes.truncateCell}>
        <div className={clsx('query', booleanState && 'nowrapQuery')}>
          {query}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <IconButton
          icon={<CopyText />}
          className={clsx('p-0', COPY_BUTTON_CLASS_NAME)}
          onClick={() => handleCopyText(query)}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { JobDetailsTable };
