import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'flex',
    gap: 12,
    width: '100%',
    '& .tableSearch': {
      flexGrow: 1,
    },
    marginBottom: 24,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 0,
    paddingBottom: 24,
  },
  navContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  backButtons: {
    display: 'inline-flex',
    flexDirection: 'column',
    verticalAlign: 'top',
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: 0,
    paddingRight: 0,
  },
  emptyBlock: {
    marginBottom: 20,
  },
}));
