import React from 'react';
import { useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  TextareaAutosize,
  TextField,
  FormControl,
} from '@mui/material';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import { CustomDatePicker } from 'Components/components.js';
import { useStyles } from './Jira.styles.js';
import { ReactComponent as MultiCheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as MultiUncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';
import { ReactComponent as RadioIcon } from 'assets/img/icons/checkIcons/radio.svg';
import { ReactComponent as EmptyRadioIcon } from 'assets/img/icons/checkIcons/empty-radio.svg';

const StringField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>
      <TextField
        id={`field-${field.key}`}
        label=''
        variant='outlined'
        sx={{ width: '100%' }}
        onChange={(e) => onChangeFieldValue(field, e.target.value)}
        required={field.required}
      />
    </FormControl>
  );
};

const NumberField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>
      <div className={classes.fieldContainer}>
        <TextField
          id={`field-${field.key}`}
          label=''
          variant='outlined'
          sx={{ width: '100%' }}
          InputProps={{
            inputComponent: NumericFormat,
          }}
          onChange={(e) => onChangeFieldValue(field, +e.target.value)}
          required={field.required}
        />
      </div>
    </FormControl>
  );
};

const TextareaField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>
      <div>
        <TextareaAutosize
          className={classes.textareaField}
          minRows={3}
          onChange={(e) =>
            onChangeFieldValue(field, {
              type: 'doc',
              version: 1,
              content: [
                {
                  type: 'paragraph',
                  content: [
                    {
                      text: e.target.value,
                      type: 'text',
                    },
                  ],
                },
              ],
            })
          }
          required={field.required}
        />
      </div>
    </FormControl>
  );
};

const UserField = ({
  field,
  accounts,
  onChangeFieldValue,
  multiple = false,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(multiple ? [] : undefined);
  const resultValue = (value) => {
    if (multiple) {
      return value.map((item) => {
        return { id: item.accountId };
      });
    }
    return { id: value.accountId };
  };
  const handleChange = (value) => {
    setValue(value);
    onChangeFieldValue(field, resultValue(value));
  };

  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>

      <Autocomplete
        className={clsx(classes.fieldContainer, 'menuItemSelect')}
        onChange={(e, value) => handleChange(value)}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        value={value}
        options={accounts?.values}
        getOptionLabel={(option) => option?.displayName}
        renderOption={(props, option, { selected }) => {
          return (
            <Box
              component='li'
              value={option}
              {...props}
              className={clsx(classes.listItems, selected && 'selected')}
            >
              {!!option.avatarUrls && (
                <img
                  className={clsx(
                    classes.jiraAvatar,
                    `${classes.jiraAvatar}--user`
                  )}
                  src={option.avatarUrls['48x48']}
                  alt=''
                />
              )}
              {option.displayName}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            required={
              field.required ? (multiple ? !value.length : !value) : false
            }
          />
        )}
      />
    </FormControl>
  );
};

const SelectField = ({ field, onChangeFieldValue, multiple = false }) => {
  const classes = useStyles();
  const [value, setValue] = useState(multiple ? [] : undefined);
  const resultValue = (value) => {
    if (multiple) {
      return value.map((item) => {
        return { id: item.id };
      });
    }
    return { id: value.id };
  };
  const handleChange = (value) => {
    setValue(value);
    onChangeFieldValue(field, resultValue(value));
  };
  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>

      <Autocomplete
        className={clsx(classes.fieldContainer, 'menuItemSelect')}
        options={field?.allowedValues}
        getOptionLabel={(option) => option.name || option.value}
        value={value}
        onChange={(e, value) => handleChange(value)}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        renderOption={(props, option, { selected }) => {
          return (
            <Box
              component='li'
              value={option}
              {...props}
              className={clsx(classes.listItems, selected && 'selected')}
            >
              {option.name || option.value}
            </Box>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            required={
              field.required ? (multiple ? !value.length : !value) : false
            }
          />
        )}
      />
    </FormControl>
  );
};

const CheckboxField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  const [checkboxValue, setCheckboxValue] = useState([]);

  const handleChange = (value) => {
    setCheckboxValue(value);
    onChangeFieldValue(
      field,
      value.map((item) => {
        return { id: item.id };
      })
    );
  };

  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>

      <Autocomplete
        className={classes.fieldContainer}
        options={field?.allowedValues}
        id={`field-${field.key}`}
        value={checkboxValue}
        multiple
        disableCloseOnSelect
        onChange={(e, value) => handleChange(value)}
        getOptionLabel={(option) => option.value}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            className={clsx(classes.listItems, selected && 'selected')}
          >
            <Checkbox
              icon={<MultiUncheckIcon width={18} height={18} />}
              checkedIcon={<MultiCheckIcon width={18} height={18} />}
              sx={{ padding: 0, marginRight: 4 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            required={field.required ? !checkboxValue.length : false}
          />
        )}
      />
    </FormControl>
  );
};

const RadioField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  const [value, setValue] = useState();

  const handleChange = (value) => {
    setValue(value);
    onChangeFieldValue(field, { id: value.id });
  };

  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>

      <Autocomplete
        options={field?.allowedValues}
        id={`field-${field.key}`}
        className={classes.fieldContainer}
        value={value}
        onChange={(e, value) => handleChange(value)}
        getOptionLabel={(option) => option.value}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            className={clsx(classes.listItems, selected && 'selected')}
          >
            <Checkbox
              icon={<EmptyRadioIcon width={18} height={18} />}
              checkedIcon={<RadioIcon width={18} height={18} />}
              sx={{ padding: 0, marginRight: 4 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            required={field.required ? !value : false}
          />
        )}
      />
    </FormControl>
  );
};

const LabelField = ({ field, onChangeFieldValue }) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);

  const handleChange = (value) => {
    setValue(value);
    onChangeFieldValue(field, value);
  };

  return (
    <FormControl sx={{ marginBottom: 6 }}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>
      <div className={classes.fieldContainer}>
        <Autocomplete
          multiple
          options={value}
          freeSolo
          onChange={(e, value) => handleChange(value)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant='outlined'
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Select label'
              required={field.required ? !value.length : false}
            />
          )}
        />
      </div>
    </FormControl>
  );
};

const DatepickerField = ({
  field,
  onChangeFieldValue,
  showTimeSelect = false,
}) => {
  const classes = useStyles();

  const handleChange = (value) => {
    onChangeFieldValue(
      field,
      showTimeSelect
        ? moment(value).toISOString()
        : moment(value).format('YYYY-MM-DD')
    );
  };

  return (
    <FormControl sx={{ marginBottom: 6 }} required={field.required}>
      <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
        {field.name}{' '}
        {field.required && <span className={classes.requiredIcon}>*</span>}
      </div>
      <div className={classes.fieldContainer}>
        <CustomDatePicker
          defaultStartDate={null}
          onChooseDate={handleChange}
          showTimeSelect={showTimeSelect}
          required={field.required}
        />
      </div>
    </FormControl>
  );
};

export const SystemField = ({ field, accounts, onChangeFieldValue }) => {
  switch (field.system) {
    case 'description':
      return (
        <TextareaField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'reporter':
    case 'assignee':
      return (
        <UserField
          field={field}
          accounts={accounts}
          onChangeFieldValue={onChangeFieldValue}
        />
      );
    case 'labels':
      return (
        <LabelField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'priority':
    case 'components':
    case 'fixVersions':
      return (
        <SelectField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    default:
      switch (field.type) {
        case 'string':
          return (
            <StringField
              field={field}
              onChangeFieldValue={onChangeFieldValue}
            />
          );
        case 'user':
          return (
            <UserField
              field={field}
              accounts={accounts}
              onChangeFieldValue={onChangeFieldValue}
            />
          );
        default:
          return '';
      }
  }
};

export const CustomField = ({ field, accounts, onChangeFieldValue }) => {
  switch (field.type) {
    case 'textfield':
      return (
        <StringField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'userpicker':
      return (
        <UserField
          field={field}
          accounts={accounts}
          onChangeFieldValue={onChangeFieldValue}
        />
      );
    case 'multiuserpicker':
      return (
        <UserField
          field={field}
          accounts={accounts}
          onChangeFieldValue={onChangeFieldValue}
          multiple={true}
        />
      );
    case 'textarea':
      return (
        <TextareaField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'float':
      return (
        <NumberField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'url':
      return (
        <StringField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'select':
      return (
        <SelectField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'multiselect':
      return (
        <SelectField
          field={field}
          onChangeFieldValue={onChangeFieldValue}
          multiple={true}
        />
      );
    case 'multicheckboxes':
      return (
        <CheckboxField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'radiobuttons':
      return (
        <RadioField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'labels':
      return (
        <LabelField field={field} onChangeFieldValue={onChangeFieldValue} />
      );
    case 'datepicker':
      return (
        <DatepickerField
          field={field}
          onChangeFieldValue={onChangeFieldValue}
        />
      );
    case 'datetime':
      return (
        <DatepickerField
          field={field}
          onChangeFieldValue={onChangeFieldValue}
          showTimeSelect={true}
        />
      );
    default:
      return '';
  }
};
