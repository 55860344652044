import React from 'react';

const RemoveScanModalBody = () => {
  return (
    <div className='txt-mainDark-13-500' style={{ textAlign: 'center' }}>
      Are vou sure vou want to delete data quality scan?
      <br /> This can't be undone.
    </div>
  );
};

export { RemoveScanModalBody };
