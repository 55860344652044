import { numberFormat } from './number-helpers.js';

const getPercentString = (data) => {
  if (data === 0) {
    return '0%';
  }
  const isIncrease = data > 0;
  const isLessThanOnePercent = data > -1 && data < 1;
  const marker = isLessThanOnePercent ? '< ' : isIncrease ? '+' : '-';
  const viewData = isLessThanOnePercent ? 1 : +Math.abs(data).toFixed();

  return `${marker}${numberFormat(viewData)}%`;
};

export { getPercentString };
