const sourceTableId = 'SOURCE_TABLE';
const emailId = 'EMAIL';
const tagsId = 'TAGS';
const projectId = 'PROJECT';
const totalId = 'TOTAL';
const totalCostsId = 'TOTAL_COSTS';
const reservationsId = 'RESERVATIONS';

const SourceTable = {
  ID: sourceTableId,
  LABEL: 'Source table',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

const Email = {
  ID: emailId,
  LABEL: 'Principal email',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

const Tags = {
  ID: tagsId,
  LABEL: 'Pipeline tags / labels',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

const Reservations = {
  ID: reservationsId,
  LABEL: 'Reservations',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};

const Project = {
  ID: projectId,
  LABEL: 'GC Project',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

const Total = {
  ID: totalId,
  LABEL: 'Total bytes/slots hours',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

const TotalCost = {
  ID: totalCostsId,
  LABEL: 'Total cost',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

export { SourceTable, Email, Tags, Reservations, Project, Total, TotalCost };
