import { createSlice } from '@reduxjs/toolkit';
import { DATA_STATUS, SLICE_NAME } from 'constants/constants.js';
import { getProjects } from './actions.js';

const initialState = {
  projectsStatus: DATA_STATUS.idle,
  projects: [],
};

export const projectsSlice = createSlice({
  name: SLICE_NAME.projects,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProjects.pending, (state) => {
      state.projectsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projects = action.payload.values;
      state.projectsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getProjects.rejected, (state) => {
      state.projectsStatus = DATA_STATUS.rejected;
    });
  },
});

export default projectsSlice.reducer;
