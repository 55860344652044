import React from 'react';
import clsx from 'clsx';
import { LoadingButton as MaterialLoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from './Button.js';
import { useStyles } from './Button.styles.js';

const LoadingButton = ({
  isLoading,
  onClick,
  children = '',
  variant = BUTTON_VARIANT.contained,
  size = BUTTON_SIZE.small,
  color = BUTTON_COLOR.secondary,
  className = '',
  startIcon,
  endIcon,
  fullWidth = false,
}) => {
  const classes = useStyles();

  return (
    <MaterialLoadingButton
      loading={isLoading}
      onClick={onClick}
      variant={variant}
      size={size}
      color={color}
      className={clsx(classes.button, className)}
      loadingIndicator={
        <CircularProgress size={22} sx={{ color: '#B4B8C7' }} />
      }
      loadingPosition={startIcon && 'start'}
      startIcon={startIcon ? startIcon : null}
      endIcon={endIcon ? endIcon : null}
      fullWidth={fullWidth}
    >
      {children}
    </MaterialLoadingButton>
  );
};

export { LoadingButton };
