import React, { createContext } from 'react';
import { useMemo, useReducer } from 'hooks/hooks';

export const TYPES = {
  SET_HEIGHT: 'SET_HEIGHT',
  SET_SIZE: 'SET_SIZE',
};

const initialState = {
  size: 'S',
  height: 450,
};

export const WidgetContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_SIZE':
      return { ...state, size: action.payload };
    case 'SET_HEIGHT':
      return { ...state, height: action.payload };
    default:
      return initialState;
  }
};

export default function WidgetContextProvider({
  children,
  initialSettings = {},
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...initialSettings,
  });

  const contextValue = useMemo(() => {
    return [state, dispatch];
  }, [state, dispatch]);

  return (
    <WidgetContext.Provider value={contextValue}>
      {children}
    </WidgetContext.Provider>
  );
}

export const withWidgetContextProvider = (WrappedComponent) => {
  const NewComponent = ({ initialSettings, ...props }) => {
    const [state, dispatch] = useReducer(reducer, {
      ...initialState,
      ...initialSettings,
    });

    const contextValue = useMemo(() => {
      return [state, dispatch];
    }, [state, dispatch]);

    return (
      <WidgetContext.Provider value={contextValue}>
        <WrappedComponent {...props} />
      </WidgetContext.Provider>
    );
  };
  const { displayName, name } = WrappedComponent;
  NewComponent.displayName = `withWidgetContextProvider${
    displayName || name || 'Component'
  }`;
  return NewComponent;
};
