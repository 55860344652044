import React from 'react';
import {
  useSelector,
  useState,
  useEffect,
  useDispatch,
  useMemo,
} from 'hooks/hooks.js';
import { getColumns } from 'slices/actions.js';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import ColumnTable from 'Components/Columns/ColumnTable.js';
import {
  columnNameId,
  headColumnName,
  headDescription,
  headType,
} from 'Components/Columns/HeadCellsEnum.js';

const headCells = [headType, headColumnName, headDescription];

const SchemaModalBody = ({ table, dataset, page, rowsPerPage }) => {
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(columnNameId);
  const dispatch = useDispatch();

  const { columns, columnsStatus } = useSelector((state) => ({
    columns: state.columns.columns,
    columnsStatus: state.columns.columnsStatus,
  }));

  const isLoadingColumns = useMemo(() => {
    return columnsStatus === DATA_STATUS.pending;
  }, [columnsStatus]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getColumns({
          dataset,
          table,
          column: '',
          isColumnEqual: false,
          order,
          sortBy,
          page: page + 1,
          limit: rowsPerPage,
        })
      );
    }
  }, [page, rowsPerPage, dispatch, dataset, table, order, sortBy]);

  const handleRequestSort = (property) => {
    const isAsc = sortBy === property && order === ORDER_TYPES.asc;
    setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
    setSortBy(property);
  };

  return (
    <div>
      <ColumnTable
        tableColumns={columns}
        headCells={headCells}
        handleRequestSort={handleRequestSort}
        isLoadingData={isLoadingColumns}
      />
    </div>
  );
};

export { SchemaModalBody };
