import React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { PositionLoader } from 'Components/components.js';
import { ReactComponent as CopyText } from 'assets/img/icons/copyIcon.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icons/sidebarIcons/arrowRight.svg';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';

const DEFAULT_ROWS_PER_PAGE = 5;

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: '100%',
    },
    container: {
      position: 'relative',
      height: 'max-content',
      background: theme.palette.common.white,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.secondaryDark,
      overflow: 'unset',
      '& th': {
        padding: 12,
        paddingLeft: '8px',
        background: theme.palette.common.white,
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: theme.palette.common.secondaryDark,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      '& tr': {
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: theme.palette.common.secondaryDark,
        '&.firstRow td ': {
          color: theme.palette.common.mainDarkText,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
        '&.MuiTableRow-hover:hover, &.isSelected': {
          color: theme.palette.common.mainDarkText,
          background: theme.palette.secondary.light,
        },
        '&.rowCursor': {
          cursor: 'pointer',
        },
      },
      '& td': {
        paddingLeft: '8px',
        fontSize: 'inherit',
        lineHeight: 'inherit',
        color: 'inherit',
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
    copyLink: {
      marginLeft: 8,
      cursor: 'pointer',
    },
    paginationContainer: {
      '& .MuiTablePagination-displayedRows': {
        margin: 0,
        fontSize: 13,
        lineHeight: '22px',
        color: theme.palette.common.secondaryDark,
      },
      '& .MuiIconButton-root': {
        '&.MuiIconButton-colorInherit': {
          color: theme.palette.common.mainDarkText,
        },
        '&.Mui-disabled': {
          color: theme.palette.divider,
        },
      },
    },
  };
});

const RowComponent = ({
  row,
  columns,
  withChart,
  chartComponent,
  onRowClick,
}) => {
  const [isOpenCell, setIsOpenCell] = useState(false);

  const handleChangeOpenCell = useCallback(() => {
    setIsOpenCell((prev) => !prev);
  }, []);

  return (
    <>
      <TableRow
        className={clsx(
          row.isFirst && 'firstRow',
          row.datetimeValue !== undefined && 'rowCursor',
          row?.isSelected && 'isSelected'
        )}
        hover
        role='checkbox'
        tabIndex={-1}
        onClick={() => onRowClick(row)}
      >
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={`${column.id}/${row.id}`}
              align={column.align}
              style={{
                width: column.style.width ? column.style.width : 'inherit',
                verticalAlign: 'middle',
                minWidth: column.style.minWidth,
              }}
            >
              {column.format && typeof value === 'number'
                ? column.format(value)
                : value}
              {column.id === 'openCellIcon' &&
                (isOpenCell ? (
                  <ArrowDown
                    onClick={handleChangeOpenCell}
                    style={{ width: 10, cursor: 'pointer' }}
                  />
                ) : (
                  <ArrowRight
                    onClick={handleChangeOpenCell}
                    style={{ width: 10, cursor: 'pointer' }}
                  />
                ))}
            </TableCell>
          );
        })}
      </TableRow>

      {withChart && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderBottomWidth: isOpenCell ? 1 : 0,
            }}
            colSpan={12}
          >
            <Collapse in={isOpenCell} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 2 }}>{chartComponent}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

const IncidentsTable = ({
  onRowClick = () => {},
  onPageChange = () => {},
  columns = [],
  rows = [],
  rowsPage = 0,
  rowsTotal = rows.length,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  pagination = true,
  paginationFetch = false,
  isFetchingRows = false,
  withChart = false,
  chartComponent,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(rowsPage);
  const [total] = useState(rowsTotal);
  const [perPage, setPerPage] = useState(rowsPerPage);

  useEffect(() => {
    setPerPage(pagination ? perPage : total);
  }, [total]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPerPage(+event.target.value);
    handleChangePage(null, 0);
  };

  const handleCopy = (columnId) => {
    const row = rows.find((row) => row[columnId]);
    if (row) {
      navigator.clipboard.writeText(row[columnId].props.children);
    }
  };

  const rowsByPage = useMemo(() => {
    return paginationFetch
      ? rows
      : rows.slice(page * perPage, page * perPage + perPage);
  }, [rows, page, perPage, paginationFetch]);

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        {isFetchingRows && (
          <div className='loaderContainer'>
            <PositionLoader />
          </div>
        )}
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>
                  {column.label}
                  {column.copy !== undefined && column.copy && (
                    <CopyText
                      onClick={() => handleCopy(column.id)}
                      className={classes.copyLink}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsByPage.map((row) => {
              return (
                <RowComponent
                  key={row.id}
                  row={row}
                  columns={columns}
                  chartComponent={chartComponent}
                  withChart={withChart}
                  onRowClick={onRowClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      {pagination && (
        <div className={classes.paginationContainer}>
          <TablePagination
            labelRowsPerPage='Items per page'
            rowsPerPageOptions={[DEFAULT_ROWS_PER_PAGE]}
            component='div'
            count={total}
            rowsPerPage={perPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </div>
  );
};

export { IncidentsTable };
