import React from 'react';
import { TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PipelineTagsChips } from 'Components/components.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const VISIBLE_ITEMS = 2;

const useStyles = makeStyles((theme) => ({
  tagsContainer: {
    '&.MuiTableCell-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
  },
}));

const JobTagsTableCell = ({ jobTags = [] }) => {
  const classes = useStyles();
  const { TAGS_CELL_WIDTH } = PipelineTableCellStyles;

  return (
    <TableCell className={classes.tagsContainer}>
      {jobTags.length ? (
        <PipelineTagsChips
          pipelineTags={jobTags}
          maxWidth={TAGS_CELL_WIDTH}
          visibleItems={VISIBLE_ITEMS}
        />
      ) : (
        <span className='txt-grey-13-500'>N/A</span>
      )}
    </TableCell>
  );
};

export { JobTagsTableCell };
