import React from 'react';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { baseStyles } from '../base.styles.js';
import { ownStyles } from './third-slider.style.js';
import CostsImg from 'assets/img/banners/costs-image.svg';

const ThirdSlider = () => {
  const classes = baseStyles();
  const ownClasses = ownStyles();

  return (
    <div key='slide3' className={classes.wrapper}>
      <div className={classes.rightSide}>
        <div className={clsx(classes.textWrapper, ownClasses.textPosition)}>
          <div className='txt-mainDark-24-700 mb-4'>
            Introducing Costs Insights
          </div>

          <div className='txt-mainDark-13-500'>
            <div className='mb-4'>With Pipeline FinOps, you can:</div>
            <ul className={clsx(ownClasses.list, 'mb-4')}>
              <li className='txt-mainDark-13-500'>
                Gain costs insights into all GCP pipelines and models.
              </li>

              <li className='txt-mainDark-13-500'>
                Determine precise costs for running data models and pipelines
                and tools.
              </li>

              <li className='txt-mainDark-13-500'>
                Identify optimization opportunities through ChatGPT integration.
              </li>
            </ul>
            Maximize efficiency and cost-effectiveness with Pipeline FinOps.
          </div>
        </div>
      </div>

      <div className={classes.leftSide}>
        <Image src={CostsImg} alt='Costs page' />
      </div>
    </div>
  );
};

export { ThirdSlider };
