import React from 'react';
import {
  useMemo,
  useEffect,
  useState,
  useRef,
  useQueryClient,
} from 'hooks/hooks.js';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Image } from 'react-bootstrap';
import { createNewOption, getCustomStyles } from './libs/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import Search from 'assets/img/search.svg';

const SEARCH_DEBOUNCE_DELAY = 500;
const SEARCH_LIMIT = 50;
const SEARCH_ITEM_HEIGHT = 36;
const SEARCH_STALE_TIME = 5 * 60 * 1000;

const AsyncMultiSearch = ({
  defaultSelectValue,
  searchUrl,
  searchQueryParam,
  queryParams = {},
  onSelect = () => {},
  optionLabel = 'Search for:',
  className = '',
  placeholderName = 'Start typing name...',
  searchWithDropdown = false,
  searchKey = '',
  getLabelFromResponseItem = null,
  getValueFromResponseItem = null,
}) => {
  const selectRef = useRef();
  const customStyles = useMemo(
    () => getCustomStyles(optionLabel, searchWithDropdown),
    [optionLabel, searchWithDropdown]
  );
  const queryClient = useQueryClient();

  const [searchSelectedOption, setSearchSelectedOption] = useState([]);

  const getQueryParams = (search) => {
    return {
      [searchQueryParam]: search || '',
    };
  };
  const loadOptions = async (search, loadedOptions, { page }) => {
    const searchQueryParams = search && getQueryParams(search);
    const params = { ...queryParams, ...searchQueryParams };

    const response = await queryClient.fetchQuery(
      [searchUrl, params, SEARCH_LIMIT, page],
      ({ queryKey }) => {
        const [url, searchQueryParams, limit, page] = queryKey;
        return fetcherGet(url, { ...searchQueryParams, limit, page });
      },
      { staleTime: SEARCH_STALE_TIME }
    );
    if (!response?.values || !response?.pagination) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }

    const hasMore = response.pagination.total > page * SEARCH_LIMIT;
    const newOptions = response.values.map((value) =>
      createNewOption(value, getLabelFromResponseItem, getValueFromResponseItem)
    );

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleChange = (item) => {
    if (item) {
      setSearchSelectedOption(item);
      onSelect([...item.map((item) => item.value)]);
    } else {
      setSearchSelectedOption([]);
      onSelect([]);
    }
  };

  useEffect(() => {
    setSearchSelectedOption([]);
  }, [searchKey]);

  useEffect(() => {
    if (defaultSelectValue?.length > 0) {
      setSearchSelectedOption(
        defaultSelectValue.map((item) =>
          createNewOption(
            item,
            getLabelFromResponseItem,
            getValueFromResponseItem
          )
        )
      );
    } else {
      setSearchSelectedOption([]);
    }
  }, [defaultSelectValue]);

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder =
      scrollHeight - clientHeight - (SEARCH_LIMIT * SEARCH_ITEM_HEIGHT) / 2;
    return bottomBorder < scrollTop;
  };

  return (
    <AsyncPaginate
      value={searchSelectedOption}
      onChange={handleChange}
      key={JSON.stringify([defaultSelectValue, searchUrl])}
      selectRef={selectRef}
      styles={customStyles}
      className={className}
      placeholder={
        <div>
          <Image src={Search} width={20} height={20} />
          <span className='ms-2 txt-grey-13-500'>{placeholderName}</span>
        </div>
      }
      isClearable={true}
      loadOptions={loadOptions}
      shouldLoadMore={shouldLoadMore}
      debounceTimeout={SEARCH_DEBOUNCE_DELAY}
      additional={{ page: 1 }}
      isMulti
      closeMenuOnSelect={false}
    />
  );
};

export { AsyncMultiSearch };
