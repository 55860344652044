import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 800,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.secondary.light,
    borderRadius: 16,
    '& .carousel .slider-wrapper': {
      borderRadius: 16,
    },
    '& .carousel .control-dots': {
      width: 'max-content',
      bottom: 26,
      margin: '10px 20px',
      textAlign: 'left',
      '& .dot': {
        width: 6,
        height: 6,
        margin: '0 4px',
        boxShadow: 'none',
        background: theme.palette.divider,
        opacity: 1,
        outline: 'none',
        '&.selected': {
          width: 8,
          height: 8,
          background: theme.palette.common.mainDarkText,
        },
      },
    },
    '& .arrow': {
      position: 'absolute',
      zIndex: 2,
      bottom: 24,
    },
    '& .arrowSkip': {
      left: 148,
    },
    '& .arrowNext': {
      left: 236,
      width: 140,
    },
  },
}));
