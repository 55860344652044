import { useDispatch, useEffect, useMemo, useSelector } from 'hooks/hooks.js';
import { DATA_STATUS } from 'constants/constants.js';
import { getProjects } from 'slices/actions.js';

const useGetProjects = () => {
  const dispatch = useDispatch();

  const { projects, projectsStatus } = useSelector((state) => ({
    projects: state.projects.projects,
    projectsStatus: state.projects.projectsStatus,
  }));

  useEffect(() => {
    if (projectsStatus === DATA_STATUS.idle) {
      dispatch(getProjects());
    }
  }, [dispatch, projectsStatus]);

  const isLoadingProjects = useMemo(() => {
    return projectsStatus === DATA_STATUS.pending;
  }, [projectsStatus]);

  return { projects, isLoadingProjects };
};

export { useGetProjects };
