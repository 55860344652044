import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { AppRoutes } from 'app-routes.js';
import { useStyles } from './EmptyLooker.styles.js';
import { Button, BUTTON_COLOR } from 'Components/components.js';
import { ReactComponent as Looker } from 'assets/img/icons/lookerBlue.svg';

const EmptyLooker = ({ positionAbsolute = true }) => {
  const history = useHistory();
  const classes = useStyles();
  const navigateToIntegrations = () =>
    history.push(AppRoutes.Integrations.path);

  return (
    <div
      className={clsx(
        classes.container,
        positionAbsolute && classes.positionContainer
      )}
    >
      <div className={classes.titleContainer}>
        <Looker />
        <div className='txt-grey-13-500'>
          Connect Looker to seamlessly monitor and alert
          <br />
          for issues with the data-feeding Looker dashboards
        </div>
      </div>
      <Button
        text='Connect'
        fullWidth={false}
        color={BUTTON_COLOR.secondary}
        onClick={navigateToIntegrations}
        className={classes.button}
      />
    </div>
  );
};

export { EmptyLooker };
