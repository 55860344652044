import { TableCell } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    border: 'none',
    '&.darkBold': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.greyText': {
      color: theme.palette.text.secondary,
    },
    '&.blueText': {
      color: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.blueBoldText': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&.redText': {
      color: theme.palette.error.main,
      '& svg path': {
        fill: theme.palette.error.main,
      },
    },
    '&.redBoldText': {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightBold,
      '& svg path': {
        fill: theme.palette.error.main,
      },
    },
  },
  [`&.${tableCellClasses.head}`]: {
    paddingLeft: theme.spacing(3),
  },
  [`&.${tableCellClasses.body}`]: {
    paddingLeft: theme.spacing(3),
  },
}));

export { StyledTableCell };
