import { useEffect, useHistory, useLocation } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button } from 'Components/components.js';
import { errorBoundaryEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { ReactComponent as Image } from 'assets/img/notFoundPage.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 336,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    border: `1px dashed ${theme.palette.common.secondaryDark}`,
    borderRadius: 12,
    color: '#627D98',
    '& > div': {
      textAlign: 'center',
    },
  },
}));

const ErrorComponent = ({ error }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const navigateHomePage = () => history.push(AppRoutes.Dashboard.path);

  useEffect(() => {
    if (error.stack.length > 0) {
      errorBoundaryEvent(error.stack, location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error.stack]);

  return (
    <div className={clsx(classes.container)}>
      <Image />
      <div className='txt-mainDark-20-700 mt-1'>Oops!</div>
      <div>
        <div>An unexpected error seems to occur.</div>
        <div>We are working on solving the problem</div>
      </div>
      <Button fullWidth={false} onClick={navigateHomePage} text='Home page' />
    </div>
  );
};

export { ErrorComponent };
