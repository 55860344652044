import { createSlice } from '@reduxjs/toolkit';
import { DATA_STATUS, SLICE_NAME } from 'constants/constants.js';
import { getColumns, getUniqueColumns, updateDesc } from './actions.js';

const initialState = {
  columnsStatus: DATA_STATUS.idle,
  uniqueColumnsStatus: DATA_STATUS.idle,
  columns: [],
  uniqueColumns: [],
  columnsNumber: 0,
};

export const columnsSlice = createSlice({
  name: SLICE_NAME.columns,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getColumns.pending, (state) => {
      state.columnsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getColumns.fulfilled, (state, action) => {
      state.columns = action.payload.values;
      state.columnsNumber = action.payload.pagination.total;
      state.columnsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getColumns.rejected, (state) => {
      state.columnsStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getUniqueColumns.pending, (state) => {
      state.uniqueColumnsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getUniqueColumns.fulfilled, (state, action) => {
      state.uniqueColumns = action.payload.values;
      state.uniqueColumnsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getUniqueColumns.rejected, (state) => {
      state.uniqueColumnsStatus = DATA_STATUS.rejected;
    });

    builder.addCase(updateDesc.pending, (state) => {
      state.columnsStatus = DATA_STATUS.pending;
    });
    builder.addCase(updateDesc.fulfilled, (state, action) => {
      state.columnsStatus = DATA_STATUS.fulfilled;
      state.columns = state.columns.map((column) => {
        if (column.uuid === action.payload.uuid) {
          return { ...column, description: action.payload.desc };
        }

        return column;
      });
    });
    builder.addCase(updateDesc.rejected, (state) => {
      state.columnsStatus = DATA_STATUS.rejected;
    });
  },
});

export default columnsSlice.reducer;
