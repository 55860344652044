import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherPost } from 'utils/utils.js';

const errorBoundaryEvent = (error, page) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('In development mode, skipping request.');
    return;
  }

  fetcherPost(QUERY_TYPES.errorBoundaryEvent, {
    error,
    page,
  })
    .then(() => console.log('errorBoundaryEvent success'))
    .catch((error) =>
      console.log(`errorBoundaryEvent error: ${error.message}`)
    );
};

export { errorBoundaryEvent };
