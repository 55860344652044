import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Card, Row, Col } from 'react-bootstrap';
import { ReactComponent as ConnectedIcon } from 'assets/img/icons/dashboard-icons/connectedIcon.svg';
import { ReactComponent as FolderIcon } from 'assets/img/icons/dashboard-icons/folderIcon.svg';
import { ReactComponent as WatchIcon } from 'assets/img/icons/dashboard-icons/watchIcons.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/dashboard-icons/checkIcon.svg';
import { ReactComponent as CloseButton } from 'assets/img/icons/closeIconBgd.svg';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    backgroundColor: theme.palette.secondary.light,
    borderColor: theme.palette.secondary.light,
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 25,
    '& .closeIcon': {
      cursor: 'pointer',
    },
  },
  card: {
    padding: 24,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 12,
    '& .textPosition': {
      marginBottom: 4,
    },
  },
  imgContainer: {
    position: 'relative',
    width: 80,
    height: 80,
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 24,
  },
  imgBlueContainer: {
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },
  checkIconContainer: {
    position: 'absolute',
    left: 54,
    top: 54,
  },
}));

const OnboardingInfo = ({ projectStatus, closeHandler }) => {
  const classes = useStyles();
  const secondStepAnalysis = projectStatus.dataReady;
  const thirdStepAnalysis = projectStatus.dataPatternReady;

  return (
    <Card className={classes.cardContainer}>
      <Card.Body className={classes.card}>
        <div className={classes.headerContainer}>
          <div className='txt-mainDark-32-700'>Here's what's coming up</div>
          {secondStepAnalysis && thirdStepAnalysis && (
            <CloseButton className='closeIcon' onClick={closeHandler} />
          )}
        </div>
        <div>
          <Row>
            <Col xs={12} lg={4}>
              <div style={{ marginRight: 40 }}>
                <div className={classes.imgContainer}>
                  <ConnectedIcon />
                  <div className={classes.checkIconContainer}>
                    <CheckIcon />
                  </div>
                </div>
                <p className='txt-mainDark-16-700 textPosition'>
                  1. Your GCP project connected
                </p>
                <p className='txt-mainDark-13-500'>
                  Your account is created, and the integration configuration is
                  successful.
                </p>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div style={{ marginRight: 40 }}>
                <div
                  className={clsx(
                    classes.imgContainer,
                    secondStepAnalysis && classes.imgBlueContainer
                  )}
                >
                  <FolderIcon />
                  {secondStepAnalysis && (
                    <div className={classes.checkIconContainer}>
                      <CheckIcon />
                    </div>
                  )}
                </div>
                <p className='txt-mainDark-16-700 textPosition'>
                  2. Data table collected
                </p>
                <p className='txt-mainDark-13-500'>
                  Your Google Cloud Platform is connected to Masthead, and all
                  your data is up to date.
                </p>
              </div>
            </Col>
            <Col xs={12} lg={4}>
              <div>
                <div
                  className={clsx(
                    classes.imgContainer,
                    thirdStepAnalysis && classes.imgBlueContainer
                  )}
                >
                  <WatchIcon />
                  {thirdStepAnalysis && (
                    <div className={classes.checkIconContainer}>
                      <CheckIcon />
                    </div>
                  )}
                </div>
                <p className='txt-mainDark-16-700 textPosition'>
                  3. We are training ML system and working on costs and lineage
                </p>
                <p className='txt-mainDark-13-500'>
                  We are parsing logs to deliver cost insights, lineage and
                  training our system to detect incidents and anomalies.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export { OnboardingInfo };
