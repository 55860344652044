import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: 336,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    border: `1px dashed ${theme.palette.common.secondaryDark}`,
    borderRadius: 12,
    '& .greyColor': {
      color: '#627D98',
    },
  },
}));
