import React from 'react';
import { useState, useEffect, useQuery, useCallback } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { Loader } from 'Components/components.js';
import ImageStartPage from 'assets/img/icons/latestTableIcon.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    width: 600,
    margin: 'auto',
  },
  image: {
    width: 40,
    height: 40,
  },
}));

const LatestTable = ({ seenCategory, onChange }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const { data } = useQuery(
    [QUERY_TYPES.lastSeenPages, seenCategory],
    ({ queryKey }) => {
      const [url, seenCategory] = queryKey;
      return fetcherGet(url, { seenCategory });
    }
  );

  useEffect(() => {
    if (data !== undefined) {
      setIsLoading(false);
    }
  }, [data]);

  const handleChange = useCallback(
    (value) => {
      setIsLoading(true);
      fetcherGet(QUERY_TYPES.tables, {
        dataset: value.dataset,
        table: value.name,
        limit: 1,
        page: 1,
      }).then((data) => {
        setIsLoading(false);
        if (data?.values?.length) {
          onChange(data.values[0]);
        }
      });
    },
    [onChange]
  );

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <img src={ImageStartPage} className={classes.image} />
          <p className='txt-grey-13-500'>
            Select the necessary table in the search or in data sources
          </p>
          <p
            className='txt-grey-13-500'
            style={{ margin: 0, textAlign: 'center' }}
          >
            Latest table:&nbsp;
            <span className='txt-grey-13-500'>
              {!data?.tables?.length
                ? 'No tables'
                : data.tables
                    .map((item, i) => (
                      <span
                        key={getFullTableName(item.name, item.dataset) + i}
                        className='txt-blue-13-500'
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleChange(item)}
                      >
                        {item.name}
                      </span>
                    ))
                    .reduce((prev, curr) => [prev, ', ', curr])}
            </span>
          </p>
        </>
      )}
    </div>
  );
};

export { LatestTable };
