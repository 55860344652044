import moment from 'moment';
import { QUERY_TYPES } from 'constants/constants.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';

const getIncidentsData = ({
  queryClient,
  incident,
  startDate,
  endDate,
  incidentStatuses,
  page = 0,
  limit = 1,
  enabled = true,
}) => {
  return queryClient.fetchQuery(
    [
      `${QUERY_TYPES.incidents}/${incident.incidentId}`,
      startDate,
      endDate,
      incidentStatuses,
      page,
      limit,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const params = new URLSearchParams();
      params.append(
        'from',
        moment(convertLocalToUTCDate(startDate)).utc().format()
      );
      params.append(
        'to',
        moment(convertLocalToUTCDate(endDate)).utc().format()
      );
      params.append('type', incident.incidentType);
      for (const incidentStatus of incidentStatuses) {
        params.append('incidentStatuses', incidentStatus);
      }
      params.set('page', page + 1);
      params.set('limit', limit);
      return fetcherGet(url, params);
    },
    {
      enabled: enabled,
    }
  );
};

export { getIncidentsData };
