import { useMemo } from 'hooks/hooks.js';
import { INCIDENT_STATUS } from 'constants/constants.js';

const useAnomalyTables = (data) => {
  return useMemo(
    () =>
      (data?.values || []).reduce((res, item) => {
        const fullTableName = `${item.project}.${item.dataset}.${item.table}`;
        if (
          item.table &&
          item.dataset &&
          !res[fullTableName] &&
          item.status !== INCIDENT_STATUS.EXPECTED &&
          item.status !== INCIDENT_STATUS.ARCHIVE
        ) {
          res[fullTableName] = true;
        }
        return res;
      }, {}),
    [data]
  );
};

export { useAnomalyTables };
