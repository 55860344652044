import React from 'react';

const CONTROL_ELEMENTS_DATA = {
  scanIdInput: {
    label: 'ID',
    id: 'id',
    isRequired: true,
    placeholder: 'Write ID for scan',
    helperText:
      'Letters, numbers and dashes allowed. Cannot be changed after creation.',
  },

  tableScanInput: {
    label: 'Table to scan',
    id: 'table-to-scan',
    isRequired: true,
  },

  regionInput: {
    label: 'Region',
    id: 'region',
    isRequired: true,
    helperText:
      'Select GCP region where to create the datascan. This selection is required for tables in multi-region data sets.',
  },

  scheduleRepeatsSelect: {
    label: 'Repeats',
    id: 'repeats',
  },

  customScheduleInput: {
    label: 'Custom schedule',
    id: 'custom-schedule',
  },

  timeInput: {
    label: 'At time',
    id: 'time',
    isRequired: true,
  },

  daysSelect: {
    label: 'Select days',
    id: 'select-days',
    multiple: true,
  },

  monthDaysSelect: {
    label: 'On the',
    id: 'on-the-days',
    multiple: true,
  },

  timezoneSelect: {
    label: 'Timezone',
    id: 'timezone',
  },

  advanceSettingsSwitch: {
    label: (
      <>
        <div>Advance settings</div>
        <div className='txt-grey-13-500'>
          Scope, filters row and sampling size
        </div>
      </>
    ),
    id: 'advance-settings',
  },

  scopeSelect: {
    label: 'Scope',
    id: 'scope',
    isRequired: true,
  },

  timestampInput: {
    label: 'Timestamp column',
    id: 'timestamp-column',
    isRequired: true,
  },

  samplingSizeSelect: {
    label: 'Sampling size',
    id: 'sampling-size',
    isRequired: true,
  },

  customSamplingSizeInput: {
    label: 'Custom sampling size',
    id: 'custom-sampling-size',
    isRequired: true,
    helperText: 'Input will accept a numerical value between 0.001 and 100',
    withEndAdornment: true,
    endAdornment: <div>%</div>,
  },

  filterRowsCheckbox: {
    label: 'Filter rows',
    id: 'filter-rows',
  },

  dimensionSelect: {
    label: 'Dimension',
    id: 'dimension',
    isRequired: true,
  },

  descriptionInput: {
    label: 'Description',
    id: 'description',
    multiline: true,
  },

  thresholdInput: {
    label: 'Passing threshold (%)',
    id: 'passing-threshold',
    isRequired: true,
    helperText: 'Input will accept a numerical value between 1 and 100',
  },

  columnSelect: {
    label: 'Column name',
    id: 'column-name',
    isRequired: true,
  },

  ignoreNullsSwitch: {
    label: 'Ignore nulls',
    id: 'ignore-nulls',
  },

  regexInput: {
    label: 'Regex',
    id: 'regex',
    isRequired: true,
  },

  columnStatisticSelect: {
    label: 'Column statistic',
    id: 'column-statistic',
  },

  minValueInput: {
    label: 'Min value',
    id: 'min-value',
    helperText: 'Minimum and Maximum cannot both be empty',
    isRequired: true,
  },

  maxValueInput: {
    label: 'Max value',
    id: 'max-value',
  },

  strictMinSwitch: {
    label: 'Strict min.',
    id: 'strict-min',
    formLabel: "If ticked, the rule uses '> instead of >='",
  },

  strictMaxSwitch: {
    label: 'Strict max.',
    id: 'strict-max',
    formLabel: "If ticked, the rule uses '<' instead of '<='",
  },
};

export { CONTROL_ELEMENTS_DATA };
