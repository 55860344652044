import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { getPercentString } from 'utils/helpers/helpers.js';
import { ReactComponent as ArrowDown } from 'assets/img/icons/arrows/arrow-long-down.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 'max-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 4,

    height: 28,
    padding: '3px 8px',

    background: ({ isWhite }) => {
      return isWhite
        ? theme.palette.common.white
        : theme.palette.secondary.light;
    },
    borderRadius: '100px',

    '& .blueIcon path': {
      fill: theme.palette.primary.main,
    },

    '& .redIcon': {
      transform: 'rotate(180deg)',
      '& path': {
        fill: theme.palette.error.main,
      },
    },
  },
}));

const PercentStatsBlock = ({ data, isWhite = false }) => {
  const classes = useStyles({ isWhite });
  const isIncrease = data > 0;
  const existingIcon = data !== 0;

  return (
    <div className={classes.container}>
      <div className={clsx(isIncrease ? 'txt-red-13-500' : 'txt-blue-13-500')}>
        {getPercentString(data)}
      </div>
      {existingIcon && (
        <ArrowDown className={clsx(isIncrease ? 'redIcon' : 'blueIcon')} />
      )}
    </div>
  );
};

export { PercentStatsBlock };
