const StartTimeId = 'START_TIME';
const jobNameId = 'JOB_NAME';
const durationId = 'DURATION';
const slotsHoursId = 'SLOTS_HOURS';
const bytesId = 'BYTES';

const StartTime = {
  ID: StartTimeId,
  LABEL: 'Start time',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const JobName = {
  ID: jobNameId,
  LABEL: 'Job name',
  SORT: false,
  ALIGN: 'left',
  IS_SHOW: true,
};
const Duration = {
  ID: durationId,
  LABEL: 'Duration',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};
const SlotsHours = {
  ID: slotsHoursId,
  LABEL: 'Slots hours',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};
const Bytes = {
  ID: bytesId,
  LABEL: 'Bytes',
  SORT: false,
  ALIGN: 'right',
  IS_SHOW: true,
};

export { StartTime, JobName, Duration, SlotsHours, Bytes };
