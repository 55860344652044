import React from 'react';

const MutedElementModalBody = ({ element = 'table' }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      The {element} will be muted before you change the status. Notifications
      will not come to Slack, but you will see them in incidents.
    </div>
  );
};

const CriticalElementModalBody = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      If you downgrade it, your @channel won't be mentioned, and you may not get
      a notification unless the Masthead bot is configured for this status.
    </div>
  );
};

export { MutedElementModalBody, CriticalElementModalBody };
