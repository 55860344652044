const INITIAL_TECHNOLOGY_OPTION = {
  value: '',
  label: 'All technologies',
};

/** Dropdowns */
export const TABLE_ALERT_STATUS_FILTER = 'tableAlertStatus';
export const ACTION_STATUS_FILTER = 'pipelineActionStatus';
export const TIME_PERIOD_FILTER = 'pipelineTimePeriod';
export const TECHNOLOGY_FILTER = 'technology';
export const COST_EFFICIENCY_FILTER = 'costEfficiency';

const PipelineDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  ACTION_STATUS_FILTER,
  TIME_PERIOD_FILTER,
  TECHNOLOGY_FILTER,
  COST_EFFICIENCY_FILTER,
];

const CostsDropdowns = [
  TABLE_ALERT_STATUS_FILTER,
  COST_EFFICIENCY_FILTER,
  TECHNOLOGY_FILTER,
];

/** Search */
export const SELECTED_EMAIL = 'selectedEmail';
export const SEARCH_EMAIL = 'searchEmail';
export const SELECTED_TABLE = 'selectedTable';
export const SEARCH_TABLE = 'searchTable';
export const SELECTED_DESTINATION_TABLE = 'selectedDestinationTable';
export const SEARCH_DESTINATION_TABLE = 'searchDestinationTable';
export const SELECTED_TAGS = 'selectedTags';
export const SELECTED_LABEL = 'selectedLabel';
export const SEARCH_LABEL = 'searchLabel';

const DefaultSearchValues = {
  [SELECTED_EMAIL]: '',
  [SEARCH_EMAIL]: '',
  [SELECTED_TABLE]: null,
  [SEARCH_TABLE]: '',
  [SELECTED_DESTINATION_TABLE]: null,
  [SEARCH_DESTINATION_TABLE]: '',
  [SELECTED_TAGS]: [],
  [SELECTED_LABEL]: null,
  [SEARCH_LABEL]: '',
};

export {
  INITIAL_TECHNOLOGY_OPTION,
  PipelineDropdowns,
  CostsDropdowns,
  DefaultSearchValues,
};
