import { getImageByXMLSvg } from 'utils/helpers/helpers.js';
import { TABLE_TYPES } from 'constants/constants.js';

const getSvgDBTableImageContent = (color) => {
  return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.40002 13.6V2.39999H13.6V13.6H2.40002ZM3.60002 5.95039H12.4V3.59999H3.60002V5.95039ZM6.93362 9.18319H9.06642V7.15039H6.93362V9.18319ZM6.93362 12.4H9.06642V10.3832H6.93362V12.4ZM3.60002 9.18319H5.73362V7.15039H3.60002V9.18319ZM10.2664 9.18319H12.4V7.15039H10.2664V9.18319ZM3.60002 12.4H5.73362V10.3832H3.60002V12.4ZM10.2664 12.4H12.4V10.3832H10.2664V12.4Z" fill="${color}"/>
</svg>`;
};

const getSvgViewImageContent = (color) => {
  return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.0664 14C5.6776 14 5.3472 13.864 5.0752 13.592C4.80267 13.3195 4.6664 12.9888 4.6664 12.6V6.0664C4.6664 5.6776 4.80267 5.3472 5.0752 5.0752C5.3472 4.80267 5.6776 4.6664 6.0664 4.6664H12.6C12.9888 4.6664 13.3195 4.80267 13.592 5.0752C13.864 5.3472 14 5.6776 14 6.0664V12.6C14 12.9888 13.864 13.3195 13.592 13.592C13.3195 13.864 12.9888 14 12.6 14H6.0664ZM6.0664 7.3H12.6V6.0664H6.0664V7.3ZM8.7 9.9664H9.9664V8.7H8.7V9.9664ZM8.7 12.6H9.9664V11.3664H8.7V12.6ZM6.0664 9.9664H7.3V8.7H6.0664V9.9664ZM11.3664 9.9664H12.6V8.7H11.3664V9.9664ZM6.0664 12.6H7.3V11.3664H6.0664V12.6ZM11.3664 12.6H12.6V11.3664H11.3664V12.6ZM3.4 11.3336C3.0112 11.3336 2.68053 11.1973 2.408 10.9248C2.136 10.6528 2 10.3224 2 9.9336V3.4C2 3.0112 2.136 2.68053 2.408 2.408C2.68053 2.136 3.0112 2 3.4 2H9.9336C10.3224 2 10.6528 2.136 10.9248 2.408C11.1973 2.68053 11.3336 3.0112 11.3336 3.4V3.9664H9.9336V3.4H3.4V9.9336H3.9664V11.3336H3.4Z" fill="${color}"/>
</svg>`;
};

const getSvgExternalImageContent = (color) => {
  return `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.60002 13.6C3.26669 13.6 2.98349 13.4832 2.75042 13.2496C2.51682 13.0165 2.40002 12.7333 2.40002 12.4V3.59999C2.40002 3.26666 2.51682 2.98346 2.75042 2.75039C2.98349 2.51679 3.26669 2.39999 3.60002 2.39999H12.4C12.7334 2.39999 13.0166 2.51679 13.2496 2.75039C13.4832 2.98346 13.6 3.26666 13.6 3.59999V4.79999H12.4V3.59999H3.60002V12.4H12.4V11.2H13.6V12.4C13.6 12.7333 13.4832 13.0165 13.2496 13.2496C13.0166 13.4832 12.7334 13.6 12.4 13.6H3.60002ZM11.2 11.2L10.3504 10.3504L12.1 8.59999H6.40002V7.39999H12.1L10.3504 5.64959L11.2 4.79999L14.4 7.99999L11.2 11.2Z" fill="${color}"/>
</svg>`;
};

const getDatasetTypeImage = (datasetType, nodeImageColor) => {
  const getImageContent = () => {
    switch (datasetType) {
      case TABLE_TYPES.view:
        return getSvgViewImageContent(nodeImageColor);
      case TABLE_TYPES.external:
        return getSvgExternalImageContent(nodeImageColor);
      default:
        return getSvgDBTableImageContent(nodeImageColor);
    }
  };

  const datasetXmlImage = getImageContent();

  return getImageByXMLSvg(datasetXmlImage);
};

export { getDatasetTypeImage };
