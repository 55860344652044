const GraphShapeNames = {
  PATH_SHAPE: 'path-shape',
  MAIN_BOX: 'main-box',
  PROJECT_NAME_BOX: 'project-name-box',
  PROJECT_NAME_TEXT: 'project-name-text',
  TOP_BOX_GROUP: 'top-box-group',
  NODE_ICON: 'node-icon',
  TITLE_SHAPE: 'title-shape',
  SUBTITLE_SHAPE: 'subtitle-shape',
  DIVIDER_SHAPE: 'divider-shape',
  NODE_ALERT_TYPE_ICON: 'node-alert-type-icon',

  ADD_DOWNSTREAM_BTN: 'add-downstream-btn',
  ADD_DOWNSTREAM_BTN_TEXT: 'add-downstream-btn-text',
  ADD_UPSTREAM_BTN: 'add-upstream-btn',
  ADD_UPSTREAM_BTN_TEXT: 'add-upstream-btn-text',

  REMOVE_DOWNSTREAM_BTN: 'remove-downstream-btn',
  REMOVE_DOWNSTREAM_BTN_TEXT: 'remove-downstream-btn-text',
  REMOVE_UPSTREAM_BTN: 'remove-upstream-btn',
  REMOVE_UPSTREAM_BTN_TEXT: 'remove-upstream-btn-text',

  DOWNSTREAM_PREV_BTN: 'downstream-prev-btn',
  DOWNSTREAM_NEXT_BTN: 'downstream-next-btn',
  UPSTREAM_PREV_BTN: 'upstream-prev-btn',
  UPSTREAM_NEXT_BTN: 'upstream-next-btn',

  EXPAND: 'expand',
  COLLAPSE: 'collapse',
  DISABLE_COLUMNS: 'disable-columns',

  VISIBLE_TAG_NAME: 'visible-tag-name',
  EXPAND_TAGS: 'expand-tags',
  COLLAPSE_TAGS: 'collapse-tags',

  PAGINATION_TEXT: 'pagination-text',
  PAGINATION_LEFT: 'pagination-left',
  PAGINATION_LEFT_GREY: 'pagination-left-grey',
  PAGINATION_RIGHT: 'pagination-right',
  PAGINATION_RIGHT_GREY: 'pagination-right-grey',

  HIDDEN_TABLES_GROUP: 'hidden-tables-group',
  HIDDEN_TABLES_BOX: 'hidden-tables-box',
  TRANSPARENT_TABLES_BOX: 'transparent-tables-box',
  HIDDEN_TABLES_TEXT: 'hidden-tables-text',
  HIDDEN_TABLES_ARROW_GREY: 'hidden-tables-arrow-grey',
  HIDDEN_TABLES_ARROW_BLUE: 'hidden-tables-arrow-blue',
};

export { GraphShapeNames };
