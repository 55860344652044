import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  graphContainer: {
    '& canvas': {
      height: '380px !important',
    },
    '& .g2-tooltip': {
      padding: '8px !important',
      fontFamily: 'Inter, Arial, sans-serif !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      color: '#545454 !important',
      backgroundColor: 'rgba(255, 255, 255, 0.9) !important',
      border: '1px solid #e2e2e2 !important',
      borderRadius: '12px !important',
      boxShadow: 'rgb(174, 174, 174) 0px 0px 10px !important',

      '&-title, &-list-item': {
        fontFamily: 'Inter, Arial, sans-serif !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        color: '#545454 !important',
      },
    },
  },
}));
