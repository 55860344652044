import React from 'react';
import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  usePagination,
  useDispatch,
  useSelector,
} from 'hooks/hooks.js';
import { useStyles } from './SelectModalBody.styles.js';
import {
  Button,
  BUTTON_VARIANT,
  Pagination,
  PositionLoader,
  RadioWithLabel,
  TableDatasetSearch,
} from 'Components/components.js';
import { getTables } from 'slices/actions.js';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const getIdFromTable = (table) => {
  return getFullTableName(table.table, table.dataset);
};

const SelectTableModalBody = ({
  closeModal,
  getSelectedValue,
  selectedTable,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const [tableInfo, setTableInfo] = useState(null);
  const [value, setValue] = useState(selectedTable);

  const isDisabledSelectButton = useMemo(() => {
    return !value;
  }, [value]);

  const handleChangeValue = useCallback((value) => {
    setValue(value);
  }, []);

  const { tablesData, tablesDataStatus, tablesNumber } = useSelector(
    (state) => ({
      tablesData: state.tables.tablesData,
      tablesDataStatus: state.tables.tablesDataStatus,
      tablesNumber: state.tables.tablesNumber,
    })
  );

  const isLoadingTablesData = useMemo(() => {
    return tablesDataStatus === DATA_STATUS.pending;
  }, [tablesDataStatus]);

  useEffect(() => {
    dispatch(
      getTables({
        dataset: tableInfo?.dataset || '',
        table: tableInfo?.table || '',
        searchTable: '',
        alertTypes: '',
        datasetTypes: '',
        sortBy: 'DATASET',
        order: ORDER_TYPES.asc,
        page: page + 1,
        limit: rowsPerPage,
        onlyRecommendPriority: false,
      })
    );
  }, [dispatch, page, rowsPerPage, tableInfo?.dataset, tableInfo?.table]);

  const handleTableChange = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setTableInfo(value);
    },
    [handleChangePage]
  );

  const saveModalValue = useCallback(() => {
    getSelectedValue(value);
    closeModal();
  }, [closeModal, getSelectedValue, value]);

  return (
    <div className={classes.modalBodyContainer}>
      <div className={classes.contentContainer}>
        <TableDatasetSearch
          onTableChange={handleTableChange}
          withDatasets={false}
        />
        <div className={classes.tableContainer}>
          {isLoadingTablesData ? (
            <PositionLoader />
          ) : (
            <RadioWithLabel
              selectedOption={value}
              options={tablesData}
              onChangeValue={handleChangeValue}
              getValueFromOption={getIdFromTable}
              getLabelFromOption={getIdFromTable}
              className='tableItem'
            />
          )}
        </div>

        <Pagination
          count={tablesNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button
          text='Select'
          fullWidth={false}
          className='button'
          onClick={saveModalValue}
          isDisabled={isDisabledSelectButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { SelectTableModalBody };
