import * as React from 'react';
import { makeStyles } from '@mui/styles';
import backgroundPoints from 'assets/img/backgrounds/points.svg';

const useStyles = makeStyles((theme) => ({
  wrapperContainer: {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundPoints})`,
    backgroundColor: theme.palette.common.white,
  },
  wrapper: {
    position: 'relative',
    minWidth: 608,
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 16,
    '& .headerContainer': {
      display: 'flex',
      flexDirection: 'column',
      padding: 36,
      borderRadius: '16px 16px 0 0',
      backgroundColor: '#181818',
      background:
        'radial-gradient(59.47% 110.69% at 98.36% 4.29%, #8D8843 0%, ' +
        'rgba(108, 130, 91, 0.912842) 18.76%, rgba(29, 77, 107, 0.65) 44.99%, ' +
        'rgba(19, 73, 106, 0.6) 57.79%, ' +
        'rgba(22, 120, 191, 0.24) 76.8%, rgba(8, 98, 234, 0) 100%)',
    },
    '& .mainContainer': {
      padding: '32px 36px 36px',
    },
    '& .textWhiteColor': {
      color: theme.palette.common.white,
      textAlign: 'center',
    },
    '& .link': {
      display: 'inline-block',
      color: theme.palette.common.white,
      textDecoration: 'underline',
    },
    '& .bottomText': {
      position: 'absolute',
      left: '50%',
      bottom: -36,
      transform: 'translateX(-50%)',
    },
  },
}));

export default function AuthSide({ component: Component, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapperContainer}>
      <div className={classes.wrapper}>
        <Component {...props} />
      </div>
    </div>
  );
}
