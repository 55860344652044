import React from 'react';
import { useMutation } from 'hooks/hooks.js';
import { Checkbox } from '@mui/material';
import {
  Chip,
  EditCell,
  StyledTableCell,
  StyledTableRow,
} from 'Components/components.js';
import { fetcherPost } from 'utils/utils.js';
import { getAlertColorType, getAlertLabel } from 'utils/helpers/helpers.js';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';

const DictionaryDatasetDetails = ({
  isItemSelected,
  datasetInfo,
  handleClick,
  onDatasetClick,
  updateDataset,
}) => {
  const { mutateAsync: updateDesc } = useMutation(
    (desc) => {
      return fetcherPost(`/api/v1/datasets?dataset=${datasetInfo.name}`, {
        value: desc,
      });
    },
    {
      onSuccess: (data, variables) => {
        if (data?.success) {
          datasetInfo.description = variables;
          updateDataset(datasetInfo);
        }
      },
    }
  );

  return (
    <StyledTableRow
      role='checkbox'
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <StyledTableCell className='checkboxContainer'>
        <Checkbox
          onClick={(event) => handleClick(event, datasetInfo)}
          checked={isItemSelected}
          icon={<UncheckIcon />}
          checkedIcon={<CheckIcon />}
          className='checkbox'
        />
      </StyledTableCell>

      <StyledTableCell className='blueBoldText'>
        <span
          className='appLink'
          onClick={() => onDatasetClick(datasetInfo.name)}
        >
          {datasetInfo.name}
        </span>
      </StyledTableCell>

      <StyledTableCell>
        <Chip
          label={getAlertLabel(datasetInfo?.alertType)}
          color={getAlertColorType(datasetInfo?.alertType)}
        />
      </StyledTableCell>

      <StyledTableCell>
        <EditCell value={datasetInfo.description} onEdit={updateDesc} />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { DictionaryDatasetDetails };
