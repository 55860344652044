import React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useSyncTableToSearchParam,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  CheckboxWithLabel,
  InputWithAction,
  Input,
  RightSideModal,
  SwitchWithLabel,
} from 'Components/components.js';
import {
  SqlBlock,
  ScheduleBlock,
  SelectTableModalBody,
  SamplingSizeSelect,
  ScopeSelect,
  RegionSelect,
} from '../components.js';
import { useModal, useUserInfo } from 'context/context.js';
import {
  getFullTableName,
  startWithLowercaseLetter,
  letterNumberHyphenInput,
  endWithLetterOrNumber,
} from 'utils/helpers/helpers.js';
import {
  CONTROL_ELEMENTS_DATA,
  ENTIRE_DATA_SCOPE,
  INPUT_ERRORS,
  US_TABLE_LOCATION,
  EU_TABLE_LOCATION,
  ALL_DATA_SAMPLING_SIZE,
} from '../../libs/constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
  },
  container: {
    width: 600,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
}));

const GeneralBlock = ({
  updateScanData,
  isUniqueScanIdError = false,
  changeIsUniqueScanIdError = () => {},
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { currentProject } = useUserInfo();
  const [scanId, setScanId] = useState('');
  const [scanIdError, setScanIdError] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isFilterRowsSelected, setIsFilterRowsSelected] = useState(false);
  const [isAdvanceSettingsSelected, setIsAdvanceSettingsSelected] =
    useState(false);
  const [samplingSizeValue, setSamplingSizeValue] = useState(
    ALL_DATA_SAMPLING_SIZE
  );
  const [isSamplingPercentError, setIsSamplingPercentError] = useState(null);
  const [scheduleCron, setScheduleCron] = useState(null);
  const [isScheduleCronError, setIsScheduleCronError] = useState(null);
  const [selectedScope, setSelectedScope] = useState(ENTIRE_DATA_SCOPE);
  const [scopeColumn, setScopeColumn] = useState('');
  const [sqlText, setSqlText] = useState('');
  const [tableLocation, setTableLocation] = useState(null);

  const { tableInfoFromUrl, clearSearchParams } = useSyncTableToSearchParam(
    '',
    '',
    null
  );

  const {
    scanIdInput,
    tableScanInput,
    advanceSettingsSwitch,
    filterRowsCheckbox,
  } = CONTROL_ELEMENTS_DATA;

  const isVisibleRegionSelect = useMemo(() => {
    if (selectedTable) {
      return (
        selectedTable.location === US_TABLE_LOCATION ||
        selectedTable.location === EU_TABLE_LOCATION
      );
    }

    return false;
  }, [selectedTable]);

  const selectedTableLocation = useMemo(() => {
    return isVisibleRegionSelect
      ? tableLocation?.value
      : selectedTable?.location;
  }, [isVisibleRegionSelect, selectedTable?.location, tableLocation]);

  const handleChangeScanId = useCallback((value) => {
    if (value.length && !startWithLowercaseLetter(value)) {
      setScanIdError(INPUT_ERRORS.invalidFirstCharacter);
    } else if (value.length && !letterNumberHyphenInput(value)) {
      setScanIdError(INPUT_ERRORS.invalidId);
    } else if (value.length && !endWithLetterOrNumber(value)) {
      setScanIdError(INPUT_ERRORS.invalidLastCharacter);
    } else {
      setScanIdError(null);
    }

    setScanId(value);
  }, []);

  useEffect(() => {
    if (isUniqueScanIdError && !scanIdError) {
      setScanIdError(
        INPUT_ERRORS.uniqueId(currentProject, selectedTableLocation, scanId)
      );
      changeIsUniqueScanIdError(false);
      window.scrollTo(0, 0);
    }
  }, [
    isUniqueScanIdError,
    scanIdError,
    currentProject,
    selectedTableLocation,
    scanId,
    changeIsUniqueScanIdError,
  ]);

  const handleChangeSelectedTables = useCallback((value) => {
    setSelectedTable(value);
    setTableLocation(null);
  }, []);

  const handleChangeFilterRows = useCallback((value) => {
    setIsFilterRowsSelected(value);
    if (!value) {
      setSqlText('');
    }
  }, []);

  const handleChangeSettingsSwitch = useCallback(
    (value) => {
      setIsAdvanceSettingsSelected(value);

      if (!value) {
        setSelectedScope(ENTIRE_DATA_SCOPE);
        setScopeColumn('');
        setSamplingSizeValue(ALL_DATA_SAMPLING_SIZE);
        handleChangeFilterRows(false);
      }
    },
    [handleChangeFilterRows]
  );

  const handleChangeSamplingSize = useCallback((value, error) => {
    setSamplingSizeValue(value);
    setIsSamplingPercentError(error);
  }, []);

  const handleChangeScheduleCron = useCallback((value, isError) => {
    setScheduleCron(value);
    setIsScheduleCronError(isError);
  }, []);

  const handleChangeScopeData = useCallback((value, selectedScope) => {
    setScopeColumn(value);
    setSelectedScope(selectedScope);
  }, []);

  const handleChangeSqlText = useCallback((value) => {
    setSqlText(value);
  }, []);

  const handleChangeTableLocation = useCallback((value) => {
    setTableLocation(value);
  }, []);

  useEffect(() => {
    updateScanData(
      scanId,
      !!scanIdError,
      selectedTable?.table,
      selectedTable?.dataset,
      selectedTableLocation,
      scheduleCron,
      isScheduleCronError,
      selectedScope,
      scopeColumn?.length ? scopeColumn : null,
      isFilterRowsSelected,
      sqlText.length ? sqlText : null,
      samplingSizeValue,
      isSamplingPercentError
    );
  }, [
    scanIdError,
    isScheduleCronError,
    scanId,
    scheduleCron,
    updateScanData,
    scopeColumn,
    selectedScope,
    sqlText,
    samplingSizeValue,
    isSamplingPercentError,
    selectedTable?.table,
    selectedTable?.dataset,
    isFilterRowsSelected,
    selectedTable?.location,
    tableLocation,
    selectedTableLocation,
  ]);

  const isDisableTableBtn = useMemo(() => {
    if (selectedTable) {
      return false;
    }

    return !!tableInfoFromUrl;
  }, [tableInfoFromUrl, selectedTable]);

  useEffect(() => {
    if (tableInfoFromUrl && !selectedTable) {
      fetcherGet(QUERY_TYPES.tables, {
        dataset: tableInfoFromUrl.dataset,
        table: tableInfoFromUrl.table,
        limit: 1,
        page: 1,
      })
        .then((data) => {
          if (data?.values?.length) {
            setSelectedTable(data.values[0]);
          }
        })
        .finally(() => {
          clearSearchParams();
        });
    }
  }, [tableInfoFromUrl, selectedTable, clearSearchParams]);

  const fullTableName = useMemo(() => {
    return selectedTable
      ? getFullTableName(selectedTable.table, selectedTable.dataset)
      : '';
  }, [selectedTable]);

  const openSelectTable = useCallback(() => {
    setModal(() => (
      <RightSideModal
        title='Select table'
        ModalBodyComponent={SelectTableModalBody}
        getSelectedValue={handleChangeSelectedTables}
        selectedTable={selectedTable}
        mainBlockMargin={0}
      />
    ));
  }, [handleChangeSelectedTables, selectedTable, setModal]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div className='txt-mainDark-16-700'>General</div>

        <Input
          value={scanId}
          onChange={handleChangeScanId}
          label={scanIdInput.label}
          isRequired={scanIdInput.isRequired}
          id={scanIdInput.id}
          placeholder={scanIdInput.placeholder}
          helperText={scanIdInput.helperText}
          errorComponent={scanIdError}
        />

        <InputWithAction
          value={fullTableName}
          label={tableScanInput.label}
          isRequired={tableScanInput.isRequired}
          onButtonClick={openSelectTable}
          id={tableScanInput.id}
          isDisabledButton={isDisableTableBtn}
        />

        {isVisibleRegionSelect && (
          <RegionSelect
            multiRegion={selectedTable?.location}
            selectedRegion={tableLocation}
            onChangeRegion={handleChangeTableLocation}
          />
        )}
      </div>

      <ScheduleBlock
        changeScheduleCron={handleChangeScheduleCron}
        className={classes.container}
      />

      <div className={classes.container}>
        <SwitchWithLabel
          label={advanceSettingsSwitch.label}
          id={advanceSettingsSwitch.id}
          isChecked={isAdvanceSettingsSelected}
          handleChange={handleChangeSettingsSwitch}
        />

        {isAdvanceSettingsSelected && (
          <>
            <ScopeSelect
              table={selectedTable?.table}
              dataset={selectedTable?.dataset}
              changeScopeData={handleChangeScopeData}
            />

            <SamplingSizeSelect changeSamplingSize={handleChangeSamplingSize} />

            <CheckboxWithLabel
              isChecked={isFilterRowsSelected}
              handleChange={handleChangeFilterRows}
              label={filterRowsCheckbox.label}
              id={filterRowsCheckbox.id}
            />

            {isFilterRowsSelected && (
              <SqlBlock
                isWhiteTextBlock={false}
                value={sqlText}
                onChangeSqlData={handleChangeSqlText}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { GeneralBlock };
