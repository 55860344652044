import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useGetBigQueryIncidentGroups = ({
  startDate,
  endDate,
  page,
  rowsPerPage,
  dataset,
  table,
  searchTable,
  incidentGroupsTypes,
  incidentGroupsAlert,
  incidentGroupsStatuses,
  pipelineType,
  incidentIdAndType,
  enabled = true,
  cacheTime = 0,
}) => {
  const { isFetching: isIncidentGroupsFetching, data } = useQuery(
    [
      QUERY_TYPES.incidentGroups,
      startDate,
      endDate,
      page,
      rowsPerPage,
      dataset,
      table,
      searchTable,
      incidentGroupsTypes,
      incidentGroupsAlert,
      incidentGroupsStatuses,
      pipelineType,
      incidentIdAndType,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      const incidentTypes = [];
      incidentTypes.push(...incidentGroupsTypes.map((item) => item.value));

      const incidentStatuses = [];
      if (
        incidentGroupsStatuses.length > 1 ||
        (incidentGroupsStatuses.length &&
          incidentGroupsStatuses[0].value !== '')
      ) {
        incidentStatuses.push(
          ...incidentGroupsStatuses.map((item) => item.value)
        );
      }

      const incidentAlerts = [];
      if (
        incidentGroupsAlert.length > 1 ||
        (incidentGroupsAlert.length && incidentGroupsAlert[0].value !== '')
      ) {
        incidentAlerts.push(...incidentGroupsAlert.map((item) => item.value));
      }

      const params = new URLSearchParams();
      params.append(
        'from',
        moment(convertLocalToUTCDate(startDate)).utc().format()
      );
      params.append(
        'to',
        moment(convertLocalToUTCDate(endDate)).utc().format()
      );
      params.append('page', page + 1);
      params.append('limit', rowsPerPage);
      params.append('dataset', dataset || '');
      params.append('table', table || '');
      params.append('search', searchTable || '');

      if (incidentIdAndType?.id && incidentIdAndType?.type) {
        params.append('id', incidentIdAndType.id);
        params.append('type', incidentIdAndType.type);
      } else {
        for (const incidentType of incidentTypes) {
          params.append('incidentTypeGroups', incidentType);
        }
        for (const incidentAlert of incidentAlerts) {
          params.append('alertTypes', incidentAlert);
        }
        for (const incidentStatus of incidentStatuses) {
          params.append('incidentStatuses', incidentStatus);
        }
        if (pipelineType) {
          params.append('pipelineType', pipelineType);
        }
      }
      return fetcherGet(url, params);
    },
    { cacheTime, enabled }
  );

  const incidentGroups = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const incidentsTotal = useMemo(() => {
    return data?.incidentPagination.total || 0;
  }, [data?.incidentPagination.total]);

  const incidentsGroupPagination = useMemo(() => {
    return data?.groupPagination.total || 0;
  }, [data?.groupPagination.total]);

  return {
    incidentGroups,
    isIncidentGroupsFetching,
    incidentsTotal,
    incidentsGroupPagination,
  };
};

export { useGetBigQueryIncidentGroups };
