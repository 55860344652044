import React, { forwardRef } from 'react';
import {
  useEffect,
  usePagination,
  useImperativeHandle,
  useSelector,
  useDispatch,
  useMemo,
} from 'hooks/hooks.js';
import { Box, Table, TableBody, TableContainer, Paper } from '@mui/material';
import { useStyles } from './Schema.styles.js';
import {
  AMPL_PAGE_EVENT,
  DATA_STATUS,
  ORDER_TYPES,
} from 'constants/constants.js';
import {
  Pagination,
  PositionLoader,
  RightSideModal,
} from 'Components/components.js';
import {
  SchemaHead,
  SchemaDetails,
  SchemaModalBody,
} from './components/components.js';
import { useModal } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName, getWidgetLabel } from 'utils/helpers/helpers.js';
import { getColumns } from 'slices/actions.js';
import { columnNameId } from 'Components/Columns/HeadCellsEnum.js';

const headCells = [
  { id: 'Type', label: '' },
  { id: 'ColumnsName', label: 'Columns name' },
  { id: 'Description', label: 'Description' },
];

const Schema = forwardRef((props, ref) => {
  const { table, dataset, toggleExpandButton, handleChangeDivider } = props;
  const classes = useStyles();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const { setModal } = useModal();
  const dispatch = useDispatch();
  const { schema } = getWidgetLabel();

  const { columns, columnsNumber, columnsStatus } = useSelector((state) => ({
    columns: state.columns.columns,
    columnsNumber: state.columns.columnsNumber,
    columnsStatus: state.columns.columnsStatus,
  }));

  const isLoadingColumns = useMemo(() => {
    return columnsStatus === DATA_STATUS.pending;
  }, [columnsStatus]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getColumns({
          dataset,
          table,
          column: '',
          isColumnEqual: false,
          order: ORDER_TYPES.asc,
          sortBy: columnNameId,
          page: page + 1,
          limit: rowsPerPage,
        })
      );
    }
  }, [page, rowsPerPage, dispatch, dataset, table]);

  useEffect(() => {
    toggleExpandButton(columns.length > 0);
    handleChangeDivider(columns.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns.length]);

  useImperativeHandle(ref, () => ({
    openSchemaModal() {
      setModal(() => (
        <RightSideModal
          modalWidth={1200}
          totalCount={columnsNumber}
          title='Table Schema'
          ModalBodyComponent={SchemaModalBody}
          table={table}
          dataset={dataset}
        />
      ));

      amplEvent(
        `${AMPL_PAGE_EVENT.monitors} -> ${getFullTableName(
          table,
          dataset
        )} -> Widget ${schema.title} -> click -> Expand button`
      );
    },
  }));

  if (columns.length === 0) {
    return '';
  }

  return (
    <>
      <Box>
        <Paper sx={{ boxShadow: 'none' }}>
          <TableContainer>
            <Table>
              <SchemaHead headCells={headCells} />
              <TableBody className={classes.tableBody}>
                {isLoadingColumns && (
                  <div className='loaderContainer'>
                    <PositionLoader />
                  </div>
                )}
                {columns.map((column) => (
                  <SchemaDetails key={column.uuid} column={column} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>

      <Pagination
        count={columnsNumber}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        showFirstButton={false}
        showLastButton={false}
      />
    </>
  );
});

export { Schema };
