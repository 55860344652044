import React from 'react';
import {
  useEffect,
  useState,
  useBqBillingData,
  useGetProjects,
  useCallback,
  useCostsStats,
  useCostsDestinationTables,
  usePipelinesSubtypesData,
  useCostsStatsPrevious,
  useMemo,
  useCostsDeadEndTables,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import {
  PIPELINE_SUBTYPE_LABELS,
  ALERT_FILTER_OPTIONS,
} from 'utils/constants.js';
import {
  COST_EFFICIENCY_FILTER_OPTIONS,
  DEAD_END_TABLES,
} from 'constants/constants.js';
import { CostsWelcome, CostsTable } from '../components.js';
import {
  PositionLoader,
  EmptyData,
  CostsStats,
  Backdrop,
} from 'Components/components.js';
import { useUserInfo } from 'context/context.js';
import {
  COST_EFFICIENCY_FILTER,
  INITIAL_TECHNOLOGY_OPTION,
  SEARCH_DESTINATION_TABLE,
  SEARCH_EMAIL,
  SEARCH_LABEL,
  SEARCH_TABLE,
  SELECTED_DESTINATION_TABLE,
  SELECTED_EMAIL,
  SELECTED_LABEL,
  SELECTED_TABLE,
  SELECTED_TAGS,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';

const FILTER_OPTION_ALL = { value: '', label: 'All Job Types' };

const CostsTab = ({
  startDate,
  endDate,
  searchValues,
  handleChangeSearchValues,
  page,
  rowsPerPage,
  handleChangePage,
  onReceivePaginationTotal,
}) => {
  const classes = useStyles();
  const { isCurrentProjectDemo } = useUserInfo();
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);

  const [pipelineSubtypes, setPipelineSubtypes] = useState([]);
  const [filterPipelineSubtypeOptions, setFilterPipelineSubtypeOptions] =
    useState([]);
  const [filterPipelineSubtypeSelected, setFilterPipelineSubtypeSelected] =
    useState([FILTER_OPTION_ALL]);

  const [dropdownsValue, setDropdownsValue] = useState({
    [COST_EFFICIENCY_FILTER]: [COST_EFFICIENCY_FILTER_OPTIONS[0]],
    [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
  });

  const { projects, isLoadingProjects } = useGetProjects();

  const isDeadEndTablesSelected = useMemo(() => {
    return dropdownsValue[COST_EFFICIENCY_FILTER][0].value === DEAD_END_TABLES;
  }, [dropdownsValue]);

  const {
    currentBqBillingData,
    isWelcome,
    isBqBillingDataFetching,
    bqBillingData,
  } = useBqBillingData();

  const isAllowedStatsRequest = !isWelcome || isCurrentProjectDemo;

  const { costsStats, isCostsStatsFetching } = useCostsStats(
    startDate,
    endDate,
    isAllowedStatsRequest
  );

  const { costsStatsPrevious } = useCostsStatsPrevious(
    startDate,
    endDate,
    isAllowedStatsRequest
  );

  const {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  } = useCostsDestinationTables({
    startDate,
    endDate,
    page,
    rowsPerPage,
    searchDestinationTable: searchValues[SELECTED_DESTINATION_TABLE]?.table,
    searchDestinationDataset: searchValues[SELECTED_DESTINATION_TABLE]?.dataset,
    searchDestinationValue: searchValues[SEARCH_DESTINATION_TABLE],
    searchTable: searchValues[SELECTED_TABLE]?.table,
    searchDataset: searchValues[SELECTED_TABLE]?.dataset,
    searchValue: searchValues[SEARCH_TABLE],
    selectedEmail: searchValues[SELECTED_EMAIL],
    searchEmail: searchValues[SEARCH_EMAIL],
    selectedTags: searchValues[SELECTED_TAGS],
    selectedLabelKey: searchValues[SELECTED_LABEL]?.key,
    selectedLabelValue: searchValues[SELECTED_LABEL]?.value,
    searchLabel: searchValues[SEARCH_LABEL],
    alertTypes: dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER][0].value,
    pipelineSubtypes,
    isDeadEndTablesSelected,
    enabled: isAllowedStatsRequest,
  });

  const isAllowedSubtypesRequest =
    dropdownsValue[TECHNOLOGY_FILTER][0].value !==
      INITIAL_TECHNOLOGY_OPTION.value && isAllowedStatsRequest;

  const { pipelineSubtypesData } = usePipelinesSubtypesData(
    startDate,
    endDate,
    dropdownsValue[TECHNOLOGY_FILTER][0].value,
    isAllowedSubtypesRequest
  );

  const { deadEndTablesStats } = useCostsDeadEndTables(
    startDate,
    endDate,
    isAllowedStatsRequest
  );

  useEffect(() => {
    if (pipelineSubtypesData) {
      updateFilterPipelineSubtypeOptions(pipelineSubtypesData);
    }
  }, [pipelineSubtypesData]);

  const updateFilterPipelineSubtypeOptions = (values) => {
    setFilterPipelineSubtypeSelected([FILTER_OPTION_ALL]);
    setFilterPipelineSubtypeOptions([
      FILTER_OPTION_ALL,
      ...values.map((value) => {
        return {
          value: value,
          label: PIPELINE_SUBTYPE_LABELS[value] || 'Unknown',
        };
      }),
    ]);
  };

  const onFilterPipelineSubtypeSelect = (values) => {
    handleChangePage({}, 0);
    setFilterPipelineSubtypeSelected(values);
  };

  useEffect(() => {
    onReceivePaginationTotal(destinationTablesTotal);
  }, [destinationTablesTotal, onReceivePaginationTotal]);

  useEffect(() => {
    setPipelineSubtypes(
      filterPipelineSubtypeSelected
        .filter((item) => item.value !== '')
        .map((item) => item.value)
    );
  }, [filterPipelineSubtypeSelected]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const handleChangeOpenBackdrop = useCallback((value) => {
    setIsOpenBackdrop(value);
  }, []);

  useEffect(() => {
    handleChangePage({}, 0);
  }, [handleChangePage, startDate, endDate]);

  if (isLoadingProjects || isCostsStatsFetching || isBqBillingDataFetching) {
    return <PositionLoader />;
  }

  if (isWelcome && !isCurrentProjectDemo) {
    return <CostsWelcome projects={projects} bqBillingData={bqBillingData} />;
  }

  return (
    <>
      {costsStats.length > 0 && (
        <CostsStats
          costsStats={costsStats}
          isCostsStatsFetching={isCostsStatsFetching}
          costsStatsPrevious={costsStatsPrevious}
          deadEndTablesStats={deadEndTablesStats}
          startDate={startDate}
          endDate={endDate}
          className={classes.statsContainer}
        />
      )}

      <div className={classes.mainContainer}>
        {costsStats.length > 0 ? (
          <CostsTable
            destinationTables={destinationTables}
            searchValues={searchValues}
            onChangeSearchValues={handleChangeSearchValues}
            startDate={startDate}
            endDate={endDate}
            isFetchingDestinationTables={isFetchingDestinationTables}
            pipelines={costsStats}
            alertTypes={dropdownsValue[TABLE_ALERT_STATUS_FILTER]}
            selectedPipelineType={dropdownsValue[TECHNOLOGY_FILTER][0].value}
            pipelineSubtypes={pipelineSubtypes}
            isDeadEndTablesSelected={isDeadEndTablesSelected}
            currentBqBillingData={currentBqBillingData}
            filterPipelineSubtypeOptions={filterPipelineSubtypeOptions}
            filterPipelineSubtypeSelected={filterPipelineSubtypeSelected}
            onFilterPipelineSubtypeSelect={onFilterPipelineSubtypeSelect}
            onChangeDropdownsValue={handleChangeDropdownsValue}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
          />
        ) : (
          <EmptyData title='No data for this period' />
        )}

        <Backdrop isOpen={isOpenBackdrop} />
      </div>
    </>
  );
};

export { CostsTab };
