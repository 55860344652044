import { configureStore } from '@reduxjs/toolkit';
import tablesSlice from 'slices/tables/tables.slice.js';
import columnsSlice from 'slices/columns/columns.slice.js';
import lookerSlice from 'slices/looker/looker.slice.js';
import projectsSlice from 'slices/projects/projects.slice.js';
import projectTreeSlice from 'slices/project-tree/project-tree.slice.js';
import dataScanSlice from 'slices/data-scans/data-scans.slice.js';

export default configureStore({
  reducer: {
    tables: tablesSlice,
    columns: columnsSlice,
    looker: lookerSlice,
    projects: projectsSlice,
    projectTree: projectTreeSlice,
    dataScans: dataScanSlice,
  },
});
