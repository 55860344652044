import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'inline-block',
    cursor: 'pointer',
    position: 'relative',
    marginRight: 16,
  },
  label: {
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.common.white,
    border: '1px solid #BEC5E7',
    borderRadius: 4,
    '&:hover': {
      '& svg path': {
        fill: theme.palette.primary.main,
      },
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.opened': {
      '& svg path': {
        fill: theme.palette.primary.main,
      },
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '&.selected': {
      background: theme.palette.secondary.main,
    },
  },
  list: {
    position: 'absolute',
    top: 40,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '100%',
    width: 160,
    background: theme.palette.common.white,
    borderRadius: 12,
    listStyle: 'none',
    padding: 0,
    zIndex: 3,
    filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16))',
  },
  listItem: {
    margin: 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.mainDarkText,
    fontSize: 13,
    fontWeight: 500,
    padding: '4px 8px',
    lineHeight: '18px',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&.selected': {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      borderRadius: 12,
    },
  },
}));
