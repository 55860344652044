import axios from 'axios';

export const fetcherGet = (url, params) =>
  axios.get(url, { params }).then((res) => res.data);

export const fetcherPost = (url, data, params) =>
  axios.post(url, data, params).then((res) => res.data);

export const fetcherPut = (url, data, params) =>
  axios.put(url, data, params).then((res) => res.data);

export const fetcherPatch = (url, data, params) =>
  axios.patch(url, data, params).then((res) => res.data);

export const fetcherDelete = (url, data, params) =>
  axios.delete(url, data, params).then((res) => res.data);
