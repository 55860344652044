import { JobStatuses } from '../constants/constants.js';

const getJobStatusText = (status, rulesFailed) => {
  if (status === JobStatuses.PENDING) {
    return 'Pending';
  }

  if (status === JobStatuses.CANCELING) {
    return 'Cancelling';
  }

  if (status === JobStatuses.FAILED || rulesFailed) {
    return `${rulesFailed} rules failed`;
  }

  if (status === JobStatuses.CANCELLED) {
    return `Cancelled`;
  }

  if (status === JobStatuses.SUCCEEDED) {
    return 'Success';
  }

  if (status === JobStatuses.RUNNING) {
    return 'Running';
  }

  return '';
};

export { getJobStatusText };
