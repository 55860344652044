const millisecondsToHoursTime = (milliseconds) => {
  const seconds = (milliseconds / 1000) % 60;
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));

  let formattedSeconds = null;

  if (seconds < 1 && seconds > 0) {
    formattedSeconds = seconds.toFixed(1);
  } else {
    formattedSeconds = Math.floor(seconds);
  }

  const hoursText = hours > 0 ? `${hours} h` : '';
  const minutesText = minutes > 0 ? `${minutes} min` : '';
  const secondsText = formattedSeconds > 0 ? `${formattedSeconds} sec` : '';

  return [hoursText, minutesText, secondsText]
    .filter((text) => text.length > 0)
    .join(' ');
};

export { millisecondsToHoursTime };
