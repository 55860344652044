import React, { useMemo } from 'react';
import { useState, useCallback, useEffect } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Toolbar } from 'Components/components.js';
import {
  ColumnNameBlock,
  RuleTypesBlock,
  EditRuleBlock,
} from './components/components.js';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
  },
}));

const BuiltInRuleModalBody = ({
  closeModal,
  table = '',
  dataset = '',
  saveRules = () => {},
}) => {
  const classes = useStyles();
  const [rules, setRules] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRuleType, setSelectedRuleType] = useState(null);
  const [toolbarText, setToolbarText] = useState('');
  const [isNewRuleAdded, setIsNewRuleAdded] = useState(false);

  // const saveAddedRules = useCallback(() => {
  //   saveRules(rules);
  //   setRules([]);
  // }, [rules, saveRules]);

  // const isDisabledSaveRules = useMemo(() => {
  //   return !rules.length;
  // }, [rules]);

  const addedRuleTypes = useMemo(() => {
    if (!selectedColumn?.name) {
      return [];
    }
    return rules
      .filter((rule) => {
        return rule.column === selectedColumn.name;
      })
      .map((rule) => rule.data.type);
  }, [rules, selectedColumn?.name]);

  const handleChangeSelectedColumn = useCallback((column) => {
    setSelectedColumn(column);
  }, []);

  const handleChangeSelectedRule = useCallback((ruleType) => {
    setSelectedRuleType(ruleType);
  }, []);

  const handleChangeNewRule = useCallback(
    (value) => {
      saveRules([value]);
      setRules([]);
      // setRules((prev) => {
      //   return [...prev, value];
      // });
      setIsNewRuleAdded(true);
    },
    [saveRules]
  );

  useEffect(() => {
    if (isNewRuleAdded) {
      setToolbarText(`${selectedRuleType?.label} added`);
      setTimeout(() => {
        setToolbarText('');
        setIsNewRuleAdded(false);
      }, 3000);
    }
  }, [isNewRuleAdded, selectedRuleType]);

  return (
    <div className={classes.container}>
      <ColumnNameBlock
        selectedColumn={selectedColumn}
        onColumnClick={handleChangeSelectedColumn}
        table={table}
        dataset={dataset}
      />
      <RuleTypesBlock
        selectedColumn={selectedColumn}
        selectedRuleType={selectedRuleType}
        onRuleClick={handleChangeSelectedRule}
        addedRuleTypes={addedRuleTypes}
      />
      <EditRuleBlock
        selectedColumn={selectedColumn}
        selectedRuleType={selectedRuleType}
        closeModal={closeModal}
        addNewRule={handleChangeNewRule}
        // onSaveRules={saveAddedRules}
        // isDisabledSaveRules={isDisabledSaveRules}
      />

      <Toolbar actionText={toolbarText} />
    </div>
  );
};

export { BuiltInRuleModalBody };
