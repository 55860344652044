const SLICE_NAME = {
  tables: 'tables',
  columns: 'columns',
  looker: 'looker',
  gbqIncidents: 'gbqIncidents',
  projects: 'projects',
  projectTree: 'projectTree',
  dataScan: 'dataScan',
};

export { SLICE_NAME };
