import { makeStyles } from '@mui/styles';
import BannerBgdImg from 'assets/img/glow.svg';

export const ownStyles = makeStyles((theme) => ({
  list: {
    marginBottom: 32,
    paddingLeft: 20,
  },
  leftSideOwn: {
    backgroundImage: `url(${BannerBgdImg})`,
    backgroundColor: theme.palette.common.mainDarkText,
    '& .text-container': {
      width: 324,
      color: theme.palette.common.white,
    },
  },
}));
