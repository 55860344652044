import { makeStyles } from '@mui/styles';
import checkIcon from 'assets/img/dropdownIcons/checkMark.svg';

export const useStyles = makeStyles((theme) => ({
  datePicker: {
    width: 'auto',
    whiteSpace: 'nowrap',
  },
  datePickerInput: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  calendarContainer: {
    zIndex: 3,
    '&[data-placement=bottom-start]': {
      left: '160px !important',
    },
    '& .react-datepicker': {
      boxShadow:
        'rgba(0, 0, 0, 0.04) 6px 2px 16px 0px, rgba(0, 0, 0, 0.04) -6px -2px 16px 0px',
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      border: 'none',
      '&__triangle': {
        '&::after, &::before': {
          display: 'none',
        },
      },
      '&__navigation-icon::before': {
        width: 6,
        height: 6,
        top: 11,
        borderWidth: '1px 1px 0 0',
        borderColor: theme.palette.common.mainDarkText,
      },
      '&__year-read-view': {
        border: 'none',
        '&:hover .react-datepicker__year-read-view--down-arrow': {
          borderTopColor: theme.palette.common.mainDarkText,
        },
        '&--down-arrow': {
          width: 6,
          height: 6,
          top: 6,
          right: '-12px',
          borderWidth: '1px 1px 0 0',
          borderColor: theme.palette.common.mainDarkText,
          '&:hover': {
            borderColor: theme.palette.common.mainDarkText,
          },
        },
      },
      '&__header': {
        backgroundColor: theme.palette.common.white,
        border: 'none',
      },
      '&__current-month': {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '22px',
        color: theme.palette.common.mainDarkText,
      },
      '&__day': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        width: 32,
        height: 32,
        margin: 0,
        color: theme.palette.common.mainDarkText,
        '&-name': {
          fontFamily: theme.typography.fontFamily,
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 11,
          lineHeight: '14px',
          color: theme.palette.common.secondaryDark,
        },
        '&--today': {
          background: theme.palette.secondary.light,
          borderRadius: '50%',
          fontWeight: 500,
        },
        '&--disabled': {
          color: theme.palette.common.secondaryDark,
          '&:hover': {
            cursor: 'not-allowed',
            color: theme.palette.common.secondaryDark,
            border: 'none',
          },
        },
        '&--outside-month': {
          opacity: 0,
          pointerEvents: 'none',
        },
        '&:hover': {
          color: theme.palette.primary.main,
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '50%',
          backgroundColor: theme.palette.common.white,
          cursor: 'pointer',
        },
        '&--in-selecting-range': {
          backgroundColor: theme.palette.secondary.light,
          color: theme.palette.common.mainDarkText,
          borderRadius: '50%',
        },
        '&--selecting-range': {
          '&-start': {
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            color: theme.palette.common.white,
          },
        },
        '&--selected, &--in-range': {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: '50%',
          color: theme.palette.common.mainDarkText,
        },
        '&--range-start, &--range-end': {
          backgroundColor: theme.palette.primary.main,
          borderRadius: '50%',
          color: theme.palette.common.white,
        },
      },
      '&__year': {
        '&-dropdown': {
          padding: 8,
          backgroundColor: theme.palette.common.white,
          border: 'none',
          borderRadius: 6,
          filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.16))',
        },
        '&-option': {
          '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            borderRadius: 12,
          },
          '&--selected': {
            display: 'none',
            '&_year': {
              color: theme.palette.primary.main,
              backgroundImage: `url(${checkIcon})`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: '6px',
              backgroundPositionY: 'center',
            },
          },
        },
      },
      '&__navigation--years': {
        display: 'none',
      },
    },
  },
  dateTimeContainer: {
    '& .react-datepicker': {
      display: 'flex',
      borderRadius: 6,
      '&__day': {
        '&--selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
      },
      '&__time-container': {
        border: 'none',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 13,
        lineHeight: '22px',
        color: theme.palette.common.mainDarkText,
        '& .react-datepicker-time__header, .react-datepicker__time-list-item': {
          fontFamily: theme.typography.fontFamily,
          fontWeight: 500,
          fontSize: 13,
          lineHeight: '22px',
          color: theme.palette.common.mainDarkText,
        },
        '& .react-datepicker__time-list-item': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&--selected': {
            backgroundColor: `${theme.palette.secondary.main} !important`,
            color: `${theme.palette.primary.main} !important`,
            '&:hover': {
              backgroundColor: `${theme.palette.secondary.main} !important`,
            },
          },
          '&:hover': {
            backgroundColor: `${theme.palette.common.white} !important`,
            color: theme.palette.primary.main,
          },
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 16,
          bottom: 16,
          right: 85,
          width: 1,
          background: theme.palette.divider,
        },
      },
    },
  },
  listContainer: {
    width: 160,
    height: '100%',
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    top: 0,
    right: '100%',
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    boxShadow:
      'rgba(0, 0, 0, 0.04) -2px 2px 16px 0px, rgba(0, 0, 0, 0.04) -6px -2px 16px 0px',
  },
  list: {
    listStyle: 'none',
    margin: '12px 8px',
    padding: 0,
    '& .listItem': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 8px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&.selected': {
        color: theme.palette.primary.main,
        background: theme.palette.secondary.main,
        borderRadius: 12,
      },
    },
  },
  divider: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 16,
      bottom: 16,
      left: 160,
      width: 1,
      background: theme.palette.divider,
    },
  },
  buttonsContainer: {
    margin: '4px 16px 16px 16px',
  },
  cancelButton: {
    '&.btn.MuiButton-root': {
      width: 106,
      height: 44,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
  agreeButton: {
    '&.btn.MuiButton-root': {
      width: 100,
      height: 44,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
