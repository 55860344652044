import { useState, useQuery, useEffect } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const JOBS_LIMIT = 5;

const usePipelineLastRuns = (jobHash) => {
  const [pipelineLastRuns, setPipelineLastRuns] = useState();

  const { data, isFetching } = useQuery(
    [QUERY_TYPES.pipelineLastJobs, jobHash, JOBS_LIMIT],
    ({ queryKey }) => {
      const [url, jobHash, limit] = queryKey;
      return fetcherGet(url, { jobHash, limit });
    },
    { enabled: !!jobHash }
  );

  useEffect(() => {
    if (data?.values) {
      setPipelineLastRuns(data.values);
    }
  }, [data?.values]);

  return { pipelineLastRuns, isFetching };
};

export { usePipelineLastRuns };
