import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { IconButton as MaterialIconButton } from '@mui/material';

const ICON_BUTTON_COLOR = {
  primary: 'primary',
  secondary: 'secondary',
};

const ICON_BUTTON_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiIconButton-root': {
      '&:hover, &:active': {
        backgroundColor: 'transparent',
        '& svg path': {
          fill: theme.palette.primary.main,
        },
      },
      '&.Mui-disabled': {
        cursor: 'not-allowed',
        '& svg path': {
          fill: theme.palette.text.disabled,
        },
      },
    },
    '& .MuiIconButton-sizeMedium': {},
  },
}));

const IconButton = ({
  // TODO size styles
  icon,
  onClick,
  isDisabled = false,
  className = '',
  color = ICON_BUTTON_COLOR.primary,
  size = ICON_BUTTON_SIZE.medium,
}) => {
  const classes = useStyles();

  return (
    <MaterialIconButton
      onClick={onClick}
      disabled={isDisabled}
      className={clsx(classes.button, className)}
      color={color}
      size={size}
    >
      {icon}
    </MaterialIconButton>
  );
};

export { IconButton };
