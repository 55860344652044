import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { EmptySearchData } from 'Pages/Pipelines/components/components.js';
import { ResourceTableHead } from './ResourceTableHead.js';
import { ResourceTableRows } from './ResourceTableRows.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const ResourceTable = ({
  destinationTables = [],
  isFetchingDestinationTables,
  startDate,
  endDate,
  selectedTag,
}) => {
  const classes = useStyles();
  const isEmptySearch =
    selectedTag.length &&
    !destinationTables.length &&
    !isFetchingDestinationTables;

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <ResourceTableHead />
        <TableBody className={classes.tableBody}>
          {isEmptySearch ? (
            <EmptySearchData searchType='label/tag' colSpan={7} />
          ) : (
            destinationTables.length > 0 &&
            destinationTables.map((destinationTable, index) => {
              return (
                <ResourceTableRows
                  key={
                    getFullTableName(
                      destinationTable.table,
                      destinationTable.dataset
                    ) + index
                  }
                  destinationTable={destinationTable}
                  startDate={startDate}
                  endDate={endDate}
                  selectedTag={selectedTag}
                />
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { ResourceTable };
