const getPipelineDiff = (pipeline, statsPrevious) => {
  const findPipelineBefore = statsPrevious.find(
    (item) => item.pipelineType === pipeline.pipelineType
  );
  if (findPipelineBefore) {
    const beforeCosts = findPipelineBefore.costs;
    if (beforeCosts === 0) {
      return null;
    }
    const costs = pipeline.costs;
    const perc = (costs / beforeCosts) * 100;
    return Math.round(100 - perc) * -1;
  }

  return null;
};

const getTotalPipelineDiff = (totalAmount, totalAmountPrevious) => {
  if (totalAmountPrevious === 0) {
    return null;
  }

  const perc = (totalAmount / totalAmountPrevious) * 100;
  const totalAmountInfo = Math.round(100 - perc) * -1;

  return totalAmountInfo;
};

export { getPipelineDiff, getTotalPipelineDiff };
