export const firebaseConfig = {
  apiKey: 'AIzaSyCMiMvl7la-3n79vzN2od1p0qtHvem8bNc',
  authDomain: 'masthead-prod.firebaseapp.com',
  projectId: 'masthead-prod',
  storageBucket: 'masthead-prod.appspot.com',
  messagingSenderId: '431544431936',
  appId: '1:431544431936:web:991b30bdcc6d695c8b76a5',
};

// export const firebaseConfig = {
//   apiKey: "AIzaSyBHZYNgOon5qwgtJjiI1nk_rXVO8LpV1YQ",
//   authDomain: "gothic-sequence-307320.firebaseapp.com",
//   projectId: "gothic-sequence-307320",
//   storageBucket: "gothic-sequence-307320.appspot.com",
//   messagingSenderId: "2002720941",
//   appId: "1:2002720941:web:76cab6b583097ac5efce24",
//   measurementId: "G-M1Q1W6K4DD"
// };
