import React, { createContext } from 'react';
import { useContext, useEffect, useState, useMemo } from 'hooks/hooks.js';
import { onIdTokenChanged } from 'firebase/auth';
import { auth, amplEvent } from 'service/services.js';
import { AUTH_ERRORS } from 'constants/constants.js';
import { fetcherPost } from 'utils/utils.js';
import { DEMO_PROJECT } from 'utils/constants.js';
import { PositionLoader } from 'Components/components.js';

const UserContext = createContext(null);
const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(false);

  const currentProject = useMemo(() => {
    return user?.info.currentProject;
  }, [user?.info.currentProject]);

  const isCurrentProjectDemo = useMemo(() => {
    return currentProject === DEMO_PROJECT;
  }, [currentProject]);

  useEffect(() => {
    onIdTokenChanged(auth, (res) => {
      if (!res || res.accessToken === undefined || !res.accessToken) {
        setLoading(false);
        return setUser(null);
      }
      setUserLoading(true);

      fetcherPost('/auth', { token: res.accessToken })
        .then((data) => {
          if (data.value !== undefined && data.value) {
            res.info = data.value;
            amplEvent('Auth -> IdTokenChanged');
            setUser(res);
            setAuthError(null);
          } else {
            setAuthError(AUTH_ERRORS.USER_NOT_FOUND);
            auth.signOut().then();
          }
        })
        .catch(() => {
          setAuthError(AUTH_ERRORS.STH_ELSE);
          auth.signOut().then();
        })
        .finally(() => {
          setLoading(false);
          setUserLoading(false);
        });
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        authError,
        setAuthError,
        userLoading,
        currentProject,
        isCurrentProjectDemo,
      }}
    >
      {loading ? <PositionLoader /> : children}
    </UserContext.Provider>
  );
};

const useUserInfo = () => useContext(UserContext);

export { UserContext, UserContextProvider, useUserInfo };
