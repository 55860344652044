const datasetNameId = 'name';
const descriptionId = 'description';
const alertStatusId = 'alertType';

const DatasetNameHead = {
  id: datasetNameId,
  label: 'Dataset name',
  sort: true,
};

const AlertStatusHead = {
  id: alertStatusId,
  label: 'Status',
  sort: true,
};

const DescriptionHead = {
  id: descriptionId,
  label: 'Description',
};

export { DatasetNameHead, AlertStatusHead, DescriptionHead };
