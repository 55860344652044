import React from 'react';
import {
  useCallback,
  useHistory,
  useMemo,
  useState,
  useEffect,
  useDispatch,
  useSelector,
} from 'hooks/hooks.js';
import { useStyles } from './ScanCreation.styles.js';
import {
  BackButton,
  Button,
  BUTTON_VARIANT,
  Backdrop,
  BACKDROP_POSITION,
} from 'Components/components.js';
import { GeneralBlock, RulesBlock } from './components/components.js';
import { useMessages } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  DATA_STATUS,
  QUERY_TYPES,
} from 'constants/constants.js';
import {
  ALL_DATA_SAMPLING_SIZE,
  ENTIRE_DATA_SCOPE,
  INCREMENTAL_SCOPE,
} from './libs/constants/constants.js';
import { clearCreateDataScanStatus, createDataScan } from 'slices/actions.js';
import { getRequestDTOFromDataScan } from './libs/mappers/mappers.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherPost } from 'utils/utils.js';

const ScanCreation = () => {
  const classes = useStyles();
  const { setMessage } = useMessages();
  const history = useHistory();
  const dispatch = useDispatch();
  const [newRules, setNewRules] = useState([]);
  const [isUniqueScanIdError, setIsUniqueScanIdError] = useState(false);

  useEffect(() => amplEvent(AMPL_PAGE_EVENT.createDataScan), []);

  const { createDataScanStatus } = useSelector((state) => ({
    createDataScanStatus: state.dataScans.createDataScanStatus,
  }));

  const isLoadingScanCreating = useMemo(() => {
    return createDataScanStatus === DATA_STATUS.pending;
  }, [createDataScanStatus]);

  const [scanCreationData, setScanCreationData] = useState({
    scanId: '',
    isScanIdError: false,
    dataset: '',
    table: '',
    tableLocation: '',
    scheduleCron: null,
    isScheduleCronError: null,
    selectedScope: ENTIRE_DATA_SCOPE,
    incrementalColumn: null,
    isRowFilterSelected: true,
    rowFilter: null,
    samplingPercent: ALL_DATA_SAMPLING_SIZE,
    isSamplingPercentError: null,
  });

  const handleChangeScanCreationData = useCallback(
    (
      scanId,
      isScanIdError,
      table,
      dataset,
      tableLocation,
      scheduleCron,
      isScheduleCronError,
      selectedScope,
      incrementalColumn,
      isRowFilterSelected,
      rowFilter,
      samplingPercent,
      isSamplingPercentError
    ) => {
      setScanCreationData({
        scanId,
        isScanIdError,
        dataset,
        table,
        tableLocation,
        scheduleCron: scheduleCron,
        isScheduleCronError,
        selectedScope,
        incrementalColumn,
        isRowFilterSelected,
        rowFilter,
        samplingPercent,
        isSamplingPercentError,
      });
    },
    []
  );

  const isDisabledAddRuleButton = useMemo(() => {
    return (
      !scanCreationData.scanId ||
      scanCreationData.isScanIdError ||
      !scanCreationData.table ||
      !scanCreationData.dataset ||
      !scanCreationData.tableLocation ||
      scanCreationData.isScheduleCronError ||
      (scanCreationData.selectedScope === INCREMENTAL_SCOPE &&
        !scanCreationData.incrementalColumn) ||
      (scanCreationData.isRowFilterSelected && !scanCreationData.rowFilter) ||
      scanCreationData.isSamplingPercentError
    );
  }, [scanCreationData]);

  const handleAddRules = useCallback((values) => {
    setNewRules((prev) => {
      return [...values, ...prev];
    });
  }, []);

  const handleEditRule = useCallback((value) => {
    setNewRules((prev) => {
      const newRules = [...prev];
      newRules.splice(
        newRules.findIndex((item) => item.id === value.id),
        1,
        value
      );
      return newRules;
    });
  }, []);

  const handleRemoveNewRules = useCallback((value) => {
    setNewRules((prev) => {
      return [...prev.filter((item) => item.id !== value.id)];
    });
  }, []);

  const handleChangeIsUniqueScanIdError = useCallback((value) => {
    setIsUniqueScanIdError(value);
  }, []);

  const create = useCallback(
    (isRun = false) => {
      fetcherPost(
        `${QUERY_TYPES.dataPlex}/locations/${scanCreationData.tableLocation}/dataScans/${scanCreationData.scanId}/validation`
      ).then((data) => {
        if (!data.success) {
          setIsUniqueScanIdError(true);
        } else {
          setIsUniqueScanIdError(false);
          const createDataScanRequest = getRequestDTOFromDataScan(
            scanCreationData,
            newRules
          );
          dispatch(
            createDataScan({
              location: scanCreationData.tableLocation,
              dataScan: createDataScanRequest,
              isRun,
            })
          );
        }
      });
    },
    [scanCreationData, newRules, dispatch]
  );

  const createWithoutRun = useCallback(() => {
    create(false);
  }, [create]);

  const createAndRun = useCallback(() => {
    create(true);
  }, [create]);

  useEffect(() => {
    if (createDataScanStatus === DATA_STATUS.fulfilled) {
      dispatch(clearCreateDataScanStatus());
      history.push(AppRoutes.DataQuality.path);
    }

    if (createDataScanStatus === DATA_STATUS.rejected) {
      setMessage('Something went wrong, try again');
      dispatch(clearCreateDataScanStatus());
    }
  }, [createDataScanStatus, dispatch, history, scanCreationData, setMessage]);

  const isDisabledCreateButton = useMemo(() => {
    return newRules.length === 0 || scanCreationData.isScanIdError;
  }, [newRules.length, scanCreationData]);

  const isShowedCreateAndRunButton = useMemo(() => {
    return scanCreationData.scheduleCron === null;
  }, [scanCreationData]);

  return (
    <>
      <div className={classes.headerContainer}>
        <BackButton />
        <div className='txt-mainDark-20-700'>Create data quality scan</div>
      </div>

      <div className={classes.mainContainer}>
        <GeneralBlock
          updateScanData={handleChangeScanCreationData}
          isUniqueScanIdError={isUniqueScanIdError}
          changeIsUniqueScanIdError={handleChangeIsUniqueScanIdError}
        />
        <RulesBlock
          dataset={scanCreationData.dataset}
          table={scanCreationData.table}
          isDisabledAddRuleButton={isDisabledAddRuleButton}
          saveRules={handleAddRules}
          editRule={handleEditRule}
          removeRule={handleRemoveNewRules}
          rules={newRules}
        />

        <div className={classes.buttonsContainer}>
          <Button
            text='Create scan'
            fullWidth={false}
            isDisabled={isDisabledCreateButton}
            onClick={createWithoutRun}
          />
          {isShowedCreateAndRunButton && (
            <Button
              text='Create and run scan'
              fullWidth={false}
              isDisabled={isDisabledCreateButton}
              onClick={createAndRun}
            />
          )}
          <Button
            variant={BUTTON_VARIANT.text}
            text='Cancel'
            fullWidth={false}
            onClick={history.goBack}
          />
        </div>
      </div>

      <Backdrop
        isOpen={isLoadingScanCreating}
        position={BACKDROP_POSITION.fixed}
      />
    </>
  );
};

export default ScanCreation;
