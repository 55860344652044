import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  DateRangePicker,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .title': {
      width: '100%',
    },
  },
}));

const PageHeader = ({
  children,
  hasDateRangePicker = true,
  startDate,
  endDate,
  onChooseDates,
  daysLimit = 0,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography
        variant={TYPOGRAPHY_VARIANT.h1}
        color={TYPOGRAPHY_COLOR.primary}
        className='title'
      >
        {children}
      </Typography>
      {hasDateRangePicker && (
        <DateRangePicker
          defaultStartDate={startDate}
          defaultEndDate={endDate}
          onChooseDates={onChooseDates}
          daysLimit={daysLimit}
        />
      )}
    </div>
  );
};

export { PageHeader };
