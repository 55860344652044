import { useState, useEffect, useMemo } from 'hooks/hooks.js';

const useOpenTableRawBySearch = (
  searchValue,
  searchTable,
  destinationTable
) => {
  const [isOpenTableRaw, setIsOpenTableRaw] = useState(false);

  const isNeedSearchInSources = useMemo(() => {
    if (
      searchTable &&
      (searchTable.table !== destinationTable.table ||
        searchTable.dataset !== destinationTable.dataset)
    ) {
      return true;
    }
    if (
      searchValue &&
      !destinationTable.table.includes(searchValue) &&
      !destinationTable.dataset.includes(searchValue)
    ) {
      return true;
    }

    return false;
  }, [
    destinationTable.dataset,
    destinationTable.table,
    searchTable,
    searchValue,
  ]);

  useEffect(() => {
    setIsOpenTableRaw(isNeedSearchInSources);
  }, [isNeedSearchInSources]);

  const handleChangeOpen = () => setIsOpenTableRaw((prev) => !prev);

  return { isOpenTableRaw, handleChangeOpen };
};

export { useOpenTableRawBySearch };
