import React from 'react';
import { Popover as MaterialPopover } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiPaper-root': {
      minWidth: 224,
      borderRadius: 12,
      '& > *': {
        padding: '8px 12px',
        fontFamily: theme.typography.fontFamily,
        fontSize: 13,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px',
        textTransform: 'none',
        letterSpacing: 'normal',
        cursor: 'pointer',
      },
    },
  },
}));

const Popover = ({ open, anchorEl, onClose, children }) => {
  const classes = useStyles();

  return (
    <MaterialPopover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={classes.container}
    >
      {children}
    </MaterialPopover>
  );
};

export { Popover };
