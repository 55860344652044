import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { CheckIcon, UncheckIcon } from 'assets/img/icons/checkIcons';

const useStyles = makeStyles((theme) => ({
  formFieldText: {
    '& .MuiFormControlLabel-label': {
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.mainDarkText,
      fontWeight: 700,
      fontSize: 13,
      lineHeight: '22px',
    },
    '& .MuiCheckbox-root svg': {
      height: 16,
      width: 16,
    },
    '& .MuiCheckbox-root': {
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 8,
    },
    '& .MuiFormControlLabel-asterisk': {
      display: 'none',
    },
  },
}));

const PrivacyCheckField = ({ checked, onChangeChecked }) => {
  const classes = useStyles();

  return (
    <FormControl className={clsx(classes.formFieldText, 'mb-1 mt-1')}>
      <FormControlLabel
        className='formFieldText--blue'
        control={
          <Checkbox
            icon={<UncheckIcon />}
            checkedIcon={<CheckIcon />}
            checked={checked}
            onChange={onChangeChecked}
            sx={{ '.MuiCheckbox-root svg': { height: 20, fontSize: 20 } }}
            required
          />
        }
        label={
          <>
            <span>I agree to the Masthead Data </span>
            <a
              href='https://mastheadata.com/privacy-policy'
              target='_blank'
              rel='noreferrer'
              style={{ color: '#0000D2' }}
            >
              Privacy Policy
            </a>
          </>
        }
      />
    </FormControl>
  );
};

export { PrivacyCheckField };
