import React from 'react';
import { useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useModal } from 'context/context.js';
import {
  Divider,
  DateRangePicker,
  Button,
  BUTTON_VARIANT,
} from 'Components/components.js';
import { BOTTOM_START } from 'Components/DateRangePicker/DateRangePicker';
import { defaultEndDate, defaultStartDate } from 'utils/helpers/helpers.js';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 474,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 16,
    background: theme.palette.common.white,
    borderRadius: 16,
  },
  modalDesc: {
    marginBottom: 16,
  },
  buttonsContainer: {
    marginTop: 20,
    display: 'flex',
    gap: 12,
    justifyContent: 'end',
    alignItems: 'center',
  },
  downloadButton: {
    width: 140,
  },
}));

const ReportDownloadModal = ({
  onAgree,
  title = 'Download Report',
  text = '',
  agreeButtonText = 'Download',
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const closeModal = () => setModal(null);
  const [startDate, setStartDate] = useState(defaultStartDate());
  const [endDate, setEndDate] = useState(defaultEndDate());
  const onChooseDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={clsx(classes.modalTitle, 'txt-mainDark-16-700')}>
        {title}
      </div>
      <Divider />
      <div className={clsx(classes.modalDesc, 'txt-mainDark-13-500')}>
        {text}
      </div>

      <DateRangePicker
        defaultStartDate={startDate}
        defaultEndDate={endDate}
        onChooseDates={onChooseDates}
        calendarPlacement={BOTTOM_START}
      />

      <div className={classes.buttonsContainer}>
        <Button
          text='Cancel'
          variant={BUTTON_VARIANT.text}
          onClick={closeModal}
          fullWidth={false}
        />
        <Button
          text={agreeButtonText}
          className={classes.downloadButton}
          onClick={() => {
            onAgree(startDate, endDate);
            closeModal();
          }}
          fullWidth={false}
        />
      </div>
    </div>
  );
};

export { ReportDownloadModal };
