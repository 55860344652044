import { CHIP_COLOR } from 'Components/Chip/Chip.js';
import Critical from 'assets/img/alert/critical.svg';
import Priority from 'assets/img/alert/priority.svg';
import Regular from 'assets/img/alert/regular.svg';
import Mute from 'assets/img/alert/mute.svg';
import CustomPipelineIcon from 'assets/img/icons/dashboard-icons/CustomPipelineIcon.svg';
import DbtIcon from 'assets/img/icons/dashboard-icons/dbt-icon.svg';
import DataformIcon from 'assets/img/icons/dashboard-icons/dataform.svg';
import FivetranIcon from 'assets/img/icons/dashboard-icons/fivetran-icon.svg';
import DTSIcon from 'assets/img/icons/pipelines-icons/data-transfer-service-icon.svg';
import LookerIcon from 'assets/img/looker.svg';
import DataddoIcon from 'assets/img/dataddo.svg';
import TableauIcon from 'assets/img/tableau.svg';
import DomoIcon from 'assets/img/icons/pipelines-icons/domo-icon.svg';
import StitchIcon from 'assets/img/icons/pipelines-icons/stitch.svg';
import AirbyteIcon from 'assets/img/icons/pipelines-icons/airbyte.png';

import {
  MutedIcon,
  PriorityIcon,
  RegularIcon,
  CriticalIcon,
} from 'assets/img/alert';
import { TABLE_TYPES } from 'constants/constants.js';

export const CRITICAL_ALERT_TYPE = 'CRITICAL';
export const PRIORITY_ALERT_TYPE = 'PRIORITY';
export const REGULAR_ALERT_TYPE = 'REGULAR';
export const MUTED_ALERT_TYPE = 'MUTED';

export const ALERT_FILTER_OPTIONS = [
  { value: '', label: 'All statuses' },
  { value: CRITICAL_ALERT_TYPE, label: 'Critical' },
  { value: PRIORITY_ALERT_TYPE, label: 'Priority' },
  { value: REGULAR_ALERT_TYPE, label: 'Unspecified' },
  { value: MUTED_ALERT_TYPE, label: 'Muted' },
];

export const ALERT_TYPES_OPTIONS = [
  {
    value: CRITICAL_ALERT_TYPE,
    alertLabel: 'Critical',
    label: 'Make critical',
    icon: Critical,
    iconSvg: CriticalIcon,
    colorType: CHIP_COLOR.secondary,
  },
  {
    value: PRIORITY_ALERT_TYPE,
    alertLabel: 'Priority',
    label: 'Make priority',
    icon: Priority,
    iconSvg: PriorityIcon,
    colorType: CHIP_COLOR.warning,
  },
  {
    value: REGULAR_ALERT_TYPE,
    alertLabel: 'Unspecified',
    label: 'Make unspecified',
    icon: Regular,
    iconSvg: RegularIcon,
    colorType: CHIP_COLOR.info,
  },
  {
    value: MUTED_ALERT_TYPE,
    alertLabel: 'Muted',
    label: 'Make muted',
    icon: Mute,
    iconSvg: MutedIcon,
    colorType: CHIP_COLOR.default,
  },
];

export const DICTIONARY_TABLE_INTERNAL_TYPE = TABLE_TYPES.dbTable;
export const DICTIONARY_TABLE_VIEW_TYPE = TABLE_TYPES.view;
export const DICTIONARY_TABLE_EXTERNAL_TYPE = TABLE_TYPES.external;

export const DICTIONARY_TYPES_OPTIONS = [
  { value: '', label: 'All' },
  { value: DICTIONARY_TABLE_INTERNAL_TYPE, label: 'Table' },
  { value: DICTIONARY_TABLE_VIEW_TYPE, label: 'View' },
  { value: DICTIONARY_TABLE_EXTERNAL_TYPE, label: 'External' },
];

export const DICTIONARY_LOOKER_DASHBOARD_TYPE = 'DASHBOARD';
export const DICTIONARY_LOOKER_LOOK_TYPE = 'LOOK';

export const DICTIONARY_LOOKER_TYPES_OPTIONS = [
  { value: '', label: 'All' },
  { value: DICTIONARY_LOOKER_DASHBOARD_TYPE, label: 'Dashboard' },
  { value: DICTIONARY_LOOKER_LOOK_TYPE, label: 'Look' },
];

export const DICTIONARY_TABLE_UPSTREAM = 'UPSTREAM';
export const DICTIONARY_TABLE_DOWNSTREAM = 'DOWNSTREAM';

export const DICTIONARY_TYPES_STREAM = [
  { value: '', label: 'Upstream and Downstream' },
  { value: DICTIONARY_TABLE_UPSTREAM, label: 'Upstream' },
  { value: DICTIONARY_TABLE_DOWNSTREAM, label: 'Downstream' },
];

export const USER_ACTION_COPY = 'COPY';
export const USER_ACTION_REMOVE = 'REMOVE';

export const USER_ACTIONS = [
  { value: USER_ACTION_COPY, label: 'Copy email' },
  { value: USER_ACTION_REMOVE, label: 'Remove' },
];

export const USER_STATUS = {
  ACTIVE: 1,
  PENDING: 2,
};

export const USER_ROLE_OWNER = 'OWNER';
export const USER_ROLE_USER = 'USER';

export const BQ_BILLING_TYPE = {
  ON_DEMANDS: 'ON_DEMANDS',
  FLAT_RATE: 'FLAT_RATE',
  EDITIONS: 'EDITIONS',
};

export const BQ_BILLING_OPTIONS = [
  { value: BQ_BILLING_TYPE.ON_DEMANDS, label: 'On-demand' },
  { value: BQ_BILLING_TYPE.FLAT_RATE, label: 'Flat-rate' },
  { value: BQ_BILLING_TYPE.EDITIONS, label: 'Editions' },
];

export const COMMITMENT_PLAN = {
  HOURLY: 'HOURLY',
  MONTHLY: 'MONTHLY',
  ANNUAL: 'ANNUAL',
  NONE: 'NONE',
};
export const COMMITMENT_PLAN_OPTIONS = [
  { value: COMMITMENT_PLAN.HOURLY, label: 'Flex slots' },
  { value: COMMITMENT_PLAN.MONTHLY, label: 'Monthly' },
  { value: COMMITMENT_PLAN.ANNUAL, label: 'Annual' },
];

export const EDITION_PLAN = {
  STANDARD: 'STANDARD',
  ENTERPRISE: 'ENTERPRISE',
  ENTERPRISE_PLUS: 'ENTERPRISE_PLUS',
  NONE: 'NONE',
};
export const EDITION_PLAN_OPTIONS = [
  { value: EDITION_PLAN.STANDARD, label: 'Standard' },
  { value: EDITION_PLAN.ENTERPRISE, label: 'Enterprise' },
  { value: EDITION_PLAN.ENTERPRISE_PLUS, label: 'Enterprise Plus' },
];

export const PAYMENT_TYPE_DATA = {
  [BQ_BILLING_TYPE.ON_DEMANDS]: {
    title: 'On-demand',
    color: '#95A1FC',
  },
  [BQ_BILLING_TYPE.FLAT_RATE]: {
    title: 'Flat-rate',
    color: '#59B6AB',
  },
  [BQ_BILLING_TYPE.EDITIONS]: {
    title: 'Editions',
    color: '#FFA1A1',
    plans: {
      [EDITION_PLAN.STANDARD]: {
        title: 'Standard',
        color: '#BBEEED',
      },
      [EDITION_PLAN.ENTERPRISE]: {
        title: 'Enterprise',
        color: '#D1BBFF',
      },
      [EDITION_PLAN.ENTERPRISE_PLUS]: {
        title: 'Enterprise Plus',
        color: '#B2E229',
      },
      [EDITION_PLAN.NONE]: {
        title: 'None',
        color: '#FFCEA1',
      },
    },
  },
};

export const COMMITMENT_MODEL = {
  PAY_AS_YOU_GO: 'PAY_AS_YOU_GO',
  ONE_YEAR: 'ONE_YEAR',
  THREE_YEAR: 'THREE_YEAR',
  NONE: 'NONE',
};
export const COMMITMENT_MODEL_OPTIONS = [
  { value: COMMITMENT_MODEL.PAY_AS_YOU_GO, label: 'Pay as you go' },
  { value: COMMITMENT_MODEL.ONE_YEAR, label: '1 yr commit' },
  { value: COMMITMENT_MODEL.THREE_YEAR, label: '3 yr commit' },
];

export const PIPELINE_TYPE_STRING = {
  BROWSER: 'BROWSER',
  CUSTOM: 'CUSTOM',
  DBT: 'DBT',
  FIVETRAN: 'FIVETRAN',
  DATAFORM: 'DATAFORM',
  BQ_DTS: 'BQ_DTS',
  LOOKER: 'LOOKER',
  DOMO: 'DOMO',
  TABLEAU: 'TABLEAU',
  DATADDO: 'DATADDO',
  STITCH: 'STITCH',
  AIRBYTE: 'AIRBYTE',
};

export const PIPELINE_TYPE = {
  CUSTOM: 0,
  DBT: 1,
  FIVETRAN: 2,
  DATAFORM: 3,
  BQ_DTS: 4,
  LOOKER: 5,
  DOMO: 6,
  TABLEAU: 7,
  DATADDO: 8,
  STITCH: 9,
  AIRBYTE: 10,
};

export const PIPELINE_TYPE_MAP = {
  [PIPELINE_TYPE_STRING.CUSTOM]: 0,
  [PIPELINE_TYPE_STRING.DBT]: 1,
  [PIPELINE_TYPE_STRING.FIVETRAN]: 2,
  [PIPELINE_TYPE_STRING.DATAFORM]: 3,
  [PIPELINE_TYPE_STRING.BQ_DTS]: 4,
  [PIPELINE_TYPE_STRING.LOOKER]: 5,
  [PIPELINE_TYPE_STRING.DOMO]: 6,
  [PIPELINE_TYPE_STRING.TABLEAU]: 7,
  [PIPELINE_TYPE_STRING.DATADDO]: 8,
  [PIPELINE_TYPE_STRING.STITCH]: 9,
  [PIPELINE_TYPE_STRING.AIRBYTE]: 10,
};

export const PIPELINE_TYPE_DATA = {};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.CUSTOM] = {
  title: 'Custom pipeline',
  icon: CustomPipelineIcon,
  colorType: '#95A1FC',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.DBT] = {
  title: 'DBT',
  icon: DbtIcon,
  colorType: '#59B6AB',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.FIVETRAN] = {
  title: 'Fivetran',
  icon: FivetranIcon,
  colorType: '#FFA1A1',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.DATAFORM] = {
  title: 'Dataform',
  icon: DataformIcon,
  colorType: '#BBEEED',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.BQ_DTS] = {
  title: 'Data Transfer Service',
  icon: DTSIcon,
  colorType: '#D1BBFF',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.LOOKER] = {
  title: 'Looker',
  icon: LookerIcon,
  colorType: '#B2E229',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.DOMO] = {
  title: 'Domo',
  icon: DomoIcon,
  colorType: '#90BAF9',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.TABLEAU] = {
  title: 'Tableau',
  icon: TableauIcon,
  colorType: '#FFCEA1',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.DATADDO] = {
  title: 'Dataddo',
  icon: DataddoIcon,
  colorType: '#B6B0FB',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.STITCH] = {
  title: 'Stitch',
  icon: StitchIcon,
  colorType: '#FFC1E3',
};
PIPELINE_TYPE_DATA[PIPELINE_TYPE.AIRBYTE] = {
  title: 'Airbyte',
  icon: AirbyteIcon,
  colorType: '#A1F7D1',
};

export const PIPELINE_SUBTYPE_LABELS = {
  UNDEFINED: 'Undefined',
  SCHEDULED_QUERY: 'Scheduled Query',
  GOOGLE_ADS: 'Google Ads',
  MERCHANT_CENTER: 'Merchant Center',
  YOUTUBE_CHANNEL: 'Youtube Channel',
  YOUTUBE_CONTENT_OWNER: 'Youtube Content Owner',
  BQTS: 'BTQS',
  DOUBLECLICK_SEARCH: 'Doubleclick Search',
  SCRIPT_JOB: 'Script Job',
};

export const MH_PIPELINE_PREFIX = 'masthead-prod_pipeline_';
export const MH_JOB_CHANGE_PREFIX = 'masthead-prod_job-change_';

export const DEMO_PROJECT = 'gothic-sequence-307320';
export const DEMO_PROJECT_TITLE = 'Demo project';
