import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { useStyles } from '../IncidentCard.styles.js';
import { Chip, OverflownText, IconButton } from 'Components/components.js';
import {
  getAlertColorType,
  getAlertIcon,
  getAlertLabel,
  handleCopyText,
} from 'utils/helpers/helpers.js';
import { ANOMALY_TYPE, INCIDENT_LABEL_BY_TYPE } from 'constants/constants.js';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copyIcon.svg';

const getTableType = (type) => {
  switch (type) {
    case 'EXTERNAL':
      return 'external table';
    case 'VIEW':
      return 'view';
    default:
      return 'table';
  }
};

const IncidentsTitle = ({ groupIncident }) => {
  const classes = useStyles();
  const { table, tableType, dataset, alertType, anomalyType, project } =
    groupIncident;

  const AlertIconComponent = getAlertIcon(groupIncident.alertType);

  const incidentPlace = useMemo(() => {
    switch (anomalyType) {
      case ANOMALY_TYPE.PIPELINE_ERROR:
        return table
          ? getTableType(tableType)
          : dataset
          ? 'dataset'
          : 'project';
      default:
        return '';
    }
  }, [dataset, anomalyType, table, tableType]);

  const incidentTitle = useMemo(() => {
    return table ? table : dataset ? dataset : project;
  }, [project, dataset, table]);

  return (
    <section className={classes.incidentTitle}>
      {alertType ? (
        <Chip
          label={getAlertLabel(alertType)}
          color={getAlertColorType(alertType)}
          startIcon={<AlertIconComponent />}
          sx={{ mr: 2 }}
        />
      ) : null}

      <span className='txt-mainDark-16-500'>
        {INCIDENT_LABEL_BY_TYPE[anomalyType]} {incidentPlace}
      </span>
      <OverflownText title={incidentTitle} maxWidth={600}>
        <span className='txt-mainDark-16-700'>&nbsp;{incidentTitle}</span>
      </OverflownText>
      <IconButton
        icon={<CopyIcon />}
        onClick={() => handleCopyText(incidentTitle)}
      />
    </section>
  );
};

export { IncidentsTitle };
