import { makeStyles } from '@mui/styles';

const MAIN_PAGE_MARGIN = 32;

export const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: 'relative',
    minHeight: `calc(100vh - ${MAIN_PAGE_MARGIN * 2}px)`,
  },
  mainContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(6),
  },

  widgetsContainer: {
    display: 'flex',
    gap: theme.spacing(6),
  },
}));
