import React from 'react';
import { PipelineTagsChips } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const PIPELINE_TAG_MAX_WIDTH = 300;

const PipelineTagsWidget = ({ jobTags = [] }) => {
  const {
    jobTags: { title, subtitle },
  } = getWidgetLabel();

  return (
    <Widget
      label={title}
      secondaryLabel={subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <div>
        {jobTags.length > 0 && (
          <PipelineTagsChips
            pipelineTags={jobTags}
            visibleItems={jobTags.length}
            maxWidth={PIPELINE_TAG_MAX_WIDTH}
          />
        )}
      </div>
    </Widget>
  );
};

export { PipelineTagsWidget };
