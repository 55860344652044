import G6 from '@antv/g6';

const getContainerWidthByText = (string, maxWidth, fontSize = 9) => {
  let currentWidth = 0;
  const pattern = new RegExp('[\u4E00-\u9FA5]+');

  string.split('').forEach((letter) => {
    if (pattern.test(letter)) {
      currentWidth += fontSize;
    } else {
      currentWidth += G6.Util.getLetterWidth(letter, fontSize);
    }
  });

  return currentWidth;
};

export { getContainerWidthByText };
