import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import ArrowLeft from 'assets/img/icons/sidebarIcons/arrowLeft.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.btn': {
      width: 40,
      borderRadius: 12,
    },
    '&:active': {
      border: 'none',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
  },
}));

const BackButton = ({ className }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <button
      type='button'
      className={clsx(classes.button, 'btn btn-white me-3', className)}
      onClick={history.goBack}
    >
      <Image src={ArrowLeft} />
    </button>
  );
};

export { BackButton };
