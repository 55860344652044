import { useCallback, useState } from 'hooks/hooks.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const useChangeTab = (tabs, startTab, searchParams) => {
  const getCurrentTabFromUrl = () => {
    const tab = searchParams?.get(SEARCH_PARAMS.TAB);
    if (tab && Object.values(tabs).includes(tab)) {
      return tab;
    }
    return startTab;
  };
  const [currentTab, setCurrentTab] = useState(getCurrentTabFromUrl());

  const onTabClick = useCallback((selectedTab) => {
    setCurrentTab(selectedTab);
  }, []);

  return { currentTab, onTabClick };
};

export { useChangeTab };
