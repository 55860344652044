import React from 'react';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'constants/constants.js';

const MILLISECONDS_IN_SECONDS = 1000;

const getDatePeriod = (startDate, frequencyInSeconds) => {
  const endDate =
    new Date(startDate).getTime() +
    frequencyInSeconds * MILLISECONDS_IN_SECONDS;
  const startDateYearFormat = moment(startDate)
    .utc()
    .format(DATE_FORMAT.monthYear);
  const startDateTimeFormat = moment(startDate)
    .utc()
    .format(DATE_FORMAT.timeSecond);
  const endDateYearFormat = moment(endDate).utc().format(DATE_FORMAT.monthYear);
  const endDateTimeFormat = moment(endDate)
    .utc()
    .format(DATE_FORMAT.timeSecond);

  switch (startDateYearFormat) {
    case endDateYearFormat:
      return (
        <div>
          {startDateYearFormat}{' '}
          <span style={{ fontWeight: 700 }}>
            {startDateTimeFormat} - {endDateTimeFormat}
          </span>
        </div>
      );
    default:
      return (
        <>
          <div>
            <span style={{ fontWeight: 700 }}>{startDateYearFormat} </span>
            {startDateTimeFormat} -
          </div>

          <div>
            <span style={{ fontWeight: 700 }}>{endDateYearFormat} </span>
            {endDateTimeFormat}
          </div>
        </>
      );
  }
};

export { getDatePeriod };
