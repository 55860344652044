import {
  millisecondsToHoursTime,
  bytesToSize,
  numberFormat,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { METRIC_TYPE } from 'constants/constants.js';

const calcDeviation = (value, minThreshold, maxThreshold) => {
  if (value > maxThreshold) {
    return (value / maxThreshold) * 100 - 100;
  }

  if (value < minThreshold) {
    return (value / minThreshold) * 100 - 100;
  }

  return 0;
};

const getIncidentFormatData = (metricType, value) => {
  let incidentValue;

  switch (metricType) {
    case METRIC_TYPE.SLOTS_MS:
      incidentValue = millisecondsToTimeSlots(value);
      break;
    case METRIC_TYPE.BILLED_BYTES:
      incidentValue = bytesToSize(value, true, 1);
      break;
    case METRIC_TYPE.DURATION:
      incidentValue = millisecondsToHoursTime(value);
      break;
    default:
      incidentValue = numberFormat(value);
  }

  return incidentValue;
};

export { calcDeviation, getIncidentFormatData };
