import React from 'react';
import {
  useEffect,
  useRef,
  useState,
  useOnClickOutside,
  useMemo,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { useStyles } from './Dropdowns.style.js';
import { INCIDENT_FILTER_OPTIONS } from 'constants/constants.js';
import { CheckMarkIcon } from 'assets/img/dropdownIcons';

const StyledDropdown = ({
  Icon,
  options,
  defaultValue = [],
  selectAllOption,
  onSelect,
  multipleSelect = true,
  afterLabel = '',
  beforeLabel = '',
  className = '',
}) => {
  const [selected, setSelected] = useState(defaultValue);
  const [isOpened, setIsOpened] = useState(false);
  const classes = useStyles();

  const isCustomSelected = useMemo(() => {
    return Boolean(
      selectAllOption && selected[0]?.value !== selectAllOption?.value
    );
  }, [selectAllOption, selected]);

  useEffect(() => {
    if (defaultValue && defaultValue.length) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpened(false));

  const isSelected = (value) => !!selected.find((opt) => value === opt.value);

  const onSelectHandler = (option, selected) => {
    setSelected((prev) => {
      let updated = [];

      if (multipleSelect) {
        updated = selected
          ? prev.filter((opt) => option.value !== opt.value)
          : [...prev, option];
      } else {
        updated = [option];
      }

      if (selectAllOption && multipleSelect) {
        if (
          (updated.length === options.length - 1 &&
            !updated.find((it) => it.value === selectAllOption.value)) ||
          updated.length === 0 ||
          (option.value === selectAllOption.value && !selected)
        ) {
          updated = [selectAllOption];
        } else {
          updated = updated.filter(
            (opt) => opt.value !== selectAllOption.value
          );
        }
      }

      if (updated.length === 0) {
        updated = [INCIDENT_FILTER_OPTIONS[0]];
      }

      onSelect && onSelect(updated);

      return updated;
    });

    setIsOpened(false);
  };

  const labelText =
    beforeLabel + selected.map(({ label }) => label).join(' and ') + afterLabel;

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div
        className={clsx(
          classes.label,
          isOpened && 'opened',
          isCustomSelected && 'selected',
          'txt-mainDark-13-700'
        )}
        onClick={() => setIsOpened((prev) => !prev)}
      >
        {Icon !== undefined && <Icon height={16} width={16} />}
        {selected && (
          <span
            className={classes.labelText}
            style={{
              marginLeft: Icon !== undefined ? 10 : 0,
            }}
          >
            {labelText}
          </span>
        )}
      </div>
      {isOpened && (
        <ul ref={ref} className={classes.list}>
          {options.map(({ value, label }) => {
            const selected = isSelected(value);
            return (
              <li
                onClick={() => onSelectHandler({ value, label }, selected)}
                className={clsx(classes.listItem, selected && 'selected')}
                key={`${value}-${label}`}
              >
                <span>{label}</span>
                {selected && <CheckMarkIcon />}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export { StyledDropdown };
