import React from 'react';
import { Input } from 'Components/components.js';
import { INPUT_LABEL, INPUT_VALIDATION } from '../libs/constants/constants.js';

const InputName = ({ nameValue, onNameValueChange }) => {
  return (
    <Input
      id='name'
      value={nameValue}
      onChange={onNameValueChange}
      placeholder='e.g. Dave Gahan'
      label={INPUT_LABEL.name}
      isRequired={true}
      inputProps={{ maxLength: INPUT_VALIDATION.maxLength }}
    />
  );
};

const InputCompany = ({ companyValue, onCompanyValueChange }) => {
  return (
    <Input
      id='company'
      value={companyValue}
      onChange={onCompanyValueChange}
      placeholder='e.g. My Data-Driven Company'
      label={INPUT_LABEL.company}
      isRequired={true}
      inputProps={{ maxLength: INPUT_VALIDATION.maxLength }}
    />
  );
};

const InputUseCase = ({ useCaseValue, onUseCaseValueChange }) => {
  return (
    <Input
      id='useCase'
      value={useCaseValue}
      onChange={onUseCaseValueChange}
      placeholder='e.g. We do not know what pipelines are failing  '
      label={INPUT_LABEL.useCase}
      inputProps={{ maxLength: INPUT_VALIDATION.maxLength }}
    />
  );
};

const InputProject = ({ projectValue, onProjectValueChange }) => {
  return (
    <Input
      id='project'
      value={projectValue}
      onChange={onProjectValueChange}
      label={INPUT_LABEL.projectId}
      isRequired={true}
      inputProps={{ maxLength: INPUT_VALIDATION.maxLength }}
    />
  );
};

export { InputName, InputCompany, InputUseCase, InputProject };
