import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useCostsSourceTables = ({
  dataset,
  table,
  startDate,
  endDate,
  sourceTable,
  sourceDataset,
  searchValue,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  pipelineType,
  pipelineSubtypes,
  project,
  enabled = true,
}) => {
  const { isFetching: isFetchingSources, data } = useQuery(
    [
      QUERY_TYPES.jobSourcesCost,
      dataset,
      table,
      startDate,
      endDate,
      sourceTable,
      sourceDataset,
      searchValue,
      principalEmail,
      searchPrincipalEmail,
      jobTagNames,
      pipelineType,
      pipelineSubtypes,
      project,
    ],
    ({ queryKey }) => {
      const [url, dataset, table, start, end] = queryKey;
      const params = new URLSearchParams();
      params.append('dataset', dataset);
      params.append('table', table);
      params.append(
        'from',
        moment(convertLocalToUTCDate(start)).utc().format()
      );
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      params.append('sourceTable', sourceTable || '');
      params.append('sourceDataset', sourceDataset || '');
      params.append('search', searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      params.append('pipelineType', pipelineType);
      project && params.append('project', project);

      pipelineSubtypes?.forEach((pipelineSubtype) =>
        params.append('pipelineSubtypes', pipelineSubtype)
      );
      jobTagNames?.forEach((tag) => params.append('jobTagNames', tag));

      return fetcherGet(url, params);
    },
    {
      enabled:
        Boolean(table && dataset) && enabled && pipelineType !== undefined,
    }
  );

  const sources = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { sources, isFetchingSources };
};

export { useCostsSourceTables };
