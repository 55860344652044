import { ReactComponent as DatasetIcon } from 'assets/img/icons/projectTreeIcons/dataset.svg';

const createDatasetOption = (item, disableDatasets) => ({
  label: (
    <span>
      <DatasetIcon style={{ marginRight: 5 }} /> {item.dataset}
    </span>
  ),
  value: item.dataset,
  item: item,
  isDataset: true,
  isDisabled: disableDatasets,
});

export { createDatasetOption };
