import { useState } from 'hooks/hooks.js';

const usePopover = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenPopover = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return { anchorEl, isOpenPopover, handleClose, handleChangePopover };
};

export { usePopover };
