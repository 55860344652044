const PipelineWidgets = {
  DURATION_VOLUME: 'durationVolume',
  COST_DATA_VOLUME: 'costDataVolume',
  FRESHNESS: 'freshness',
  LAST_RUNS: 'lastRuns',
  LINEAGE: 'lineage',
  QUERY: 'query',
};

export { PipelineWidgets };
