import React from 'react';
import { useExpandTableCellContent } from 'hooks/hooks.js';
import { Chip, CHIP_COLOR } from 'Components/components.js';

const MAX_VISIBLE_ITEM = 3;
const MAX_LABEL_WIDTH = 280;

const CollapsedChips = ({
  data = [],
  visibleItems = MAX_VISIBLE_ITEM,
  maxWidth = MAX_LABEL_WIDTH,
}) => {
  const { content, handleChangeCollapse, renderCollapsedItemsNumber } =
    useExpandTableCellContent(data, 'element', 'elements', visibleItems);

  return (
    <>
      {data.length > 0 && (
        <div>
          {content.map((item, index) => (
            <Chip
              label={item}
              maxWidth={maxWidth}
              color={CHIP_COLOR.info}
              key={`${item}/${index}`}
              className='me-1 mb-1'
            />
          ))}

          {data.length > visibleItems && (
            <span
              className='txt-grey-13-500 nowrapText'
              onClick={handleChangeCollapse}
              style={{ cursor: 'pointer' }}
            >
              {renderCollapsedItemsNumber()}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export { CollapsedChips };
