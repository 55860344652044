import React from 'react';
import { useCallback, useMemo, useQuery, useState } from 'hooks/hooks.js';
import { IncidentCard } from '../../GlobalPageComponents/components.js';
import { LookerIncidentTitle, LookerIncidentsStats } from './components.js';
import { useConfiguration } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES, IncidentTabs } from 'constants/constants.js';
import {
  CRITICAL_ALERT_TYPE,
  MUTED_ALERT_TYPE,
  PRIORITY_ALERT_TYPE,
  REGULAR_ALERT_TYPE,
} from 'utils/constants.js';
import {
  DASHBOARD_TYPE,
  LOOK_TYPE,
} from 'Pages/Dictionary/components/DictionaryLooker/enums/enums.js';

const LookerIncidentCard = ({
  groupIncident,
  startDate,
  endDate,
  incidentIdAndType,
  incidentStatuses,
  tableInfo,
  searchDashboardName,
  searchLookName,
  searchDashboardId,
  searchLookId,
}) => {
  const { integrations } = useConfiguration();
  const [currentIncident, setCurrentIncident] = useState(null);

  const { isFetching, data: lookerDictionaryData } = useQuery(
    [QUERY_TYPES.lookerDictionary, groupIncident.dataset, groupIncident.table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
        searchTable: '',
        searchDashboardElementName: '',
        searchDashboardId: '',
        searchDashboardName: '',
        searchLookId: '',
        searchLookName: '',
        type: '',
        sortBy: 'NAME',
        order: 'ASC',
        page: 1,
        limit: 1000,
      });
    },
    {
      enabled:
        integrations?.looker !== null &&
        !!groupIncident.dataset &&
        !!groupIncident.table,
    }
  );

  const dashboards = useMemo(() => {
    return {
      critical:
        lookerDictionaryData?.values.filter(
          (item) => item.alertType === CRITICAL_ALERT_TYPE
        ) || [],
      priority:
        lookerDictionaryData?.values.filter(
          (item) => item.alertType === PRIORITY_ALERT_TYPE
        ) || [],
      regular:
        lookerDictionaryData?.values.filter(
          (item) => item.alertType === REGULAR_ALERT_TYPE
        ) || [],
      muted:
        lookerDictionaryData?.values.filter(
          (item) => item.alertType === MUTED_ALERT_TYPE
        ) || [],
    };
  }, [lookerDictionaryData?.values]);

  const dashboardNameById = useMemo(() => {
    if (searchDashboardId.length) {
      const dashboard = lookerDictionaryData?.values.filter(
        (item) =>
          item.lookerId === +searchDashboardId && item.type === DASHBOARD_TYPE
      );

      return dashboard[0]?.name;
    }

    return null;
  }, [lookerDictionaryData?.values, searchDashboardId]);

  const lookNameById = useMemo(() => {
    if (searchLookId.length) {
      const dashboard = lookerDictionaryData?.values.filter(
        (item) => item.lookerId === +searchLookId && item.type === LOOK_TYPE
      );

      return dashboard[0]?.name;
    }

    return null;
  }, [lookerDictionaryData?.values, searchLookId]);

  const handleChangeCurrentIncident = useCallback((incident) => {
    setCurrentIncident(incident);
  }, []);

  return (
    <IncidentCard
      groupIncident={groupIncident}
      startDate={startDate}
      endDate={endDate}
      incidentIdAndType={incidentIdAndType}
      incidentStatuses={incidentStatuses}
      onChangeCurrentIncident={handleChangeCurrentIncident}
      tableInfo={tableInfo}
      isFetchingExternalData={isFetching}
      currentTab={IncidentTabs.LOOKER}
    >
      <LookerIncidentTitle
        groupIncident={groupIncident}
        dashboards={dashboards}
        searchDashboardName={searchDashboardName}
        searchLookName={searchLookName}
        dashboardNameById={dashboardNameById}
        lookNameById={lookNameById}
      />
      {currentIncident && (
        <LookerIncidentsStats
          dashboards={dashboards}
          groupIncident={groupIncident}
        />
      )}
    </IncidentCard>
  );
};

export { LookerIncidentCard };
