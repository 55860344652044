import {
  useJobVolume,
  useJobFrequency,
  useMemo,
  useEffect,
} from 'hooks/hooks.js';
import { Volume } from './Volume.js';
import { getAreaData } from './utils.js';

const PipelineDataVolume = ({
  jobHash,
  metricType,
  startDate,
  endDate,
  currentDatetime = null,
  onLoadingVolumeDataChange,
}) => {
  const { volumeData, isLoadingVolumeData } = useJobVolume(
    jobHash,
    metricType,
    startDate,
    endDate
  );
  const { frequencyMilliseconds } = useJobFrequency(jobHash, metricType);

  const volumeAreaData = useMemo(() => {
    if (volumeData.length > 0) {
      if (volumeData.every((item) => item.value === null)) {
        return [];
      } else {
        return getAreaData(volumeData);
      }
    }

    return [];
  }, [volumeData]);

  useEffect(() => {
    if (onLoadingVolumeDataChange) {
      onLoadingVolumeDataChange(isLoadingVolumeData);
    }
  }, [isLoadingVolumeData, onLoadingVolumeDataChange]);

  if (!isLoadingVolumeData && !volumeAreaData.length) {
    return (
      <div className='txt-grey-13-500 text-center text-italic'>
        No data for graph
      </div>
    );
  }

  return (
    <Volume
      volumeData={volumeAreaData}
      isFetchingVolumeData={isLoadingVolumeData}
      frequencyMilliseconds={frequencyMilliseconds}
      currentDatetime={currentDatetime}
      metricType={metricType}
      volumeGraphWidth={712}
    />
  );
};

export { PipelineDataVolume };
