import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { CollapsedChips } from 'Components/components.js';

const VISIBLE_LABELS = 1;

const TableLabelsChips = ({ tableLabels = [] }) => {
  const data = useMemo(() => {
    return tableLabels.map((label) => (
      <span>
        {label.key} : <span className='txt-mainDark-13-700'>{label.value}</span>
      </span>
    ));
  }, [tableLabels]);

  return <CollapsedChips data={data} visibleItems={VISIBLE_LABELS} />;
};

export { TableLabelsChips };
