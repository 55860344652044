import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    '&.MuiButton-containedPrimary': {
      '& svg path': {
        fill: theme.palette.common.white,
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&.MuiButton-containedSecondary': {
      '& svg path': {
        fill: theme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
    },

    '&.MuiButton-textPrimary': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
}));
