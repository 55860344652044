import { getDatasetTypeImage, getPipelineTypeImage } from './helpers.js';
import { NodeTypes } from '../../enums/enums.js';
import lookerIcon from 'assets/img/icons/lineageIcons/node-icons/looker-icon.svg';
import sourceUriIcon from 'assets/img/icons/lineageIcons/node-icons/source-uri.svg';
import defaultIcon from 'assets/img/icons/lineageIcons/node-icons/table.svg';

const getNodeTypeImage = (
  nodeType,
  datasetType,
  pipelineType,
  nodeImageColor
) => {
  switch (nodeType) {
    case NodeTypes.DATASET:
      return getDatasetTypeImage(datasetType, nodeImageColor);
    case NodeTypes.PIPELINE:
      return getPipelineTypeImage(pipelineType, nodeImageColor);
    case NodeTypes.LOOKER_DASHBOARD_ELEMENT:
    case NodeTypes.LOOKER_LOOK:
      return lookerIcon;
    case NodeTypes.SOURCE_URI:
    case NodeTypes.DESTINATION_URI:
      return sourceUriIcon;
    case NodeTypes.SERVICE_ACCOUNT:
      return sourceUriIcon;
    default:
      return defaultIcon;
  }
};

export { getNodeTypeImage };
