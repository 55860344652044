import React from 'react';
import { useCallback } from 'hooks/hooks.js';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { useStyles } from './OnboardingBanner.style.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import {
  FirstSlider,
  ThirdSlider,
  FourthSlider,
} from './components/components.js';
import { useModal } from 'context/context.js';

const OnboardingBanner = ({ onClose }) => {
  const classes = useStyles();
  const { setModal } = useModal();

  const closeModal = useCallback(() => {
    onClose();
    setModal(null);
  }, [onClose, setModal]);

  return (
    <div className={classes.modalContainer}>
      <Carousel
        showStatus={false}
        autoPlay={false}
        showThumbs={false}
        useKeyboardArrows={true}
        autoFocus={true}
        renderArrowPrev={() => (
          <Button
            text='Skip all'
            onClick={closeModal}
            variant={BUTTON_VARIANT.text}
            className='arrow arrowSkip'
            fullWidth={false}
          />
        )}
        renderArrowNext={(onClickHandler, hasNext) => (
          <Button
            onClick={hasNext ? onClickHandler : closeModal}
            text={hasNext ? 'Next' : 'Done'}
            className='arrow arrowNext'
            fullWidth={false}
          />
        )}
      >
        <FirstSlider />
        <ThirdSlider />
        <FourthSlider onClose={onClose} />
      </Carousel>
    </div>
  );
};

export { OnboardingBanner };
