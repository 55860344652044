import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import {
  Modal,
  RadioDropdown,
  RightSideModal,
  StyledTableRow,
  StyledTableCell,
} from 'Components/components.js';
import { useModal } from 'context/context.js';
import {
  EditRuleModalBody,
  RemoveRuleModalBody,
} from 'Pages/DataQuality/components/components.js';
import {
  ACTION_EDIT,
  ACTION_REMOVE,
  EDIT_RULE_DROPDOWN_ACTIONS,
} from 'Pages/DataQuality/libs/constants/constants.js';
import { createRuleParamsByType } from 'Pages/DataQuality/libs/helpers/helper.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const RulesTable = ({
  rowData,
  saveRule = () => {},
  removeRule = () => {},
  isDisabledRemoveRule = false,
}) => {
  const { column, dimensionLabel, data, thresholdLabel, typeLabel } = rowData;
  const { setModal } = useModal();
  const params = createRuleParamsByType(data);

  const ruleDropdownActions = useMemo(() => {
    if (isDisabledRemoveRule) {
      return EDIT_RULE_DROPDOWN_ACTIONS.filter(
        (item) => item.value !== ACTION_REMOVE
      );
    }

    return EDIT_RULE_DROPDOWN_ACTIONS;
  }, [isDisabledRemoveRule]);

  const onFilterSelect = (event) => {
    if (event.value === ACTION_EDIT) {
      setModal(() => (
        <RightSideModal
          title={`Edit rule`}
          ModalBodyComponent={EditRuleModalBody}
          mainBlockMargin={0}
          saveRule={saveRule}
          rule={rowData}
        />
      ));
    }

    if (event.value === ACTION_REMOVE) {
      setModal(() => (
        <Modal
          onAgree={() => removeRule(rowData)}
          title={`Remove rule for ${column} column`}
          titlePosition='center'
          ModalBodyComponent={RemoveRuleModalBody}
          agreeButtonText='Remove'
        />
      ));
    }
  };

  return (
    <StyledTableRow>
      <StyledTableCell className='darkBold'>{column}</StyledTableCell>

      <StyledTableCell>{typeLabel}</StyledTableCell>

      <StyledTableCell>{dimensionLabel}</StyledTableCell>

      <StyledTableCell>
        {params.length
          ? params.map(({ key, value }) => (
              <div key={key}>{`${key}: ${value}`}</div>
            ))
          : '-'}
      </StyledTableCell>

      <StyledTableCell>{thresholdLabel}</StyledTableCell>

      <StyledTableCell>
        <RadioDropdown
          options={ruleDropdownActions}
          current={{ icon: PointsIcon }}
          onSelect={(event) => onFilterSelect(event)}
          bottom={'-24px'}
          right={'12px'}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export { RulesTable };
