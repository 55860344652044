const descendingComparator = (a, b, orderBy) => {
  if (a[orderBy] !== undefined && typeof a[orderBy] !== 'string') {
    return a[orderBy] - b[orderBy];
  }
  let orderA = '';
  let orderB = '';
  const fields = orderBy.split('.');
  for (const field of fields) {
    orderA += a[field] || '';
    orderB += b[field] || '';
  }

  return orderA.localeCompare(orderB);
};

const getComparator = (order, orderBy) => {
  return order === 'asc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  if (!array) {
    return [];
  }
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export { stableSort, getComparator };
