import React from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop as MaterialBackdrop } from '@mui/material';
import { Loader } from 'Components/components.js';

const BACKDROP_POSITION = {
  fixed: 'fixed',
  absolute: 'absolute',
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    '&.MuiBackdrop-root': {
      position: (props) => props.position,
      alignItems: (props) => (props.isPageCentering ? 'start' : 'center'),
      backgroundColor: '#f7f8fc70',
      borderRadius: 4,
    },

    '& > div': {
      top: (props) => props.isPageCentering && 400,
    },
  },
}));

const Backdrop = ({
  isOpen,
  position = BACKDROP_POSITION.absolute,
  isPageCentering = false,
}) => {
  const classes = useStyles({ isPageCentering, position });

  return (
    <MaterialBackdrop
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isOpen}
      className={classes.backdrop}
    >
      <Loader />
    </MaterialBackdrop>
  );
};

export { Backdrop, BACKDROP_POSITION };
