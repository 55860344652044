import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const CONTAINER_HEIGHT = 240;
const BORDER_WIDTH = 1;
const CONTENT_HEIGHT = 240 - BORDER_WIDTH * 2;

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    height: CONTAINER_HEIGHT,
    border: `${BORDER_WIDTH}px solid ${theme.palette.divider}`,
    borderRadius: 12,
    color: theme.palette.common.mainDarkText,
    fontFamily: 'Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace',
    fontSize: 13,
    lineHeight: '22px',
  },
  textarea: {
    width: '100%',
    height: CONTENT_HEIGHT,
    padding: '10px 0',
    paddingLeft: '3.5rem',
    border: 'none',
    borderRadius: 12,
    resize: 'none',
    outline: 'none',
    '&:focus-visible': {
      outline: 'none',
    },
    '&::placeholder': {
      color: theme.palette.divider,
    },
  },
  numbersContainer: {
    position: 'absolute',
    top: 0,
    minWidth: '1.5rem',
    width: 'min-content',
    height: CONTENT_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    textAlign: 'center',
    color: theme.palette.divider,
    backgroundColor: theme.palette.secondary.light,
    padding: 10,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  number: {
    '&.active': {
      color: theme.palette.common.mainDarkText,
    },
  },
}));

const TextareaWithLineNumbers = ({
  value,
  numberOfLines,
  onChangeValue,
  name,
  placeholder = 'Enter Message',
}) => {
  const classes = useStyles();
  const lineCount = useMemo(() => value.split('\n').length, [value]);
  const linesArr = useMemo(
    () =>
      Array.from(
        { length: Math.max(numberOfLines, lineCount) },
        (_, i) => i + 1
      ),
    [lineCount, numberOfLines]
  );

  const lineCounterRef = useRef(null);
  const textareaRef = useRef(null);

  const handleTextareaChange = (event) => {
    onChangeValue(event.target.value);
  };

  const handleTextareaScroll = () => {
    if (lineCounterRef.current && textareaRef.current) {
      lineCounterRef.current.scrollTop = textareaRef.current.scrollTop;
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.numbersContainer} ref={lineCounterRef}>
        {linesArr.map((count) => {
          const activeNumber = count <= lineCount;
          return (
            <div
              className={clsx(classes.number, activeNumber && 'active')}
              key={count}
            >
              {count}
            </div>
          );
        })}
      </div>
      <textarea
        name={name}
        onChange={handleTextareaChange}
        onScroll={handleTextareaScroll}
        placeholder={placeholder}
        ref={textareaRef}
        value={value}
        wrap='off'
        className={classes.textarea}
      />
    </div>
  );
};

export { TextareaWithLineNumbers };
