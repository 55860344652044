import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    borderRadius: 12,
  },
  largeBlock: {
    position: 'relative',
    height: '100%',
    padding: '32px 24px',
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    borderBottomLeftRadius: 12,
  },
  singleProjectContainer: {
    position: 'absolute',
    top: '50%',
    left: 24,
    transform: 'translateY(-50%)',
  },
  multipleProjectContainer: {
    height: 700,
  },
  formContainer: {
    overflow: 'scroll',
    height: '90%',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  smallBlock: {
    width: '38%',
    backgroundColor: theme.palette.secondary.main,
    borderBottomRightRadius: 12,
  },
  image: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '46%',
    transform: 'translateY(-50%)',
  },
}));
