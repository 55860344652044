import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { Freshness } from 'Components/components.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const TableDataFreshness = ({
  table,
  dataset,
  onChangeExistingData,
  startDate,
  endDate,
}) => {
  const { isFetching, data } = useQuery(
    [QUERY_TYPES.freshness, table, dataset, startDate, endDate],
    ({ queryKey }) => {
      const [url, table, dataset, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
        from: moment(convertLocalToUTCDate(startDate)).utc().format(),
        to: moment(convertLocalToUTCDate(endDate)).utc().format(),
      });
    },
    { enabled: Boolean(table && dataset), cacheTime: 0 }
  );

  // Destroy after removing table
  const isDestroyingGraph = useMemo(() => {
    return !table || isFetching;
  }, [isFetching, table]);

  return (
    <Freshness
      data={data?.values}
      isFetchingData={isFetching}
      onChangeExistingData={onChangeExistingData}
      isDestroyingGraph={isDestroyingGraph}
    />
  );
};

export { TableDataFreshness };
