import { makeStyles } from '@mui/styles';

export const ownStyles = makeStyles((theme) => ({
  integrationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
    padding: 16,
    background: theme.palette.common.white,
    boxShadow: '4px 12px 24px rgba(0, 0, 0, 0.04)',
    borderRadius: 12,
  },
  integrationItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  leftSideOwn: {
    '& .banner-img': {
      position: 'relative',
      bottom: '-26px',
      borderRadius: '0 0 16px 0',
    },
  },
}));
