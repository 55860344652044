import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { Grid } from '@mui/material';
import { useStyles } from './StatsWidget.styles.js';
import {
  PositionLoader,
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import {
  getPipelineDiff,
  getTotalPipelineDiff,
  numberFormat,
} from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { PIPELINE_TYPE_DATA } from 'utils/constants.js';
import { PipelineTabs } from 'constants/constants.js';

const CostsStatsWidget = ({
  costsStats,
  isLoadingStats,
  costsStatsPrevious,
  startDate,
  endDate,
}) => {
  const classes = useStyles();

  const totalAmount = useMemo(() => {
    return costsStats.reduce(
      (accumulator, { costs }) => accumulator + costs,
      0
    );
  }, [costsStats]);

  const totalAmountPrevious = useMemo(() => {
    return costsStatsPrevious.reduce(
      (accumulator, { costs }) => accumulator + costs,
      0
    );
  }, [costsStatsPrevious]);

  const totalAmountInfo = getTotalPipelineDiff(
    totalAmount,
    totalAmountPrevious
  );

  const statsCardData = costsStats.map((item) => {
    const { pipelineType, costs } = item;
    const diff = getPipelineDiff(item, costsStatsPrevious);
    const lineStatWidth = `${Math.ceil((costs * 100) / totalAmount)}%`;

    return {
      name: PIPELINE_TYPE_DATA[pipelineType]?.title,
      count: numberFormat(costs),
      color: PIPELINE_TYPE_DATA[pipelineType]?.colorType,
      diff,
      lineStatWidth,
      gridSize: costsStats.length === 2 || costsStats.length === 4 ? 6 : 4,
      icon: PIPELINE_TYPE_DATA[pipelineType]?.icon,
      pipelineType: pipelineType,
    };
  });

  return (
    <section className={classes.widget}>
      {isLoadingStats ? (
        <PositionLoader />
      ) : (
        <>
          <section>
            <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
              Pipeline compute costs
            </Typography>

            <section className={classes.flexRawContainer}>
              <Link
                to={{
                  pathname: AppRoutes.Pipelines.path,
                  search: new URLSearchParams({
                    tab: PipelineTabs.COSTS,
                  }).toString(),
                  state: {
                    startDate,
                    endDate,
                  },
                }}
              >
                <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
                  ${numberFormat(totalAmount)}
                </Typography>
              </Link>

              {totalAmountInfo !== null && (
                <PercentStatsBlock data={totalAmountInfo} />
              )}
            </section>
          </section>

          <div className={classes.lineStats}>
            {statsCardData.map((item) => (
              <Tooltip
                key={item.name}
                title={
                  <div className={classes.tooltip}>
                    <div className='title'>
                      <Image
                        src={item.icon}
                        width={16}
                        height={16}
                        alt='logo'
                      />
                      <p className='txt-mainDark-13-500'>{item.name}</p>
                    </div>

                    <p className='txt-mainDark-13-500'>Cost: ${item.count}</p>
                  </div>
                }
              >
                <div
                  className={classes.line}
                  style={{
                    backgroundColor: item.color,
                    width: item.lineStatWidth,
                  }}
                />
              </Tooltip>
            ))}
          </div>

          <section className={classes.cardsContainer}>
            <Grid container spacing={4}>
              {statsCardData.map((item) => {
                return (
                  <Grid
                    item
                    xs={item.gridSize}
                    md={item.gridSize}
                    key={item.name}
                  >
                    <StatsCard
                      statsCardData={item}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </section>
        </>
      )}
    </section>
  );
};

const StatsCard = ({ statsCardData, startDate, endDate }) => {
  const { name, count, color, diff, icon, pipelineType } = statsCardData;
  const classes = useStyles();

  return (
    <Link
      className={classes.card}
      to={{
        pathname: AppRoutes.Pipelines.path,
        search: new URLSearchParams({
          tab: PipelineTabs.COSTS,
        }).toString(),
        state: {
          startDate,
          endDate,
          filterPipelineTechnologyIds: [pipelineType],
        },
      }}
    >
      <Image src={icon} width={20} height={20} alt={`${name} logo`} />

      <div>
        <div className={clsx(classes.flexRawContainer, 'smallGap')}>
          <div className={classes.marker} style={{ backgroundColor: color }} />
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{name}</Typography>
        </div>

        <section className={classes.flexRawContainer}>
          <Typography variant={TYPOGRAPHY_VARIANT.h5} component={'h5'}>
            ${count}
          </Typography>
          {diff !== null && <PercentStatsBlock data={diff} isWhite={true} />}
        </section>
      </div>
    </Link>
  );
};

export { CostsStatsWidget };
