import React from 'react';
import { useCallback, useEffect, useMemo } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Alert, Input, SwitchWithLabel } from 'Components/components.js';
import { CONTROL_ELEMENTS_DATA } from '../../libs/constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  itemsContainer: {
    display: 'flex',
    gap: 16,
  },
  item: {
    flex: '1 1 0',
  },
}));

const MinMaxValue = ({ changeMinMaxData, minMaxData }) => {
  const classes = useStyles();
  const { minValueInput, maxValueInput, strictMinSwitch, strictMaxSwitch } =
    CONTROL_ELEMENTS_DATA;

  const minValue = useMemo(() => {
    return minMaxData?.minValue || '';
  }, [minMaxData?.minValue]);

  const maxValue = useMemo(() => {
    return minMaxData?.maxValue || '';
  }, [minMaxData?.maxValue]);

  const strictMinChecked = useMemo(() => {
    return minMaxData?.strictMinChecked || false;
  }, [minMaxData?.strictMinChecked]);

  const strictMaxChecked = useMemo(() => {
    return minMaxData?.strictMaxChecked || false;
  }, [minMaxData?.strictMaxChecked]);

  const error = useMemo(() => {
    return minMaxData?.error || null;
  }, [minMaxData?.error]);

  const handleChangeMinValue = useCallback(
    (value) => {
      changeMinMaxData({
        minValue: value,
        maxValue: maxValue,
        strictMinChecked: strictMinChecked,
        strictMaxChecked: strictMaxChecked,
        error: error,
      });
    },
    [changeMinMaxData, maxValue, strictMinChecked, strictMaxChecked, error]
  );

  const handleChangeMaxValue = useCallback(
    (value) => {
      changeMinMaxData({
        minValue: minValue,
        maxValue: value,
        strictMinChecked: strictMinChecked,
        strictMaxChecked: strictMaxChecked,
        error: error,
      });
    },
    [changeMinMaxData, minValue, strictMinChecked, strictMaxChecked, error]
  );

  const handleChangeMinSwitch = useCallback(
    (value) => {
      changeMinMaxData({
        minValue: minValue,
        maxValue: maxValue,
        strictMinChecked: value,
        strictMaxChecked: strictMaxChecked,
        error: error,
      });
    },
    [changeMinMaxData, maxValue, minValue, strictMaxChecked, error]
  );

  const handleChangeMaxSwitch = useCallback(
    (value) => {
      changeMinMaxData({
        minValue: minValue,
        maxValue: maxValue,
        strictMinChecked: strictMinChecked,
        strictMaxChecked: value,
        error: error,
      });
    },
    [changeMinMaxData, maxValue, strictMinChecked, minValue, error]
  );

  useEffect(() => {
    let newError = null;
    if (minValue && maxValue && +minValue > +maxValue) {
      newError = 'Minimum value must be smaller than maximum';
    }
    // if (!minValue && !maxValue) {
    //   newError = 'At least one of minValue and maxValue need to be provided.';
    // }
    if (newError !== error) {
      changeMinMaxData({
        minValue: minValue,
        maxValue: maxValue,
        strictMinChecked: strictMinChecked,
        strictMaxChecked: strictMaxChecked,
        error: newError,
      });
    }
  }, [
    changeMinMaxData,
    error,
    maxValue,
    minValue,
    strictMaxChecked,
    strictMinChecked,
  ]);

  return (
    <div className={classes.container}>
      <div className={classes.itemsContainer}>
        <Input
          value={minValue}
          onChange={handleChangeMinValue}
          label={minValueInput.label}
          id={minValueInput.id}
          helperText={minValueInput.helperText}
          isRequired={minValueInput.isRequired}
        />

        <Input
          value={maxValue}
          onChange={handleChangeMaxValue}
          label={maxValueInput.label}
          id={maxValueInput.id}
        />
      </div>

      <Alert alertComponent={error} withTopMargin={false} />

      <div className={classes.itemsContainer}>
        <SwitchWithLabel
          isChecked={strictMinChecked}
          handleChange={handleChangeMinSwitch}
          label={strictMinSwitch.label}
          id={strictMinSwitch.id}
          formLabel={strictMinSwitch.formLabel}
          className={classes.item}
        />

        <SwitchWithLabel
          isChecked={strictMaxChecked}
          handleChange={handleChangeMaxSwitch}
          label={strictMaxSwitch.label}
          id={strictMaxSwitch.id}
          formLabel={strictMaxSwitch.formLabel}
          className={classes.item}
        />
      </div>
    </div>
  );
};

export { MinMaxValue };
