import { useQuery, useMemo } from 'hooks/hooks.js';
import moment from 'moment';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineSourceTables = ({
  dataset,
  table,
  startDate,
  endDate,
  sourceTable,
  sourceDataset,
  searchValue,
  principalEmail,
  searchPrincipalEmail,
  jobTagNames,
  pipelineType,
  enabled = true,
}) => {
  const { isFetching: isFetchingSources, data } = useQuery(
    [
      QUERY_TYPES.pipelineSourceTables,
      dataset,
      table,
      startDate,
      endDate,
      sourceTable,
      sourceDataset,
      searchValue,
      principalEmail,
      searchPrincipalEmail,
      jobTagNames,
      pipelineType,
    ],
    ({ queryKey }) => {
      const [url, dataset, table, start, end] = queryKey;
      const params = new URLSearchParams();
      params.append('dataset', dataset);
      params.append('table', table);
      params.append('from', moment(start).utc().format());
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      params.append('sourceTable', sourceTable || '');
      params.append('sourceDataset', sourceDataset || '');
      params.append('search', searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      params.append('pipelineType', pipelineType);
      for (const tag of jobTagNames) {
        params.append('jobTagNames', tag);
      }

      return fetcherGet(url, params);
    },
    {
      enabled:
        Boolean(table && dataset) && enabled && pipelineType !== undefined,
      cacheTime: 0,
    }
  );

  const sources = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { sources, isFetchingSources };
};

export { usePipelineSourceTables };
