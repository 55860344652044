import React from 'react';
import Widget from 'Components/Widget/Widget.js';
import { ViewQuery } from './ViewQuery.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const ViewQueryWidget = ({ tableInfo }) => {
  const { viewQuery } = getWidgetLabel();

  return (
    <Widget
      label={viewQuery.title}
      secondaryLabel={viewQuery.subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
    >
      <ViewQuery {...tableInfo} />
    </Widget>
  );
};

export { ViewQueryWidget };
