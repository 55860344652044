import { useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useGetIsAnomalyTable = (
  dataset,
  table,
  startDate = null,
  endDate = null
) => {
  return useQuery(
    [QUERY_TYPES.tablesIsAnomaly, dataset, table, startDate, endDate],
    async ({ queryKey }) => {
      const [url, dataset, table, startDate, endDate] = queryKey;
      const response = await fetcherGet(url, {
        dataset,
        table,
        from: startDate
          ? moment(convertLocalToUTCDate(startDate)).utc().format()
          : null,
        to: endDate
          ? moment(convertLocalToUTCDate(endDate)).utc().format()
          : null,
      });
      return response?.value;
    },
    {
      enabled: !!dataset && !!table,
      staleTime: 5 * 60 * 1000,
    }
  );
};

export { useGetIsAnomalyTable };
