import { useState, useEffect } from 'hooks/hooks.js';
import axios from 'axios';
import { QUERY_TYPES, AUTH_ERRORS } from 'constants/constants.js';

const useGetExistingProjects = (token) => {
  const [existingProjects, setExistingProjects] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios
      .get(QUERY_TYPES.projects, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data: projects }) => {
        setExistingProjects(projects.values);
      })
      .catch(() => {
        setError(AUTH_ERRORS.STH_ELSE);
      });
  }, [token]);

  return { existingProjects, error };
};

export { useGetExistingProjects };
