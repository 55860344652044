const SEARCH_PARAMS = {
  TABLE: 'table',
  DATASET: 'dataset',
  ID: 'id',
  TYPE: 'type',
  SEARCH: 'search',
  CREATE_PROJECT: 'createProject',
  TAB: 'tab',
  PROJECT: 'project',
};

export { SEARCH_PARAMS };
