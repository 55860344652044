import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  GeneralSearch,
  SearchDropdown,
  TableDatasetSearch,
} from 'Components/components.js';
import { DataQualitySearchOptions } from '../libs/enums/enums.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    '& .searchInput': {
      flexGrow: 1,
    },
  },
}));

const DataQualitySearch = ({
  handleScanIdSearch = () => {},
  handleTableChange = () => {},
  handleTableSearch = () => {},
  defaultDataset = '',
  defaultTable = '',
  defaultTableSearchValue = '',
  className = '',
}) => {
  const classes = useStyles();
  const searchOptions = Object.values(DataQualitySearchOptions);

  const isSearchByUpstream = useMemo(() => {
    return !!defaultDataset || !!defaultTable || !!defaultTableSearchValue;
  }, [defaultDataset, defaultTable, defaultTableSearchValue]);

  const [selectSearchOptions, setSelectSearchOptions] = useState(
    isSearchByUpstream ? DataQualitySearchOptions.TABLES : searchOptions[0]
  );

  const handleChangeSearchSelectedOption = (value) => {
    handleScanIdSearch('');
    handleTableChange(null);
    handleTableSearch('');
    setSelectSearchOptions(value);
  };

  const renderSearchField = () => {
    switch (selectSearchOptions) {
      case DataQualitySearchOptions.SCAN_ID:
        return (
          <GeneralSearch
            options={[]}
            onSearch={handleScanIdSearch}
            onSelect={handleScanIdSearch}
            className='searchInput'
            placeholderName='Start typing scan id...'
            searchWithDropdown={true}
            withSelect={false}
            searchKey={DataQualitySearchOptions.SCAN_ID}
          />
        );
      case DataQualitySearchOptions.TABLES:
        return (
          <TableDatasetSearch
            onTableChange={handleTableChange}
            onSearch={handleTableSearch}
            defaultDataset={defaultDataset}
            defaultTable={defaultTable}
            defaultSearchValue={defaultTableSearchValue}
            className='searchInput'
            placeholderName='Start typing table name...'
            searchWithDropdown={true}
            disableDatasets={true}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={clsx(classes.container, className)}>
      <SearchDropdown
        options={searchOptions}
        selectedOption={selectSearchOptions}
        onOptionClick={handleChangeSearchSelectedOption}
      />
      {renderSearchField()}
    </div>
  );
};

export { DataQualitySearch };
