import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { PositionLoader } from 'Components/components.js';

const LoadingTableRaw = () => {
  return (
    <TableRow style={{ position: 'relative', height: 100 }}>
      <TableCell>
        <PositionLoader />
      </TableCell>
    </TableRow>
  );
};

export { LoadingTableRaw };
