import moment from 'moment/moment';
import { getIncidentFormatData } from 'utils/helpers/helpers.js';

const MAX_PERCENT = 100;
const FILTERED_DATA_TYPE = 'PERCENTAGE';

const getAreaDataWithCallback = (data, getInterval) => {
  return data.map((item) => ({
    ...item,
    value: item.value === null && item.isAnomaly ? 0 : item.value,
    interval: getInterval(item),
  }));
};

const getAreaData = (data) => {
  const getIntervalCallback = (item) => {
    return (item.min === 0 || item.min === null) &&
      (item.max === 0 || item.max === null)
      ? [0, 0]
      : [item.min > 0 ? item.min : 1, item.max > 0 ? item.max : 1];
  };

  return getAreaDataWithCallback(data, getIntervalCallback);
};

const getAreaDataWithThresholdCoefficient = (data, percentThreshold) => {
  const thresholdCoefficient = percentThreshold / MAX_PERCENT;
  const getIntervalCallback = (item) => {
    const minInterval = item.minPredicted
      ? Math.round(
          item.minRaw - (item.minPredicted - item.minRaw) * thresholdCoefficient
        )
      : item.minRaw;
    const maxInterval = item.maxPredicted
      ? Math.round(
          item.maxRaw + (item.maxRaw - item.maxPredicted) * thresholdCoefficient
        )
      : item.maxRaw;

    return [
      minInterval > 0 ? minInterval : 1,
      maxInterval > 0 ? maxInterval : 1,
    ];
  };

  return getAreaDataWithCallback(data, getIntervalCallback);
};

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD, hh:mm a');
};

const getIntervalTitle = (interval = [], metricType = null) => {
  if (interval.length) {
    const firstValue = interval.at(0);
    const lastValue = interval.at(-1);

    if (firstValue !== 0 && lastValue !== 0) {
      return `${getIncidentFormatData(
        metricType,
        firstValue
      )} - ${getIncidentFormatData(metricType, lastValue)}`;
    }
  }

  return 'No data available';
};

const createFilteredDataTooltipItemName = (
  minFilteredData,
  maxFilteredData
) => {
  let name = 'custom bar';

  const isPercentageFilter = (data) =>
    data && data.filterType === FILTERED_DATA_TYPE;

  if (isPercentageFilter(maxFilteredData) && !minFilteredData) {
    name = `custom bar (${maxFilteredData.filterValue}%)`;
  } else if (isPercentageFilter(minFilteredData) && !maxFilteredData) {
    name = `custom bar (${minFilteredData.filterValue}%)`;
  } else if (
    isPercentageFilter(maxFilteredData) &&
    isPercentageFilter(minFilteredData) &&
    maxFilteredData.filterValue === minFilteredData.filterValue
  ) {
    name = `custom bar (${maxFilteredData.filterValue}%)`;
  }

  return name;
};

const createFilteredDataTooltipItemValue = (
  minFilteredData,
  maxFilteredData,
  metricType
) => {
  const formatData = (data) =>
    data
      ? getIncidentFormatData(metricType, data.value > 0 ? data.value : 0)
      : '';
  const isPercentageFilter = (data) =>
    data && data.filterType === FILTERED_DATA_TYPE;

  let value = 'No data available';

  if (maxFilteredData && minFilteredData) {
    const maxIsPercentage = isPercentageFilter(maxFilteredData);
    const minIsPercentage = isPercentageFilter(minFilteredData);

    if (maxIsPercentage !== minIsPercentage) {
      value = `${formatData(minFilteredData)}${
        minIsPercentage ? ` (${minFilteredData.filterValue}%)` : ''
      } - ${formatData(maxFilteredData)}${
        maxIsPercentage ? ` (${maxFilteredData.filterValue}%)` : ''
      }`;
    } else {
      value = `${formatData(minFilteredData)} - ${formatData(maxFilteredData)}`;
    }
  } else if (maxFilteredData && !minFilteredData) {
    value = `${formatData(maxFilteredData)}`;
  } else if (minFilteredData && !maxFilteredData) {
    value = `${formatData(minFilteredData)}`;
  }

  return value;
};

export {
  getAreaData,
  getAreaDataWithThresholdCoefficient,
  getDatetimeFormat,
  getIntervalTitle,
  createFilteredDataTooltipItemName,
  createFilteredDataTooltipItemValue,
};
