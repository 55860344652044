import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, BUTTON_COLOR } from 'Components/components.js';
import { useProjectTree } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { ReactComponent as DoubleArrowRight } from 'assets/img/icons/arrows/double-arrow-right.svg';

const useStyles = makeStyles(() => ({
  rotateIcon: {
    transform: (props) => (props.isOpen ? 'rotate(180deg)' : ''),
    transition: 'transform 300ms ease',
  },
}));

const SourcesButton = ({ onClick }) => {
  const { isOpen } = useProjectTree();
  const classes = useStyles({ isOpen });

  const onSourcesBtnClick = (event) => {
    onClick();
    amplEvent(`Sources button click -> ${event.target.textContent}`);
  };

  return (
    <Button
      color={BUTTON_COLOR.secondary}
      onClick={onSourcesBtnClick}
      startIcon={<DoubleArrowRight className={classes.rotateIcon} />}
      text={isOpen ? 'Hide Sources' : 'Show Sources'}
      fullWidth={false}
    />
  );
};

export { SourcesButton };
