import React from 'react';
import { useCallback, useState, useEffect } from 'hooks/hooks.js';
import { Input, Select } from 'Components/components.js';
import { numberInput } from 'utils/helpers/helpers.js';
import {
  ALL_DATA_SAMPLING_SIZE,
  CONTROL_ELEMENTS_DATA,
  CUSTOM_SAMPLING_SIZE,
  INPUT_ERRORS,
  SAMPLING_SIZE_OPTIONS,
} from '../../libs/constants/constants.js';

const MAX_CUSTOM_SIZE = 100;
const MIN_CUSTOM_SIZE = 0.001;

const getDefaultSamplingSizeValue = (defaultValue) => {
  if (defaultValue) {
    return (
      SAMPLING_SIZE_OPTIONS.find((item) => item.value === defaultValue) ||
      SAMPLING_SIZE_OPTIONS[SAMPLING_SIZE_OPTIONS.length - 1]
    );
  }

  return SAMPLING_SIZE_OPTIONS[0];
};

const SamplingSizeSelect = ({ changeSamplingSize, defaultSizeValue = '' }) => {
  const { samplingSizeSelect, customSamplingSizeInput } = CONTROL_ELEMENTS_DATA;
  const [error, setError] = useState(null);

  const [sizeValue, setSizeValue] = useState(
    getDefaultSamplingSizeValue(defaultSizeValue.toString())
  );
  const [customSize, setCustomSize] = useState(
    defaultSizeValue ? defaultSizeValue.toString() : ALL_DATA_SAMPLING_SIZE
  );

  const handleChangeSamplingSize = useCallback((value) => {
    setSizeValue(value);
    setError(null);
  }, []);

  const handleChangeCustomSize = useCallback((value) => {
    if (
      (value.length && !numberInput(value)) ||
      +value > MAX_CUSTOM_SIZE ||
      +value < MIN_CUSTOM_SIZE
    ) {
      setError(INPUT_ERRORS.invalidCustomSamplingSize);
    } else {
      setError(null);
    }

    setCustomSize(value);
  }, []);

  useEffect(() => {
    changeSamplingSize(
      sizeValue.value === CUSTOM_SAMPLING_SIZE ? customSize : sizeValue.value,
      !!error
    );
  }, [customSize, error, changeSamplingSize, sizeValue.value]);

  return (
    <>
      <Select
        options={SAMPLING_SIZE_OPTIONS}
        value={sizeValue}
        onChange={handleChangeSamplingSize}
        label={samplingSizeSelect.label}
        isRequired={samplingSizeSelect.isRequired}
        id={samplingSizeSelect.id}
      />

      {sizeValue.value === CUSTOM_SAMPLING_SIZE && (
        <Input
          value={customSize.toString()}
          onChange={handleChangeCustomSize}
          label={customSamplingSizeInput.label}
          id={customSamplingSizeInput.id}
          isRequired={customSamplingSizeInput.isRequired}
          helperText={customSamplingSizeInput.helperText}
          withEndAdornment={customSamplingSizeInput.withEndAdornment}
          EndAdornmentComponent={customSamplingSizeInput.endAdornment}
          errorComponent={error}
        />
      )}
    </>
  );
};

export { SamplingSizeSelect };
