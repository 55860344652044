import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: 'grid',
    'grid-template-columns': '1fr 1fr 1fr',
  },
  card: {
    padding: 24,
    height: 318,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    gap: 16,
  },
  cardItemInfo: {
    display: 'flex',
    gap: 16,
  },
  logoContainer: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
    '& .logoImg': {
      width: 60,
      height: 60,
    },
  },
  chipContainer: {
    padding: '4px 4px 4px 6px',
    display: 'flex',
    gap: 6,
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 4,
  },
  chip: {
    display: 'inline-block',
    fontSize: 13,
    color: theme.palette.primary.main,
    fontWeight: 700,
    lineHeight: '22px',
    borderRadius: 4,
    '&.disabled': {
      color: theme.palette.divider,
      background: theme.palette.secondary.light,
    },
  },
  withDivider: {
    position: 'relative',
    '&:after': {
      content: "''",
      position: 'absolute',
      height: '100%',
      width: 1,
      background: '#d8e1ec',
      left: 0,
      top: 0,
    },
  },
  comingSoonBtn: {
    boxSizing: 'border-box',
    padding: 11,
    marginTop: 32,
  },
  form: {
    '& .MuiFormLabel-root': {
      color: '#627D98',
    },
  },
  integrationBanner: {
    background: theme.palette.common.white,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    borderRadius: 12,
    '& .btn-blue': {
      width: 100,
      height: 44,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  bannerIcon: {
    marginRight: theme.spacing(4),
    width: 32,
    height: 32,
  },
  modalWrapper: {
    width: 442,
  },
}));
