import React from 'react';
import {
  useEffect,
  useMemo,
  useState,
  useMutation,
  useQueryClient,
} from 'hooks/hooks.js';
import { NumericFormat } from 'react-number-format';
import {
  MenuItem,
  Select,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useStyles } from './CostsForm.styles.js';
import { Alert, Button } from 'Components/components.js';
import {
  BQ_BILLING_OPTIONS,
  BQ_BILLING_TYPE,
  COMMITMENT_MODEL,
  COMMITMENT_MODEL_OPTIONS,
  COMMITMENT_PLAN,
  COMMITMENT_PLAN_OPTIONS,
  EDITION_PLAN,
  EDITION_PLAN_OPTIONS,
} from 'utils/constants.js';
import { QUERY_TYPES } from 'constants/constants.js';
import {
  OnDemandsRegions,
  MonthlyFRRegions,
  AnnualFRRegions,
  HourlyFRRegions,
  StandardPayAsYouGoRegions,
  EnterprisePayAsYouGoRegions,
  EnterprisePlusPayAsUGoRegions,
  Enterprise1yCommitRegions,
  EnterprisePlus1yCommitRegions,
  Enterprise3yCommitRegions,
  EnterprisePlus3yCommitRegions,
} from './regions.js';
import { fetcherPost } from 'utils/utils.js';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-round.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/unCheck-round.svg';

const subTitleText =
  'Fill out the form to receive the most up-to-date insights.';
const defaultPriceText = 'Price, $';
const onDemandPriceText = 'Price per 1TiB, $';
const hourlyEdPriceText = 'Price per slot hour, $';
const hourlyFRPriceText = 'Hourly price per 100 slots, $';
const monthlyFRPriceText = 'Monthly price per 100 slots, $';
const annualFRPriceText = 'Monthly price per 100 slots, $';

const regionsByBillingConf = (bqBillingData) => {
  if (!bqBillingData) {
    return [];
  }
  if (BQ_BILLING_TYPE.ON_DEMANDS === bqBillingData.paymentType) {
    return OnDemandsRegions;
  } else if (
    BQ_BILLING_TYPE.FLAT_RATE === bqBillingData.paymentType &&
    bqBillingData.commitmentPlan
  ) {
    switch (bqBillingData.commitmentPlan) {
      case COMMITMENT_PLAN.HOURLY:
        return HourlyFRRegions;
      case COMMITMENT_PLAN.MONTHLY:
        return MonthlyFRRegions;
      case COMMITMENT_PLAN.ANNUAL:
        return AnnualFRRegions;
      default:
        return [];
    }
  } else if (
    BQ_BILLING_TYPE.EDITIONS === bqBillingData.paymentType &&
    bqBillingData.editionCommitmentModel &&
    bqBillingData.editionPlan
  ) {
    switch (bqBillingData.editionPlan) {
      case EDITION_PLAN.STANDARD:
        return StandardPayAsYouGoRegions;
      case EDITION_PLAN.ENTERPRISE:
        switch (bqBillingData.editionCommitmentModel) {
          case COMMITMENT_MODEL.PAY_AS_YOU_GO:
            return EnterprisePayAsYouGoRegions;
          case COMMITMENT_MODEL.ONE_YEAR:
            return Enterprise1yCommitRegions;
          case COMMITMENT_MODEL.THREE_YEAR:
            return Enterprise3yCommitRegions;
          default:
            return [];
        }
      case EDITION_PLAN.ENTERPRISE_PLUS:
        switch (bqBillingData.editionCommitmentModel) {
          case COMMITMENT_MODEL.PAY_AS_YOU_GO:
            return EnterprisePlusPayAsUGoRegions;
          case COMMITMENT_MODEL.ONE_YEAR:
            return EnterprisePlus1yCommitRegions;
          case COMMITMENT_MODEL.THREE_YEAR:
            return EnterprisePlus3yCommitRegions;
          default:
            return [];
        }
      default:
        return [];
    }
  }

  return [];
};

const priceTextByBillingConf = (bqBillingData) => {
  if (!bqBillingData) {
    return defaultPriceText;
  }
  if (BQ_BILLING_TYPE.ON_DEMANDS === bqBillingData.paymentType) {
    return onDemandPriceText;
  } else if (
    BQ_BILLING_TYPE.FLAT_RATE === bqBillingData.paymentType &&
    bqBillingData.commitmentPlan
  ) {
    switch (bqBillingData.commitmentPlan) {
      case COMMITMENT_PLAN.HOURLY:
        return hourlyFRPriceText;
      case COMMITMENT_PLAN.MONTHLY:
        return monthlyFRPriceText;
      case COMMITMENT_PLAN.ANNUAL:
        return annualFRPriceText;
      default:
        return defaultPriceText;
    }
  } else if (
    BQ_BILLING_TYPE.EDITIONS === bqBillingData.paymentType &&
    bqBillingData.editionCommitmentModel &&
    bqBillingData.editionPlan
  ) {
    return hourlyEdPriceText;
  }

  return defaultPriceText;
};
const subTitleByBillingConf = (bqBillingData) => {
  if (!bqBillingData) {
    return subTitleText;
  }
  const subTitleParts = [];
  const regions = regionsByBillingConf(bqBillingData);
  const region = regions.find((item) => item.id === bqBillingData.region);
  if (region) {
    subTitleParts.push(region.title);
  }

  const priceModel = BQ_BILLING_OPTIONS.find(
    (item) => item.value === bqBillingData.paymentType
  );
  if (priceModel) {
    subTitleParts.push(priceModel.label);
  }

  const commitmentPlan = COMMITMENT_PLAN_OPTIONS.find(
    (item) => item.value === bqBillingData.commitmentPlan
  );
  if (commitmentPlan) {
    subTitleParts.push(commitmentPlan.label);
  }
  const editionPlan = EDITION_PLAN_OPTIONS.find(
    (item) => item.value === bqBillingData.editionPlan
  );
  if (editionPlan) {
    subTitleParts.push(editionPlan.label);
  }
  const editionCommitmentModel = COMMITMENT_MODEL_OPTIONS.find(
    (item) => item.value === bqBillingData.editionCommitmentModel
  );
  if (editionCommitmentModel) {
    subTitleParts.push(editionCommitmentModel.label);
  }
  subTitleParts.push(`$${bqBillingData.price}`);

  return subTitleParts.join(', ');
};

const CostsForm = ({
  isMultipleProjects,
  project,
  bqBillingData,
  isDefaultOpen,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [pricingModel, setPricingModel] = useState(
    bqBillingData?.paymentType || ''
  );
  const [commitmentPlan, setCommitmentPlan] = useState(
    bqBillingData?.commitmentPlan || ''
  );
  const [editionPlan, setEditionPlan] = useState(
    bqBillingData?.editionPlan || ''
  );
  const [editionCommitmentModel, setEditionCommitmentModel] = useState(
    bqBillingData?.editionCommitmentModel || ''
  );
  const [region, setRegion] = useState(bqBillingData?.region || '');
  const [price, setPrice] = useState(bqBillingData?.price || '');
  const [regions, setRegions] = useState(regionsByBillingConf(bqBillingData));
  const [subTitle, setSubTitle] = useState(
    subTitleByBillingConf(bqBillingData)
  );
  const [error, setError] = useState(null);
  const [isFullInfo, setIsFullInfo] = useState(bqBillingData ? true : false);
  const [isOpen, setIsOpen] = useState(
    isMultipleProjects ? isDefaultOpen : true
  );
  const [priceText, setPriceText] = useState(
    priceTextByBillingConf(bqBillingData)
  );

  useEffect(() => {
    if (bqBillingData) {
      setIsFullInfo(true);
      setSubTitle(subTitleByBillingConf(bqBillingData));
    }
  }, [bqBillingData]);

  const handleOpen = () => {
    if (!isMultipleProjects) {
      return;
    }
    setIsOpen((prev) => !prev);
  };

  const onPricingModelChange = (event) => {
    const value = event.target.value;
    if (BQ_BILLING_TYPE.ON_DEMANDS === value) {
      setRegions(OnDemandsRegions);
      setPriceText(onDemandPriceText);
    } else {
      setRegions([]);
    }
    setPricingModel(event.target.value);
    setCommitmentPlan('');
    setEditionCommitmentModel('');
    setEditionPlan('');
    setRegion('');
    setPrice('');
    setError(null);
  };

  const onCommitmentPlanChange = (event) => {
    const value = event.target.value;
    setCommitmentPlan(event.target.value);
    switch (value) {
      case COMMITMENT_PLAN.HOURLY:
        setRegions(HourlyFRRegions);
        setPriceText(hourlyFRPriceText);
        break;
      case COMMITMENT_PLAN.MONTHLY:
        setRegions(MonthlyFRRegions);
        setPriceText(monthlyFRPriceText);
        break;
      case COMMITMENT_PLAN.ANNUAL:
        setRegions(AnnualFRRegions);
        setPriceText(annualFRPriceText);
        break;
      default:
        setRegions([]);
        break;
    }
    setEditionCommitmentModel('');
    setRegion('');
    setPrice('');
    setError(null);
  };

  const onEditionPlanChange = (event) => {
    const value = event.target.value;
    setEditionPlan(value);
    setPriceText(hourlyEdPriceText);
    setRegions([]);
    setRegion('');
    if (value === EDITION_PLAN.STANDARD) {
      setEditionCommitmentModel(COMMITMENT_MODEL.PAY_AS_YOU_GO);
      setRegions(StandardPayAsYouGoRegions);
    } else {
      setEditionCommitmentModel('');
    }
    setPrice('');
    setError(null);
  };

  const onEditionCommitmentModelChange = (event) => {
    const value = event.target.value;
    setEditionCommitmentModel(value);
    switch (value) {
      case COMMITMENT_MODEL.PAY_AS_YOU_GO:
        switch (editionPlan) {
          case EDITION_PLAN.STANDARD:
            setRegions(StandardPayAsYouGoRegions);
            break;
          case EDITION_PLAN.ENTERPRISE:
            setRegions(EnterprisePayAsYouGoRegions);
            break;
          case EDITION_PLAN.ENTERPRISE_PLUS:
            setRegions(EnterprisePlusPayAsUGoRegions);
            break;
          default:
            setRegions([]);
            break;
        }
        break;
      case COMMITMENT_MODEL.ONE_YEAR:
        switch (editionPlan) {
          case EDITION_PLAN.ENTERPRISE:
            setRegions(Enterprise1yCommitRegions);
            break;
          case EDITION_PLAN.ENTERPRISE_PLUS:
            setRegions(EnterprisePlus1yCommitRegions);
            break;
          default:
            setRegions([]);
            break;
        }
        break;
      case COMMITMENT_MODEL.THREE_YEAR:
        switch (editionPlan) {
          case EDITION_PLAN.ENTERPRISE:
            setRegions(Enterprise3yCommitRegions);
            break;
          case EDITION_PLAN.ENTERPRISE_PLUS:
            setRegions(EnterprisePlus3yCommitRegions);
            break;
          default:
            setRegions([]);
            break;
        }
        break;
      default:
        setRegions([]);
        break;
    }

    setRegion('');
    setCommitmentPlan('');
    setPrice('');
    setError(null);
  };

  const onRegionChange = (event) => {
    const value = event.target.value;
    setRegion(value);
    const regionData = regions.find((item) => item.id === value);
    if (regionData) {
      setPrice(regionData.price);
    }
    setError(null);
  };

  const onPriceChange = (event) => {
    setPrice(event.target.value);
    setError(null);
  };

  const isShowCommitmentPlan = useMemo(() => {
    return pricingModel && pricingModel === BQ_BILLING_TYPE.FLAT_RATE;
  }, [pricingModel]);

  const isShowEditionPlan = useMemo(() => {
    return pricingModel && pricingModel === BQ_BILLING_TYPE.EDITIONS;
  }, [pricingModel]);

  const isShowEditionCommitmentModel = useMemo(() => {
    return editionPlan && editionPlan !== EDITION_PLAN.STANDARD;
  }, [editionPlan]);

  const { mutateAsync: saveBqBilling } = useMutation(
    (data) => {
      return fetcherPost(`/api/v1/bq-billing?project=${project}`, data);
    },
    {
      onSuccess: (data) => {
        if (data?.success) {
          queryClient.invalidateQueries(QUERY_TYPES.bqBillingData);
          setSubTitle(`${region}, ${pricingModel}, $${price}`);
          setIsOpen(false);
        } else {
          setError('Something went wrong.');
        }
      },
      onError: () => {
        setError('Something went wrong.');
      },
    }
  );

  const saveValue = () => {
    if (!region) {
      setError('Choose region');
      return;
    }

    if (!pricingModel) {
      setError('Choose pricing model type');
      return;
    }

    if (!price) {
      setError('Enter price');
      return;
    }

    saveBqBilling({
      paymentType: pricingModel,
      commitmentPlan: commitmentPlan ? commitmentPlan : COMMITMENT_PLAN.NONE,
      editionPlan: editionPlan ? editionPlan : EDITION_PLAN.NONE,
      editionCommitmentModel: editionCommitmentModel
        ? editionCommitmentModel
        : COMMITMENT_MODEL.NONE,
      region,
      price,
    });
  };

  return (
    <div className={classes.formContainer}>
      <div
        onClick={handleOpen}
        style={{ cursor: isMultipleProjects && 'pointer' }}
        className={classes.headerContainer}
      >
        <div>
          <div className='txt-mainDark-16-700'>{project}</div>
          <div className='txt-grey-13-500'>{subTitle}</div>
        </div>

        {isFullInfo ? <CheckIcon /> : <UncheckIcon />}
      </div>

      {(isOpen || !isMultipleProjects) && (
        <div className='formBlock'>
          <div>
            <FormControl fullWidth>
              <InputLabel disableAnimation={true}>
                Pricing model type
              </InputLabel>
              <Select
                value={pricingModel}
                onChange={onPricingModelChange}
                displayEmpty
              >
                <MenuItem disabled value=''>
                  Choose billing type
                </MenuItem>
                {BQ_BILLING_OPTIONS.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {isShowCommitmentPlan && (
            <div>
              <FormControl fullWidth>
                <InputLabel disableAnimation={true}>Commitment plan</InputLabel>
                <Select
                  value={commitmentPlan}
                  onChange={onCommitmentPlanChange}
                  displayEmpty
                >
                  <MenuItem disabled value=''>
                    Choose plan
                  </MenuItem>
                  {COMMITMENT_PLAN_OPTIONS.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {isShowEditionPlan && (
            <div>
              <FormControl fullWidth>
                <InputLabel disableAnimation={true}>Edition plan</InputLabel>
                <Select
                  value={editionPlan}
                  onChange={onEditionPlanChange}
                  displayEmpty
                >
                  <MenuItem disabled value=''>
                    Choose plan
                  </MenuItem>
                  {EDITION_PLAN_OPTIONS.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {isShowEditionCommitmentModel && (
            <div>
              <FormControl fullWidth>
                <InputLabel disableAnimation={true} required>
                  Commitment models
                </InputLabel>
                <Select
                  value={editionCommitmentModel}
                  onChange={onEditionCommitmentModelChange}
                  displayEmpty
                  required
                >
                  <MenuItem disabled value=''>
                    Choose commitment model
                  </MenuItem>
                  {COMMITMENT_MODEL_OPTIONS.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {regions.length > 0 && (
            <div>
              <FormControl fullWidth>
                <InputLabel disableAnimation={true} required>
                  Region of data stores
                </InputLabel>
                <Select
                  value={region}
                  onChange={onRegionChange}
                  displayEmpty
                  required
                >
                  <MenuItem disabled value=''>
                    Choose region
                  </MenuItem>
                  {regions.map((item) => (
                    <MenuItem value={item.id} key={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          {region && (
            <div>
              <InputLabel disableAnimation={true}>{priceText}</InputLabel>
              <TextField
                variant='outlined'
                placeholder='Enter price'
                value={price}
                InputProps={{
                  inputComponent: NumericFormat,
                }}
                onChange={onPriceChange}
                fullWidth
                required
              />
            </div>
          )}

          <Alert alertComponent={error} withTopMargin={false} />

          {isMultipleProjects ? (
            <Button text='Save' onClick={saveValue} />
          ) : (
            <Button text='Build Insights' onClick={saveValue} />
          )}
        </div>
      )}
    </div>
  );
};

export default CostsForm;
