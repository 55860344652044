import React from 'react';
import { useState, useChangeProject, useCallback } from 'hooks/hooks.js';
import { DEMO_PROJECT } from 'utils/constants.js';
import { useStyles } from './DemoBanner.style.js';

const DemoBanner = () => {
  const classes = useStyles();
  const [isShow, setIsShow] = useState(true);
  const { changeProject } = useChangeProject();

  const updateDemoProject = useCallback(() => {
    changeProject(DEMO_PROJECT);
  }, [changeProject]);

  const handleHideBanner = useCallback(() => {
    setIsShow(false);
  }, []);

  if (!isShow) {
    return '';
  }

  return (
    <div className={classes.container}>
      <div className='txt-mainDark-16-700 whiteText'>
        See Masthead in action. Explore our demo project!
      </div>
      <div className={classes.btnContainer}>
        <button
          type='button'
          style={{}}
          className='btn btn-link whiteText'
          onClick={handleHideBanner}
        >
          I’ll do it later
        </button>
        <button
          type='button'
          style={{}}
          className='btn btn-blue mt-0'
          onClick={updateDemoProject}
        >
          Explore Now
        </button>
      </div>

      <div className={classes.blurContainer1} />
      <div className={classes.blurContainer2} />
      <div className={classes.blurContainer3} />
      <div className={classes.blurContainer4} />
      <div className={classes.blurContainer5} />
    </div>
  );
};

export { DemoBanner };
