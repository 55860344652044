/* A function that splits a string `limit` times and adds the remainder as a final array index.
 * > var a = 'convoluted.madeup.example';
 * > a.split('.', 1);
 * < ['convoluted']
 * what expected:
 * < ['convoluted', 'madeup.example']
 */
const splitWithRemainder = (str, separator, limit) => {
  const strSplit = str.split(separator);
  return strSplit.length <= limit
    ? strSplit
    : [strSplit[0]].concat(strSplit.slice(1).join(separator));
};

export { splitWithRemainder };
