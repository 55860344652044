import React from 'react';
import {
  useEffect,
  useState,
  useMutation,
  useQuery,
  useMemo,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { fetcherDelete, fetcherGet, fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import {
  Button,
  BUTTON_COLOR,
  PageHeader,
  PositionLoader,
} from 'Components/components.js';
import { useMessages, useUserInfo } from 'context/context.js';

const useStyles = makeStyles((theme) => ({
  adminContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 200,
  },
  menuItemSelect: {
    width: 440,
  },
}));

const PISOTSKYI_EMAIL = 'andrew.pisotskyi@mastheadata.com';
const TKACHOV_EMAIL = 'sergey.tkachov@mastheadata.com';

export default function Admin() {
  const { user } = useUserInfo();
  const { setMessage } = useMessages();
  const classes = useStyles();
  const [tenants, setTenants] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsForRemove, setProjectsForRemove] = useState([]);
  const [currentTenant, setCurrentTenant] = useState('');
  const [currentRemoveTenant, setCurrentRemoveTenant] = useState('');
  const [currentRemoveProject, setCurrentRemoveProject] = useState('');
  const [currentProject, setCurrentProject] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const isShowRemoveForm = useMemo(() => {
    if (user) {
      return user.email === PISOTSKYI_EMAIL || user.email === TKACHOV_EMAIL;
    }

    return false;
  }, [user]);

  const { data } = useQuery([QUERY_TYPES.tenants], ({ queryKey }) => {
    const [url] = queryKey;
    return fetcherGet(url);
  });
  const { mutateAsync: updateAdminData } = useMutation(
    (data) => fetcherPost('/admin/data', data),
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while changing admin data');
        } else {
          window.location.reload();
        }
      },
    }
  );
  const { mutateAsync: removeTenant } = useMutation(
    () => fetcherDelete(`/admin/tenants/${currentRemoveTenant}`, {}),
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while removing tenant');
        } else {
          window.location.reload();
        }
      },
    }
  );
  const { mutateAsync: removeProject } = useMutation(
    () => fetcherDelete(`/admin/projects/${currentRemoveProject}`, {}),
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while removing project');
        } else {
          window.location.reload();
        }
      },
    }
  );

  useEffect(() => {
    if (data?.values.length) {
      setTenants(data?.values.map((it) => it.tenant));
    }
  }, [data]);

  useEffect(() => {
    setCurrentProject('');
    if (currentTenant && data?.values.length) {
      const value = data?.values.find((it) => it.tenant === currentTenant);
      if (value) {
        setProjects(value.projects);
        setCurrentProject(value.projects[0]);
      }
    }
  }, [currentTenant, data]);
  useEffect(() => {
    setCurrentRemoveProject('');
    if (currentRemoveTenant && data?.values.length) {
      const value = data?.values.find(
        (it) => it.tenant === currentRemoveTenant
      );
      if (value) {
        setProjectsForRemove(value.projects);
      }
    }
  }, [currentRemoveTenant, data]);

  useEffect(() => {
    if (user?.info?.adminData) {
      setCurrentTenant(user.info.adminData.currentTenant);
      setCurrentProject(user.info.adminData.currentProject);
    }
  }, [user]);

  const handleTenantChange = (event) => setCurrentTenant(event.target.value);
  const handleRemoveTenantChange = (event) =>
    setCurrentRemoveTenant(event.target.value);
  const handleRemoveProjectChange = (event) =>
    setCurrentRemoveProject(event.target.value);
  const handleProjectChange = (event) => setCurrentProject(event.target.value);

  const saveAdminData = () => {
    setIsLoading(true);
    updateAdminData({ currentTenant, currentProject }).finally(() =>
      setIsLoading(false)
    );
  };
  const handleRemoveTenantProject = () => {
    if (!currentRemoveTenant && !currentRemoveProject) {
      return;
    }
    if (
      window.confirm(
        `Are you sure you want to remove\ntenant: ${currentRemoveTenant}\nproject: ${currentRemoveProject}\n?`
      )
    ) {
      setIsLoading(true);
      if (currentRemoveProject) {
        removeProject().finally(() => setIsLoading(false));
      } else {
        removeTenant().finally(() => setIsLoading(false));
      }
    } else {
      setCurrentRemoveTenant('');
      setCurrentRemoveProject('');
    }
  };

  return (
    <main>
      <PageHeader hasDateRangePicker={false}>Admin</PageHeader>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <div className={classes.adminContainer}>
          <div>
            <FormControl sx={{ mr: 5 }}>
              <InputLabel id='tenant'>Choose tenant</InputLabel>
              <Select
                key='tenant'
                labelId='tenant'
                className={classes.menuItemSelect}
                value={currentTenant}
                onChange={handleTenantChange}
              >
                <MenuItem key='' value=''>
                  <em>None</em>
                </MenuItem>
                {tenants.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id='project'>Choose project</InputLabel>
              <Select
                labelId='project'
                className={classes.menuItemSelect}
                value={currentProject}
                onChange={handleProjectChange}
              >
                <MenuItem key='' value='' disabled>
                  <em>Choose project</em>
                </MenuItem>
                {projects.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            <br />
            <Button text='Save' onClick={saveAdminData} fullWidth={false} />
          </div>

          <hr />

          {isShowRemoveForm ? (
            <div>
              <FormControl sx={{ mr: 5 }}>
                <InputLabel id='remove-tenant'>Remove tenant</InputLabel>
                <Select
                  key='tenant'
                  labelId='tenant'
                  className={classes.menuItemSelect}
                  value={currentRemoveTenant}
                  onChange={handleRemoveTenantChange}
                >
                  <MenuItem key='' value=''>
                    <em>None</em>
                  </MenuItem>
                  {tenants.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <FormControl>
                <InputLabel id='project'>Remove project</InputLabel>
                <Select
                  labelId='project'
                  className={classes.menuItemSelect}
                  value={currentRemoveProject}
                  onChange={handleRemoveProjectChange}
                >
                  <MenuItem key='' value=''>
                    <em>None</em>
                  </MenuItem>
                  {projectsForRemove.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <br />
              <br />
              <Button
                text='Remove'
                color={BUTTON_COLOR.error}
                onClick={handleRemoveTenantProject}
                fullWidth={false}
              />
            </div>
          ) : null}
        </div>
      )}
    </main>
  );
}
