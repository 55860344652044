import React from 'react';
import {
  useMemo,
  useState,
  useTableAnalysis,
  useEffect,
  useMutation,
  useQueryClient,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Slider } from '@mui/material';
import {
  Modal,
  PositionLoader,
  TableDataVolume,
  Typography,
  TYPOGRAPHY_COLOR,
} from 'Components/components.js';
import { fetcherPost } from 'utils/utils.js';
import { amplEvent } from 'service/services.js';
import { useMessages } from 'context/context.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { QUERY_TYPES, ALERT_SEVERITY } from 'constants/constants.js';

const MAX_SLIDER_VALUE = 300;
const MIN_SLIDER_VALUE = -100;
const SLIDER_STEP = 5;

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    width: 824,
  },
  sliderContainer: {
    width: 300,
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  sliderLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: -12,
  },
}));

const VolumeSensitiveSettingsModal = ({
  table,
  dataset,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const { setMessage } = useMessages();
  const queryClient = useQueryClient();
  const { percentThreshold, isFetchingTableAnalysis } = useTableAnalysis({
    dataset,
    table,
  });
  const [customPercentThreshold, setCustomPercentThreshold] = useState(0);

  useEffect(() => {
    setCustomPercentThreshold(percentThreshold);
  }, [percentThreshold]);

  const isDisabledSaveButton = useMemo(() => {
    return percentThreshold === customPercentThreshold;
  }, [customPercentThreshold, percentThreshold]);

  const { mutateAsync: saveThresholdCoefficient } = useMutation(
    () => {
      return fetcherPost(
        `${QUERY_TYPES.tableAnalysisParams}?dataset=${dataset}&table=${table}`,
        { thresholdCoefficient: customPercentThreshold }
      );
    },
    {
      onSuccess: () => {
        setMessage(
          'The threshold coefficient has been changed',
          ALERT_SEVERITY.success
        );
        amplEvent(
          `${getFullTableName(
            table,
            dataset
          )} -> change threshold coefficient -> ${ALERT_SEVERITY.success}`
        );
        queryClient.invalidateQueries(QUERY_TYPES.tableAnalysisParams);
      },
      onError: () => {
        setMessage('Got an error while changing the threshold coefficient');
        amplEvent(
          `${getFullTableName(
            table,
            dataset
          )} -> change threshold coefficient -> ${ALERT_SEVERITY.error}`
        );
      },
    }
  );

  const handleChangeCustomThreshold = (_, newThreshold) => {
    setCustomPercentThreshold(newThreshold);
  };

  return (
    <Modal
      onAgree={saveThresholdCoefficient}
      title='Sensitive settings'
      ModalBodyComponent={SensitiveSettingsBody}
      agreeButtonText='Save'
      isDisabledSaveButton={isDisabledSaveButton}
      className={classes.modalContainer}
      customPercentThreshold={customPercentThreshold}
      onChangeCustomThresholdCoefficient={handleChangeCustomThreshold}
      table={table}
      dataset={dataset}
      startDate={startDate}
      endDate={endDate}
      isLoading={isFetchingTableAnalysis}
    />
  );
};

const SensitiveSettingsBody = ({
  customPercentThreshold,
  onChangeCustomThresholdCoefficient,
  table,
  dataset,
  startDate,
  endDate,
  isLoading = true,
}) => {
  const classes = useStyles();

  return (
    <div>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <>
          <div className={classes.sliderContainer}>
            <Slider
              value={customPercentThreshold}
              onChange={onChangeCustomThresholdCoefficient}
              valueLabelDisplay='auto'
              size='small'
              min={MIN_SLIDER_VALUE}
              max={MAX_SLIDER_VALUE}
              step={SLIDER_STEP}
            />

            <div className={classes.sliderLabel}>
              <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
                {MIN_SLIDER_VALUE}% min
              </Typography>
              <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
                {MAX_SLIDER_VALUE}% max
              </Typography>
            </div>
          </div>

          <TableDataVolume
            table={table}
            dataset={dataset}
            startDate={startDate}
            endDate={endDate}
            customPercentThreshold={customPercentThreshold}
          />
        </>
      )}
    </div>
  );
};

export { VolumeSensitiveSettingsModal };
