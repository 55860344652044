import React from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from 'Components/components.js';

const INPUT_VARIANT = 'outlined';
const END_ADORNMENT_POSITION = 'end';

const INPUT_TYPE = {
  text: 'text',
  password: 'password',
  number: 'number',
  time: 'time',
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& input[type="time"]::-webkit-calendar-picker-indicator': {
      display: 'none',
    },
    '& .MuiFormHelperText-root': {
      textAlign: (props) => props.helperTextAlign,
    },
  },
}));

const Input = ({
  id = '',
  value = '',
  onChange,
  placeholder = '',
  label = '',
  type = INPUT_TYPE.text,
  helperText = '',
  multiline = false,
  errorComponent = null,
  fullWidth = true,
  isRequired = false,
  isDisabled = false,
  helperTextAlign = 'start',
  withEndAdornment = false,
  isReadOnly = false,
  withEventData = false,
  EndAdornmentComponent,
  onInputBlur = () => {},
  onInputFocus = () => {},
  ...props
}) => {
  const classes = useStyles({ helperTextAlign });

  return (
    <FormControl
      className={classes.formControl}
      variant={INPUT_VARIANT}
      fullWidth={fullWidth}
    >
      {!!label.length && (
        <InputLabel
          htmlFor={id}
          required={isRequired}
          disableAnimation={true}
          variant={INPUT_VARIANT}
          shrink
        >
          {label}
        </InputLabel>
      )}

      <TextField
        id={id}
        value={value}
        onChange={(event) =>
          onChange(withEventData ? event : event.target.value)
        }
        variant={INPUT_VARIANT}
        placeholder={placeholder}
        required={isRequired}
        type={type}
        multiline={multiline}
        fullWidth={fullWidth}
        disabled={isDisabled}
        InputProps={{
          readOnly: isReadOnly,
          endAdornment: withEndAdornment ? (
            <InputAdornment position={END_ADORNMENT_POSITION}>
              {EndAdornmentComponent}
            </InputAdornment>
          ) : null,
        }}
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        {...props}
      />

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}

      <Alert alertComponent={errorComponent} />
    </FormControl>
  );
};

export { Input, INPUT_TYPE };
