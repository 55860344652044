import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  flexContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  mainContainer: {
    height: '100%',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
  },

  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 16,
    '& .searchInput': {
      flexGrow: 1,
    },
  },

  buttonContainer: {
    display: 'flex',
    gap: 16,
  },

  tableContainer: {
    marginTop: 16,
    overflowX: 'scroll',
  },
}));
