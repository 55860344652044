const COLUMN_TYPE = {
  bignumeric: 'BIGNUMERIC',
  bigdecimal: 'BIGDECIMAL',
  boolean: 'BOOLEAN',
  bytes: 'BYTES',
  date: 'DATE',
  datetime: 'DATETIME',
  float64: 'FLOAT64',
  float: 'FLOAT',
  geography: 'GEOGRAPHY',
  int64: 'INT64',
  int: 'INT',
  smallint: 'SMALLINT',
  integer: 'INTEGER',
  bigint: 'BIGINT',
  tinyint: 'TINYINT',
  byteint: 'BYTEINT',
  interval: 'INTERVAL',
  json: 'JSON',
  numeric: 'NUMERIC',
  decimal: 'DECIMAL',
  record: 'RECORD',
  string: 'STRING',
  time: 'TIME',
  timestamp: 'TIMESTAMP',
  array: 'ARRAY',
  struct: 'STRUCT',
};

export { COLUMN_TYPE };
