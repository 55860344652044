import { COLUMN_TYPE } from 'constants/constants.js';
import arrayIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/array.svg';
import bigDecIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bigdec.svg';
import bigIntIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bigint.svg';
import bigNumIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bignum.svg';
import boolIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bool.svg';
import bytesIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bytes.svg';
import byteIntIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/bytint.svg';
import dateIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/date.svg';
import dateTimeIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/datetm.svg';
import decIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/dec.svg';
import floatIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/float.svg';
import float64Icon from 'assets/img/icons/lineageIcons/columnTypeIcons/float64.svg';
import geoIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/geo.svg';
import intIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/int.svg';
import int64Icon from 'assets/img/icons/lineageIcons/columnTypeIcons/int64.svg';
import invlIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/invl.svg';
import jsonIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/json.svg';
import numIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/num.svg';
import recordIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/record.svg';
import smallIntIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/smlint.svg';
import stringIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/string.svg';
import structIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/struct.svg';
import timeIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/time.svg';
import timeStIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/timest.svg';
import tnyIntIcon from 'assets/img/icons/lineageIcons/columnTypeIcons/tnyint.svg';

const getColumnTypeIcon = (type) => {
  switch (type) {
    case COLUMN_TYPE.bignumeric:
      return bigNumIcon;
    case COLUMN_TYPE.bigdecimal:
      return bigDecIcon;
    case COLUMN_TYPE.boolean:
      return boolIcon;
    case COLUMN_TYPE.bytes:
      return bytesIcon;
    case COLUMN_TYPE.date:
      return dateIcon;
    case COLUMN_TYPE.datetime:
      return dateTimeIcon;
    case COLUMN_TYPE.float64:
      return float64Icon;
    case COLUMN_TYPE.float:
      return floatIcon;
    case COLUMN_TYPE.geography:
      return geoIcon;
    case COLUMN_TYPE.int64:
      return int64Icon;
    case COLUMN_TYPE.int:
      return intIcon;
    case COLUMN_TYPE.smallint:
      return smallIntIcon;
    case COLUMN_TYPE.integer:
      return intIcon;
    case COLUMN_TYPE.bigint:
      return bigIntIcon;
    case COLUMN_TYPE.tinyint:
      return tnyIntIcon;
    case COLUMN_TYPE.byteint:
      return byteIntIcon;
    case COLUMN_TYPE.interval:
      return invlIcon;
    case COLUMN_TYPE.json:
      return jsonIcon;
    case COLUMN_TYPE.numeric:
      return numIcon;
    case COLUMN_TYPE.decimal:
      return decIcon;
    case COLUMN_TYPE.record:
      return recordIcon;
    case COLUMN_TYPE.string:
      return stringIcon;
    case COLUMN_TYPE.time:
      return timeIcon;
    case COLUMN_TYPE.timestamp:
      return timeStIcon;
    case COLUMN_TYPE.array:
      return arrayIcon;
    case COLUMN_TYPE.struct:
      return structIcon;

    default:
      return '';
  }
};

export { getColumnTypeIcon };
