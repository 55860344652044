import { DEPENDING_TABLE_WIDTH } from 'Pages/Dictionary/Dictionary.styles.js';
import { OverflownText } from 'Components/components.js';

const DictionaryTableDependingSourceUri = ({ sourceUri }) => {
  return (
    <OverflownText title={sourceUri} maxWidth={DEPENDING_TABLE_WIDTH}>
      {sourceUri}
    </OverflownText>
  );
};

export { DictionaryTableDependingSourceUri };
