import React from 'react';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { baseStyles } from '../base.styles.js';
import { ownStyles } from './first-slider.style.js';
import { ReactComponent as Person } from 'assets/img/personalities/joseph-arriola.svg';
import YaloLogo from 'assets/img/yaloLogo.svg';

const FirstSlider = () => {
  const classes = baseStyles();
  const ownClasses = ownStyles();

  return (
    <div key='slide1' className={classes.wrapper}>
      <div className={classes.rightSide}>
        <div className={classes.textWrapper}>
          <div className='txt-mainDark-24-700 mb-4'>Welcome to Masthead</div>
          <div className='txt-mainDark-13-500 mb-3'>
            Within the next{' '}
            <span className='txt-mainDark-13-700'>
              15 minutes Masthead will:
            </span>
            <ul className={ownClasses.list}>
              <li className='txt-mainDark-13-500'>
                Pull metadata for tables, views, and columns in your Dashboard &
                Data Dictionary.
              </li>

              <li className='txt-mainDark-13-500'>
                Show you Schema Changes and Pipeline Errors.
              </li>

              <li className='txt-mainDark-13-500'>
                Visualise Data Lineage instantly after any pipeline executes.
              </li>
            </ul>
            Freshness and Volume anomalies live in up to{' '}
            <span className='txt-mainDark-13-700'>5 hours</span>.
          </div>
        </div>
      </div>

      <div className={clsx(classes.leftSide, ownClasses.leftSideOwn)}>
        <div className='txt-mainDark-13-500 text-container'>
          <div style={{ marginBottom: 32 }}>
            In 24 hour after installation Masthead caught
            <br />
            the schema change error, that saved us at least
            <br />
            the day of investigation
          </div>
          <Person className='mb-2' />
          <div className='mb-2'>
            Joseph Arriola
            <br /> Senior Data Manager
          </div>

          <Image
            src={YaloLogo}
            alt='Yalo company logo'
            style={{ height: 16 }}
          />
        </div>
      </div>
    </div>
  );
};

export { FirstSlider };
