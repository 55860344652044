import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactComponent as MagicIcon } from 'assets/img/icons/magic.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    cursor: 'pointer',
    padding: '5px 8px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(1),
    '& svg path': {
      fill: theme.palette.primary.main,
    },
  },

  selected: {
    background: 'linear-gradient(65deg, #2F38C8 18.97%, #886CF6 94.15%)',
    '& p': {
      color: theme.palette.common.white,
    },
    '& svg path': {
      fill: theme.palette.common.white,
    },
  },
}));

const SuggestedPriority = ({ isSelected, handleChangeSelected }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, isSelected && classes.selected)}
      onClick={handleChangeSelected}
    >
      <MagicIcon />
      <p className='txt-blue-13-700'>Suggested to prioritise</p>
    </div>
  );
};

export { SuggestedPriority };
