import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  graph: {
    '&.volume': {
      '& canvas': {
        height: '380px !important',
        cursor: 'pointer !important',
      },
    },

    '&.scatter': {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: theme.palette.secondary.light,
      borderRadius: theme.spacing(4),

      '& canvas': {
        width: '100% !important',
        height: '218px !important',
      },
    },

    '& .g2-tooltip': {
      padding: '8px !important',
      fontFamily: 'Inter, Arial, sans-serif !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      color: '#545454 !important',
      backgroundColor: 'rgba(255, 255, 255, 0.9) !important',
      border: '1px solid #e2e2e2 !important',
      borderRadius: '12px !important',
      boxShadow: 'rgb(174, 174, 174) 0px 0px 10px !important',

      '&-title, &-list-item': {
        fontFamily: 'Inter, Arial, sans-serif !important',
        fontSize: '12px !important',
        fontWeight: '500 !important',
        color: '#545454 !important',
      },
    },
  },
  volumeGraphContainer: {
    position: 'relative',

    '& .greyContainer': {
      position: 'absolute',
      top: -36,
      right: 0,
      padding: theme.spacing(1.5),
      textAlign: 'center',
      backgroundColor: '#EEE',
      borderRadius: theme.spacing(1),
      opacity: 0.7,
    },
  },
  actionInfo: {
    display: 'block',
    textAlign: 'right',
  },
}));
