import { LookerSearchOptions } from 'Pages/Dictionary/libs/enums/enums.js';

const LOOKER_SEARCH_OPTIONS = [
  LookerSearchOptions.DASHBOARD_NAME,
  LookerSearchOptions.DASHBOARD_ID,
  LookerSearchOptions.LOOK_NAME,
  LookerSearchOptions.LOOK_ID,
];

export { LOOKER_SEARCH_OPTIONS };
