import React from 'react';
import {
  FormControl,
  Autocomplete,
  InputLabel,
  TextField,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as ClearIcon } from 'assets/img/icons/closeIcon.svg';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';

const INPUT_VARIANT = 'standard';

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
      padding: '15px 16px',
      '& .MuiAutocomplete-input': {
        padding: 0,
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiOutlinedInput-input': {
      height: 'max-content',
    },
    '& .MuiAutocomplete-endAdornment': {
      top: 'calc(50% - 12px)',
    },
    '& .MuiAutocomplete-popupIndicator': {
      marginRight: 4,
    },
    '& .MuiAutocomplete-popper': {
      boxShadow:
        '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    },
    '& .MuiAutocomplete-option, & .MuiAutocomplete-noOptions': {
      color: theme.palette.common.mainDarkText,
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 'initial',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      color: theme.palette.primary.main,
    },
    '& .MuiChip-root': {
      height: 'max-content',
      padding: 4,
      color: theme.palette.common.mainDarkText,
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      '& .MuiChip-label': {
        paddingLeft: 6,
      },
    },
  },
}));

const AutocompleteSelect = ({
  multiple = false,
  options = [{ value: '', label: '' }],
  value = multiple ? [] : null,
  onChange,
  id = '',
  isRequired = false,
  fullWidth = true,
  label = '',
  placeholder = '',
}) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classes.formControl}
      variant={INPUT_VARIANT}
      fullWidth={fullWidth}
    >
      {!!label.length && (
        <InputLabel
          htmlFor={id}
          required={isRequired}
          disableAnimation={true}
          variant={INPUT_VARIANT}
          shrink
        >
          {label}
        </InputLabel>
      )}

      <Autocomplete
        disablePortal
        id={id}
        options={options}
        value={value}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => {
          return <TextField placeholder={placeholder} {...params} />;
        }}
        clearIcon={null}
        popupIcon={<ArrowDown />}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        getOptionLabel={(option) => {
          return option.label;
        }}
        isOptionEqualToValue={(option, value) => {
          if (!value || !option) {
            return false;
          }
          return option.value === value.value;
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            {multiple && (
              <Checkbox
                icon={<UncheckIcon width={18} height={18} />}
                checkedIcon={<CheckIcon width={18} height={18} />}
                sx={{ padding: 0, marginRight: 4 }}
                checked={selected}
                id={option.value}
              />
            )}
            {option.label}
          </li>
        )}
        ChipProps={{
          deleteIcon: <ClearIcon />,
        }}
      />
    </FormControl>
  );
};

export { AutocompleteSelect };
