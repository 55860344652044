const GraphDefaultModes = {
  DRAG_CANVAS: 'drag-canvas',
  DRAG_NODE: 'drag-node',
  ZOOM_CANVAS: 'zoom-canvas',
};

const GraphModes = {
  TOGGLE_COLLAPSED: 'toggle-collapsed',
};

export { GraphDefaultModes, GraphModes };
