import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useTableAnalysis = ({ dataset, table, isEnabled = true }) => {
  const { isFetching: isFetchingTableAnalysis, data } = useQuery(
    [QUERY_TYPES.tableAnalysisParams, dataset, table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
      });
    },
    { enabled: Boolean(dataset && table && isEnabled) }
  );

  const resampleFrequency = useMemo(() => {
    return data?.value?.resampleFrequency || null;
  }, [data?.value?.resampleFrequency]);

  const percentThreshold = useMemo(() => {
    return data?.value?.thresholdCoefficient || 0;
  }, [data?.value?.thresholdCoefficient]);

  return { resampleFrequency, percentThreshold, isFetchingTableAnalysis };
};

export { useTableAnalysis };
