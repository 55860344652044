import {
  useDateRangePicker,
  useEffect,
  useState,
  useCallback,
} from 'hooks/hooks.js';
import { PipelineDataFreshness } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { JOB_METRIC_TYPES } from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { PipelineWidgets } from '../libs/constants/constants.js';

const PipelineFreshnessWidget = ({
  jobHash,
  currentBqBillingData,
  onChangeLoadingWidget,
}) => {
  const { paymentType = BQ_BILLING_TYPE.FLAT_RATE } =
    currentBqBillingData || {};
  const { startDate, endDate } = useDateRangePicker();
  const [isLoadingFreshnessData, setIsLoadingFreshnessData] = useState(true);

  const metricType =
    paymentType === BQ_BILLING_TYPE.ON_DEMANDS
      ? JOB_METRIC_TYPES.bytes
      : JOB_METRIC_TYPES.slots;

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(PipelineWidgets.FRESHNESS, isLoadingFreshnessData);
    }
  }, [isLoadingFreshnessData, onChangeLoadingWidget]);

  const handleChangeLoadingFreshnessData = useCallback((value) => {
    setIsLoadingFreshnessData(value);
  }, []);

  return (
    <Widget
      label='Execution freshness'
      secondaryLabel='Frequency of pipeline execution'
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      <PipelineDataFreshness
        jobHash={jobHash}
        metricType={metricType}
        startDate={startDate}
        endDate={endDate}
        onLoadingFreshnessDataChange={handleChangeLoadingFreshnessData}
      />
    </Widget>
  );
};

export { PipelineFreshnessWidget };
