import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import attentionIcon from 'assets/img/icons/attentionIcon.svg';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(4, 4, 4, 6),
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 8,
  },
}));

const EmptyBlock = ({ children = null, className = '' }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.wrapper, className)}>
      <img src={attentionIcon} alt='attention icon' />
      <p className='txt-mainDark-13-700'>{children}</p>
    </div>
  );
};

export { EmptyBlock };
