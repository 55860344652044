import React from 'react';
import { useCallback, useMemo, useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  Button,
  BUTTON_VARIANT,
  CheckboxWithLabel,
} from 'Components/components.js';
import { SamplingSizeSelect, ScheduleBlock, SqlBlock } from '../components.js';
import {
  CONTROL_ELEMENTS_DATA,
  ENTIRE_DATA_SCOPE,
  INCREMENTAL_SCOPE,
} from '../../libs/constants/constants.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    height: '100%',
    margin: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
    overflow: 'scroll',
  },
  contentItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  buttonContainer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));

const EditScanModalBody = ({ closeModal, saveDataScan, dataScan }) => {
  const {
    id,
    dataPlexId,
    table,
    dataset,
    incrementalColumn,
    samplingPercent,
    rowFilter,
    scheduleCron,
    location,
  } = dataScan;
  const classes = useStyles();
  const [newScheduleCron, setNewScheduleCron] = useState(null);
  const [isFilterRows, setIsFilterRows] = useState(!!rowFilter);
  const [samplingSizeValue, setSamplingSizeValue] = useState(samplingPercent);
  const [sqlValue, setSqlValue] = useState(!!rowFilter ? rowFilter : '');

  const { filterRowsCheckbox } = CONTROL_ELEMENTS_DATA;

  const fullTableName = useMemo(() => {
    return getFullTableName(table, dataset);
  }, [dataset, table]);

  const dataScanScope = useMemo(() => {
    return incrementalColumn ? INCREMENTAL_SCOPE : ENTIRE_DATA_SCOPE;
  }, [incrementalColumn]);

  const isDisabledSaveButton = useMemo(() => {
    return (
      samplingSizeValue === samplingPercent ||
      (isFilterRows && !sqlValue.length)
    );
  }, [isFilterRows, samplingPercent, samplingSizeValue, sqlValue.length]);

  const handleChangeFilterRows = useCallback((value) => {
    setIsFilterRows(value);
  }, []);

  const handleChangeScheduleCron = useCallback((value, isError) => {
    setNewScheduleCron(value);
  }, []);

  const handleChangeSamplingSizeValue = useCallback((value) => {
    setSamplingSizeValue(value);
  }, []);

  const handleChangeSql = useCallback((newValue) => {
    setSqlValue(newValue);
  }, []);

  const onSaveButtonClick = useCallback(() => {
    saveDataScan({
      id,
      dataPlexId,
      location,
      scheduleCron: newScheduleCron,
      samplingPercent: +samplingSizeValue,
      rowFilter: sqlValue ? sqlValue : null,
    });
    closeModal();
  }, [
    closeModal,
    newScheduleCron,
    samplingSizeValue,
    saveDataScan,
    sqlValue,
    id,
    dataPlexId,
    location,
  ]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.contentContainer}>
        <div className='txt-grey-13-500'>
          <div className='mb-1'>
            Scan ID <span className='txt-mainDark-13-500'>{dataPlexId}</span>
          </div>
          <div className='mb-1'>
            Table to scan{' '}
            <span className='txt-mainDark-13-500'>{fullTableName}</span>
          </div>
          <div className='mb-1'>
            Scope <span className='txt-mainDark-13-500'>{dataScanScope}</span>
          </div>
        </div>

        <ScheduleBlock
          defaultScheduleValue={scheduleCron}
          changeScheduleCron={handleChangeScheduleCron}
        />

        <div className={classes.contentItem}>
          <div className='txt-mainDark-13-700'>Advance settings</div>
          <SamplingSizeSelect
            changeSamplingSize={handleChangeSamplingSizeValue}
            defaultSizeValue={samplingPercent}
          />

          <CheckboxWithLabel
            isChecked={isFilterRows}
            handleChange={handleChangeFilterRows}
            label={filterRowsCheckbox.label}
            id={filterRowsCheckbox.id}
          />

          {isFilterRows && (
            <SqlBlock value={sqlValue} onChangeSqlData={handleChangeSql} />
          )}
        </div>
      </div>

      <div className={classes.buttonContainer}>
        <Button
          text='Save'
          fullWidth={false}
          className='button'
          onClick={onSaveButtonClick}
          isDisabled={isDisabledSaveButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { EditScanModalBody };
