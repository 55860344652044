import clsx from 'clsx';
import { useStyles } from 'Components/Widget/Widget.styles.js';
import { StatsCard } from './StatsCard.js';
import { PIPELINE_TYPE_DATA } from 'utils/constants.js';
import PipelineIcon from 'assets/img/sidebarIcons/pipeline.svg';

const INITIAL_VALUE = 0;

const PipelineStats = ({ pipelineStats, className = '' }) => {
  const classes = useStyles();

  const totalPipelines = pipelineStats
    .map((item) => item.count)
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      INITIAL_VALUE
    );

  const totalPipelinesStatCardInfo = {
    count: totalPipelines,
    pipelineType: '',
    iconPath: PipelineIcon,
    title: 'Total pipelines',
  };

  const statsCardsInfo = [totalPipelinesStatCardInfo];
  statsCardsInfo.push(
    ...pipelineStats.map((item) => {
      return {
        count: item.count,
        pipelineType: item.pipelineType,
        iconPath: PIPELINE_TYPE_DATA[item.pipelineType]?.icon,
        title: PIPELINE_TYPE_DATA[item.pipelineType]?.title,
      };
    })
  );

  return (
    <div className={clsx(classes.cardContainer, 'fourCards', className)}>
      {statsCardsInfo.map((statsCard) => (
        <StatsCard key={statsCard.title} statsCard={statsCard} />
      ))}
    </div>
  );
};

export { PipelineStats };
