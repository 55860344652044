import React from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { ReactComponent as InfoIcon } from 'assets/img/icons/ic-24-info.svg';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const useStyles = makeStyles((theme) => ({
  widget: {
    width: 338,

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),

    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },

  mainInfo: {},

  secondaryInfo: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(2),
  },

  spaceBetweenContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },

  icon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    '& path': {
      fill: theme.palette.text.secondary,
    },
  },

  linkIcon: {
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
}));

const StatsWidget = ({
  statsInfo,
  pathname,
  state = {},
  search = {},
  tooltipTip = '',
}) => {
  const classes = useStyles();
  const { name, mainInfo, subname, info } = statsInfo;

  return (
    <section className={classes.widget}>
      <section className={classes.mainInfo}>
        <div className={classes.spaceBetweenContainer}>
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{name}</Typography>

          <Tooltip title={tooltipTip} hasMaxWidthTooltip={true}>
            <InfoIcon className={classes.icon} />
          </Tooltip>
        </div>

        <Typography variant={TYPOGRAPHY_VARIANT.h2}>{mainInfo}</Typography>
      </section>

      <Link
        to={{
          pathname,
          state,
          search: new URLSearchParams(search).toString(),
        }}
      >
        <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
          See all
        </Typography>
        <ArrowRight className={classes.linkIcon} />
      </Link>

      <section className={classes.secondaryInfo}>
        <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
          {subname}
        </Typography>
        <Typography variant={TYPOGRAPHY_VARIANT.h5}>{info}</Typography>
      </section>
    </section>
  );
};

export { StatsWidget };
