import React from 'react';
import {
  useCallback,
  useEffect,
  useHistory,
  useState,
  useMutation,
  useQueryClient,
  useSyncTableToSearchParam,
  useDateRangePicker,
  useMemo,
  useAutoChangeProject,
} from 'hooks/hooks.js';
import { Grid } from '@mui/material';
import { useStyles } from './Monitors.styles.js';
import {
  PageHeader,
  AnomaliesWidget,
  ErrorsWidget,
  DataScanWidget,
  ReportsWidget,
  TableInfoWidget,
  SchemaWidget,
  LineageWidget,
  ViewQueryWidget,
} from './components/components.js';
import {
  AMPL_PAGE_EVENT,
  TABLE_TYPES,
  QUERY_TYPES,
  ANOMALY_TYPE,
  INCIDENT_TYPE_GROUP,
} from 'constants/constants.js';
import { EMPTY_DATA_INFO } from './libs/constants.js';
import { fetcherPost } from 'utils/utils.js';
import {
  LatestTable,
  SourcesButton,
  EmptyBlock,
  TableDatasetSearch,
} from 'Components/components.js';
import { getFullTableName, getWidgetLabel } from 'utils/helpers/helpers.js';
import { useConfiguration, useProjectTree } from 'context/context.js';
import { amplEvent } from 'service/services.js';

const DEFAULT_EXISTING_DATA = {
  volume: true,
  freshness: true,
  pipelineErrors: true,
  reports: true,
  lineage: true,
};

export default function Monitors() {
  const classes = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { isDataplexConnected } = useConfiguration();
  const [tableInfo, setTableInfo] = useState(null);
  const { tableFromURL, tableInfoFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(tableInfo?.table, tableInfo?.dataset, null);
  const [startPage, setStartPage] = useState(tableFromURL === null);
  const { setIsOpen, setSelectTable, setOnSelectTable, setSelectDataset } =
    useProjectTree();
  const { startDate, endDate, onChooseDates } = useDateRangePicker();
  const { volume, freshness, pipelineErrors } = getWidgetLabel(tableInfo?.type);
  const [existingData, setExistingData] = useState(DEFAULT_EXISTING_DATA);

  useAutoChangeProject();

  const isVisibleWidget = useMemo(() => {
    return {
      volume: tableInfo !== null && tableInfo?.type !== TABLE_TYPES.view,
      freshness: tableInfo !== null && tableInfo?.type !== TABLE_TYPES.view,
      viewQuery: tableInfo !== null && tableInfo?.type === TABLE_TYPES.view,
    };
  }, [tableInfo]);

  const handleChangeExistingData = useCallback((key, value) => {
    setExistingData((prev) => ({ ...prev, [key]: value }));
  }, []);

  const isEmptyTableData = useMemo(() => {
    return (
      !existingData.lineage &&
      !existingData.freshness &&
      !existingData.volume &&
      !existingData.pipelineErrors &&
      !existingData.reports
    );
  }, [
    existingData.freshness,
    existingData.lineage,
    existingData.pipelineErrors,
    existingData.reports,
    existingData.volume,
  ]);

  const isInactiveTables = useMemo(() => {
    return (
      existingData.lineage &&
      !existingData.freshness &&
      !existingData.volume &&
      !existingData.pipelineErrors &&
      !existingData.reports
    );
  }, [
    existingData.freshness,
    existingData.lineage,
    existingData.pipelineErrors,
    existingData.reports,
    existingData.volume,
  ]);

  const isEmptyLineage = useMemo(() => {
    return (
      !existingData.lineage &&
      (existingData.freshness ||
        existingData.volume ||
        existingData.pipelineErrors)
    );
  }, [
    existingData.freshness,
    existingData.lineage,
    existingData.pipelineErrors,
    existingData.volume,
  ]);

  useEffect(() => amplEvent(AMPL_PAGE_EVENT.monitors), []);

  const { mutateAsync: updateLastSeenTables } = useMutation(
    (params) => {
      return fetcherPost('/api/v1/last-tables', params);
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(QUERY_TYPES.lastSeenPages);
        }
      },
    }
  );

  useEffect(() => {
    if (tableFromURL === null) {
      setStartPage(true);
      setTableInfo(null);
      setSelectTable(null);
      setSelectDataset(null);
      setIsOpen(true);
    } else {
      setStartPage(false);
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFromURL]);

  useEffect(() => {
    return () => setIsOpen(false);
  }, [setIsOpen]);

  useEffect(() => {
    if (tableInfo?.table && tableInfo?.dataset) {
      updateLastSeenTables({
        name: tableInfo?.table,
        dataset: tableInfo?.dataset,
        category: 'MONITORS',
      });

      amplEvent(
        `${AMPL_PAGE_EVENT.monitors} -> selected table -> ${getFullTableName(
          tableInfo.table,
          tableInfo.dataset
        )}`
      );
    }
  }, [tableInfo?.table, tableInfo?.dataset, updateLastSeenTables]);

  const handleChange = useCallback((value) => {
    setTableInfo(value);
    setExistingData(DEFAULT_EXISTING_DATA);
    setSelectTable(value);
    setStartPage(!value);
    if (value === null) clearSearchParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (handleChange !== undefined) {
      setOnSelectTable({ onChange: handleChange });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleChange]);

  const handleProjectTree = () => setIsOpen((prev) => !prev);

  const toPages = (pathname, state, title, widgetLabel, tab = '') => {
    const baseParams = {
      dataset: tableInfo?.dataset,
      table: tableInfo?.table,
    };
    const params = tab.length ? Object.assign({ tab }, baseParams) : baseParams;

    return {
      call: () => {
        if (tableInfo) {
          history.push({
            pathname,
            search: new URLSearchParams(params).toString(),
            state,
          });

          amplEvent(
            `${AMPL_PAGE_EVENT.monitors} -> ${getFullTableName(
              tableInfo?.table,
              tableInfo?.dataset
            )} -> Widget ${widgetLabel} -> click -> ${title}`
          );
        }
      },
      title,
    };
  };

  return (
    <>
      <div className={classes.searchContainer}>
        <SourcesButton onClick={handleProjectTree} />
        <TableDatasetSearch
          optionLabel='Monitors for:'
          onTableChange={handleChange}
          defaultDataset={tableInfoFromUrl?.dataset}
          defaultTable={tableInfoFromUrl?.table}
          className='tableSearch'
          disableDatasets={true}
          withSearch={false}
        />
      </div>
      {startPage ? (
        <LatestTable onChange={handleChange} seenCategory='MONITORS' />
      ) : (
        <>
          <PageHeader
            tableInfo={tableInfo}
            onChangeTableInfo={handleChange}
            startDate={startDate}
            endDate={endDate}
            onChooseDates={onChooseDates}
          />

          <main className={classes.content}>
            {isEmptyTableData && (
              <EmptyBlock
                children={EMPTY_DATA_INFO.emptyTableData}
                className={classes.emptyBlock}
              />
            )}

            {isInactiveTables && (
              <EmptyBlock
                children={EMPTY_DATA_INFO.emptyTableAnomalies}
                className={classes.emptyBlock}
              />
            )}

            <Grid
              container
              direction='row'
              justifyContent='flex-start'
              alignItems='flex-start'
              spacing={4}
            >
              <Grid container item md={8} spacing={4}>
                {isVisibleWidget.viewQuery && (
                  <ViewQueryWidget tableInfo={tableInfo} />
                )}

                {isVisibleWidget.volume && (
                  <AnomaliesWidget
                    anomalyType={ANOMALY_TYPE.VOLUME}
                    label={volume.title}
                    secondaryLabel={volume.subtitle}
                    tableInfo={tableInfo}
                    incidentGroupsTypes={
                      INCIDENT_TYPE_GROUP.BQ_VOLUME_INSERTED_ROWS
                    }
                    startDate={startDate}
                    endDate={endDate}
                    isExisting={existingData.volume}
                    onChangeExistingData={handleChangeExistingData}
                    toPages={toPages}
                  />
                )}

                {isVisibleWidget.freshness && (
                  <AnomaliesWidget
                    anomalyType={ANOMALY_TYPE.FRESHNESS}
                    label={freshness.title}
                    secondaryLabel={freshness.subtitle}
                    tableInfo={tableInfo}
                    incidentGroupsTypes={
                      INCIDENT_TYPE_GROUP.BQ_FRESHNESS_INSERTED_ROWS
                    }
                    startDate={startDate}
                    endDate={endDate}
                    isExisting={existingData.freshness}
                    onChangeExistingData={handleChangeExistingData}
                    toPages={toPages}
                  />
                )}

                <ErrorsWidget
                  anomalyType={ANOMALY_TYPE.PIPELINE_ERROR}
                  label={pipelineErrors.title}
                  secondaryLabel={pipelineErrors.subtitle}
                  tableInfo={tableInfo}
                  incidentGroupsTypes={INCIDENT_TYPE_GROUP.LOG_PIPELINE_ERROR}
                  startDate={startDate}
                  endDate={endDate}
                  isExisting={existingData.pipelineErrors}
                  onChangeExistingData={handleChangeExistingData}
                  toPages={toPages}
                />

                {isDataplexConnected && (
                  <DataScanWidget tableInfo={tableInfo} />
                )}
              </Grid>

              <Grid container item md={4} rowSpacing={4}>
                <ReportsWidget
                  table={tableInfo?.table}
                  dataset={tableInfo?.dataset}
                  toDictionaryPage={toPages}
                  onChangeExistingData={handleChangeExistingData}
                />

                <LineageWidget
                  table={tableInfo?.table}
                  dataset={tableInfo?.dataset}
                  toLineagePage={toPages}
                  onChangeExistingData={handleChangeExistingData}
                  isEmptyLineage={isEmptyLineage}
                />

                <TableInfoWidget
                  tableInfo={tableInfo}
                  startDate={startDate}
                  endDate={endDate}
                />
                <SchemaWidget tableInfo={tableInfo} />
              </Grid>
            </Grid>
          </main>
        </>
      )}
    </>
  );
}
