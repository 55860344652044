import { getFullTableName } from 'utils/helpers/helpers.js';
import { iconByTableType } from './icon-by-table-type.js';

const createTableOption = (item, withDatasets) => ({
  label: (
    <span className={withDatasets ? 'isTable' : ''}>
      {iconByTableType(item.type, item.isPartition)} {item.dataset}.{item.table}
    </span>
  ),
  value: getFullTableName(item.table, item.dataset),
  item: item,
});

export { createTableOption };
