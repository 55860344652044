import { JobStatuses } from '../constants/constants.js';
import errorIcon from 'assets/img/icons/checkIcons/check-error.svg';
import successIcon from 'assets/img/icons/checkIcons/complete.svg';
import progressIcon from 'assets/img/icons/ic-24-progress.svg';
import infoFullIcon from 'assets/img/icons/ic-24-info-full.svg';

const getJobStatusIcon = (status, rulesFailed) => {
  if (status === JobStatuses.PENDING || status === JobStatuses.CANCELING) {
    return progressIcon;
  }

  if (status === JobStatuses.FAILED || rulesFailed) {
    return errorIcon;
  }

  if (status === JobStatuses.SUCCEEDED) {
    return successIcon;
  }

  return infoFullIcon;
};

export { getJobStatusIcon };
