import React from 'react';
import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useStyles } from 'Pages/Integrations/Integrations.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { amplEvent } from 'service/services.js';

const DISCONNECT_EVENT = 'Looker -> Click Disconnect button - success';

const LookerItem = ({ clientUrl }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { mutateAsync: cancelLooker } = useMutation(
    () => fetcherPost('/api/v1/cancel-looker'),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_TYPES.looker),
    }
  );

  const onDisconnect = () => {
    cancelLooker();
    amplEvent(DISCONNECT_EVENT);
  };

  return (
    <div className={classes.cardItem}>
      <div className={classes.cardItemInfo}>
        <span className='txt-grey-13-500'>Host URL and port:</span>
        <span className='txt-mainDark-13-500'>{clientUrl}</span>
      </div>

      <Button
        text='Disconnect'
        variant={BUTTON_VARIANT.text}
        onClick={onDisconnect}
        fullWidth={false}
        className='p-0'
      />
    </div>
  );
};

export { LookerItem };
