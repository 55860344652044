const createNewOption = (
  input,
  getLabelFromInput = null,
  getValueFromInput = null
) => {
  const label = getLabelFromInput !== null ? getLabelFromInput(input) : input;
  const value = getValueFromInput !== null ? getValueFromInput(input) : input;

  return { label, value };
};

export { createNewOption };
