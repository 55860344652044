import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppRoutes } from 'app-routes.js';
import { useUserInfo } from 'context/context.js';
import { Layout } from 'Components/components.js';

function SupperPrivateRoute({ component: Component, ...rest }) {
  const { user } = useUserInfo();
  return (
    <Route
      {...rest}
      render={(props) =>
        !!user && user.info.supperAdmin ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: AppRoutes.Dashboard.path }} />
        )
      }
    />
  );
}

export { SupperPrivateRoute };
