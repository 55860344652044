import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    paddingBottom: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .tab': {
      marginRight: 16,
      padding: '0 16px 22px',
      cursor: 'pointer',
      '&--active': {
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const Tabs = ({ tabs, currentTab, onTabClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabsContainer}>
      {Object.values(tabs).map((value, index) => {
        const isActiveTab = currentTab === value;

        return (
          <span
            key={`${value}/${index}`}
            onClick={() => onTabClick(value)}
            className={clsx(
              'txt-mainDark-16-500 tab',
              isActiveTab && 'tab--active'
            )}
          >
            {value}
          </span>
        );
      })}
    </div>
  );
};

export { Tabs };
