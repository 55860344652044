import React from 'react';
import {
  useMemo,
  useCallback,
  useHistory,
  useGetProjects,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { MenuItem } from '@mui/material';
import { AppRoutes } from 'app-routes.js';
import { ProjectAvatar } from 'Components/components.js';
import { DEMO_PROJECT, DEMO_PROJECT_TITLE } from 'utils/constants.js';
import { CheckMarkIcon } from 'assets/img/dropdownIcons';
import { ReactComponent as AddProjectIcon } from 'assets/img/icons/plus-icon.svg';

const useStyles = makeStyles((theme) => ({
  menuItemIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 4,
  },
}));

const ProjectsDropdown = ({
  selectedProject,
  onProjectClick,
  projectStatus,
  className,
  title = 'Projects',
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { projects } = useGetProjects();

  const isDemoProjectSelected = useMemo(() => {
    return selectedProject === DEMO_PROJECT;
  }, [selectedProject]);

  const isShowDemoProject = useMemo(() => {
    // return projectStatus && projectStatus.dataPatternReady === false;
    return false;
  }, []);

  const navigateToDeploymentPage = useCallback(() => {
    history.push(AppRoutes.Deployment.path);
  }, [history]);

  return (
    <>
      <div className={clsx(className, 'txt-grey-11-500')}>{title}</div>

      {projects?.map((project, index) => {
        const isSelected = selectedProject === project;

        return (
          <MenuItem
            key={index + project}
            onClick={() => onProjectClick(project)}
          >
            <ProjectAvatar project={project} isSelected={isSelected} />
            <div style={{ width: '100%', marginLeft: 8 }}>{project}</div>
            {isSelected && <CheckMarkIcon />}
          </MenuItem>
        );
      })}
      {isShowDemoProject && (
        <MenuItem onClick={() => onProjectClick(DEMO_PROJECT)}>
          <ProjectAvatar
            project={DEMO_PROJECT_TITLE}
            isSelected={isDemoProjectSelected}
            isDemo={true}
          />
          <div style={{ width: '100%', marginLeft: 8 }}>
            {DEMO_PROJECT_TITLE}
          </div>
          {isDemoProjectSelected && <CheckMarkIcon />}
        </MenuItem>
      )}

      <MenuItem onClick={navigateToDeploymentPage}>
        <div className={classes.menuItemIcon}>
          <AddProjectIcon />
        </div>
        <div className='txt-blue-13-500'>Add project</div>
      </MenuItem>
    </>
  );
};

export { ProjectsDropdown };
