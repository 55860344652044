import React from 'react';
import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  usePagination,
  useDispatch,
  useSelector,
} from 'hooks/hooks.js';
import { useStyles } from './SelectModalBody.styles.js';
import {
  Button,
  BUTTON_VARIANT,
  GeneralSearch,
  Pagination,
  PositionLoader,
  RadioWithLabel,
} from 'Components/components.js';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import { getColumns, getUniqueColumns } from 'slices/actions.js';
import { columnNameId } from 'Components/Columns/HeadCellsEnum.js';

const getIdFromColumn = (column) => {
  return column.name;
};

const timestampTypes = ['DATE', 'TIMESTAMP'];

const SelectTimestampColumnModalBody = ({
  table,
  dataset,
  closeModal,
  getSelectedValue,
  selectedColumn,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState(selectedColumn);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  const [column, setColumn] = useState('');
  const [isColumnEqual, setIsColumnEqual] = useState(false);

  const {
    columns,
    columnsStatus,
    columnsNumber,
    uniqueColumns,
    uniqueColumnsStatus,
  } = useSelector((state) => ({
    columns: state.columns.columns,
    columnsNumber: state.columns.columnsNumber,
    columnsStatus: state.columns.columnsStatus,
    uniqueColumns: state.columns.uniqueColumns,
    uniqueColumnsStatus: state.columns.uniqueColumnsStatus,
  }));

  const isLoadingColumns = useMemo(() => {
    return (
      columnsStatus === DATA_STATUS.pending ||
      uniqueColumnsStatus === DATA_STATUS.pending
    );
  }, [columnsStatus, uniqueColumnsStatus]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getColumns({
          dataset,
          table,
          column,
          isColumnEqual,
          order: ORDER_TYPES.asc,
          sortBy: columnNameId,
          page: page + 1,
          limit: rowsPerPage,
          types: timestampTypes,
        })
      );
    }
  }, [page, rowsPerPage, dispatch, column, isColumnEqual, table, dataset]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getUniqueColumns({
          dataset,
          table,
          types: timestampTypes,
        })
      );
    }
  }, [dispatch, table, dataset]);

  const searchOptions = useMemo(() => {
    return uniqueColumns.map((column) => {
      return { label: column, value: column };
    });
  }, [uniqueColumns]);

  const isDisabledSelectButton = useMemo(() => {
    return !value;
  }, [value]);

  const handleColumnSearch = useCallback((value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColumnSelect = useCallback((value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(!!value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = useCallback((value) => {
    setValue(value);
  }, []);

  const saveModalValue = useCallback(() => {
    getSelectedValue(value);
    closeModal();
  }, [closeModal, getSelectedValue, value]);

  return (
    <div className={classes.modalBodyContainer}>
      <div className={classes.contentContainer}>
        <GeneralSearch
          options={searchOptions}
          onSearch={handleColumnSearch}
          onSelect={handleColumnSelect}
          isLoading={isLoadingColumns}
          placeholderName='Start typing column name...'
        />

        <div className={classes.tableContainer}>
          {isLoadingColumns ? (
            <PositionLoader />
          ) : columns.length ? (
            <RadioWithLabel
              selectedOption={value}
              options={columns}
              onChangeValue={handleChangeValue}
              getValueFromOption={getIdFromColumn}
              getLabelFromOption={getIdFromColumn}
              className='tableItem'
            />
          ) : (
            <div className='txt-mainDark-13-500'>No rows to display</div>
          )}
        </div>

        <Pagination
          count={columnsNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <div className={classes.buttonContainer}>
        <Button
          text='Select'
          fullWidth={false}
          className='button'
          onClick={saveModalValue}
          isDisabled={isDisabledSelectButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { SelectTimestampColumnModalBody };
