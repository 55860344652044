import React from 'react';
import { useMemo, useState, useCallback } from 'hooks/hooks.js';
import { Input } from 'Components/components.js';
import {
  CONTROL_ELEMENTS_DATA,
  INPUT_ERRORS,
  INPUT_VALIDATION,
} from '../../libs/constants/constants.js';

const HELPER_TEXT_ALIGN = 'end';

const DescriptionInput = ({ value, onChange }) => {
  const { descriptionInput } = CONTROL_ELEMENTS_DATA;
  const [error, setError] = useState(null);

  const descriptionInputHelperText = useMemo(() => {
    return `${value?.length || 0} / ${
      INPUT_VALIDATION.maxDescriptionValueLength
    }`;
  }, [value]);

  const handleChangeValue = useCallback(
    (newValue) => {
      if (newValue.length === INPUT_VALIDATION.maxDescriptionValueLength) {
        setError(INPUT_ERRORS.limitCharacters);
      } else {
        setError(null);
      }

      onChange(newValue);
    },
    [onChange]
  );

  return (
    <>
      <Input
        value={value}
        onChange={handleChangeValue}
        label={descriptionInput.label}
        id={descriptionInput.id}
        multiline={descriptionInput.multiline}
        helperText={descriptionInputHelperText}
        helperTextAlign={HELPER_TEXT_ALIGN}
        inputProps={{ maxLength: INPUT_VALIDATION.maxDescriptionValueLength }}
        errorComponent={error}
      />
    </>
  );
};

export { DescriptionInput };
