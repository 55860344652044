import React from 'react';
import { TableRow, TableCell, styled } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { getColumnTypeIcon } from 'utils/helpers/helpers.js';

const maxLength = 28;

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: '8px 8px 8px 0',
    whiteSpace: 'nowrap',
  },
}));

const SchemaDetails = ({ column }) => {
  const { name, uuid, type, description } = column;
  const isLongText = (columnText) => columnText.length > maxLength;
  const shortDesc = (columnText) =>
    isLongText(columnText)
      ? columnText.slice(0, maxLength) + '...'
      : columnText;

  return (
    <TableRow key={uuid}>
      <StyledTableCell className='tableCell'>
        <img src={getColumnTypeIcon(type)} alt='' style={{ minWidth: 36 }} />
      </StyledTableCell>

      <StyledTableCell className='tableCell'>{shortDesc(name)}</StyledTableCell>

      <StyledTableCell className='tableCell'>
        {description.length ? (
          shortDesc(description)
        ) : (
          <div className='tableCell tableCell--grey'>No description</div>
        )}
      </StyledTableCell>
    </TableRow>
  );
};

export { SchemaDetails };
