import { RULE_TYPES, DIMENSION_OPTIONS } from '../constants/constants.js';

const MAX_PERCENT = 100;

const getJobRulesDTOFromResponse = (rule) => {
  return {
    id: rule.id,
    dimension: rule.dimension,
    dimensionLabel:
      DIMENSION_OPTIONS.find((item) => item.value === rule.dimension)?.label ||
      rule.dimension,
    threshold: rule.threshold,
    thresholdLabel: !rule.threshold
      ? `${MAX_PERCENT}%`
      : `${rule.threshold * MAX_PERCENT}%`,
    column: rule.column,
    ignoreNull: rule.ignoreNull,
    description: rule.description,
    typeLabel:
      RULE_TYPES.find((item) => item.value === rule.data.type)?.label ||
      rule.data.type,
    data: rule.data,
    failedRows: rule.failedRows,
    query: rule.query,
    passed: rule.passed,
  };
};

export { getJobRulesDTOFromResponse };
