import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TablePagination } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTablePagination-root': {
      height: theme.spacing(13),
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'end',
      '& .MuiInputBase-root': {
        border: 'none',
        backgroundColor: 'transparent',
      },
    },
    '& .MuiToolbar-gutters': {
      paddingLeft: 0,
    },
    '& .MuiTablePagination-toolbar': {
      minHeight: 'max-content',
      '& .MuiTablePagination-actions': {
        marginLeft: theme.spacing(2),
      },
    },
    '& .MuiTablePagination-select': {
      textAlign: 'center',
      textAlignLast: 'center',
    },
    '& .MuiTablePagination-selectLabel': {
      margin: 0,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize,
      lineHeight: theme.typography.lineHeight,
    },
    '& .MuiTablePagination-displayedRows': {
      margin: 0,
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: theme.typography.fontSize,
      lineHeight: theme.typography.lineHeight,
    },
    '& .MuiTablePagination-actions': {
      marginLeft: theme.spacing(2),
    },
    '& .MuiSelect-select': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& .MuiIconButton-root': {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
      '&.Mui-disabled': {
        color: theme.palette.divider,
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: theme.spacing(5),
    },
  },
}));

const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_OPTIONS = [5, DEFAULT_ROWS_PER_PAGE, 25];

const Pagination = ({
  count,
  rowsPerPage,
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  defaultRowsPerPage = DEFAULT_ROWS_PER_PAGE,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  showFirstButton = true,
  showLastButton = true,
  className = '',
}) => {
  const classes = useStyles();
  const existingPagination = useMemo(() => {
    return count > defaultRowsPerPage;
  }, [count, defaultRowsPerPage]);

  const validRowsPerPage = useMemo(() => {
    if (
      rowsPerPageOptions.includes(rowsPerPage) ||
      !rowsPerPageOptions.length
    ) {
      return rowsPerPage;
    }

    return rowsPerPageOptions[0];
  }, [rowsPerPage, rowsPerPageOptions]);

  return (
    <>
      {existingPagination && (
        <div className={clsx(classes.container, className)}>
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component='div'
            count={count}
            rowsPerPage={validRowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage='Items per page'
            showFirstButton={showFirstButton}
            showLastButton={showLastButton}
          />
        </div>
      )}
    </>
  );
};

export { Pagination };
