import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: 0,
    marginBottom: 20,
  },
  dropdownsContainer: {
    marginBottom: 24,
  },
  tableContainer: {
    flexGrow: 3,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    position: 'relative',
  },
  tableBody: {
    '& .MuiTableRow-root': {
      verticalAlign: 'center',
    },
    '& .tableCell': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
  },
  userInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  userAvatar: {
    width: 40,
    height: 40,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: 4,
  },
  pendingContainer: {
    height: 32,
    padding: '5px 8px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    fontWeight: 700,
  },
  paginationContainer: {
    '& .MuiTablePagination-root, & .MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
  },
}));
