import React from 'react';
import { useCallback, useState } from 'hooks/hooks.js';
import moment from 'moment/moment';
import clsx from 'clsx';
import { useStyles } from '../JobsBlock.js';
import {
  Button,
  BUTTON_VARIANT,
  RightSideModal,
} from 'Components/components.js';
import {
  getJobStatusIcon,
  getJobStatusText,
} from 'Pages/DataQuality/libs/helpers/helper.js';
import { JobDetailsModalBody } from 'Pages/DataQuality/components/components.js';
import { useModal } from 'context/context.js';

const JobItem = ({ job }) => {
  const {
    rulesCount,
    rulesPassed,
    dataPlexId,
    startTime,
    endTime,
    state,
    rowsCount,
  } = job;
  const classes = useStyles();
  const { setModal } = useModal();
  const [jobRulesNumber, setJobRulesNumber] = useState(0);
  const rulesFailed = rulesCount - rulesPassed;

  const handleChangeJobRulesNumber = useCallback((newNumber) => {
    setJobRulesNumber(newNumber);
  }, []);

  const onJobClick = () => {
    setModal(() => (
      <RightSideModal
        title={`Job ID: ${dataPlexId} results`}
        subtitle={`Started ${moment(startTime)
          .utc()
          .format('MMM Do YYYY, h:mm:ss a')} • Ended ${moment(endTime)
          .utc()
          .format('MMM Do YYYY, h:mm:ss a')}`}
        ModalBodyComponent={JobDetailsModalBody}
        modalWidth={1400}
        dataPlexJobId={dataPlexId}
        totalCount={jobRulesNumber}
        onChangeJobRulesNumber={handleChangeJobRulesNumber}
      />
    ));
  };

  return (
    <div className={clsx(classes.item, 'txt-mainDark-13-500')}>
      <div className={clsx(classes.statusContainer, 'txt-mainDark-13-700')}>
        <img
          src={getJobStatusIcon(state, rulesFailed)}
          alt={state.toLowerCase()}
          className='statusIcon'
        />

        <Button
          text={getJobStatusText(state, rulesFailed)}
          variant={BUTTON_VARIANT.text}
          onClick={onJobClick}
          fullWidth={false}
          className='p-0'
        />
      </div>
      <div>
        <span className='txt-grey-13-500'>Start time:</span>{' '}
        {startTime ? moment(startTime).fromNow() : '-'}
      </div>
      <div>
        <span className='txt-grey-13-500'>Records scanned:</span> {rowsCount}
      </div>
    </div>
  );
};

export { JobItem };
