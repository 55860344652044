import 'App.css';

import React from 'react';
import { Switch } from 'react-router-dom';
import Login from 'Pages/Login/Login.js';
import SignUp from 'Pages/Login/SignUp.js';
import Deployment from 'Pages/Login/Deployment.js';
import DeploymentAddProject from 'Pages/Login/DeploymentAddProject.js';
import DeploymentChooseProject from 'Pages/Login/DeploymentChooseProject.js';
import Dashboard from 'Pages/Dashboard/Dashboard.js';
import Monitors from 'Pages/Monitors/Monitors.js';
import Incidents from 'Pages/Incidents/Incidents.js';
import Lineage from 'Pages/Lineage/Lineage.js';
import Integrations from 'Pages/Integrations/Integrations.js';
import Admin from 'Pages/Admin/admin.js';
import Dictionary from 'Pages/Dictionary/Dictionary.js';
import DataQuality from 'Pages/DataQuality/DataQuality.js';
import ScanDetails from 'Pages/DataQuality/ScanDetails.js';
import ScanCreation from 'Pages/DataQuality/ScanCreation.js';
import Users from 'Pages/Users/Users.js';
import Pipelines from 'Pages/Pipelines/Pipelines.js';
import NotFound from 'Pages/NotFound/NotFound.js';
import { DataQualityRoute } from 'Pages/DataQuality/routes/routes.js';
import {
  SupperPrivateRoute,
  PrivateRoute,
  AuthRoute,
} from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';

const App = () => (
  <div className='styles-override'>
    <Switch>
      <AuthRoute exact path={AppRoutes.Login.path} component={Login} />
      <AuthRoute exact path={AppRoutes.SignUp.path} component={SignUp} />
      <AuthRoute
        exact
        path={AppRoutes.Deployment.path}
        component={Deployment}
      />
      <AuthRoute
        exact
        path={AppRoutes.DeploymentAddProject.path}
        component={DeploymentAddProject}
      />
      <AuthRoute
        exact
        path={AppRoutes.DeploymentChooseProject.path}
        component={DeploymentChooseProject}
      />

      <PrivateRoute
        exact
        path={AppRoutes.Dashboard.path}
        component={Dashboard}
      />
      <PrivateRoute exact path={AppRoutes.Monitors.path} component={Monitors} />
      <PrivateRoute
        exact
        path={AppRoutes.Incidents.path}
        component={Incidents}
      />
      <PrivateRoute exact path={AppRoutes.Lineage.path} component={Lineage} />
      <PrivateRoute
        exact
        path={AppRoutes.Dictionary.path}
        component={Dictionary}
      />
      <PrivateRoute
        exact
        path={AppRoutes.DataQuality_createScan.path}
        component={DataQualityRoute}
        subComponent={ScanCreation}
      />
      <PrivateRoute
        exact
        path={AppRoutes.DataQuality_scanDetails.path}
        component={DataQualityRoute}
        subComponent={ScanDetails}
      />
      <PrivateRoute
        exact
        path={AppRoutes.DataQuality.path}
        component={DataQualityRoute}
        subComponent={DataQuality}
      />
      <PrivateRoute
        exact
        path={AppRoutes.Pipelines.path}
        component={Pipelines}
      />
      <PrivateRoute
        exact
        path={AppRoutes.Integrations.path}
        component={Integrations}
      />
      <PrivateRoute exact path={AppRoutes.Users.path} component={Users} />
      <SupperPrivateRoute exact path={AppRoutes.Admin.path} component={Admin} />

      <PrivateRoute path={AppRoutes.Any.path} component={NotFound} />
    </Switch>
  </div>
);

export default App;
