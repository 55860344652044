import { Button, BUTTON_VARIANT, Input } from 'Components/components.js';

const InputWithAction = ({
  buttonText = 'Browse',
  onButtonClick,
  isDisabledButton = false,
  ...props
}) => {
  return (
    <Input
      isReadOnly={true}
      withEndAdornment={true}
      isDisabled={isDisabledButton}
      EndAdornmentComponent={
        <Button
          variant={BUTTON_VARIANT.text}
          text={buttonText}
          onClick={onButtonClick}
          isDisabled={isDisabledButton}
        />
      }
      {...props}
    />
  );
};

export { InputWithAction };
