import moment from 'moment';
import { DATE_FORMAT } from 'constants/constants.js';

const formatIncidents = (data = []) => {
  return data.reduce((acc, incident) => {
    const key = moment(incident.datetime).utc().format(DATE_FORMAT.monthYear);
    return { ...acc, [key]: [...(acc[key] || []), incident] };
  }, {});
};

export { formatIncidents };
