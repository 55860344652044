import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useCostsDeadEndTables = (startDate, endDate, enabled = true) => {
  const { data } = useQuery(
    [QUERY_TYPES.deadEndTablesStats, startDate, endDate],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    { enabled }
  );

  const deadEndTablesStats = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  return { deadEndTablesStats };
};

export { useCostsDeadEndTables };
