import React from 'react';
import { useOnClickOutside, useRef, useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useStyles } from './Dropdowns.style.js';
import { ReactComponent as CollapseIcon } from 'assets/img/icons/collapseIcon.svg';

const useOwnStyles = makeStyles((theme) => ({
  wrapper: {
    margin: 0,
  },
  label: {
    minWidth: 174,
    height: 46,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '4px 16px',
    borderRadius: '4px 0 0 4px',
    '&:hover': {
      color: 'inherit',
      background: 'inherit',
    },
  },
  labelText: {
    margin: 0,
  },
}));

const SearchDropdown = ({
  options = [],
  selectedOption = '',
  onOptionClick = () => {},
  className = '',
}) => {
  const classes = useStyles();
  const ownClasses = useOwnStyles();
  const ref = useRef();
  const [isOpened, setIsOpened] = useState(false);

  useOnClickOutside(ref, () => setIsOpened(false));

  const handleChangeOpen = () => {
    setIsOpened((prev) => !prev);
  };

  const onListItemClick = (e) => {
    onOptionClick(e?.target.textContent);
    setIsOpened(false);
  };

  return (
    <div className={clsx(classes.wrapper, ownClasses.wrapper, className)}>
      <div
        className={clsx(classes.label, ownClasses.label)}
        onClick={handleChangeOpen}
      >
        <span className={clsx(classes.labelText, ownClasses.labelText)}>
          {selectedOption}
        </span>
        <CollapseIcon />
      </div>

      {isOpened && (
        <ul ref={ref} className={classes.list}>
          {options.map((option) => {
            const isSelected = selectedOption === option;

            return (
              <li
                onClick={onListItemClick}
                className={clsx(classes.listItem, isSelected && 'selected')}
                key={option}
              >
                <span>{option}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export { SearchDropdown };
