import React from 'react';
import { useMemo, useQuery, useEffect } from 'hooks/hooks.js';
import { Select } from 'Components/components.js';
import { CONTROL_ELEMENTS_DATA } from '../../libs/constants/constants.js';
import { QUERY_TYPES } from 'constants/query-types.js';
import { fetcherGet } from 'utils/utils.js';

const RegionSelect = ({ multiRegion, selectedRegion, onChangeRegion }) => {
  const { regionInput } = CONTROL_ELEMENTS_DATA;
  const { isSuccess, data: locations } = useQuery(
    [QUERY_TYPES.dataPlexLocations, multiRegion],
    ({ queryKey }) => {
      const [url, multiRegion] = queryKey;
      return fetcherGet(url, { multiRegion });
    }
  );

  const options = useMemo(() => {
    if (locations?.values) {
      return locations.values.map((location) => {
        return {
          value: location,
          label: location,
        };
      });
    }

    return [];
  }, [locations?.values]);

  useEffect(() => {
    if (isSuccess && !selectedRegion && options?.length) {
      onChangeRegion(options[0]);
    }
  }, [isSuccess, onChangeRegion, options, selectedRegion]);

  return (
    <Select
      value={selectedRegion}
      onChange={onChangeRegion}
      options={options}
      label={regionInput.label}
      isRequired={regionInput.isRequired}
      id={regionInput.id}
      helperText={regionInput.helperText}
    />
  );
};

export { RegionSelect };
