import { useHistory, useLocation, useState } from 'hooks/hooks.js';
import { defaultEndDate, defaultStartDate } from 'utils/helpers/helpers.js';

const useDateRangePicker = () => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState(
    location.state?.startDate || defaultStartDate()
  );
  const [endDate, setEndDate] = useState(
    location.state?.endDate || defaultEndDate()
  );

  const onChooseDates = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    location.state = location.state || {};
    location.state.startDate = start;
    location.state.endDate = end;

    history.replace({
      pathname: location.pathname,
      state: location.state,
      search: searchParams.toString(),
    });
  };

  return { startDate, endDate, onChooseDates };
};

export { useDateRangePicker };
