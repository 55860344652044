import React from 'react';
import Modal from '@mui/material/Modal';
import { useModal } from 'context/context.js';

const ModalBase = ({ children }) => {
  const { setModal } = useModal();

  const closeModal = () => setModal(null);
  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      {children}
    </Modal>
  );
};

export default ModalBase;
