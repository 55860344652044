import { MH_JOB_CHANGE_PREFIX, MH_PIPELINE_PREFIX } from 'utils/constants.js';

const getFullTableName = (table, dataset) => {
  if (table?.includes(MH_PIPELINE_PREFIX)) {
    return table.replace(MH_PIPELINE_PREFIX, '');
  }

  if (table?.includes(MH_JOB_CHANGE_PREFIX)) {
    return table.replace(MH_JOB_CHANGE_PREFIX, '');
  }

  return `${dataset}.${table}`;
};

export { getFullTableName };
