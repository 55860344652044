import { useStyles } from './NotFound.style.js';
import { ReactComponent as Image } from 'assets/img/notFoundPage.svg';

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Image />
      <div className='txt-mainDark-20-700 mt-1'>Oops!</div>
      <div className='txt-grey-13-500 greyColor'>
        The page you are looking for doesn't exist or has been moved
      </div>
    </div>
  );
};

export default NotFound;
