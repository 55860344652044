import React from 'react';
import { ReactComponent as PartitionIcon } from 'assets/img/icons/projectTreeIcons/partition.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/projectTreeIcons/view20.svg';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/projectTreeIcons/external20.svg';
import { ReactComponent as TableIcon } from 'assets/img/icons/projectTreeIcons/table20.svg';

const iconByTableType = (type, isPartition) => {
  if (isPartition) {
    return <PartitionIcon style={{ marginRight: 5 }} />;
  }
  switch (type) {
    case 'VIEW':
      return <ViewIcon style={{ marginRight: 5 }} />;
    case 'EXTERNAL':
      return <ExternalIcon style={{ marginRight: 5 }} />;
    default:
      return <TableIcon style={{ marginRight: 5 }} />;
  }
};

export { iconByTableType };
