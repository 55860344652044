import React from 'react';
import {
  useEffect,
  useDispatch,
  useMemo,
  useSelector,
  useCallback,
  useState,
} from 'hooks/hooks.js';
import {
  RulesBlock,
  JobsBlock,
} from 'Pages/DataQuality/components/components.js';
import { DATA_STATUS } from 'constants/constants.js';
import {
  getDataScanRules,
  updateDataScanRules,
  clearUpdateDataScanRulesStatus,
} from 'slices/actions';
import {
  getRuleDTOFromResponse,
  getUpdateRequestDTOFromRules,
} from 'Pages/DataQuality/libs/mappers/mappers.js';
import { useMessages } from 'context/context.js';

const MAX_RULES_LIMIT = 1000;

const OverviewTab = () => {
  const dispatch = useDispatch();
  const [rules, setRules] = useState([]);
  const { setMessage } = useMessages();
  const { currentScan, dataScanRules, dataScanRulesStatus } = useSelector(
    (state) => ({
      currentScan: state.dataScans.dataScan,
      dataScanRules: state.dataScans.dataScanRules,
      dataScanRulesStatus: state.dataScans.dataScanRulesStatus,
    })
  );

  const updateRequest = useCallback(
    (newRules) => {
      const updateDataScanRulesRequest = getUpdateRequestDTOFromRules(newRules);
      dispatch(
        updateDataScanRules({
          location: currentScan.location,
          dataPlexId: currentScan.dataPlexId,
          updateDataScanRulesRequest,
        })
      ).then((action) => {
        dispatch(clearUpdateDataScanRulesStatus());
        if (action.meta.requestStatus === DATA_STATUS.fulfilled) {
          dispatch(
            getDataScanRules({
              location: currentScan.location,
              dataPlexId: currentScan.dataPlexId,
              limit: MAX_RULES_LIMIT,
              page: 1,
            })
          );
        }
        if (action.meta.requestStatus === DATA_STATUS.rejected) {
          setMessage('Something went wrong, try again');
        }
      });
    },
    [currentScan, dispatch, setMessage]
  );

  useEffect(() => {
    if (currentScan && dataScanRulesStatus === DATA_STATUS.idle) {
      dispatch(
        getDataScanRules({
          location: currentScan.location,
          dataPlexId: currentScan.dataPlexId,
          limit: MAX_RULES_LIMIT,
          page: 1,
        })
      );
    }
  }, [currentScan, dataScanRulesStatus, dispatch]);

  useEffect(() => {
    if (dataScanRulesStatus === DATA_STATUS.fulfilled) {
      setRules(dataScanRules.map((item) => getRuleDTOFromResponse(item)));
    }
  }, [dataScanRules, dataScanRulesStatus]);

  const handleAddRules = useCallback(
    (values) => {
      const newRules = [...values, ...rules];
      setRules(newRules);
      updateRequest(newRules);
    },
    [rules, updateRequest]
  );

  const handleEditRule = useCallback(
    (value) => {
      const newRules = [value, ...rules.filter((item) => item.id !== value.id)];
      setRules(newRules);
      updateRequest(newRules);
    },
    [rules, updateRequest]
  );

  const isDisabledRemoveRuleButton = useMemo(() => {
    return rules.length === 1;
  }, [rules.length]);

  const handleChangeRemoveRule = useCallback(
    (value) => {
      const newRules = rules.filter((item) => item.id !== value.id);
      setRules(newRules);
      updateRequest(newRules);
    },
    [updateRequest, rules]
  );

  return (
    <>
      <JobsBlock />
      <RulesBlock
        dataset={currentScan?.dataset || ''}
        table={currentScan?.table || ''}
        saveRules={handleAddRules}
        editRule={handleEditRule}
        rules={rules}
        isDisabledRemoveRuleButton={isDisabledRemoveRuleButton}
        removeRule={handleChangeRemoveRule}
      />
    </>
  );
};

export { OverviewTab };
