import React, { createContext } from 'react';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useLocation,
  useHistory,
} from 'hooks/hooks.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { ALERT_SEVERITY, QUERY_TYPES } from 'constants/constants.js';
import { useMessages, useUserInfo } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { INTEGRATIONS } from 'Pages/Integrations/libs/constants/constants.js';

const eventButtonConnect = '-> Click Connect button -';

const ConfigurationContext = createContext({
  integrations: {
    slack: undefined,
    jira: null,
    looker: null,
    dataplex: undefined,
    isUserInformed: false,
    dataform: undefined,
    pagerduty: undefined,
  },
  setIntegrationsInfo: () => {},
});

const ConfigurationContextProvider = ({ children }) => {
  const [integrations, setIntegrationsInfo] = useState({
    slack: undefined,
    jira: null,
    looker: undefined,
    dataplex: undefined,
    isUserInformed: false,
    dataform: undefined,
    pagerduty: undefined,
  });
  const [isPrivateRoute, setIsPrivateRoute] = useState(false);
  const { user } = useUserInfo();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { search } = useLocation();
  const { setMessage } = useMessages();

  const isLookerConnected = useMemo(() => {
    if (integrations?.looker === undefined) {
      return undefined;
    }
    return Boolean(integrations?.looker?.clientUrl);
  }, [integrations?.looker]);

  const isDataplexConnected = useMemo(() => {
    if (integrations?.dataplex === undefined) {
      return undefined;
    }
    return Boolean(integrations?.dataplex);
  }, [integrations?.dataplex]);

  const isDataplexApiDisabled = useMemo(() => {
    if (integrations?.dataplex) {
      return !integrations.dataplex.isApiEnabled;
    }

    return false;
  }, [integrations?.dataplex]);

  const isDataformConnected = useMemo(() => {
    if (integrations?.dataform === undefined) {
      return undefined;
    }
    return Boolean(integrations?.dataform);
  }, [integrations?.dataform]);

  const { data: slackData } = useQuery(
    [QUERY_TYPES.slack],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );
  const { data: jiraData } = useQuery(
    [QUERY_TYPES.jira],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );
  const { data: pagerDutyData } = useQuery(
    [QUERY_TYPES.pagerDuty],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );

  const { data: lookerData } = useQuery(
    [QUERY_TYPES.looker],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );

  const { data: dataPlexData } = useQuery(
    [QUERY_TYPES.dataPlex],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );

  const { data: dataFormData } = useQuery(
    [QUERY_TYPES.dataForm],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );

  const { mutateAsync: sendSlackId } = useMutation(
    ({ code }) => {
      return fetcherPost('/api/v1/connect-slack', { code });
    },
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while adding slack connection');
          amplEvent(`Slack ${eventButtonConnect} error`);
        } else {
          setMessage('Slack has been connected', ALERT_SEVERITY.success);
          amplEvent(`Slack ${eventButtonConnect} success`);
        }
        queryClient.invalidateQueries(QUERY_TYPES.slack);
      },
    }
  );

  const { mutateAsync: sendJiraId } = useMutation(
    ({ code }) => {
      return fetcherPost('/api/v1/connect-jira', { code });
    },
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while adding jira connection');
          amplEvent(`Jira ${eventButtonConnect} error`);
        } else {
          setMessage('Jira has been connected', ALERT_SEVERITY.success);
          amplEvent(`Jira ${eventButtonConnect} success`);
        }
        queryClient.invalidateQueries(QUERY_TYPES.jira);
      },
    }
  );

  const { mutateAsync: sendPagerDutyConfig } = useMutation(
    ({ config }) => {
      return fetcherPost(QUERY_TYPES.pagerDutyConnect, { value: config });
    },
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while adding PagerDuty connection');
          amplEvent(`PagerDuty ${eventButtonConnect} error`);
        } else {
          setMessage('PagerDuty has been connected', ALERT_SEVERITY.success);
          amplEvent(`PagerDuty ${eventButtonConnect} success`);
        }
        queryClient.invalidateQueries(QUERY_TYPES.pagerDuty);
      },
    }
  );

  useEffect(() => {
    if (slackData?.value !== undefined) {
      setIntegrationsInfo((prev) => ({
        ...prev,
        slack: slackData?.value,
      }));
    }
  }, [slackData]);

  useEffect(() => {
    setIntegrationsInfo((prev) => ({ ...prev, jira: jiraData?.value || null }));
  }, [jiraData]);

  useEffect(() => {
    if (pagerDutyData?.value !== undefined) {
      setIntegrationsInfo((prev) => ({
        ...prev,
        pagerduty: pagerDutyData?.value,
      }));
    }
  }, [pagerDutyData]);

  useEffect(() => {
    if (lookerData?.value !== undefined) {
      setIntegrationsInfo((prev) => ({
        ...prev,
        looker: lookerData?.value,
      }));
    }
  }, [lookerData]);

  useEffect(() => {
    if (dataPlexData?.value !== undefined) {
      setIntegrationsInfo((prev) => ({
        ...prev,
        dataplex: dataPlexData?.value,
      }));
    }
  }, [dataPlexData]);

  useEffect(() => {
    if (dataFormData?.value !== undefined) {
      setIntegrationsInfo((prev) => ({
        ...prev,
        dataform: dataFormData?.value,
      }));
    }
  }, [dataFormData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const code = searchParams.get('code');
    const integration = searchParams.get('item');
    const config = searchParams.get('config');

    if (code) {
      if (integration === INTEGRATIONS.jira) {
        searchParams.delete('code');
        sendJiraId({ code });
        history.replace({ search: searchParams.toString() });
      }
      if (integration === INTEGRATIONS.slack) {
        searchParams.delete('code');
        sendSlackId({ code });
        history.replace({
          search: searchParams.toString(),
          state: history.location.state,
        });
      }
    }

    if (config) {
      if (integration === INTEGRATIONS.pagerduty) {
        searchParams.delete('config');
        sendPagerDutyConfig({ config });
        history.replace({
          search: searchParams.toString(),
          state: history.location.state,
        });
      }
    }
  }, [sendSlackId, search, history, sendJiraId, sendPagerDutyConfig]);

  return (
    <ConfigurationContext.Provider
      value={{
        integrations,
        setIntegrationsInfo,
        isPrivateRoute,
        setIsPrivateRoute,
        isLookerConnected,
        isDataplexConnected,
        isDataplexApiDisabled,
        isDataformConnected,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

const useConfiguration = () => useContext(ConfigurationContext);

export { ConfigurationContext, ConfigurationContextProvider, useConfiguration };
