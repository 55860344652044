import React from 'react';
import { useCallback } from 'hooks/hooks.js';
import clsx from 'clsx';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactComponent as RadioIcon } from 'assets/img/icons/checkIcons/radio.svg';
import { ReactComponent as EmptyRadioIcon } from 'assets/img/icons/checkIcons/empty-radio.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiFormControlLabel-root': {
      marginLeft: '-10px',
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
    '& .MuiRadio-root': {
      marginRight: 22,
      padding: 0,
      transform: 'translateX(50%)',
    },
  },
}));

const RadioWithLabel = ({
  selectedOption,
  options,
  onChangeValue,
  getValueFromOption = null,
  getLabelFromOption = null,
  containerClassName = '',
  groupClassName = '',
  className = '',
}) => {
  const classes = useStyles();

  const getValue = useCallback(
    (item) => {
      if (!item) {
        return '';
      }
      if (getValueFromOption === null) {
        return item;
      }

      return getValueFromOption(item);
    },
    [getValueFromOption]
  );

  const getLabel = useCallback(
    (item) => {
      if (!item) {
        return '';
      }
      if (getLabelFromOption === null) {
        return item;
      }

      return getLabelFromOption(item);
    },
    [getLabelFromOption]
  );

  const onChange = useCallback(
    (event) => {
      if (getValueFromOption === null) {
        return event.target.value;
      }
      const option = options.find((item) => {
        return getValue(item) === event.target.value;
      });
      if (option) {
        onChangeValue(option);
      }
    },
    [getValue, onChangeValue, options, getValueFromOption]
  );

  return (
    <FormControl className={clsx(classes.container, containerClassName)}>
      <RadioGroup
        value={getValue(selectedOption)}
        onChange={onChange}
        className={groupClassName}
      >
        {options.map((item) => (
          <FormControlLabel
            key={getValue(item)}
            value={getValue(item)}
            control={
              <Radio
                icon={<EmptyRadioIcon width={20} height={20} />}
                checkedIcon={<RadioIcon width={20} height={20} />}
              />
            }
            label={getLabel(item)}
            className={className}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export { RadioWithLabel };
