import React from 'react';
import { useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { AppRoutes } from 'app-routes.js';
import { Alert } from 'Components/components.js';
import { HeaderInfoContainer } from './components.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    '& .formButton': {
      alignSelf: 'center',
    },
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  formsContainer: {
    '& .formBlock': {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
    },
  },
}));

const UserProfileInfo = ({ error, FormComponent, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const navigateToSignIn = () => history.push(AppRoutes.Login.path);

  return (
    <div className={classes.mainContainer}>
      <div className='headerContainer'>
        <div className={classes.logoContainer}>
          <Logo />
          <HeaderInfoContainer />
        </div>

        <h1 className='txt-mainDark-32-700 textWhiteColor mb-1'>Sign up</h1>

        <p className='text txt-mainDark-16-500 textWhiteColor'>
          Tell us a little about yourself so that we can set up and optimize
          <br />
          the workflow.
        </p>
      </div>

      <div className={clsx(classes.formsContainer, 'mainContainer')}>
        <Alert alertComponent={error} withTopMargin={false} className='mb-4' />

        <FormComponent {...props} />
      </div>

      <div className='bottomText txt-mainDark-13-700'>
        Do you have an account?{' '}
        <span
          className='txt-blue-13-700'
          style={{ cursor: 'pointer' }}
          onClick={navigateToSignIn}
        >
          Sign in
        </span>
      </div>
    </div>
  );
};

export { UserProfileInfo };
