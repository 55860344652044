import { makeStyles } from '@mui/styles';

export const ownStyles = makeStyles((theme) => ({
  textPosition: {
    position: 'relative',
    top: -22,
  },
  list: {
    paddingLeft: 20,
  },
}));
