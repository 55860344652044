import React from 'react';
import {
  useEffect,
  useState,
  useMutation,
  useQuery,
  useMemo,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import {
  Autocomplete,
  Box,
  TextareaAutosize,
  TextField,
  FormControl,
} from '@mui/material';
import { useStyles } from './Jira.styles.js';
import {
  Button,
  BUTTON_VARIANT,
  Divider,
  PositionLoader,
} from 'Components/components.js';
import { useModal, useMessages } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import {
  ALERT_SEVERITY,
  QUERY_TYPES,
  ANOMALY_TYPE,
} from 'constants/constants.js';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { CustomField, SystemField } from './JiraFields.js';

const summaryByIncidentType = (type) => {
  let summary = '[Masthead data]';
  switch (type) {
    case ANOMALY_TYPE.FRESHNESS:
      summary += '. Freshness anomaly';
      break;
    case ANOMALY_TYPE.VOLUME:
      summary += '. Volume anomaly';
      break;
    case ANOMALY_TYPE.PIPELINE_ERROR:
      summary += '. Pipeline error';
      break;
    default:
      summary += '';
  }

  return summary;
};

const defaultDescription = (type, project, tableInfo, data) => {
  let desc = '';
  if (!tableInfo?.table || !tableInfo?.dataset) {
    desc += `Project: ${project}\n\n`;
  } else {
    desc += `Dataset: ${tableInfo?.dataset}\nTable: ${tableInfo.table}\n\n`;
  }

  switch (type) {
    case ANOMALY_TYPE.FRESHNESS:
      desc += `The data was not updated, while it was regularly updated`;
      break;
    case ANOMALY_TYPE.VOLUME:
      const moreOrLess =
        data.current > data.max
          ? 'above the maximum value'
          : 'below the minimum value';
      const change =
        data.current > data.max
          ? data.max - data.current
          : data.current - data.min;
      desc += `Received ${data.current} records, which is ${Math.abs(
        change
      )} ${moreOrLess}`;
      break;
    case ANOMALY_TYPE.PIPELINE_ERROR:
      desc += `Details: ${data.error}\nQuery: ${data.query}`;
      break;
    default:
  }

  return desc;
};

const JiraIssueModal = ({
  groupIncident,
  gcpProject,
  tableInfo,
  copyLink,
  onPush,
}) => {
  const classes = useStyles();
  const { incidentId, anomalyType, incidentType } = groupIncident;

  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const [project, setProject] = useState();
  const [issueType, setIssueType] = useState();
  const [summary, setSummary] = useState(summaryByIncidentType(anomalyType));
  const [description, setDescription] = useState('');
  const [currentFields, setCurrentFields] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [requestFields, setRequestFields] = useState({});

  const amplJiraEvent = `${incidentId} -> Push to Jira button ->`;

  const isDisabledCreateButton = useMemo(() => {
    return isLoading || !project || !issueType || !summary || !currentFields;
  }, [currentFields, isLoading, issueType, project, summary]);

  const closeModal = () => {
    amplEvent(`${amplJiraEvent} Cancel modal`);
    setModal(null);
  };

  const { isFetching: isIncidentFetching, data: incident } = useQuery(
    [`${QUERY_TYPES.incidents}/${incidentId}/one`, incidentType],
    ({ queryKey }) => {
      const [url, type] = queryKey;
      return fetcherGet(url, { type });
    },
    { enabled: !!incidentId && !!incidentType }
  );

  useEffect(() => {
    if (incident?.value?.data) {
      setDescription(
        defaultDescription(
          anomalyType,
          gcpProject,
          tableInfo,
          incident?.value?.data[0]
        )
      );
    }
  }, [incident]);

  const { isFetching: isProjectsFetching, data: projects } = useQuery(
    [QUERY_TYPES.jiraProjects],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );
  const { isFetching: isIssueTypesFetching, data: issueTypes } = useQuery(
    [QUERY_TYPES.jiraIssueTypes, project?.id],
    ({ queryKey }) => {
      const [url, projectId] = queryKey;
      return fetcherGet(url, { project: projectId });
    },
    { enabled: !!project }
  );

  const { isFetching: isAccountsFetching, data: accounts } = useQuery(
    [QUERY_TYPES.jiraAccounts, project?.key],
    ({ queryKey }) => {
      const [url, projectKey] = queryKey;
      return fetcherGet(url, { key: projectKey });
    },
    { enabled: !!project }
  );

  const { isFetching: isFieldsFetching, data: fields } = useQuery(
    [QUERY_TYPES.jiraFields, project?.id, issueType?.id],
    ({ queryKey }) => {
      const [url, projectId, issueTypeId] = queryKey;
      return fetcherGet(url, { project: projectId, issueType: issueTypeId });
    },
    { enabled: !!project && !!issueType }
  );

  const { mutateAsync: createIssue } = useMutation(
    (params) => {
      return fetcherPost(QUERY_TYPES.jiraIssue, {
        ...params,
        incidentId,
        incidentType,
        datasetUuid: tableInfo?.uuid,
      });
    },
    {
      onSuccess: (data) => {
        if (data.value) {
          onPush(data.value);
          setMessage('Jira issue has been created', ALERT_SEVERITY.success);
          amplEvent(`${amplJiraEvent} Jira issue created -> success`);
        } else {
          setMessage('Got an error while creation issue');
          amplEvent(`${amplJiraEvent} Jira issue created -> error`);
        }
      },
    }
  );

  useEffect(() => {
    setIsLoading(
      isIncidentFetching ||
        isProjectsFetching ||
        isIssueTypesFetching ||
        isAccountsFetching ||
        isFieldsFetching
    );
  }, [
    isProjectsFetching,
    isIssueTypesFetching,
    isAccountsFetching,
    isFieldsFetching,
    isIncidentFetching,
  ]);
  useEffect(() => {
    if (fields) setCurrentFields(fields);
  }, [fields]);
  useEffect(() => {
    if (currentFields?.system?.desc) {
      onChangeFieldValue(
        currentFields.system.desc,
        descriptionToBodyRequest(
          defaultDescription(
            anomalyType,
            gcpProject,
            tableInfo,
            incident?.value?.data[0]
          )
        )
      );
    }
  }, [currentFields?.system?.desc]);

  const descriptionToBodyRequest = (desc) => {
    return {
      type: 'doc',
      version: 1,
      content: [
        {
          type: 'paragraph',
          content: [
            {
              text: desc,
              type: 'text',
            },
            {
              type: 'hardBreak',
            },
            {
              text: 'View incident',
              type: 'text',
              marks: [
                {
                  attrs: { href: copyLink },
                  type: 'link',
                },
              ],
            },
          ],
        },
      ],
    };
  };

  const handleChangeProject = (value) => {
    setProject(value);
    setIssueType(undefined);
    setSummary(summaryByIncidentType(anomalyType));
    setDescription(
      defaultDescription(
        anomalyType,
        gcpProject,
        tableInfo,
        incident?.value?.data[0]
      )
    );
    setCurrentFields(undefined);
    setRequestFields({});
  };
  const handleChangeIssueType = (value) => {
    setIssueType(value);
    setCurrentFields(undefined);
    setRequestFields({});
  };
  const handleChangeSummary = (event) => {
    setSummary(event.target.value);
  };
  const onChangeFieldValue = (field, value) => {
    const newRequestFields = { ...requestFields };
    newRequestFields[field.key] = value;
    setRequestFields(newRequestFields);
  };

  const pushIssue = () => {
    if (!project || !issueType || !summary || !currentFields) {
      return;
    }
    const requiredFields = currentFields.system.desc?.required
      ? [currentFields.system.desc.key]
      : [];
    requiredFields.push(
      ...currentFields.system.values
        .filter((it) => it.required)
        .map((it) => it.key)
    );
    requiredFields.push(
      ...currentFields.custom.values
        .filter((it) => it.required)
        .map((it) => it.key)
    );
    for (const requiredField of requiredFields) {
      if (!requestFields[requiredField]) {
        return;
      }
    }
    setIsLoading(true);
    createIssue({
      project: project.id,
      issueType: issueType.id,
      summary,
      fields: requestFields,
    })
      .catch(() => setMessage('Got an error while creation issue'))
      .finally(() => {
        setIsLoading(false);
        closeModal();
      });
  };

  return (
    <form className={classes.modalWrapper} onSubmit={(e) => e.preventDefault()}>
      <div className={clsx(classes.modalTitle, 'txt-mainDark-16-700')}>
        Create issue
      </div>
      <Divider />

      {isLoading ? (
        <div style={{ height: 150 }}>
          <PositionLoader />
        </div>
      ) : (
        <div className={clsx(classes.modalDesc, 'txt-mainDark-13-500')}>
          <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
            Project <span className={classes.requiredIcon}>*</span>
          </div>
          <FormControl
            sx={{ marginBottom: 6 }}
            className={classes.fieldContainer}
          >
            <Autocomplete
              className={clsx(classes.fieldContainer, 'menuItemSelect')}
              options={projects?.values}
              getOptionLabel={(option) => option?.name}
              value={project}
              onChange={(e, value) => handleChangeProject(value)}
              renderOption={(props, option, { selected }) => (
                <Box
                  component='li'
                  value={option}
                  {...props}
                  className={clsx(classes.listItems, selected && 'selected')}
                >
                  {!!option.avatarUrls && (
                    <img
                      className={classes.jiraAvatar}
                      src={option.avatarUrls['48x48']}
                      alt=''
                    />
                  )}
                  {option.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  required={!project}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ marginBottom: 6 }} required>
            <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
              Issue type <span className={classes.requiredIcon}>*</span>
            </div>
            <Autocomplete
              className={clsx(classes.fieldContainer, 'menuItemSelect')}
              options={issueTypes?.values || []}
              getOptionLabel={(option) => option?.name}
              value={issueType}
              disabled={!project}
              onChange={(e, value) => handleChangeIssueType(value)}
              renderOption={(props, option, { selected }) => {
                return (
                  <Box
                    component='li'
                    value={option}
                    {...props}
                    className={clsx(classes.listItems, selected && 'selected')}
                  >
                    {option.name}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  required={!issueType}
                />
              )}
            />
          </FormControl>

          <FormControl sx={{ marginBottom: 6 }}>
            <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
              Summary <span className={classes.requiredIcon}>*</span>
            </div>
            <TextField
              id={`field-summary`}
              label=''
              variant='outlined'
              sx={{ width: '100%' }}
              value={summary}
              onChange={handleChangeSummary}
              required
            />
          </FormControl>

          {!!currentFields?.system?.desc && (
            <FormControl sx={{ marginBottom: 6 }}>
              <div className='txt-mainDark-13-500' style={{ marginBottom: 12 }}>
                {currentFields.system.desc.name}{' '}
                {currentFields.system.desc.required && (
                  <span className={classes.requiredIcon}>*</span>
                )}
              </div>
              <div>
                <TextareaAutosize
                  className={classes.textareaField}
                  value={description}
                  minRows={3}
                  onChange={(e) => {
                    onChangeFieldValue(
                      currentFields.system.desc,
                      descriptionToBodyRequest(e.target.value)
                    );
                    setDescription(e.target.value);
                  }}
                  required={currentFields.system.desc.required}
                />
              </div>
            </FormControl>
          )}

          {currentFields?.system?.values.map((item) => (
            <SystemField
              key={item.key}
              field={item}
              accounts={accounts}
              onChangeFieldValue={onChangeFieldValue}
            />
          ))}

          {currentFields?.custom?.values.map((item) => (
            <CustomField
              key={item.key}
              field={item}
              accounts={accounts}
              onChangeFieldValue={onChangeFieldValue}
            />
          ))}
        </div>
      )}

      <Divider />

      <div className={classes.buttonsContainer}>
        <Button
          text='Cancel'
          variant={BUTTON_VARIANT.text}
          isDisabled={isLoading}
          onClick={closeModal}
          fullWidth={false}
        />

        <Button
          text='Create'
          isDisabled={isDisabledCreateButton}
          onClick={pushIssue}
          fullWidth={false}
          className='containedButton'
        />
      </div>
    </form>
  );
};

export default JiraIssueModal;
