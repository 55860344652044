import cronstrue from 'cronstrue';

const everyDaysOnMonthAt = (days = [], hours = 0, minutes = 0) => {
  const cronDaysOnMonthValue = days.length ? days.join(',') : '*';

  return `${minutes} ${hours} ${cronDaysOnMonthValue} * *`;
};

const everyDaysOnWeekAt = (days = [], hours = 0, minutes = 0) => {
  const cronDaysValue = days.length ? days.join(',') : '*';

  return `${minutes} ${hours} * * ${cronDaysValue}`;
};

const everyDayAt = (hours = 0, minutes = 0) => {
  return `${minutes} ${hours} * * *`;
};

const getStringByCron = (cronValue) => {
  return cronstrue.toString(cronValue, {
    verbose: true,
    use24HourTimeFormat: true,
    throwExceptionOnParseError: false,
  });
};

export { everyDaysOnMonthAt, everyDaysOnWeekAt, everyDayAt, getStringByCron };
