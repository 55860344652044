import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  useEffect,
  useState,
  useHistory,
  useMemo,
  useLocation,
  useGetTimezoneValues,
} from 'hooks/hooks.js';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { AutocompleteSelect, Button } from 'Components/components.js';
import { useSignUpInfo, useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherPost } from 'utils/utils.js';
import { getProjectNumber } from 'utils/helpers/helpers.js';
import { AUTH_ERRORS, SEARCH_PARAMS } from 'constants/constants.js';
import { auth, gcpCreator, signUpAmplEvent } from 'service/services.js';
import { SetUpProject, SetUpLoading } from './components/components.js';
import { INPUT_VALUE_ERRORS } from './libs/constants/constants.js';
import { useGetExistingProjects } from './libs/hooks/hooks.js';

const TRACKER_EVENT = 'Deployment (Choose GCP Project)';
const EVENT_BUTTON_CONNECT = `${TRACKER_EVENT} -> Click - Connect`;

const CreateFormComponent = ({
  projectValue,
  handleChangeProject,
  timezones,
  timezoneValue,
  onChangeTimezoneValue,
  signUpInfo,
  onConnectClick,
}) => {
  const { existingProjects } = useGetExistingProjects(signUpInfo?.accessToken);

  const projectsList = useMemo(() => {
    return signUpInfo.projects.filter(
      (project) => !existingProjects.includes(project.projectId)
    );
  }, [existingProjects, signUpInfo.projects]);

  return (
    <div className='form'>
      <div className='formBlock'>
        <AutocompleteSelect
          value={timezoneValue}
          onChange={onChangeTimezoneValue}
          options={timezones}
          label='What timezone are you using to schedule your data pipelines? If not sure, select default (UTC).'
          id='timeZone'
        />

        <FormControl fullWidth={true}>
          <InputLabel required={true} disableAnimation={true} shrink>
            GCP Project ID
          </InputLabel>

          <Select
            value={projectValue}
            onChange={handleChangeProject}
            displayEmpty
          >
            <MenuItem value='' disabled>
              Choose GCP Project
            </MenuItem>

            {projectsList.map((project) => (
              <MenuItem value={project.projectId} key={project.projectId}>
                {project.displayName} - {project.projectId}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <Button text='Connect' className='formButton' onClick={onConnectClick} />
    </div>
  );
};

const CreateTextBlockComponent = () => {
  return (
    <div>
      Choose the Google Cloud project you wish to observe. Masthead will
      automatically
      <br />
      create all the necessary resources within your selected project to monitor
      all your
      <br />
      data assets and pipelines.
    </div>
  );
};

const DeploymentChooseProject = () => {
  const { signUpInfo, setSignUpInfo, setSignUpError } = useSignUpInfo();
  const { setUser, setAuthError } = useUserInfo();
  const history = useHistory();
  const location = useLocation();
  const { timezones, defaultUTCTimezone } = useGetTimezoneValues();

  const [loading, setLoading] = useState(false);
  const [projectValue, setProjectValue] = useState('');
  const [timezoneValue, setTimezoneValue] = useState(defaultUTCTimezone);
  const [error, setError] = useState(null);

  useEffect(
    () =>
      signUpAmplEvent(
        `${TRACKER_EVENT}`,
        signUpInfo?.accessToken,
        signUpInfo?.name,
        signUpInfo?.company,
        signUpInfo?.companyUseCase
      ),
    [signUpInfo?.accessToken]
  );

  const handleChangeProject = (event) => {
    setProjectValue(event.target.value);
    setError(null);
  };

  const handleChangeTimezoneValue = (value) => {
    setTimezoneValue(value);
  };

  const token = signUpInfo?.token;

  if (!signUpInfo) {
    const searchParams = new URLSearchParams(location.search);
    const marketplaceId = searchParams.get('marketplaceId');

    const queryString = marketplaceId ? `?marketplaceId=${marketplaceId}` : '';
    return <Redirect to={`${AppRoutes.SignUp.path}${queryString}`} />;
  }

  const saveValue = () => {
    if (!projectValue) {
      setError(INPUT_VALUE_ERRORS.chooseGcp);
      return;
    }
    setLoading(true);
    signUpInfo.project = projectValue;
    signUpInfo.timezone = timezoneValue?.value || defaultUTCTimezone.value;

    const projectNumber = getProjectNumber(
      signUpInfo.project,
      signUpInfo.projects
    );

    gcpCreator
      .create(signUpInfo.project, token, projectNumber)
      .then(() => {
        fetcherPost('/sign-up', {
          adminEmail: signUpInfo.user.email,
          project: signUpInfo.project,
          projectNumber: projectNumber,
          name: signUpInfo.name,
          company: signUpInfo.company,
          companyRole: signUpInfo.companyRole,
          companyUseCase: signUpInfo.companyUseCase,
          marketplaceAccountId: signUpInfo.marketplaceAccountId,
          timezone: signUpInfo.timezone,
        })
          .then((data) => {
            if (data?.value) {
              setLoading(false);
              const user = signUpInfo.user;
              user.info = data.value;
              setUser(user);
              signUpAmplEvent(
                `${EVENT_BUTTON_CONNECT} (success)`,
                signUpInfo.accessToken,
                signUpInfo.name,
                signUpInfo.company,
                signUpInfo.companyUseCase
              );
              auth.updateCurrentUser(user).then();
              history.push(
                `${AppRoutes.Dashboard.path}?${SEARCH_PARAMS.CREATE_PROJECT}=success`
              );
            } else {
              setSignUpError(AUTH_ERRORS.STH_ELSE);
              signUpAmplEvent(
                `${EVENT_BUTTON_CONNECT} (error_1)`,
                signUpInfo.accessToken,
                signUpInfo.name,
                signUpInfo.company,
                signUpInfo.companyUseCase
              );
            }
          })
          .catch((e) => {
            setSignUpError(AUTH_ERRORS.STH_ELSE);
            signUpAmplEvent(
              `${EVENT_BUTTON_CONNECT} (error_2 - ${e.message})`,
              signUpInfo.accessToken,
              signUpInfo.name,
              signUpInfo.company,
              signUpInfo.companyUseCase
            );
          })
          .finally(() => {
            setLoading(false);
            setSignUpInfo(null);
          });
      })
      .catch((e) => {
        setSignUpError(e.message);
        setLoading(false);
        setSignUpInfo(null);
        setAuthError(null);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} (error_3 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      });
  };

  if (loading) {
    return <SetUpLoading />;
  }

  return (
    <SetUpProject
      error={error}
      title='Select Google Cloud Project '
      FormComponent={CreateFormComponent}
      TextBlockComponent={CreateTextBlockComponent}
      projectValue={projectValue}
      handleChangeProject={handleChangeProject}
      timezones={timezones}
      timezoneValue={timezoneValue}
      onChangeTimezoneValue={handleChangeTimezoneValue}
      signUpInfo={signUpInfo}
      onConnectClick={saveValue}
    />
  );
};

export default DeploymentChooseProject;
