import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useCostsDestinationTables = ({
  startDate,
  endDate,
  page,
  rowsPerPage,
  searchDestinationTable,
  searchDestinationDataset,
  searchDestinationValue,
  searchTable,
  searchDataset,
  searchValue,
  selectedEmail,
  searchEmail,
  selectedTags,
  selectedLabelKey,
  selectedLabelValue,
  searchLabel,
  alertTypes,
  pipelineType,
  pipelineSubtypes,
  isDeadEndTablesSelected = false,
  crossProject = false,
  enabled = true,
}) => {
  const { isFetching: isFetchingDestinationTables, data } = useQuery(
    [
      QUERY_TYPES.jobDestinationsCost,
      startDate,
      endDate,
      page,
      rowsPerPage,
      searchDestinationTable,
      searchDestinationDataset,
      searchDestinationValue,
      searchTable,
      searchDataset,
      searchValue,
      selectedEmail,
      searchEmail,
      selectedTags,
      selectedLabelKey,
      selectedLabelValue,
      searchLabel,
      alertTypes,
      pipelineType,
      pipelineSubtypes,
      isDeadEndTablesSelected,
      crossProject,
    ],
    ({ queryKey }) => {
      const [
        url,
        start,
        end,
        page,
        limit,
        destinationTable,
        destinationDataset,
        destinationSearch,
        table,
        dataset,
        searchValue,
        principalEmail,
        searchPrincipalEmail,
        selectedTags,
        selectedLabelKey,
        selectedLabelValue,
        searchLabel,
        alertTypes,
        pipelineType,
        pipelineSubtypes,
        isDeadEndTablesSelected,
        crossProject,
      ] = queryKey;
      const params = new URLSearchParams();
      const newAlertTypes = [];

      if (
        alertTypes.length > 1 ||
        (alertTypes.length && alertTypes[0].value !== '')
      ) {
        newAlertTypes.push(...alertTypes.map((item) => item.value));
      }

      params.append(
        'from',
        moment(convertLocalToUTCDate(start)).utc().format()
      );
      params.append('to', moment(convertLocalToUTCDate(end)).utc().format());
      params.append('page', page + 1);
      params.append('limit', limit);
      params.append('destinationTable', destinationTable || '');
      params.append('destinationDataset', destinationDataset || '');
      params.append('destinationSearch', destinationSearch || '');
      params.append('table', table || '');
      params.append('dataset', dataset || '');
      params.append('search', searchValue || '');
      params.append('principalEmail', principalEmail || '');
      params.append('searchPrincipalEmail', searchPrincipalEmail || '');
      params.append('tableLabelKey', selectedLabelKey || '');
      params.append('tableLabelValue', selectedLabelValue || '');
      params.append('searchTableLabel', searchLabel || '');
      params.append('pipelineType', pipelineType);
      params.append('onlyDeadEnd', isDeadEndTablesSelected);
      params.append('crossProject', crossProject);

      newAlertTypes.forEach((alertType) =>
        params.append('alertTypes', alertType)
      );
      pipelineSubtypes?.forEach((pipelineSubtype) =>
        params.append('pipelineSubtypes', pipelineSubtype)
      );
      selectedTags?.forEach((tag) => params.append('jobTagNames', tag));

      return fetcherGet(url, params);
    },
    { enabled }
  );

  const destinationTables = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  const destinationTablesTotal = useMemo(() => {
    return data?.pagination.total || 0;
  }, [data?.pagination.total]);

  return {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  };
};

export { useCostsDestinationTables };
