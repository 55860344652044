import React from 'react';
import {
  InputLabel,
  MenuItem,
  Select as MaterialSelect,
  FormControl,
  FormHelperText,
  Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Alert } from 'Components/components.js';
import { ReactComponent as ArrowDown } from 'assets/img/icons/sidebarIcons/arrowDown.svg';
import { ReactComponent as CheckIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as UncheckIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';

const SELECT_VARIANT = 'outlined';
const DEFAULT_VALUE = { value: '', label: '' };

const useStyles = makeStyles((theme) => ({
  formControl: {
    '& .MuiSelect-icon': {
      top: 'auto',
      marginRight: theme.spacing(4),
    },
    '& .MuiFormHelperText-root': {
      textAlign: (props) => props.helperTextAlign,
    },
  },
}));

const Select = ({
  multiple = false,
  options = [DEFAULT_VALUE],
  value = multiple ? [] : DEFAULT_VALUE,
  disabledValue = '',
  onChange,
  id = '',
  isRequired = false,
  fullWidth = true,
  label = '',
  errorComponent = null,
  helperText = '',
  helperTextAlign = 'start',
}) => {
  const classes = useStyles({ helperTextAlign });

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    onChange(value);
  };

  return (
    <FormControl
      className={classes.formControl}
      variant={SELECT_VARIANT}
      fullWidth={fullWidth}
    >
      <InputLabel
        htmlFor={id}
        required={isRequired}
        disableAnimation={true}
        variant={SELECT_VARIANT}
        shrink
      >
        {label}
      </InputLabel>

      <MaterialSelect
        id={id}
        value={value}
        onChange={handleChange}
        variant={SELECT_VARIANT}
        IconComponent={ArrowDown}
        multiple={multiple}
        renderValue={(selected) =>
          multiple
            ? selected.map((item) => item.label).join(', ')
            : selected.label
        }
      >
        {disabledValue.length > 0 && (
          <MenuItem disabled value=''>
            {disabledValue}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option}>
            {multiple && (
              <Checkbox
                checked={value.includes(option)}
                icon={<UncheckIcon width={18} height={18} />}
                checkedIcon={<CheckIcon width={18} height={18} />}
                sx={{ padding: 0, marginRight: 4 }}
                id={option.value}
              />
            )}
            {option.label}
          </MenuItem>
        ))}
      </MaterialSelect>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}

      <Alert alertComponent={errorComponent} />
    </FormControl>
  );
};

export { Select };
