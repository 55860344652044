const NON_NULL = 'NON_NULL';
const SET = 'SET';
const REGEX = 'REGEX';
const UNIQUENESS = 'UNIQUENESS';
const RANGE = 'RANGE';
const STATISTIC = 'STATISTIC';
const SQL = 'SQL';

const NON_NULL_LABEL = 'NULL check';
const SET_LABEL = 'Value-set check';
const REGEX_LABEL = 'Regex check';
const UNIQUENESS_LABEL = 'Uniqueness check';
const RANGE_LABEL = 'Range check';
const STATISTIC_LABEL = 'Aggregate statistics check';
const SQL_LABEL = 'Row Condition Check';
const SQL_TEXT_INFO =
  'We will utilize this expression to create a SQL clause of the form SELECT IF(row check-expression) FROM TABLE to return ' +
  'success/failure. The SQL can include reference to another table using expression subqueries. Example: ' +
  'row-check-rule -> 30 < discount_pet AND discount_pct > 50.';

const RULE_TYPES = [
  { value: NON_NULL, label: NON_NULL_LABEL },
  { value: SET, label: SET_LABEL },
  { value: REGEX, label: REGEX_LABEL },
  { value: UNIQUENESS, label: UNIQUENESS_LABEL },
  { value: RANGE, label: RANGE_LABEL },
  { value: STATISTIC, label: STATISTIC_LABEL },
  { value: SQL, label: SQL_LABEL },
];

export {
  NON_NULL,
  SET,
  REGEX,
  UNIQUENESS,
  RANGE,
  STATISTIC,
  SQL,
  RULE_TYPES,
  SQL_LABEL,
  SQL_TEXT_INFO,
};
