import React from 'react';
import { createFilter } from 'react-select';
import Select, { components } from 'react-windowed-select';
import { useEffect, useMemo, useRef, useState } from 'hooks/hooks.js';
import { Image } from 'react-bootstrap';
import OptimizedOption from './OptimizedOption.js';
import { uniqueArrayOfObjects } from 'utils/helpers/helpers.js';
import { createNewOption, getCustomStyles } from './libs/helpers/helpers.js';
import Search from 'assets/img/search.svg';

const UNIQUE_KEY = 'label';
const EVENT_KEY = 'Enter';

const GeneralSearch = ({
  optionLabel = 'Search for:',
  options,
  onSearch,
  onSelect,
  defaultSelectValue,
  defaultSearchValue,
  isLoading,
  placeholderName = 'Start typing name...',
  searchWithDropdown = false,
  withSelect = true,
  searchKey = null,
  ...props
}) => {
  const customStyles = useMemo(
    () => getCustomStyles(optionLabel, searchWithDropdown, withSelect),
    [optionLabel, searchWithDropdown, withSelect]
  );
  const baseOptions = options?.filter((option) => option.label !== '');
  const uniqueOptions = uniqueArrayOfObjects(baseOptions, UNIQUE_KEY);
  const [selectOptions, setSelectOptions] = useState(uniqueOptions);
  const [inputLabel, setInputLabel] = useState('');
  const [inputValue, setInputValue] = useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const selectRef = useRef();

  const defaultValue = useMemo(() => {
    return uniqueOptions.find((option) => option.value === defaultSelectValue);
  }, [defaultSelectValue, uniqueOptions]);

  useEffect(() => {
    setInputLabel('');
    setInputValue(null);
    setIsOpenMenu(false);
  }, [searchKey]);

  useEffect(() => {
    if (defaultValue?.value) {
      setInputValue(defaultValue);
      onSelect(defaultValue.value);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!defaultValue?.value && defaultSearchValue) {
      const option = createNewOption(defaultSearchValue);
      setInputValue(option);
      onSearch(defaultSearchValue);
    }
  }, [defaultSearchValue, defaultValue]);

  const handleOpenMenu = () => {
    setIsOpenMenu(true);
    setSelectOptions(uniqueOptions);
  };
  const handleCloseMenu = () => setIsOpenMenu(false);
  const handleChange = (item) => {
    setInputValue(item);
    onSelect(item?.value || '');
  };
  const handleInputChange = (input) => setInputLabel(input);

  const handleEnter = (e) => {
    if (e.key === EVENT_KEY) {
      e.preventDefault();
      const option = createNewOption(inputLabel);
      handleCloseMenu();
      setSelectOptions([option, ...uniqueOptions]);
      setInputValue(option);
      blurSelect();
      onSearch(inputLabel);
    }
  };

  const blurSelect = () => selectRef.current?.blur();

  return (
    <Select
      ref={selectRef}
      key={defaultValue?.value}
      defaultValue={defaultValue}
      isClearable
      isLoading={isLoading}
      styles={customStyles}
      components={{
        Option: OptimizedOption,
        DropdownIndicator: withSelect && components.DropdownIndicator,
      }}
      filterOption={createFilter({ ignoreAccents: false })}
      placeholder={
        <div>
          <Image src={Search} width={20} height={20} />
          <span className='ms-2 txt-grey-13-500'>{placeholderName}</span>
        </div>
      }
      options={selectOptions}
      onChange={handleChange}
      onKeyDown={handleEnter}
      onMenuOpen={handleOpenMenu}
      onMenuClose={handleCloseMenu}
      menuIsOpen={isOpenMenu}
      onInputChange={handleInputChange}
      value={inputValue}
      {...props}
    />
  );
};

export { GeneralSearch };
