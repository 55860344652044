import React from 'react';
import {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useCostsDestinationTables,
  useCostsStats,
  useBqBillingData,
} from 'hooks/hooks.js';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import {
  AsyncGeneralSearch,
  Backdrop,
  ResourceStats,
} from 'Components/components.js';
import { ResourceTable } from './components/components.js';
import { Dropdowns } from '../components.js';
import { amplEvent } from 'service/services.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';
import {
  AMPL_PAGE_EVENT,
  COST_EFFICIENCY_FILTER_OPTIONS,
  DEAD_END_TABLES,
  PipelineTabs,
  QUERY_TYPES,
} from 'constants/constants.js';
import { PipelineTabSearchOptions } from '../../libs/enums/enums.js';
import {
  COST_EFFICIENCY_FILTER,
  CostsDropdowns,
  INITIAL_TECHNOLOGY_OPTION,
  TABLE_ALERT_STATUS_FILTER,
  TECHNOLOGY_FILTER,
} from 'Pages/Pipelines/libs/constants/constants.js';

const PAGE_EVENT = `${AMPL_PAGE_EVENT.pipeline} -> tab -> ${PipelineTabs.RESOURCE_ALLOCATION} -> `;

const ResourceTab = ({
  startDate,
  endDate,
  page,
  rowsPerPage,
  handleChangePage,
  onReceivePaginationTotal,
}) => {
  const classes = useStyles();
  const { isWelcome } = useBqBillingData();
  const [selectedTag, setSelectedTag] = useState('');

  const [dropdownsValue, setDropdownsValue] = useState({
    [COST_EFFICIENCY_FILTER]: [COST_EFFICIENCY_FILTER_OPTIONS[0]],
    [TABLE_ALERT_STATUS_FILTER]: [ALERT_FILTER_OPTIONS[0]],
    [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
  });

  const isDeadEndTablesSelected = useMemo(() => {
    return dropdownsValue[COST_EFFICIENCY_FILTER][0].value === DEAD_END_TABLES;
  }, [dropdownsValue]);

  const isAllowedRequest = !isWelcome;

  const { costsStats, isCostsStatsFetching } = useCostsStats(
    startDate,
    endDate,
    isAllowedRequest
  );

  const {
    destinationTables,
    destinationTablesTotal,
    isFetchingDestinationTables,
  } = useCostsDestinationTables({
    startDate,
    endDate,
    page,
    rowsPerPage,
    selectedTags: [selectedTag],
    alertTypes: dropdownsValue[TABLE_ALERT_STATUS_FILTER],
    pipelineType: dropdownsValue[TECHNOLOGY_FILTER][0].value,
    crossProject: true,
    isDeadEndTablesSelected,
    enabled: isAllowedRequest,
  });

  useEffect(() => {
    onReceivePaginationTotal(destinationTablesTotal);
  }, [destinationTablesTotal, onReceivePaginationTotal]);

  const handleChangeDropdownsValue = useCallback(
    (value) => {
      setDropdownsValue(value);
      handleChangePage({}, 0);
    },
    [handleChangePage]
  );

  const handleChangeSelectedTags = useCallback(
    (tag) => {
      handleChangePage({}, 0);
      setSelectedTag(tag);

      if (tag.length) {
        amplEvent(`${PAGE_EVENT} ${tag} search`);
      }
    },
    [handleChangePage]
  );

  return (
    <>
      <ResourceStats
        startDate={startDate}
        endDate={endDate}
        selectedTag={selectedTag}
        className={classes.statsContainer}
      />

      <div className={classes.mainContainer}>
        <Dropdowns
          pipelineStats={costsStats}
          onChangeDropdownsValue={handleChangeDropdownsValue}
          existingDropdown={CostsDropdowns}
        />

        <div className={classes.searchContainer}>
          <AsyncGeneralSearch
            onSelect={handleChangeSelectedTags}
            defaultSelectValue={selectedTag}
            searchUrl={QUERY_TYPES.jobProjectTagName}
            searchQueryParam={'searchJobTagName'}
            queryParams={{ crossProject: true }}
            className='searchInput'
            placeholderName='Start typing pipeline tag / label...'
            searchKey={PipelineTabSearchOptions.TAGS}
            onSearch={null}
          />
        </div>

        <ResourceTable
          destinationTables={destinationTables}
          isFetchingDestinationTables={isFetchingDestinationTables}
          startDate={startDate}
          endDate={endDate}
          selectedTag={selectedTag}
        />

        <Backdrop
          isOpen={isFetchingDestinationTables || isCostsStatsFetching}
        />
      </div>
    </>
  );
};

export { ResourceTab };
