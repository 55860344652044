import React from 'react';
import Avatar from '@mui/material/Avatar';

const ProjectAvatar = ({
  project = '',
  isSelected = false,
  isDemo = false,
}) => {
  const getStringAvatar = (value) => {
    return value.charAt(0).toUpperCase();
  };

  return (
    <Avatar
      children={getStringAvatar(project)}
      variant='rounded'
      sx={{
        width: 24,
        height: 24,
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: 13,
        lineHeight: '20px',
        backgroundColor: isSelected
          ? '#050C2E'
          : isDemo
          ? '#8B2FFF'
          : '#2F50FF',
      }}
    />
  );
};

export { ProjectAvatar };
