import React from 'react';
import {
  usePipelineLineageData,
  useEffect,
  useQuery,
  useState,
  useMemo,
} from 'hooks/hooks.js';
import { OpenGraphPipes } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import { PipelineWidgets } from '../libs/constants/constants.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { NodeTypes } from 'Components/GraphPipes/enums/enums.js';

const RequestLineageWidget = ({ jobHash, onChangeLoadingWidget }) => {
  const { lineage: lineageWidget } = getWidgetLabel();
  const [nodes, setNodes] = useState([]);

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.lineagePipelineNodes, jobHash],
    ({ queryKey }) => {
      const [url, jobHash] = queryKey;
      return fetcherGet(url, {
        project: '',
        dataset: '',
        table: '',
        jobHash,
        isUpstream: true,
        isDownstream: true,
      });
    },
    { enabled: Boolean(jobHash), cacheTime: 0 }
  );

  useEffect(() => {
    if (data?.mainNode) {
      const nodes = data.nodes.map((it) => ({
        ...it,
        upEdges: [],
        downEdges: [],
        destinationNode: it.id === data.downEdges.at(0)?.target,
      }));
      nodes.push({
        ...data.mainNode,
        upEdges: data.upEdges,
        downEdges: data.downEdges,
      });
      setNodes(nodes);
    }
  }, [data]);

  const destinationTable = useMemo(() => {
    const destinationNode = nodes.find((item) => item.destinationNode);
    if (!destinationNode) {
      return null;
    }
    const [project, dataset, table] = destinationNode.id.split('.');
    return {
      project,
      dataset,
      table,
    };
  }, [nodes]);

  const isSourceUri = useMemo(() => {
    return nodes.some((item) => item.nodeType === NodeTypes.SOURCE_URI);
  }, [nodes]);

  const lineageData = useMemo(() => {
    return {
      graph: nodes,
    };
  }, [nodes]);

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(PipelineWidgets.LINEAGE, isFetching);
    }
  }, [isFetching, onChangeLoadingWidget]);

  return (
    <>
      {nodes.length > 0 && destinationTable && (
        <Widget
          label='Pipeline Lineage'
          secondaryLabel={lineageWidget.subtitle}
          initialSettings={{ size: 'L', height: 'auto' }}
          hidden={true}
        >
          <OpenGraphPipes
            data={lineageData}
            destinationTable={destinationTable}
            isSourceUri={isSourceUri}
          />
        </Widget>
      )}
    </>
  );
};

const LineageWidget = ({
  destinationTable,
  sourceTables,
  sourceUri,
  destinationUri,
  pipeline,
  onChangeLoadingWidget,
}) => {
  const {
    lineage: { subtitle },
  } = getWidgetLabel();
  const sourceUriData = sourceUri !== null && new Array(sourceUri);
  const sourceData =
    !sourceTables.length && !!sourceUriData ? sourceUriData : sourceTables;

  const { lineageData, isFetching } = usePipelineLineageData(
    destinationTable,
    destinationUri,
    sourceUri,
    sourceTables,
    pipeline
  );

  useEffect(() => {
    if (onChangeLoadingWidget) {
      onChangeLoadingWidget(PipelineWidgets.LINEAGE, isFetching);
    }
  }, [isFetching, onChangeLoadingWidget]);

  return (
    <>
      {sourceData.length > 0 && (
        <Widget
          label='Pipeline Lineage'
          secondaryLabel={subtitle}
          initialSettings={{ size: 'L', height: 'auto' }}
          hidden={true}
        >
          <OpenGraphPipes
            data={lineageData}
            destinationTable={destinationTable}
            isSourceUri={Boolean(sourceUri)}
          />
        </Widget>
      )}
    </>
  );
};

export { LineageWidget, RequestLineageWidget };
