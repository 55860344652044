export const scanNameId = 'DATA_PLEX_ID';
export const lastRunId = 'LAST_RUN';
export const tableNameId = 'TABLE_NAME';
export const scheduleId = 'SCHEDULE';
export const editId = 'EDIT';

export const ScanName = {
  id: scanNameId,
  label: 'Scan ID',
  sort: true,
};
export const LastRun = {
  id: lastRunId,
  label: 'last run',
  sort: true,
};
export const TableName = {
  id: tableNameId,
  label: 'Table name',
  sort: true,
};
export const Schedule = {
  id: scheduleId,
  label: 'Schedule',
  sort: false,
};

export const Edit = {
  id: editId,
  label: '',
  sort: false,
};
