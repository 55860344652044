import React from 'react';
import {
  useEffect,
  useMemo,
  useState,
  useDispatch,
  useSelector,
  useCallback,
  useSelectedElements,
  useSyncTableToSearchParam,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { useStyles } from '../../Dictionary.styles.js';
import {
  AMPL_PAGE_EVENT,
  DATA_STATUS,
  ORDER_TYPES,
  DictionaryTabs,
} from 'constants/constants.js';
import {
  changeAlertType,
  getLookerDictionary,
  getUniqueDashboardElements,
  getUniqueDashboards,
  getUniqueLooks,
} from 'slices/actions.js';
import { amplEvent } from 'service/services.js';
import {
  getFullTableName,
  isStringOfNumbersOnly,
} from 'utils/helpers/helpers.js';
import { CRITICAL_ALERT_TYPE, MUTED_ALERT_TYPE } from 'utils/constants.js';
import {
  CriticalElementModalBody,
  Highlight,
  Modal,
  MutedElementModalBody,
  TableHeadWithCheckbox,
  Toolbar,
  EmptyLooker,
} from 'Components/components.js';
import {
  DictionaryLookerDetails,
  LookerSearch,
} from './components/components.js';
import {
  Name,
  Id,
  alertStatus,
  Type,
  DashboardElement,
  UpstreamTables,
  CreatedAt,
  UpdatedAt,
} from './enums/enums.js';
import { useMessages, useModal } from 'context/context.js';

const HEAD_CELLS = [
  Name,
  Id,
  alertStatus,
  Type,
  DashboardElement,
  UpstreamTables,
  CreatedAt,
  UpdatedAt,
];

const LOOKER_EVENT = `${AMPL_PAGE_EVENT.dictionary} -> Tab ${DictionaryTabs.LOOKER} ->`;

const DictionaryLooker = ({
  filterLookerAlert,
  filterLookerType,
  onMonitors,
  isShowLookerTable,
  startDate,
  endDate,
  page,
  rowsPerPage,
  handleChangePage,
  onChangeBackdrop,
}) => {
  const classes = useStyles();
  const { setMessage } = useMessages();
  const { setModal } = useModal();
  const dispatch = useDispatch();
  const [tableInfo, setTableInfo] = useState(null);
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(Name.id);
  const [actionText, setActionText] = useState('');
  const [dashboardNameSearchOptions, setDashboardNameSearchOptions] = useState(
    []
  );
  const [searchDashboardName, setSearchDashboardName] = useState('');
  const [searchDashboardId, setSearchDashboardId] = useState('');
  const [lookNameSearchOptions, setLookNameSearchOptions] = useState([]);
  const [searchLookName, setSearchLookName] = useState('');
  const [searchLookId, setSearchLookId] = useState('');
  const [dashboardElementSearchOptions, setDashboardElementSearchOptions] =
    useState([]);
  const [searchDashboardElementName, setSearchDashboardElementName] =
    useState('');
  const [searchUpstreamValue, setSearchUpstreamValue] = useState('');
  const [searchUpstreamTable, setSearchUpstreamTable] = useState(null);
  const [selectedAlertType, setSelectedAlertType] = useState('');

  const { tableFromURL, tableInfoFromUrl, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      tableInfo?.table,
      tableInfo?.dataset,
      searchUpstreamValue,
      DictionaryTabs.LOOKER
    );

  const {
    lookerDictionaryData,
    lookerDictionaryDataStatus,
    uniqueDashboards,
    uniqueDashboardsStatus,
    uniqueLooks,
    uniqueLooksStatus,
    uniqueDashboardElements,
    uniqueDashboardElementsStatus,
    selectedAlertTypeStatus,
  } = useSelector((state) => ({
    lookerDictionaryData: state.looker.lookerDictionaryData,
    lookerDictionaryDataStatus: state.looker.lookerDictionaryDataStatus,
    uniqueDashboards: state.looker.uniqueDashboards,
    uniqueDashboardsStatus: state.looker.uniqueDashboardsStatus,
    uniqueLooks: state.looker.uniqueLooks,
    uniqueLooksStatus: state.looker.uniqueLooksStatus,
    uniqueDashboardElements: state.looker.uniqueDashboardElements,
    uniqueDashboardElementsStatus: state.looker.uniqueDashboardElementsStatus,
    selectedAlertTypeStatus: state.looker.selectedAlertTypeStatus,
  }));

  const {
    selected,
    clearSelected,
    handleSelectAllClick,
    isSelected,
    handleClickElement,
  } = useSelectedElements(lookerDictionaryData);

  const isLoadingLookerData = useMemo(() => {
    return lookerDictionaryDataStatus === DATA_STATUS.pending;
  }, [lookerDictionaryDataStatus]);

  const isLoadingUniqueDashboards = useMemo(() => {
    return uniqueDashboardsStatus === DATA_STATUS.pending;
  }, [uniqueDashboardsStatus]);

  const isLoadingUniqueLooks = useMemo(() => {
    return uniqueLooksStatus === DATA_STATUS.pending;
  }, [uniqueLooksStatus]);

  const isLoadingUniqueDashboardElements = useMemo(() => {
    return uniqueDashboardElementsStatus === DATA_STATUS.pending;
  }, [uniqueDashboardElementsStatus]);

  useEffect(() => {
    onChangeBackdrop(isLoadingLookerData);
  }, [isLoadingLookerData, onChangeBackdrop]);

  useEffect(() => {
    if (uniqueDashboardsStatus === DATA_STATUS.idle) {
      dispatch(getUniqueDashboards());
    }
  }, [dispatch, uniqueDashboardsStatus]);

  useEffect(() => {
    if (uniqueLooksStatus === DATA_STATUS.idle) {
      dispatch(getUniqueLooks());
    }
  }, [dispatch, uniqueLooksStatus]);

  useEffect(() => {
    if (uniqueDashboardElementsStatus === DATA_STATUS.idle) {
      dispatch(getUniqueDashboardElements());
    }
  }, [dispatch, uniqueDashboardElementsStatus]);

  useEffect(() => {
    const lookerAlerts = [];
    if (filterLookerAlert.length > 1 || filterLookerAlert[0].value !== '') {
      lookerAlerts.push(...filterLookerAlert.map((item) => item.value));
    }

    dispatch(
      getLookerDictionary({
        dataset:
          searchUpstreamTable?.dataset || tableInfoFromUrl?.dataset || '',
        table: searchUpstreamTable?.table || tableInfoFromUrl?.table || '',
        searchTable: searchUpstreamValue || searchFromUrl || '',
        searchDashboardElementName,
        searchDashboardId,
        searchDashboardName,
        searchLookId,
        searchLookName,
        type: filterLookerType[0].value,
        lookerAlerts,
        sortBy,
        order,
        page: page + 1,
        limit: rowsPerPage,
      })
    );
  }, [
    dispatch,
    order,
    page,
    rowsPerPage,
    sortBy,
    searchDashboardName,
    searchDashboardId,
    searchLookName,
    searchLookId,
    searchDashboardElementName,
    filterLookerType,
    filterLookerAlert,
    tableInfoFromUrl?.dataset,
    tableInfoFromUrl?.table,
    searchFromUrl,
  ]);

  useEffect(() => {
    if (uniqueDashboards.length) {
      setDashboardNameSearchOptions(
        uniqueDashboards.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueDashboards]);

  useEffect(() => {
    if (uniqueLooks.length) {
      setLookNameSearchOptions(
        uniqueLooks.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueLooks]);

  useEffect(() => {
    if (uniqueDashboardElements.length) {
      setDashboardElementSearchOptions(
        uniqueDashboardElements.map((it) => {
          return { label: it, value: it };
        })
      );
    }
  }, [uniqueDashboardElements]);

  useEffect(() => {
    if (tableFromURL === null) {
      handleChangePage({}, 0);
      setTableInfo(null);
    }
  }, [handleChangePage, tableFromURL]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchUpstreamValue('');
    }
  }, [searchFromUrl]);

  const handleDashboardNameSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchDashboardName(value || '');

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by dashboard name -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleDashboardIdSearch = useCallback(
    (value) => {
      if (!isStringOfNumbersOnly(value) && value.length) {
        setMessage('Enter a numeric value');
        return;
      }

      handleChangePage({}, 0);
      setSearchDashboardId(value || '');

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by dashboard id -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleLookNameSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchLookName(value || '');

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by look name -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleLookIdSearch = useCallback(
    (value) => {
      if (!isStringOfNumbersOnly(value) && value.length) {
        setMessage('Enter a numeric value');
        return;
      }

      handleChangePage({}, 0);
      setSearchLookId(value || '');

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by look id -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleDashboardElementSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchDashboardElementName(value || '');

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by dashboard element -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleUpstreamChange = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchUpstreamTable(value);
      setSearchUpstreamValue('');
      setTableInfo(value);

      if (value) {
        amplEvent(
          `${LOOKER_EVENT} search by upstream table -> ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }

      if (value === null) clearSearchParams();
    },
    [clearSearchParams, handleChangePage]
  );

  const handleUpstreamSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchUpstreamValue(value);
      setSearchUpstreamTable(null);
      setTableInfo(null);

      if (value.length) {
        amplEvent(`${LOOKER_EVENT} search by upstream table -> ${value}`);
      }

      if (!value) clearSearchParams();
    },
    [clearSearchParams, handleChangePage]
  );

  const handleRequestSort = (property) => {
    handleChangePage({}, 0);
    const isAsc = sortBy === property && order === ORDER_TYPES.asc;
    setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
    setSortBy(property);
  };

  const updateReportAlertType = useCallback(
    (selectedAlertType) => {
      dispatch(changeAlertType({ selected, selectedAlertType }));
      setSelectedAlertType(selectedAlertType);
    },
    [dispatch, selected]
  );

  const onReportTypeChange = useCallback(
    (selectedAlertType) => {
      if (selected.some((item) => item.alertType === CRITICAL_ALERT_TYPE)) {
        setModal(() => (
          <Modal
            onAgree={() => updateReportAlertType(selectedAlertType)}
            title="Are you sure about changing the critical element's status?"
            titlePosition='center'
            ModalBodyComponent={CriticalElementModalBody}
            agreeButtonText='OK'
          />
        ));
      } else if (selectedAlertType === MUTED_ALERT_TYPE) {
        setModal(() => (
          <Modal
            onAgree={() => updateReportAlertType(selectedAlertType)}
            title='Are you sure you want to mute the element?'
            titlePosition='center'
            ModalBodyComponent={MutedElementModalBody}
            element='elements'
            agreeButtonText='OK'
          />
        ));
      } else {
        updateReportAlertType(selectedAlertType);
      }
    },
    [setModal, updateReportAlertType]
  );

  useEffect(() => {
    if (
      selectedAlertTypeStatus === DATA_STATUS.fulfilled &&
      selectedAlertType.length
    ) {
      setActionText(
        selected.length === 1
          ? `${selected.length} element is ${selectedAlertType.toLowerCase()}`
          : `${selected.length} elements are ${selectedAlertType.toLowerCase()}`
      );
      setTimeout(() => {
        setActionText('');
      }, 3000);
      clearSelected();
      setSelectedAlertType('');
    }
  }, [selectedAlertTypeStatus, selectedAlertType]);

  const highlightName = useCallback(
    (value) => {
      return (
        <Highlight
          searchTable={''}
          searchValue={searchDashboardName || searchLookName}
          value={value}
        />
      );
    },
    [searchDashboardName, searchLookName]
  );

  const highlightDashboardElement = useCallback(
    (value) => {
      return (
        <Highlight
          searchTable={''}
          searchValue={searchDashboardElementName}
          value={value}
        />
      );
    },
    [searchDashboardElementName]
  );

  const highlightUpstream = useCallback(
    (value) => {
      return (
        <Highlight
          searchTable={searchUpstreamTable}
          searchValue={searchUpstreamValue}
          value={value}
        />
      );
    },
    [searchUpstreamTable, searchUpstreamValue]
  );

  const isUpstreamSearch = useMemo(() => {
    return searchUpstreamTable || searchUpstreamValue.length;
  }, [searchUpstreamTable, searchUpstreamValue.length]);

  return (
    <>
      <div className={classes.searchContainer}>
        <LookerSearch
          dashboardNameSearchOptions={dashboardNameSearchOptions}
          handleDashboardNameSearch={handleDashboardNameSearch}
          isDashboardNameSearchOptionsLoading={isLoadingUniqueDashboards}
          handleDashboardIdSearch={handleDashboardIdSearch}
          lookNameSearchOptions={lookNameSearchOptions}
          handleLookNameSearch={handleLookNameSearch}
          isLookNameSearchOptionsLoading={isLoadingUniqueLooks}
          handleLookIdSearch={handleLookIdSearch}
          dashboardElementSearchOptions={dashboardElementSearchOptions}
          handleDashboardElementSearch={handleDashboardElementSearch}
          isDashboardElementSearchOptionsLoading={
            isLoadingUniqueDashboardElements
          }
          handleUpstreamChange={handleUpstreamChange}
          handleUpstreamSearch={handleUpstreamSearch}
          defaultUpstreamDataset={tableInfoFromUrl?.dataset}
          defaultUpstreamTable={tableInfoFromUrl?.table}
          defaultUpstreamSearchValue={searchFromUrl}
        />
      </div>

      <>
        {isShowLookerTable ? (
          <div className={classes.tableContainer}>
            <Toolbar
              numSelected={selected.length}
              closeModal={clearSelected}
              onTypeChange={onReportTypeChange}
              actionText={actionText}
            />
            <Table>
              <TableHeadWithCheckbox
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={lookerDictionaryData?.length}
                onRequestSort={handleRequestSort}
                headCells={HEAD_CELLS}
                selectedColumn={true}
              />
              {lookerDictionaryData && (
                <TableBody className={classes.tableBody}>
                  {lookerDictionaryData.map((report) => {
                    const isReportSelected = isSelected(report);

                    return (
                      <DictionaryLookerDetails
                        key={report.id}
                        report={report}
                        onMonitors={onMonitors}
                        isReportSelected={isReportSelected}
                        handleClickElement={handleClickElement}
                        highlightName={highlightName}
                        highlightDashboardElement={highlightDashboardElement}
                        isDashboardElementSearch={
                          searchDashboardElementName.length
                        }
                        highlightUpstream={highlightUpstream}
                        isUpstreamSearch={isUpstreamSearch}
                        startDate={startDate}
                        endDate={endDate}
                      />
                    );
                  })}
                </TableBody>
              )}
            </Table>
          </div>
        ) : (
          <EmptyLooker />
        )}
      </>
    </>
  );
};

export { DictionaryLooker };
