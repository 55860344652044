import moment from 'moment-timezone';

const UTC_TIMEZONE = 'UTC';

const useGetTimezoneValues = () => {
  const timezones = [];
  const momentTimezones = moment.tz.names();

  momentTimezones.map((timezone) =>
    timezones.push({
      value: timezone,
      label: timezone.includes('GMT')
        ? timezone
        : `${timezone} (GMT${moment.tz(timezone).format('Z')})`,
    })
  );

  const userBrowserTimezone = timezones.find(
    (timezone) => timezone.value === moment.tz.guess()
  );
  const defaultUTCTimezone = timezones.find(
    (timezone) => timezone.value === UTC_TIMEZONE
  );

  return { timezones, userBrowserTimezone, defaultUTCTimezone };
};

export { useGetTimezoneValues };
