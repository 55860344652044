import React from 'react';
import {
  useEffect,
  useSelector,
  useSort,
  useState,
  useMemo,
  useQuery,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Table, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  PositionLoader,
  TableHeadWithCheckbox,
} from 'Components/components.js';
import { JobDetailsTable } from './components/components.js';
import {
  ColumnName,
  Status,
  RuleType,
  Dimension,
  FailedRows,
  Parameters,
  Threshold,
  Query,
  CopyQuery,
} from './libs/enums/enums.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { getJobRulesDTOFromResponse } from 'Pages/DataQuality/libs/mappers/mappers.js';
import { getComparator, stableSort } from 'utils/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';

const LIMIT_NUMBERS = 1000;
const PAGE_NUMBERS = 1;

const HEAD_CELLS = [
  ColumnName,
  Status,
  RuleType,
  Dimension,
  FailedRows,
  Parameters,
  Threshold,
  Query,
  CopyQuery,
];

const useStyles = makeStyles((theme) => ({
  table: {
    height: '100%',
    padding: 24,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
    overflow: 'scroll',
  },
}));

const JobDetailsModalBody = ({
  dataPlexJobId,
  onChangeJobRulesNumber,
  page,
  rowsPerPage,
}) => {
  const classes = useStyles();
  const { order, orderBy, handleRequestSort } = useSort(ColumnName.id);
  const [jobRules, setJobRules] = useState([]);

  const { currentScan } = useSelector((state) => ({
    currentScan: state.dataScans.dataScan,
  }));

  const { isFetching, data } = useQuery(
    [
      `${QUERY_TYPES.dataPlex}/locations/${currentScan.location}/dataScans/${currentScan.dataPlexId}/jobs/${dataPlexJobId}/rules`,
    ],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url, { limit: LIMIT_NUMBERS, page: PAGE_NUMBERS });
    },
    { enabled: !!currentScan }
  );

  useEffect(() => {
    if (data?.values.length) {
      setJobRules(data.values.map((item) => getJobRulesDTOFromResponse(item)));
      onChangeJobRulesNumber(data.values.length);
    }
  }, [data?.values, onChangeJobRulesNumber]);

  const sortedData = useMemo(() => {
    return stableSort(jobRules, getComparator(order, orderBy));
  }, [order, orderBy, jobRules]);

  const paginatedJobRules = useMemo(
    () =>
      sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [sortedData, page, rowsPerPage]
  );

  return (
    <div className={clsx(classes.table)}>
      {isFetching ? (
        <PositionLoader />
      ) : paginatedJobRules.length ? (
        <Table>
          <TableHeadWithCheckbox
            onRequestSort={handleRequestSort}
            headCells={HEAD_CELLS}
            selectedColumn={false}
          />
          <TableBody>
            {paginatedJobRules.map((item) => {
              return <JobDetailsTable rowData={item} key={item.id} />;
            })}
          </TableBody>
        </Table>
      ) : null}
    </div>
  );
};

export { JobDetailsModalBody };
