import React from 'react';
import { Redirect } from 'react-router-dom';
import { useEffect, useState, useHistory, useLocation } from 'hooks/hooks.js';
import clsx from 'clsx';
import { signInWithPopup } from 'firebase/auth';
import { makeStyles } from '@mui/styles';
import { auth, googleAuthProvider, amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { useUserInfo } from 'context/context.js';
import { Alert, PositionLoader } from 'Components/components.js';
import { ReactComponent as Logo } from 'assets/img/full-white-logo.svg';
import googleLogo from 'assets/img/googleIconBgd.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    gap: 32,
    borderRadius: 16,
    '&.headerContainer': {
      borderRadius: 16,
    },
  },
  googleBtn: {
    alignSelf: 'center',
    '& img': {
      width: 24,
      height: 24,
    },
  },
}));

export default function Login() {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const { authError, setAuthError } = useUserInfo();
  const signInGoogle = () => signInWithPopup(auth, googleAuthProvider);
  const { user, userLoading } = useUserInfo();
  const location = useLocation();
  const prevPath = location?.state?.from?.pathname;
  const isExistsRoute = Object.values(AppRoutes).some(
    (route) => route.path === prevPath
  );

  useEffect(() => {
    if (authError) {
      setError(authError);
      setAuthError(null);
    }
  }, [authError, setAuthError, setError]);

  const history = useHistory();
  const navigateToSignUp = () => history.push(AppRoutes.SignUp.path);

  if (user) {
    let url = '/';
    if (prevPath && isExistsRoute) {
      url = prevPath + location.state.from.search;
    }
    amplEvent('Login');
    return <Redirect to={url} />;
  }

  if (userLoading) {
    return (
      <div style={{ height: 300 }}>
        <PositionLoader />
      </div>
    );
  }

  return (
    <div className={clsx(classes.container, 'headerContainer')}>
      <Logo className={classes.logo} />

      <div className={classes.mainContent}>
        <h2 className='txt-mainDark-32-700 textWhiteColor mb-1'>
          Hi! Welcome back
        </h2>

        <Alert alertComponent={error} className='mt-4 mb-4' />

        <div className='txt-mainDark-16-500 textWhiteColor'>
          To start observing your Google Cloud environment, please use your
          <br />
          email that has GCP project admin rights.
        </div>
      </div>

      <button
        className={clsx(classes.googleBtn, 'google-btn')}
        onClick={signInGoogle}
      >
        <img src={googleLogo} alt='google' />
        <div>Sign in with Google</div>
      </button>

      <div className='bottomText txt-mainDark-13-700'>
        Don't have an account yet?{' '}
        <span
          className='txt-blue-13-700'
          style={{ cursor: 'pointer' }}
          onClick={navigateToSignUp}
        >
          Sign up
        </span>
      </div>
    </div>
  );
}
