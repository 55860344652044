import { useEffect, useGetProjects, useQuery, useState } from 'hooks/hooks.js';
import { useUserInfo } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants';

const useBqBillingData = () => {
  const { user } = useUserInfo();
  const [currentBqBillingData, setCurrentBqBillingData] = useState(null);
  const [isWelcome, setIsWelcome] = useState(true);

  const { projects } = useGetProjects();

  const { isFetching: isBqBillingDataFetching, data: bqBillingData } = useQuery(
    [QUERY_TYPES.bqBillingData],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    if (bqBillingData?.values && projects) {
      const projectBqBillingData = bqBillingData.values.find(
        (item) => item.project === user.info.currentProject
      );
      setCurrentBqBillingData(projectBqBillingData);
      setIsWelcome(
        bqBillingData.values.length !== projects.length || !projectBqBillingData
      );
    }
  }, [bqBillingData?.values, projects, user.info.currentProject]);

  return {
    currentBqBillingData,
    isWelcome,
    isBqBillingDataFetching,
    bqBillingData,
  };
};

export { useBqBillingData };
