import React from 'react';
import { useRef, useState, useOnClickOutside } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { ReactComponent as ArrowIcon } from 'assets/img/icons/arrows/arrow-long-down.svg';

const maxLength = 140;

export const useStyles = makeStyles((theme) => ({
  editCell: {
    width: '100%',
    margin: '16px 0',
    '& .editContainer': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      gap: 12,
      padding: 16,
      marginBottom: 4,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
      '& .editInput': {
        flexGrow: 1,
        border: 'none',
        '&:focus-visible': {
          outline: 'none',
        },
      },
      '& .editButton': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'end',
        width: 32,
        height: 32,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 12,
        border: 'none',
        '&--disabled': {
          backgroundColor: theme.palette.divider,
        },
      },
      '& .editButtonIcon': {
        transform: 'rotate(180deg)',
        '& path': {
          fill: theme.palette.common.white,
        },
      },
    },
  },
  showButton: {
    marginLeft: 8,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

const EditCell = ({ value, onEdit }) => {
  const ref = useRef();
  const classes = useStyles();
  const isLongValue = value.length > maxLength;
  const [isLongText, setIsLongText] = useState(isLongValue);
  const [isLoading, setIsLoading] = useState(false);
  const [isCellEdit, setIsCellEdit] = useState(false);
  const [newValue, setNewValue] = useState(value);
  const handleChangeLength = () => setIsLongText((prev) => !prev);
  const handleChangeValue = (e) => setNewValue(e.target.value);

  useOnClickOutside(ref, () => setIsCellEdit(false));

  const handleSave = () => {
    if (onEdit) {
      setIsLoading(true);
      onEdit(newValue).finally(() => {
        setIsCellEdit(false);
        setIsLoading(false);
      });
    }
  };

  const renderTextValue = () => {
    if (!isLongValue) {
      return <div>{value}</div>;
    }

    return (
      <>
        {isLongText ? (
          <div>
            {value.slice(0, maxLength)}
            <span
              className={clsx(classes.showButton, 'txt-blue-13-500')}
              onClick={handleChangeLength}
            >
              Show more
            </span>
          </div>
        ) : (
          <div>
            {value}
            <span
              className={clsx(classes.showButton, 'txt-blue-13-500')}
              onClick={handleChangeLength}
            >
              Show less
            </span>
          </div>
        )}
      </>
    );
  };

  const renderText = () => {
    return (
      <>
        {value ? (
          renderTextValue()
        ) : (
          <span className='txt-grey-13-500'>Double click to edit</span>
        )}
      </>
    );
  };

  const renderTextareaCell = () => {
    return (
      <div className={classes.editCell} ref={ref}>
        <form className='editContainer' onSubmit={(e) => e.preventDefault()}>
          <textarea
            className='editInput txt-mainDark-13-500'
            defaultValue={newValue}
            placeholder={!newValue.length ? 'Add description' : undefined}
            rows={3}
            onChange={handleChangeValue}
            disabled={isLoading}
          />
          <button
            className={clsx(isLoading && 'editButton--disabled', 'editButton')}
            onClick={handleSave}
            disabled={isLoading}
          >
            <ArrowIcon className='editButtonIcon' />
          </button>
        </form>
        <div className='txt-grey-13-500'>
          The description isn't saved in Google BigQuery
        </div>
      </div>
    );
  };

  return (
    <div onDoubleClick={() => setIsCellEdit(true)} className='tableCell'>
      {isCellEdit ? renderTextareaCell() : renderText()}
    </div>
  );
};

export { EditCell };
