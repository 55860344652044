import React from 'react';

const ChangeProjectModalBody = (projectName = '') => {
  return (
    <div
      className='txt-mainDark-13-500'
      style={{ textAlign: 'center', marginTop: 32 }}
    >
      You are about to open resource from a different project
      <br />
      <span className='u-accent'>{projectName}</span>. Continue?
    </div>
  );
};

export { ChangeProjectModalBody };
