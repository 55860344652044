const INTEGRATIONS = {
  slack: 'slack',
  jira: 'jira',
  looker: 'looker',
  dataplex: 'dataplex',
  dataform: 'dataform',
  pagerduty: 'pagerduty',
};

export { INTEGRATIONS };
