import React from 'react';
import { useMemo, useMutation, useQueryClient } from 'hooks/hooks.js';
import { useStyles } from 'Pages/Integrations/Integrations.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { amplEvent } from 'service/services.js';

const DISCONNECT_EVENT = 'PagerDuty -> Click Disconnect button ->';

const PagerDutyItem = ({ integrations }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { mutateAsync: cancelPagerDuty } = useMutation(
    () => fetcherPost(QUERY_TYPES.pagerDutyDisconnect),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_TYPES.pagerDuty),
    }
  );

  const onDisconnect = () => {
    cancelPagerDuty()
      .then(() => amplEvent(`${DISCONNECT_EVENT} success`))
      .catch(() => amplEvent(`${DISCONNECT_EVENT} error`));
  };

  const services = useMemo(() => {
    return integrations?.map((integration) => integration.name).join(', ');
  }, [integrations]);

  return (
    <div className={classes.cardItem}>
      <div className={classes.cardItemInfo}>
        <span className='txt-grey-13-500'>Services:</span>
        <span className='txt-mainDark-13-500'>{services}</span>
      </div>

      <Button
        text='Disconnect'
        variant={BUTTON_VARIANT.text}
        onClick={onDisconnect}
        fullWidth={false}
        className='p-0'
      />
    </div>
  );
};

export { PagerDutyItem };
