import { createSlice } from '@reduxjs/toolkit';
import { DATA_STATUS, SLICE_NAME } from 'constants/constants.js';
import {
  getLookerDictionary,
  getUniqueDashboards,
  getUniqueLooks,
  getUniqueDashboardElements,
  changeAlertType,
} from './actions.js';

const initialState = {
  lookerDictionaryDataStatus: DATA_STATUS.idle,
  lookerDictionaryData: [],
  uniqueDashboardsStatus: DATA_STATUS.idle,
  uniqueDashboards: [],
  uniqueLooksStatus: DATA_STATUS.idle,
  uniqueLooks: [],
  uniqueDashboardElementsStatus: DATA_STATUS.idle,
  uniqueDashboardElements: [],
  lookerNumber: 0,
  selectedAlertTypeStatus: DATA_STATUS.idle,
};

export const lookerSlice = createSlice({
  name: SLICE_NAME.looker,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLookerDictionary.pending, (state) => {
      state.lookerDictionaryDataStatus = DATA_STATUS.pending;
    });
    builder.addCase(getLookerDictionary.fulfilled, (state, action) => {
      state.lookerDictionaryData = action.payload.values;
      state.lookerNumber = action.payload.pagination.total;
      state.lookerDictionaryDataStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getLookerDictionary.rejected, (state) => {
      state.lookerDictionaryDataStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getUniqueDashboards.pending, (state) => {
      state.uniqueDashboardsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getUniqueDashboards.fulfilled, (state, action) => {
      state.uniqueDashboards = action.payload.values;
      state.uniqueDashboardsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getUniqueDashboards.rejected, (state) => {
      state.uniqueDashboardsStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getUniqueLooks.pending, (state) => {
      state.uniqueLooksStatus = DATA_STATUS.pending;
    });
    builder.addCase(getUniqueLooks.fulfilled, (state, action) => {
      state.uniqueLooks = action.payload.values;
      state.uniqueLooksStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getUniqueLooks.rejected, (state) => {
      state.uniqueLooksStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getUniqueDashboardElements.pending, (state) => {
      state.uniqueDashboardElementsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getUniqueDashboardElements.fulfilled, (state, action) => {
      state.uniqueDashboardElements = action.payload.values;
      state.uniqueDashboardElementsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getUniqueDashboardElements.rejected, (state) => {
      state.uniqueDashboardElementsStatus = DATA_STATUS.rejected;
    });

    builder.addCase(changeAlertType.pending, (state) => {
      state.selectedAlertTypeStatus = DATA_STATUS.pending;
    });
    builder.addCase(changeAlertType.fulfilled, (state, action) => {
      state.selectedAlertTypeStatus = DATA_STATUS.fulfilled;

      const selectedReportIds = action.payload.selected.map(
        (report) => report.id
      );
      state.lookerDictionaryData = state.lookerDictionaryData.map((report) => {
        if (selectedReportIds.includes(report.id)) {
          return { ...report, alertType: action.payload.selectedAlertType };
        }

        return report;
      });
    });
    builder.addCase(changeAlertType.rejected, (state) => {
      state.selectedAlertTypeStatus = DATA_STATUS.rejected;
    });
  },
});

export default lookerSlice.reducer;
