import { STATUS_FILTER_OPTIONS } from 'constants/constants.js';
import { ALERT_FILTER_OPTIONS } from 'utils/constants.js';

const filterStatusStateOptions = (stateFilterIds) => {
  return STATUS_FILTER_OPTIONS.filter(({ value }) =>
    stateFilterIds.includes(value)
  );
};

const filterAlertStateOptions = (stateFilterAlertIds) => {
  if (stateFilterAlertIds.length === ALERT_FILTER_OPTIONS.length - 1) {
    return [ALERT_FILTER_OPTIONS[0]];
  }

  return ALERT_FILTER_OPTIONS.filter(({ value }) =>
    stateFilterAlertIds.includes(value)
  );
};

const filterLookerAlertStateOptions = (stateFilterLookerAlertIds) => {
  return ALERT_FILTER_OPTIONS.filter(({ value }) =>
    stateFilterLookerAlertIds.includes(value)
  );
};

export {
  filterStatusStateOptions,
  filterAlertStateOptions,
  filterLookerAlertStateOptions,
};
