import React from 'react';
import {
  useMemo,
  useEffect,
  useState,
  useRef,
  useQueryClient,
} from 'hooks/hooks.js';
import { AsyncPaginate } from 'react-select-async-paginate';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import {
  createDatasetOption,
  createNewOption,
  createTableOption,
  getCustomStyles,
} from './libs/helpers/helpers.js';
import { getFullTableName, splitWithRemainder } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import Search from 'assets/img/search.svg';

const EVENT_KEY_ENTER = 'Enter';
const SEARCH_DEBOUNCE_DELAY = 500;
const SEARCH_LIMIT = 100;
const SEARCH_ITEM_HEIGHT = 36;
const SEARCH_STALE_TIME = 5 * 60 * 1000;

const TableDatasetSearch = ({
  optionLabel = 'Search for:',
  className = '',
  defaultDataset,
  defaultTable,
  defaultSearchValue,
  onDatasetChange = () => {},
  onTableChange = () => {},
  onSearch = () => {},
  withDatasets = true,
  disableDatasets = false,
  withSearch = true,
  placeholderName = 'Start typing table name...',
  searchWithDropdown = false,
}) => {
  const selectRef = useRef();
  const customStyles = useMemo(
    () => getCustomStyles(optionLabel, searchWithDropdown),
    [optionLabel, searchWithDropdown]
  );
  const queryClient = useQueryClient();

  const [searchSelectedOption, setSearchSelectedOption] = useState(null);

  const getQueryParams = (dataset, table, search) => {
    let datasetParam = '';
    let tableParam = '';
    let searchTableParam = '';

    if (search) {
      const splitValue = splitWithRemainder(search, '.', 2);
      if (splitValue.length === 1) {
        searchTableParam = splitValue[0];
      } else {
        datasetParam = splitValue[0];
        searchTableParam = splitValue[1];
      }
    } else {
      datasetParam = dataset;
      tableParam = table;
    }

    return {
      dataset: datasetParam || '',
      table: tableParam || '',
      searchTable: searchTableParam || '',
    };
  };

  const prepareOptions = (options, loadedOptions) => {
    return options.reduce((newOptions, item) => {
      if (
        withDatasets &&
        !newOptions.findLast((o) => o.value === item.dataset) &&
        !loadedOptions.findLast((o) => o.value === item.dataset)
      ) {
        newOptions.push(createDatasetOption(item, disableDatasets));
      }
      newOptions.push(createTableOption(item, withDatasets));
      return newOptions;
    }, []);
  };

  const loadOptions = async (search, loadedOptions, { page }) => {
    const queryParams = search
      ? getQueryParams('', '', search)
      : getQueryParams(defaultDataset, defaultTable, defaultSearchValue);

    const response = await queryClient.fetchQuery(
      [
        QUERY_TYPES.tables,
        queryParams.dataset,
        queryParams.table,
        queryParams.searchTable,
        SEARCH_LIMIT,
        page,
      ],
      ({ queryKey }) => {
        const [url, dataset, table, searchTable, limit, page] = queryKey;
        return fetcherGet(url, { dataset, table, searchTable, limit, page });
      },
      { staleTime: SEARCH_STALE_TIME }
    );
    if (!response?.values || !response?.pagination) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: page,
        },
      };
    }

    const hasMore = response.pagination.total > page * SEARCH_LIMIT;
    const newOptions = prepareOptions(response.values, loadedOptions);

    return {
      options: newOptions,
      hasMore: hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const selectDataset = (dataset, initChange = false) => {
    const option = {
      label: dataset,
      value: dataset,
    };
    if (option.value === searchSelectedOption?.value) {
      return;
    }
    setSearchSelectedOption(option);
    if (initChange) {
      onDatasetChange(dataset);
    }
  };

  const selectTable = (dataset, table, item = null, initChange = false) => {
    const option = {
      label: getFullTableName(table, dataset),
      value: getFullTableName(table, dataset),
    };
    if (option.value === searchSelectedOption?.value) {
      return;
    }
    setSearchSelectedOption(option);
    if (initChange && item) {
      onTableChange(item);
    }
  };

  const selectSearch = (search, initChange = false) => {
    const option = createNewOption(search);
    if (option.value === searchSelectedOption?.value) {
      return;
    }
    setSearchSelectedOption(option);
    if (initChange) {
      onSearch(search);
    }
  };

  const selectNull = (initChange = false) => {
    const option = null;
    if (option === searchSelectedOption) {
      return;
    }
    setSearchSelectedOption(option);
    if (initChange) {
      onTableChange(null);
    }
  };

  const onChange = (option) => {
    if (option?.value === searchSelectedOption?.value) {
      return;
    }

    if (option?.item) {
      if (option?.isDataset) {
        selectDataset(option.item.dataset, true);
      } else {
        selectTable(option.item.dataset, option.item.table, option.item, true);
      }
    } else {
      selectNull(true);
    }
  };

  const onKeyDown = (e) => {
    const inputValue = selectRef.current?.inputRef?.value;
    if (withSearch && e.key === EVENT_KEY_ENTER && inputValue) {
      e.preventDefault();
      selectSearch(inputValue, true);
      blurSelect();
    }
  };

  useEffect(() => {
    if (defaultDataset && defaultTable) {
      selectTable(defaultDataset, defaultTable);
    } else if (defaultDataset) {
      selectDataset(defaultDataset);
    } else if (defaultSearchValue) {
      selectSearch(defaultSearchValue);
    } else {
      selectNull();
    }
  }, [defaultDataset, defaultTable, defaultSearchValue]);

  useEffect(() => {
    if (defaultDataset && defaultTable) {
      queryClient
        .fetchQuery(
          [QUERY_TYPES.tables, defaultDataset, defaultTable],
          ({ queryKey }) => {
            const [url, dataset, table] = queryKey;
            return fetcherGet(url, {
              dataset: dataset,
              table: table,
              searchTable: '',
              limit: 1,
              page: 1,
            });
          }
        )
        .then((response) => {
          if (response?.values) {
            onTableChange(response.values[0]);
          }
        });
    }
  }, []);

  const blurSelect = () => selectRef.current?.blur();

  const shouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder =
      scrollHeight - clientHeight - (SEARCH_LIMIT * SEARCH_ITEM_HEIGHT) / 2;
    return bottomBorder < scrollTop;
  };

  return (
    <AsyncPaginate
      key={JSON.stringify([defaultDataset, defaultTable, defaultSearchValue])}
      selectRef={selectRef}
      styles={customStyles}
      className={clsx(className)}
      placeholder={
        <div>
          <Image src={Search} width={20} height={20} />
          <span className='ms-2 txt-grey-13-500'>{placeholderName}</span>
        </div>
      }
      isClearable={true}
      onKeyDown={onKeyDown}
      onChange={onChange}
      loadOptions={loadOptions}
      shouldLoadMore={shouldLoadMore}
      debounceTimeout={SEARCH_DEBOUNCE_DELAY}
      additional={{ page: 1 }}
      value={searchSelectedOption}
    />
  );
};

export { TableDatasetSearch };
