import React from 'react';
import { useState, useCallback, useMemo, useEffect } from 'hooks/hooks.js';
import {
  Select,
  InputWithAction,
  RightSideModal,
} from 'Components/components.js';
import { useModal } from 'context/context.js';
import { SelectTimestampColumnModalBody } from '../components.js';
import {
  INCREMENTAL_SCOPE,
  SCOPE_OPTIONS,
  CONTROL_ELEMENTS_DATA,
} from '../../libs/constants/constants.js';

const ScopeSelect = ({ table = '', dataset = '', changeScopeData }) => {
  const { setModal } = useModal();
  const [scopeValue, setScopeValue] = useState(SCOPE_OPTIONS[1]);
  const { scopeSelect, timestampInput } = CONTROL_ELEMENTS_DATA;
  const [timestampColumn, setTimestampColumn] = useState(null);

  const handleChangeScope = useCallback((value) => {
    setScopeValue(value);
    setTimestampColumn('');
  }, []);

  const handleChangeTimestampColumn = useCallback((value) => {
    setTimestampColumn(value);
  }, []);

  const isDisabledSelectColumnButton = useMemo(() => {
    return !table.length || !dataset.length;
  }, [table, dataset]);

  useEffect(() => {
    setTimestampColumn(null);
  }, [table, dataset]);

  const openSelectTable = useCallback(() => {
    setModal(() => (
      <RightSideModal
        title='Select column'
        ModalBodyComponent={SelectTimestampColumnModalBody}
        getSelectedValue={handleChangeTimestampColumn}
        selectedColumn={timestampColumn}
        mainBlockMargin={0}
        table={table}
        dataset={dataset}
      />
    ));
  }, [setModal, handleChangeTimestampColumn, timestampColumn, table, dataset]);

  useEffect(() => {
    changeScopeData(timestampColumn?.name, scopeValue.value);
  }, [changeScopeData, scopeValue?.value, timestampColumn]);

  return (
    <>
      <Select
        value={scopeValue}
        onChange={handleChangeScope}
        options={SCOPE_OPTIONS}
        label={scopeSelect.label}
        isRequired={scopeSelect.isRequired}
        id={scopeSelect.id}
      />

      {scopeValue.value === INCREMENTAL_SCOPE && (
        <InputWithAction
          value={timestampColumn?.name}
          onButtonClick={openSelectTable}
          label={timestampInput.label}
          id={timestampInput.id}
          isRequired={timestampInput.isRequired}
          isDisabledButton={isDisabledSelectColumnButton}
        />
      )}
    </>
  );
};

export { ScopeSelect };
