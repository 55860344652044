import React from 'react';
import {
  useCallback,
  usePipelineTags,
  useState,
  useTableLabels,
} from 'hooks/hooks.js';
import { TableLabelsChips, PipelineTagsChips } from 'Components/components.js';
import {
  IncidentCard,
  MetaDataItem,
} from '../../GlobalPageComponents/components.js';
import { PipelineIncidentTitle } from './components.js';
import { MH_PIPELINE_PREFIX } from 'utils/constants.js';
import { IncidentTabs } from 'constants/constants.js';

const PipelineIncidentCard = ({
  groupIncident,
  startDate,
  endDate,
  incidentIdAndType,
  incidentStatuses,
  tableInfo,
}) => {
  const [currentIncident, setCurrentIncident] = useState(null);

  const { tableLabels } = useTableLabels(
    groupIncident.table,
    groupIncident.dataset
  );

  const { pipelineTags, isFetchingTags } = usePipelineTags({
    jobHash: groupIncident.jobHash,
  });

  const handleChangeCurrentIncident = useCallback((incident) => {
    setCurrentIncident(incident);
  }, []);

  return (
    <IncidentCard
      groupIncident={groupIncident}
      startDate={startDate}
      endDate={endDate}
      incidentIdAndType={incidentIdAndType}
      incidentStatuses={incidentStatuses}
      onChangeCurrentIncident={handleChangeCurrentIncident}
      tableInfo={tableInfo}
      isFetchingExternalData={isFetchingTags}
      currentTab={IncidentTabs.PIPELINE}
      pipelineTags={pipelineTags}
    >
      <PipelineIncidentTitle groupIncident={groupIncident} />
      {currentIncident && (
        <div>
          {tableLabels.length > 0 && (
            <MetaDataItem label='Table labels' isLargeGap={true}>
              <TableLabelsChips tableLabels={tableLabels} />
            </MetaDataItem>
          )}
          {pipelineTags.length > 0 && (
            <MetaDataItem label='Pipeline tags / labels' isLargeGap={true}>
              <PipelineTagsChips pipelineTags={pipelineTags} />
            </MetaDataItem>
          )}
          {groupIncident.project && (
            <MetaDataItem label='Table Project' isLargeGap={true}>
              {groupIncident.project}
            </MetaDataItem>
          )}
          {groupIncident.pipelineProject && (
            <MetaDataItem label='Pipeline Project' isLargeGap={true}>
              {groupIncident.pipelineProject}
            </MetaDataItem>
          )}
          {groupIncident.dataset &&
            !groupIncident.dataset.includes(MH_PIPELINE_PREFIX) && (
              <MetaDataItem label='Dataset' isLargeGap={true}>
                {groupIncident.dataset}
              </MetaDataItem>
            )}
          {groupIncident.principalEmail && (
            <MetaDataItem label='Email' isLargeGap={true}>
              {groupIncident.principalEmail}
            </MetaDataItem>
          )}
        </div>
      )}
    </IncidentCard>
  );
};

export { PipelineIncidentCard };
