import { useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { Button, BUTTON_COLOR, BUTTON_VARIANT } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { BANNERS_TYPE, DictionaryTabs } from 'constants/constants.js';
import { useBanner } from 'context/context.js';
import { ReactComponent as CriticalIcon } from 'assets/img/alert/critical.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },
  title: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '& svg path': {
      fill: theme.palette.warning.main,
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: theme.spacing(4),
  },
}));

const PriorityTablesBanner = () => {
  const classes = useStyles();
  const [isClosedBanner, setIsClosedBanner] = useState(false);
  const { handleCloseBanner, isShowBanner, banners } = useBanner();
  const priorityTablesBanner = banners?.find(
    (item) => item.type === BANNERS_TYPE.priorityTables
  );
  const existingBanner = priorityTablesBanner
    ? isShowBanner(priorityTablesBanner)
    : false;

  const path = {
    pathname: AppRoutes.Dictionary.path,
    search: new URLSearchParams({
      tab: DictionaryTabs.TABLES,
    }).toString(),
    state: {
      isSuggestedPriority: true,
    },
  };

  const handleClose = () => {
    const onClose = handleCloseBanner(BANNERS_TYPE.priorityTables);
    onClose();
    setIsClosedBanner(true);
  };

  return (
    <>
      {existingBanner && !isClosedBanner ? (
        <div className={classes.container}>
          <div>
            <div className={classes.title}>
              <CriticalIcon width={24} height={24} />
              <p className='txt-mainDark-20-700'>
                The list of tables that needs attention
              </p>
            </div>

            <p className='txt-grey-13-500'>
              Review suggested tables in Dictionary and mark them Critical if
              necessary. Keep your data in order.
            </p>
          </div>
          <div className={classes.buttonsContainer}>
            <Button
              text='Close'
              variant={BUTTON_VARIANT.text}
              onClick={handleClose}
            />
            <Link to={path}>
              <Button text='Review tables' color={BUTTON_COLOR.secondary} />
            </Link>
          </div>
        </div>
      ) : null}
    </>
  );
};

export { PriorityTablesBanner };
