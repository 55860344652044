import { useState, useMutation } from 'hooks/hooks.js';
import { useMessages, useUserInfo } from 'context/context.js';
import { fetcherPost } from 'utils/utils.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';

const EVENT_BUTTON = 'Change project ->';

const useChangeProject = () => {
  const { currentProject } = useUserInfo();
  const { setMessage } = useMessages();
  const [selectedProject, setSelectedProject] = useState(currentProject);

  const { mutateAsync: updateProject } = useMutation(
    (data) => fetcherPost('/api/v1/current-project', data),
    {
      onSuccess: (data) => {
        if (!data?.success) {
          setMessage('Got an error while changing current project');
          amplEvent(`${EVENT_BUTTON} error`);
        } else {
          window.location.replace(AppRoutes.Dashboard.path);
          amplEvent(`${EVENT_BUTTON} success`);
        }
      },
    }
  );

  const changeProject = (project) => {
    if (project === currentProject) {
      return;
    }

    updateProject({ value: project }).then(() => {
      setSelectedProject(project);
      amplEvent(`${EVENT_BUTTON} ${project}`);
    });
  };

  return { selectedProject, changeProject };
};

export { useChangeProject };
