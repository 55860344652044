import React from 'react';
import { useHistory, useCallback } from 'hooks/hooks.js';
import { EmptyBlock, GraphPipes } from 'Components/components.js';
import Widget from 'Components/Widget/Widget.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import { EMPTY_DATA_INFO, EXISTING_DATA_TYPES } from '../../libs/constants.js';

const LineageWidget = ({
  table,
  dataset,
  isEmptyLineage,
  toLineagePage,
  onChangeExistingData,
}) => {
  const history = useHistory();
  const { lineage } = getWidgetLabel();

  const handleChangeExistingLineage = useCallback((isExistingLineage) => {
    onChangeExistingData(EXISTING_DATA_TYPES.lineage, isExistingLineage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToLineagePage = useCallback(() => {
    history.push({
      pathname: AppRoutes.Lineage.path,
      search: new URLSearchParams({
        dataset,
        table,
      }).toString(),
      state: { ...history.location.state },
    });
  }, [history, dataset, table]);

  return (
    <Widget
      label={lineage.title}
      secondaryLabel={lineage.subtitle}
      toPage={toLineagePage(
        AppRoutes.Lineage.path,
        { ...history.location.state },
        'View in Lineage',
        lineage.title
      )}
      initialSettings={{ size: 'L', height: 'auto' }}
      hidden={true}
    >
      {isEmptyLineage ? (
        <EmptyBlock children={EMPTY_DATA_INFO.emptyTableLineage} />
      ) : (
        <div onClick={navigateToLineagePage} style={{ cursor: 'pointer' }}>
          <div style={{ pointerEvents: 'none' }}>
            <GraphPipes
              table={table}
              dataset={dataset}
              setExistingLineage={handleChangeExistingLineage}
              containerHeight={196}
              isOpened={false}
            />
          </div>
        </div>
      )}
    </Widget>
  );
};

export { LineageWidget };
