import { ALERT_FILTER_OPTIONS, ALERT_TYPES_OPTIONS } from '../constants.js';

const getAlertLabel = (alertType) => {
  return ALERT_FILTER_OPTIONS.find((item) => item.value === alertType).label;
};

const getAlertColorType = (alertType) => {
  return ALERT_TYPES_OPTIONS.find((item) => item.value === alertType).colorType;
};

const getAlertIcon = (alertType) => {
  return ALERT_TYPES_OPTIONS.find((item) => item.value === alertType).iconSvg;
};

export { getAlertLabel, getAlertColorType, getAlertIcon };
