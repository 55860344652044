import { useState } from 'hooks/hooks.js';

const useBooleanToggle = (defaultState = false) => {
  const [booleanState, setBooleanState] = useState(defaultState);

  const handleChangeBooleanState = (newState = null) => {
    if (newState !== null) {
      setBooleanState(newState);
    } else {
      setBooleanState((prevState) => !prevState);
    }
  };

  return { booleanState, handleChangeBooleanState };
};

export { useBooleanToggle };
