import { INCIDENT_FILTER_OPTIONS } from 'constants/constants.js';
import { EMPTY_DATA } from 'Pages/Incidents/libs/constants/constants.js';

const excludedIncidentOptionsByStatuses = (excludedStatuses) =>
  INCIDENT_FILTER_OPTIONS.filter(
    (item) => excludedStatuses.includes(item.value) === false
  );

export const showStatus = (incidentOptions, status) =>
  incidentOptions.find((item) => item.value === status)?.label || '';

const showQueryOrSourceUris = (query, sourceUris) => {
  if (query.length) {
    return query;
  }
  if (sourceUris && sourceUris.length) {
    return sourceUris.join(', ');
  }
  return EMPTY_DATA;
};

export { excludedIncidentOptionsByStatuses, showQueryOrSourceUris };
