import { makeStyles } from '@mui/styles';
import { ReactComponent as FireIcon } from 'assets/img/fireIcon.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 12px',
    gap: 8,
    background: 'rgba(5, 12, 46, 0.4)',
    borderRadius: 4,

    '& .textWhiteColor': {
      color: theme.palette.common.white,
    },
  },
}));

const HeaderInfoContainer = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FireIcon />
      <div className='txt-mainDark-13-700 textWhiteColor'>
        14 days free. No card required
      </div>
    </div>
  );
};

export { HeaderInfoContainer };
