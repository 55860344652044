import React from 'react';
import { useMemo, useSelector } from 'hooks/hooks.js';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { PositionLoader } from 'Components/components.js';
import { getFullTableName, getStringByCron } from 'utils/helpers/helpers.js';
import { useUserInfo } from 'context/context.js';
import {
  ALL_DATA_SAMPLING_SIZE,
  ENTIRE_DATA_SCOPE,
  INCREMENTAL_SCOPE,
  SCHEDULE_TYPE,
} from 'Pages/DataQuality/libs/constants/constants.js';
import { DATA_STATUS } from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 24,
    display: 'flex',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
  },
  item: {
    flex: '1 1 0',
  },
}));

const DetailsTab = () => {
  const classes = useStyles();
  const { currentProject } = useUserInfo();

  const { dataScan, dataScanStatus } = useSelector((state) => ({
    dataScan: state.dataScans.dataScan,
    dataScanStatus: state.dataScans.dataScanStatus,
  }));

  const isLoadingScan = useMemo(() => {
    return dataScanStatus === DATA_STATUS.pending;
  }, [dataScanStatus]);

  const scanName = useMemo(() => {
    if (dataScan) {
      return `projects/${currentProject}/locations/${dataScan.location}/dataScans/${dataScan.dataPlexId}`;
    }

    return '-';
  }, [currentProject, dataScan]);

  const scheduleCronToText = useMemo(() => {
    if (!!dataScan?.scheduleCron) {
      const cronValue = dataScan.scheduleCron.substring(
        dataScan.scheduleCron.indexOf(' ') + 1
      );
      return getStringByCron(cronValue);
    }

    return SCHEDULE_TYPE.ON_DEMAND;
  }, [dataScan?.scheduleCron]);

  return (
    <>
      {isLoadingScan ? (
        <PositionLoader />
      ) : (
        <div className={classes.container}>
          <div className={classes.item}>
            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Scan name</span>{' '}
              <span className='txt-grey-13-500'>{scanName}</span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Scan ID</span>{' '}
              <span className='txt-grey-13-500'>{dataScan?.dataPlexId}</span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Table name</span>{' '}
              <span className='txt-grey-13-500'>
                {getFullTableName(dataScan?.table, dataScan?.dataset)}
              </span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Created on</span>{' '}
              <span className='txt-grey-13-500'>
                {moment(dataScan?.createTime)
                  .utc()
                  .format('MMMM Do YYYY, h:mm:ss a') || '-'}
              </span>
            </div>

            <div>
              <span className='txt-mainDark-13-500'>Last updated</span>{' '}
              <span className='txt-grey-13-500'>
                {moment(dataScan?.updateTime)
                  .utc()
                  .format('MMMM Do YYYY, h:mm:ss a') || '-'}
              </span>
            </div>
          </div>

          <div className={classes.item}>
            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Scope</span>{' '}
              <span className='txt-grey-13-500'>
                {dataScan?.incrementalColumn
                  ? INCREMENTAL_SCOPE
                  : ENTIRE_DATA_SCOPE}
              </span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Incremental column</span>{' '}
              <span className='txt-grey-13-500'>
                {dataScan?.incrementalColumn || 'N/A'}
              </span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Row filter</span>{' '}
              <span className='txt-grey-13-500'>
                {dataScan?.rowFilter || '-'}
              </span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Schedule</span>{' '}
              <span className='txt-grey-13-500'>{scheduleCronToText}</span>
            </div>

            <div className='mb-1'>
              <span className='txt-mainDark-13-500'>Sampling size</span>{' '}
              <span className='txt-grey-13-500'>
                {dataScan?.samplingPercent || ALL_DATA_SAMPLING_SIZE}%
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { DetailsTab };
