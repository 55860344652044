import axios from 'axios';

const DATAFORM_SERVICE_ACCOUNT =
  'masthead-dataform@masthead-prod.iam.gserviceaccount.com';
const DATAFORM_PUBSUB_TOPIC = 'masthead-dataform-topic';
const DATAFORM_PUBSUB_SUBSCRIPTION = 'masthead-dataform-subscription';
const DATAFORM_PUBSUB_SINK = 'masthead-dataform-sink';
const MESSAGE_RETENTION_DURATION = 3600; // 1h
const ACK_DEADLINE_SECONDS = 60;

const gcpPost = (url, token, data, params) =>
  axios
    .post(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const gcpPut = (url, token, data, params) =>
  axios
    .put(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const dataFormCreator = {
  async addRoleToMastheadSA(project, token) {
    return await this.addRoles(
      project,
      token,
      `serviceAccount:${DATAFORM_SERVICE_ACCOUNT}`,
      ['roles/pubsub.subscriber', 'roles/dataform.viewer']
    );
  },

  async addRoles(project, token, serviceAccount, roles) {
    const iam = await this.readIAM(project, token);
    const bRoles = iam.bindings.map((b) => b.role);
    for (const role of roles) {
      if (!bRoles.includes(role)) {
        iam.bindings.push({
          role: role,
          members: [serviceAccount],
        });
      } else {
        const currentB = iam.bindings.find((b) => b.role === role);
        currentB.members = currentB.members.concat(serviceAccount);
      }
    }

    return await this.writeIAM(project, token, iam);
  },

  async readIAM(project, token) {
    return gcpPost(
      `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:getIamPolicy`,
      token,
      {
        options: {
          requestedPolicyVersion: 3,
        },
      }
    );
  },

  async writeIAM(
    project,
    token,
    policy,
    currentRequest = 1,
    countRequests = 20
  ) {
    try {
      return await gcpPost(
        `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:setIamPolicy`,
        token,
        {
          policy: policy,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'IAM exist';
      }
      if (currentRequest <= countRequests) {
        return this.writeIAM(project, token, policy, currentRequest + 1);
      } else {
        throw e;
      }
    }
  },

  async createTopic(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/topics/${DATAFORM_PUBSUB_TOPIC}`,
        token,
        {}
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Topic exist';
      }
      throw e;
    }
  },

  async createSink(project, token) {
    try {
      const sink = await gcpPost(
        `https://logging.googleapis.com/v2/projects/${project}/sinks`,
        token,
        {
          name: DATAFORM_PUBSUB_SINK,
          destination: `pubsub.googleapis.com/projects/${project}/topics/${DATAFORM_PUBSUB_TOPIC}`,
          description: `Masthead Dataform log sink`,
          filter: `protoPayload.serviceName="dataform.googleapis.com" OR resource.type="dataform.googleapis.com/Repository"`,
        }
      );

      await this.addRoles(project, token, sink.writerIdentity, [
        'roles/pubsub.publisher',
      ]);

      return sink;
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Sink exist';
      }
      throw e;
    }
  },

  async createSubscription(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/subscriptions/${DATAFORM_PUBSUB_SUBSCRIPTION}`,
        token,
        {
          topic: `projects/${project}/topics/${DATAFORM_PUBSUB_TOPIC}`,
          messageRetentionDuration: `${MESSAGE_RETENTION_DURATION}s`,
          ackDeadlineSeconds: ACK_DEADLINE_SECONDS,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Subscription exist';
      }
      throw e;
    }
  },

  async create(project, token) {
    const results = [];
    results.push(
      await this.createTopic(project, token),
      await this.createSink(project, token),
      await this.createSubscription(project, token),
      await this.addRoleToMastheadSA(project, token)
    );

    return results;
  },
};

export { dataFormCreator };
