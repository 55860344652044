const JobStatuses = {
  STATE_UNSPECIFIED: 'STATE_UNSPECIFIED',
  RUNNING: 'RUNNING',
  CANCELING: 'CANCELING',
  CANCELLED: 'CANCELLED',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  PENDING: 'PENDING',
};

const JOBS_LIMIT = 7;

export { JobStatuses, JOBS_LIMIT };
