import React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useSort,
  useSyncTableToSearchParam,
  useSelectedElements,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { amplEvent } from 'service/services.js';
import {
  Toolbar,
  Modal,
  GeneralSearch,
  TableHeadWithCheckbox,
  MutedElementModalBody,
  CriticalElementModalBody,
} from 'Components/components.js';
import { useStyles } from '../../Dictionary.styles.js';
import {
  ALERT_FILTER_OPTIONS,
  CRITICAL_ALERT_TYPE,
  MUTED_ALERT_TYPE,
} from 'utils/constants.js';
import {
  AMPL_PAGE_EVENT,
  QUERY_TYPES,
  DictionaryTabs,
} from 'constants/constants.js';
import { useModal, useUserInfo } from 'context/context.js';
import { DictionaryDatasetDetails } from './components/components.js';
import { getComparator, stableSort } from 'utils/helpers/helpers.js';
import {
  DatasetNameHead,
  AlertStatusHead,
  DescriptionHead,
} from './enums/enums.js';

const HEAD_CELLS = [DatasetNameHead, AlertStatusHead, DescriptionHead];

const filterDataset = (
  data,
  typeFiltersAlert,
  searchDatasetName,
  datasetName
) => {
  if (!data) {
    return [];
  }

  const typeFilterAlertValues = typeFiltersAlert.map(({ value }) => value);

  if (
    typeFilterAlertValues.length === 1 &&
    typeFilterAlertValues[0] === '' &&
    !searchDatasetName &&
    !datasetName
  )
    return data;

  return data.filter(({ alertType, name }) => {
    return (
      ((typeFilterAlertValues.length === 1 &&
        typeFilterAlertValues[0] === '') ||
        typeFilterAlertValues.includes(alertType)) &&
      (!searchDatasetName || name.includes(searchDatasetName)) &&
      (!datasetName || name === datasetName)
    );
  });
};

const DictionaryDataset = ({
  onDatasetClick,
  filterAlert,
  setFilterAlert,
  page,
  rowsPerPage,
  handleChangePage,
  onReceiveTotalNumber,
  onChangeBackdrop,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [searchDatasetName, setSearchDatasetName] = useState('');
  const [datasetName, setDatasetName] = useState('');
  const [tableDatasets, setTableDatasets] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [actionText, setActionText] = useState('');
  const { tableFromURL, searchFromUrl, clearSearchParams } =
    useSyncTableToSearchParam(
      null,
      datasetName,
      searchDatasetName,
      DictionaryTabs.DATASETS
    );
  const { user } = useUserInfo();
  const { order, orderBy, handleRequestSort } = useSort(DatasetNameHead.id);

  const { isFetching, data } = useQuery(
    [QUERY_TYPES.datasets],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  useEffect(() => {
    onChangeBackdrop(isFetching);

    if (data?.values) {
      setTableDatasets(data.values);
    }
  }, [data, isFetching, onChangeBackdrop]);

  useEffect(() => {
    if (tableDatasets?.length) {
      setSearchOptions(
        tableDatasets.map((item) => {
          return { label: item.name, value: item.name };
        })
      );
    }
  }, [tableDatasets]);

  useEffect(
    () => handleChangePage({}, 0),
    [handleChangePage, filterAlert, order, orderBy, searchDatasetName]
  );

  useEffect(() => {
    if (datasetName.length) {
      amplEvent(
        `${AMPL_PAGE_EVENT.dictionary} -> Tab Dataset -> selected dataset -> ${datasetName}`
      );
    }
  }, [datasetName]);

  const filteredData = useMemo(() => {
    return stableSort(
      filterDataset(tableDatasets, filterAlert, searchDatasetName, datasetName),
      getComparator(order, orderBy)
    );
  }, [
    filterAlert,
    order,
    orderBy,
    searchDatasetName,
    datasetName,
    tableDatasets,
  ]);

  const paginatedData = useMemo(
    () =>
      filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredData, page, rowsPerPage]
  );

  const {
    selected,
    clearSelected,
    handleSelectAllClick,
    isSelected,
    handleClickElement,
  } = useSelectedElements(paginatedData);
  const { setModal } = useModal();

  const { mutateAsync: sendAlertType } = useMutation(
    (type) => {
      return Promise.all(
        selected.map((item) =>
          fetcherPost(
            `/api/v1/datasets/alert?project=${user.info.currentProject}&dataset=${item.name}`,
            { type }
          )
        )
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(QUERY_TYPES.datasets);
        setActionText(
          selected.length === 1
            ? `${selected.length} dataset is ${variables.toLowerCase()}`
            : `${selected.length} datasets are ${variables.toLowerCase()}`
        );
        setTimeout(() => {
          setActionText('');
        }, 3000);
        clearSelected();
      },
    }
  );

  const onTypeChange = (selectedItem) => {
    if (selected.some((item) => item.alertType === CRITICAL_ALERT_TYPE)) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(selectedItem)}
          title="Are you sure about changing the critical dataset's status?"
          titlePosition='center'
          ModalBodyComponent={CriticalElementModalBody}
          agreeButtonText='OK'
        />
      ));
    } else if (selectedItem === MUTED_ALERT_TYPE) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(selectedItem)}
          title='Are you sure you want to mute the dataset?'
          titlePosition='center'
          ModalBodyComponent={MutedElementModalBody}
          element='dataset'
          agreeButtonText='OK'
        />
      ));
    } else {
      sendAlertType(selectedItem);
    }
  };

  useEffect(() => {
    if (tableFromURL === null) {
      setDatasetName('');
    }
  }, [tableFromURL]);

  useEffect(() => {
    if (searchFromUrl === null) {
      setSearchDatasetName('');
    }
  }, [searchFromUrl]);

  const onDatasetChange = useCallback(
    (value) => {
      setFilterAlert([ALERT_FILTER_OPTIONS[0]]);
      if (!value) clearSearchParams();
    },
    [clearSearchParams, setFilterAlert]
  );
  const handleDatasetSearch = (value) => {
    onDatasetChange(value);
    setSearchDatasetName(value || '');
    setDatasetName('');
  };
  const handleDatasetSelect = (value) => {
    onDatasetChange(value);
    setDatasetName(value || '');
    setSearchDatasetName('');
  };

  const updateDataset = (dataset) => {
    setTableDatasets((prev) =>
      prev.map((it) => {
        if (it.name === dataset.name) {
          return dataset;
        }
        return it;
      })
    );
  };

  useEffect(() => {
    onReceiveTotalNumber(filteredData?.length);
  }, [filteredData?.length, onReceiveTotalNumber]);

  return (
    <>
      <div className={classes.searchContainer}>
        <GeneralSearch
          optionLabel='Dictionary for:'
          options={searchOptions}
          onSearch={handleDatasetSearch}
          onSelect={handleDatasetSelect}
          defaultSelectValue={tableFromURL}
          defaultSearchValue={searchFromUrl}
          className='tableSearch'
          placeholderName='Start typing dataset name...'
        />
      </div>

      <div className={classes.tableContainer}>
        <Toolbar
          numSelected={selected.length}
          closeModal={clearSelected}
          onTypeChange={onTypeChange}
          actionText={actionText}
        />
        <Table>
          <TableHeadWithCheckbox
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={paginatedData?.length}
            onRequestSort={handleRequestSort}
            headCells={HEAD_CELLS}
            selectedColumn={true}
          />
          {paginatedData && (
            <TableBody className={classes.tableBody}>
              {paginatedData.map((value, index) => {
                const isItemSelected = isSelected(value);
                return (
                  <DictionaryDatasetDetails
                    key={index + value.name}
                    datasetInfo={value}
                    updateDataset={updateDataset}
                    isItemSelected={isItemSelected}
                    handleClick={handleClickElement}
                    onDatasetClick={onDatasetClick}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
    </>
  );
};

export { DictionaryDataset };
