import React from 'react';
import { useCallback, useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { AppRoutes } from 'app-routes.js';
import { Button } from 'Components/components.js';
import { ReactComponent as Dataplex } from 'assets/img/dataplex-logo.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 440,
    alignSelf: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 12,
  },
  content: {
    textAlign: 'center',
  },
  containedButton: {
    width: 200,
  },
}));

const DataQualityEmpty = () => {
  const classes = useStyles();
  const history = useHistory();

  const navigateToCreateScanPage = useCallback(() => {
    history.push(AppRoutes.DataQuality_createScan.path);
  }, [history]);

  return (
    <div className={clsx(classes.container, 'txt-mainDark-13-500')}>
      <Dataplex />
      <div className='txt-mainDark-20-700'>No resources</div>
      <div className={classes.content}>
        Get started by creating a data quality scan to govern quality for your
        Dataplex-managed tables.
      </div>
      <Button
        text='Create scan'
        onClick={navigateToCreateScanPage}
        fullWidth={false}
        className={classes.containedButton}
      />
    </div>
  );
};

export { DataQualityEmpty };
