import clsx from 'clsx';
import { useGetIsAnomalyTable } from 'hooks/hooks.js';
import { DEPENDING_TABLE_WIDTH } from 'Pages/Dictionary/Dictionary.styles.js';
import { OverflownText } from 'Components/components.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const DictionaryTableDependingTable = ({
  item,
  onMonitors,
  startDate,
  endDate,
}) => {
  const fullTableName = getFullTableName(item.table, item.dataset);
  const { data: isAnomalyTable } = useGetIsAnomalyTable(
    item.dataset,
    item.table,
    startDate,
    endDate
  );
  return (
    <OverflownText
      maxWidth={DEPENDING_TABLE_WIDTH}
      title={fullTableName}
      className={clsx('appLink', isAnomalyTable && 'txt-red-13-500')}
      onClick={() => onMonitors(item.dataset, item.table)}
    >
      {fullTableName}
    </OverflownText>
  );
};

export { DictionaryTableDependingTable };
