export const sourceId = 'source';
export const pipelineSubtype = 'pipelineSubtype';
export const emailId = 'email';
export const tagsId = 'TAGS';
export const frequencyId = 'frequency';
export const activityId = 'lastActivity';
export const slotsId = 'slots';
export const bytesId = 'bytes';
export const costId = 'cost';
export const totalCostId = 'totalCost';
export const reservationId = 'RESERVATION';

export const headSource = {
  id: sourceId,
  label: 'Source table',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headJobType = {
  id: pipelineSubtype,
  label: 'Job type',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headEmail = {
  id: emailId,
  label: 'Principal email',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headTags = {
  id: tagsId,
  label: 'Pipeline tags / labels',
  sort: false,
  align: 'left',
  isShow: true,
};
export const headFrequency = {
  id: frequencyId,
  label: 'Update frequency',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headActivity = {
  id: activityId,
  label: 'Last Activity',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headSlots = {
  id: slotsId,
  label: 'Ave. slots hours',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headBytes = {
  id: bytesId,
  label: 'Ave. memory usage',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headCost = {
  id: costId,
  label: 'Ave. cost',
  sort: false,
  align: 'right',
  isShow: true,
};
export const headTotalCost = {
  id: totalCostId,
  label: 'Total cost',
  sort: false,
  align: 'right',
  isShow: true,
};

export const headReservation = {
  id: reservationId,
  label: 'Reservation',
  sort: false,
  align: 'left',
  isShow: true,
};
