const getRequestDTOFromDataScan = (dataScan, rules) => {
  return {
    dataPlexId: dataScan.scanId,
    namespace: dataScan.dataset,
    dataset: dataScan.table,
    incrementalColumn: dataScan.incrementalColumn,
    rowFilter: dataScan.rowFilter,
    samplingPercent: +dataScan.samplingPercent,
    scheduleCron: dataScan.scheduleCron,
    rules: rules.map((rule) => ({
      dimension: rule.dimension,
      threshold: rule.threshold,
      column: rule.column,
      ignoreNull: rule.ignoreNull,
      description: rule.description,
      data: rule.data,
    })),
  };
};

const getUpdateRequestDTOFromDataScan = (dataScan) => {
  return {
    rowFilter: dataScan.rowFilter,
    samplingPercent: +dataScan.samplingPercent,
    scheduleCron: dataScan.scheduleCron,
  };
};

export { getRequestDTOFromDataScan, getUpdateRequestDTOFromDataScan };
