import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    '&.MuiAccordion-root': {
      borderRadius: 12,
      boxShadow: 'none',
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 'max-content',
      padding: 24,
      flexDirection: 'row-reverse',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      marginLeft: 8,
      color: theme.palette.common.mainDarkText,
      fontFamily: theme.typography.fontFamily,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '20px',
    },
    '& .MuiAccordionDetails-root': {
      padding: 24,
      paddingTop: 0,
    },
  },
}));
