import { useEffect, useHistory, useLocation } from 'hooks/hooks.js';
import { SEARCH_PARAMS } from 'constants/constants.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const useSyncTableToSearchParam = (table, dataset, search, tab = null) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (
      table &&
      dataset &&
      (searchParams.get(SEARCH_PARAMS.DATASET) !== dataset ||
        searchParams.get(SEARCH_PARAMS.TABLE) !== table)
    ) {
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      searchParams.set(SEARCH_PARAMS.TABLE, table);
      searchParams.set(SEARCH_PARAMS.DATASET, dataset);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    if (
      !table &&
      dataset &&
      (searchParams.get(SEARCH_PARAMS.DATASET) !== dataset ||
        searchParams.get(SEARCH_PARAMS.TABLE) !== table)
    ) {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      searchParams.set(SEARCH_PARAMS.DATASET, dataset);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    if (
      !table &&
      !dataset &&
      search &&
      searchParams.get(SEARCH_PARAMS.SEARCH) !== search
    ) {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.DATASET);
      searchParams.set(SEARCH_PARAMS.SEARCH, search);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table, dataset, search]);

  const tableFromUrl = () => {
    if (
      searchParams.get(SEARCH_PARAMS.TABLE) &&
      searchParams.get(SEARCH_PARAMS.DATASET)
    ) {
      return getFullTableName(
        searchParams.get(SEARCH_PARAMS.TABLE),
        searchParams.get(SEARCH_PARAMS.DATASET)
      );
    } else if (searchParams.get(SEARCH_PARAMS.DATASET)) {
      return searchParams.get(SEARCH_PARAMS.DATASET);
    }

    return null;
  };

  const tableInfoFromUrl = () => {
    if (
      searchParams.get(SEARCH_PARAMS.TABLE) ||
      searchParams.get(SEARCH_PARAMS.DATASET)
    ) {
      return {
        table: searchParams.get(SEARCH_PARAMS.TABLE),
        dataset: searchParams.get(SEARCH_PARAMS.DATASET),
      };
    }

    return null;
  };

  return {
    tableFromURL: tableFromUrl(),
    tableInfoFromUrl: tableInfoFromUrl(),
    searchFromUrl: searchParams.get(SEARCH_PARAMS.SEARCH),
    clearSearchParams: () => {
      searchParams.delete(SEARCH_PARAMS.TABLE);
      searchParams.delete(SEARCH_PARAMS.DATASET);
      searchParams.delete(SEARCH_PARAMS.SEARCH);
      if (tab) searchParams.set(SEARCH_PARAMS.TAB, tab);
      history.replace({
        pathname: location.pathname,
        search: searchParams.toString(),
        state: history.location.state,
      });
    },
  };
};

export { useSyncTableToSearchParam };
