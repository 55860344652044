export const OnDemandsRegions = [
  {
    id: 'aws-eu-west-1',
    title: 'Ireland (AWS) (aws-eu-west-1)',
    price: 8.6,
  },
  {
    id: 'aws-us-west-2',
    title: 'Oregon (AWS) (aws-us-west-2)',
    price: 7.82,
  },
  {
    id: 'aws-ap-northeast-2',
    title: 'Seoul (AWS) (aws-ap-northeast-2)',
    price: 10,
  },
  {
    id: 'azure-eastus2',
    title: 'North Virginia (Azure) (azure-eastus2)',
    price: 9.13,
  },
  {
    id: 'aws-us-east-1',
    title: 'North Virginia (AWS) (aws-us-east-1)',
    price: 7.82,
  },
  {
    id: 'us',
    title: 'US (us)',
    price: 6.25,
  },
  {
    id: 'eu',
    title: 'Europe (eu)',
    price: 6.25,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas (us-west4)',
    price: 6.25,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City (us-west3)',
    price: 8.44,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles (us-west2)',
    price: 8.44,
  },
  {
    id: 'us-west1',
    title: 'Oregon (us-west1)',
    price: 7.5,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia (us-east4)',
    price: 6.25,
  },
  {
    id: 'us-east1',
    title: 'South Carolina (us-east1)',
    price: 6.25,
  },
  {
    id: 'us-central1',
    title: 'Iowa (us-central1)',
    price: 6.25,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago (southamerica-west1)',
    price: 8.94,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo (southamerica-east1)',
    price: 11.25,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto (northamerica-northeast2)',
    price: 6.56,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal (northamerica-northeast1)',
    price: 6.56,
  },
  {
    id: 'me-central2',
    title: 'Dammam (me-central2)',
    price: 10,
  },
  {
    id: 'europe-west10',
    title: 'Berlin (europe-west10)',
    price: 9.63,
  },
  {
    id: 'europe-west9',
    title: 'Paris (europe-west9)',
    price: 7.81,
  },
  {
    id: 'europe-west8',
    title: 'Milan (europe-west8)',
    price: 7.81,
  },
  {
    id: 'europe-west6',
    title: 'Zürich (europe-west6)',
    price: 8.75,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands (europe-west4)',
    price: 7.5,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt (europe-west3)',
    price: 8.13,
  },
  {
    id: 'europe-west2',
    title: 'London (europe-west2)',
    price: 7.81,
  },
  {
    id: 'europe-west1',
    title: 'Belgium (europe-west1)',
    price: 7.5,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid (europe-southwest1)',
    price: 7.81,
  },
  {
    id: 'europe-north1',
    title: 'Finland (europe-north1)',
    price: 7.5,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw (europe-central2)',
    price: 8.13,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne (australia-southeast2)',
    price: 8.13,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney (australia-southeast1)',
    price: 8.13,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta (asia-southeast2)',
    price: 7.5,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore (asia-southeast1)',
    price: 8.44,
  },
  {
    id: 'asia-south2',
    title: 'Delhi (asia-south2)',
    price: 7.5,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai (asia-south1)',
    price: 7.5,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul (asia-northeast3)',
    price: 7.5,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka (asia-northeast2)',
    price: 7.5,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo (asia-northeast1)',
    price: 7.5,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong (asia-east2)',
    price: 8.75,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan (asia-east1)',
    price: 7.19,
  },
  {
    id: 'africa-south1',
    title: 'Johannesburg (africa-south1)',
    price: 8.18,
  },
];

export const MonthlyFRRegions = [
  {
    id: 'us-east-2-azure',
    title: 'US East (Azure)',
    price: 2920,
  },
  {
    id: 'us-east-1-aws',
    title: 'North Virginia (AWS)',
    price: 2500,
  },
  {
    id: 'us',
    title: 'US',
    price: 2000,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 2000,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 2000,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 2700,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 2700,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 2000,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 2000,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 2000,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 2000,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 2860,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 3600,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 2100,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 2500,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 2500,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 2800,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 2400,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 2600,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 2500,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 2400,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 2500,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 2400,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 2600,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 2600,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 2600,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 2400,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 2700,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 2400,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 2400,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 2400,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 2400,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 2400,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 2400,
  },
];

export const AnnualFRRegions = [
  {
    id: 'us',
    title: 'US',
    price: 1700,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 1700,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 1700,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 2295,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 2295,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 1700,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 1700,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 1700,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 1700,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 2431,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 3060,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 1785,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 1785,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 2125,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 2125,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 2380,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 2040,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 2210,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 2125,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 2040,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 2125,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 2040,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 2210,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 2040,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 2210,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 2040,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 2295,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 2040,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 2040,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 2040,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 2040,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 2040,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 2040,
  },
];

export const HourlyFRRegions = [
  {
    id: 'us-east-2-azure',
    title: 'US East (Azure)',
    price: 5.8,
  },
  {
    id: 'us-east-1-aws',
    title: 'North Virginia (AWS)',
    price: 5,
  },
  {
    id: 'us',
    title: 'US',
    price: 4,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 4,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 4,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 5.4,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 5.4,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 4,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 4,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 4,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 4,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 5.7,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 7.2,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 4.2,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 4.2,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 5,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 5,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 5.6,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 4.8,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 5.2,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 5,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 4.8,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 4.8,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 5.2,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 4.8,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 5.2,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 4.8,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 5.4,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 4.8,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 4.8,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 4.8,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 4.8,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 4.8,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 4.8,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 4.8,
  },
];

export const StandardPayAsYouGoRegions = [
  {
    id: 'us',
    title: 'US',
    price: 0.04,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.044,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.04,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.05,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.05,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.04,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.047,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.04,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.04,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.04,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.04,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.057,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.062,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.044,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.046,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.05,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.049,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.052,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.052,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.052,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.056,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.044,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.052,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.052,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.044,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.047,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.044,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.052,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.054,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.054,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.044,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.049,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.046,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.046,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.051,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.051,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.051,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.056,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.046,
  },
];

export const EnterprisePayAsYouGoRegions = [
  {
    id: 'aws-ap-northeast-2',
    title: 'Seoul (AWS)',
    price: 0.096,
  },
  {
    id: 'azure-eastus2',
    title: 'North Virginia',
    price: 0.088,
  },
  {
    id: 'aws-us-east-1',
    title: 'North Virginia (AWS)',
    price: 0.075,
  },
  {
    id: 'us',
    title: 'US',
    price: 0.06,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.066,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.06,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.075,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.075,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.06,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.0705,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.06,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.06,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.06,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.06,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.0855,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.093,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.066,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.069,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.075,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.0735,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.078,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.078,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.078,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.084,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.066,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.078,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.078,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.066,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.0705,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.066,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.078,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.081,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.081,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.066,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.0735,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.069,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.069,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.0765,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.0765,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.0765,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.084,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.069,
  },
];

export const Enterprise1yCommitRegions = [
  {
    id: 'aws-ap-northeast-2',
    title: 'Seoul (AWS)',
    price: 0.077,
  },
  {
    id: 'azure-eastus2',
    title: 'North Virginia',
    price: 0.07,
  },
  {
    id: 'aws-us-east-1',
    title: 'North Virginia (AWS)',
    price: 0.06,
  },
  {
    id: 'us',
    title: 'US',
    price: 0.048,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.0528,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.048,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.06,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.06,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.048,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.0564,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.048,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.048,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.048,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.048,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.0684,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.0744,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.0528,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.0552,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.06,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.0588,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.0624,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.0624,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.0624,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.0672,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.0528,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.0624,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.0624,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.0528,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.0564,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.0528,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.0624,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.0648,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.0648,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.0528,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.0588,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.0552,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.0552,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.0612,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.0612,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.0612,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.0672,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.0552,
  },
];

export const Enterprise3yCommitRegions = [
  {
    id: 'aws-ap-northeast-2',
    title: 'Seoul (AWS)',
    price: 0.058,
  },
  {
    id: 'azure-eastus2',
    title: 'North Virginia',
    price: 0.053,
  },
  {
    id: 'aws-us-east-1',
    title: 'North Virginia (AWS)',
    price: 0.045,
  },
  {
    id: 'us',
    title: 'US',
    price: 0.036,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.0396,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.036,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.045,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.045,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.036,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.0423,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.036,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.036,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.036,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.036,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.0513,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.0558,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.0396,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.0414,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.045,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.0441,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.0468,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.0468,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.0468,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.0504,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.0396,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.0468,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.0468,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.0396,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.0423,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.0396,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.0468,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.0486,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.0486,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.0396,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.0441,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.0414,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.0414,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.0459,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.0459,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.0459,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.0504,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.0414,
  },
];

export const EnterprisePlusPayAsUGoRegions = [
  {
    id: 'us',
    title: 'US',
    price: 0.1,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.11,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.1,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.125,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.125,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.1,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.1175,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.1,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.1,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.1,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.1,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.1425,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.155,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.11,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.115,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.125,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.1225,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.13,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.13,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.13,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.14,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.11,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.13,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.13,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.11,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.1175,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.11,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.13,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.135,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.135,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.11,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.1225,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.115,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.115,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.1275,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.1275,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.1275,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.14,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.115,
  },
];

export const EnterprisePlus1yCommitRegions = [
  {
    id: 'us',
    title: 'US',
    price: 0.08,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.088,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.08,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.1,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.1,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.08,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.094,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.08,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.08,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.08,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.08,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.114,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.124,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.088,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.092,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.1,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.098,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.104,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.104,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.104,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.112,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.088,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.104,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.104,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.088,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.094,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.088,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.104,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.108,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.108,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.088,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.098,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.092,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.092,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.102,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.102,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.102,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.112,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.092,
  },
];

export const EnterprisePlus3yCommitRegions = [
  {
    id: 'us',
    title: 'US',
    price: 0.06,
  },
  {
    id: 'eu',
    title: 'Europe',
    price: 0.066,
  },
  {
    id: 'us-west4',
    title: 'Las Vegas',
    price: 0.06,
  },
  {
    id: 'us-west3',
    title: 'Salt Lake City',
    price: 0.075,
  },
  {
    id: 'us-west2',
    title: 'Los Angeles',
    price: 0.075,
  },
  {
    id: 'us-west1',
    title: 'Oregon',
    price: 0.06,
  },
  {
    id: 'us-south1',
    title: 'Dallas',
    price: 0.0705,
  },
  {
    id: 'us-east5',
    title: 'Columbus',
    price: 0.06,
  },
  {
    id: 'us-east4',
    title: 'Northern Virginia',
    price: 0.06,
  },
  {
    id: 'us-east1',
    title: 'South Carolina',
    price: 0.06,
  },
  {
    id: 'us-central1',
    title: 'Iowa',
    price: 0.06,
  },
  {
    id: 'southamerica-west1',
    title: 'Santiago',
    price: 0.0855,
  },
  {
    id: 'southamerica-east1',
    title: 'São Paulo',
    price: 0.093,
  },
  {
    id: 'northamerica-northeast2',
    title: 'Toronto',
    price: 0.066,
  },
  {
    id: 'northamerica-northeast1',
    title: 'Montréal',
    price: 0.069,
  },
  {
    id: 'me-west1',
    title: 'Tel Aviv',
    price: 0.075,
  },
  {
    id: 'me-central1',
    title: 'Doha',
    price: 0.0735,
  },
  {
    id: 'europe-west12',
    title: 'Turin',
    price: 0.078,
  },
  {
    id: 'europe-west9',
    title: 'Paris',
    price: 0.078,
  },
  {
    id: 'europe-west8',
    title: 'Milan',
    price: 0.078,
  },
  {
    id: 'europe-west6',
    title: 'Zürich',
    price: 0.084,
  },
  {
    id: 'europe-west4',
    title: 'Netherlands',
    price: 0.066,
  },
  {
    id: 'europe-west3',
    title: 'Frankfurt',
    price: 0.078,
  },
  {
    id: 'europe-west2',
    title: 'London',
    price: 0.078,
  },
  {
    id: 'europe-west1',
    title: 'Belgium',
    price: 0.066,
  },
  {
    id: 'europe-southwest1',
    title: 'Madrid',
    price: 0.0705,
  },
  {
    id: 'europe-north1',
    title: 'Finland',
    price: 0.066,
  },
  {
    id: 'europe-central2',
    title: 'Warsaw',
    price: 0.078,
  },
  {
    id: 'australia-southeast2',
    title: 'Melbourne',
    price: 0.081,
  },
  {
    id: 'australia-southeast1',
    title: 'Sydney',
    price: 0.081,
  },
  {
    id: 'asia-southeast2',
    title: 'Jakarta',
    price: 0.066,
  },
  {
    id: 'asia-southeast1',
    title: 'Singapore',
    price: 0.0735,
  },
  {
    id: 'asia-south2',
    title: 'Delhi',
    price: 0.069,
  },
  {
    id: 'asia-south1',
    title: 'Mumbai',
    price: 0.069,
  },
  {
    id: 'asia-northeast3',
    title: 'Seoul',
    price: 0.0765,
  },
  {
    id: 'asia-northeast2',
    title: 'Osaka',
    price: 0.0765,
  },
  {
    id: 'asia-northeast1',
    title: 'Tokyo',
    price: 0.0765,
  },
  {
    id: 'asia-east2',
    title: 'Hong Kong',
    price: 0.084,
  },
  {
    id: 'asia-east1',
    title: 'Taiwan',
    price: 0.069,
  },
];
