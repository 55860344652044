import React from 'react';
import { TableHead, TableSortLabel } from '@mui/material';
import { StyledTableCell } from 'Pages/Pipelines/GlobalPipeline.styles';
import { StyledTableRow } from 'Components/components';
import {
  SourceTable,
  Email,
  Tags,
  Reservations,
  Project,
  Total,
  TotalCost,
} from '../enums/enums.js';

const HEAD_CELLS = [
  SourceTable,
  Email,
  Tags,
  Reservations,
  Project,
  Total,
  TotalCost,
];

const ResourceTableHead = () => {
  return (
    <TableHead>
      <StyledTableRow>
        {HEAD_CELLS.map((headCell) => {
          return (
            headCell.IS_SHOW && (
              <StyledTableCell key={headCell.ID} align={headCell.ALIGN}>
                <TableSortLabel hideSortIcon={true}>
                  <span className='txt-grey-13-500 nowrapText'>
                    {headCell.LABEL}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
            )
          );
        })}
      </StyledTableRow>
    </TableHead>
  );
};

export { ResourceTableHead };
