import React from 'react';
import { Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ALERT_TYPES_OPTIONS } from 'utils/constants.js';
import { ReactComponent as CloseIcon } from 'assets/img/dictionaryIcons/closeIcon.svg';

const useStyles = makeStyles((theme) => ({
  toolbarContainer: {
    '& .MuiToolbar-root': {
      minHeight: 54,
      padding: '16px 12px',
      background: theme.palette.common.mainDarkText,
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
      borderRadius: 12,
      zIndex: 1,
      color: theme.palette.common.white,
    },
  },
  alertContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    marginRight: 18,
    padding: 4,
    color: theme.palette.common.white,
    cursor: 'pointer',
    '&:last-of-type': {
      marginRight: 16,
    },
    '& svg path': {
      fill: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      borderRadius: 4,
    },
  },
}));

const CustomToolbar = (props) => {
  const { numSelected, closeModal, onTypeChange, actionText } = props;
  const classes = useStyles();

  if (!numSelected && !actionText) {
    return '';
  }

  return (
    <div className={classes.toolbarContainer}>
      <Toolbar
        sx={{
          display: 'flex',
          position: 'fixed',
          bottom: '40px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        {actionText ? (
          <div style={{ width: 328, textAlign: 'center' }}>{actionText}</div>
        ) : (
          <>
            <div
              className='txt-mainDark-13-700'
              style={{
                paddingRight: 16,
                marginRight: 12,
                color: 'inherit',
                borderRight: '1px solid #fff',
              }}
            >
              {numSelected} items selected
            </div>

            <div style={{ display: 'flex' }}>
              {ALERT_TYPES_OPTIONS.map((item) => {
                const IconSvg = item.iconSvg;
                return (
                  <div
                    key={item.value}
                    className={classes.alertContainer}
                    onClick={() => onTypeChange(item.value)}
                  >
                    <IconSvg height={13} />
                    {item.label}
                  </div>
                );
              })}
            </div>

            <div style={{ borderLeft: '1px solid #fff', paddingLeft: 16 }}>
              <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
            </div>
          </>
        )}
      </Toolbar>
    </div>
  );
};

export { CustomToolbar };
