import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useOwnStyles = makeStyles((theme) => ({
  cell: {
    '&.MuiTableCell-root': {
      textAlign: 'center',
      backgroundColor: theme.palette.secondary.light,
    },
  },
}));

const EmptySearchData = ({ searchType, colSpan }) => {
  const classes = useOwnStyles();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={classes.cell}>
        No costs found associated to this {searchType}
      </TableCell>
    </TableRow>
  );
};

export { EmptySearchData };
