import React, { forwardRef } from 'react';
import { useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { TextField } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStyles } from './DateRangePicker.styles.js';

const CustomDatePicker = ({
  defaultStartDate,
  onChooseDate,
  required = false,
  showTimeSelect = false,
}) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const onChange = (date) => {
    setStartDate(date);
    onChooseDate(date);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextField
      label=''
      variant='outlined'
      sx={{ width: '100%' }}
      value={value}
      onClick={onClick}
      ref={ref}
      required={required}
    />
  ));

  return (
    <DatePicker
      customInput={<CustomInput />}
      onChange={onChange}
      selected={startDate}
      dateFormat={showTimeSelect ? 'MMM d, yyyy hh:mm aa' : 'MMM d, yyyy'}
      popperClassName={clsx(
        classes.calendarContainer,
        classes.dateTimeContainer
      )}
      popperPlacement='top-end'
      showYearDropdown
      showTimeSelect={showTimeSelect}
      timeIntervals={30}
      timeCaption='Time'
      dateFormatCalendar='MMMM'
      yearDropdownItemNumber={5}
      scrollableYearDropdown
      disabledKeyboardNavigation
      shouldCloseOnSelect={true}
    />
  );
};

export { CustomDatePicker };
