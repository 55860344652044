import React from 'react';
import theme from 'theme.js';

const INPUT_ERRORS = {
  limitCharacters: 'You have reached your maximum limit of characters allowed',
  invalidId: 'The ID has invalid characters. Enter letters, numbers or hyphens',
  invalidFirstCharacter:
    'The ID starts with an invalid character. Start with a lowercase letter',
  invalidLastCharacter:
    'The ID ends with an invalid character. End with a letter or number',
  uniqueId: (project, location, scanId) =>
    `Resource "projects/${project}/locations/${location}/dataScans/${scanId}" already exists`,
  invalidCustomSamplingSize:
    'Input will accept a numerical value between 0.001 and 100 with up to three significant decimal digits',
  invalidThresholdValue:
    'Input will accept a numerical value between 1 and 100 with up to three significant decimal digits',
  cronValidation: (
    <>
      Value is neither a valid&nbsp;
      <a
        href='https://cloud.google.com/scheduler/docs/configuring/cron-job-schedules?authuser=1&_ga=2.264166476.-1152482280.1696840971#defining_the_job_schedule'
        target='_blank'
        rel='noreferrer'
        style={{
          color: theme.palette.error.main,
          textDecoration: 'underline',
        }}
      >
        unix-cron
      </a>{' '}
      nor&nbsp;
      <a
        href='https://cloud.google.com/appengine/docs/flexible/python/configuring-your-app-with-app-yaml?authuser=1&_ga=2.194395245.-1152482280.1696840971'
        target='_blank'
        rel='noreferrer'
        style={{
          color: theme.palette.error.main,
          textDecoration: 'underline',
        }}
      >
        App Engine schedule
      </a>{' '}
      string
    </>
  ),
};

export { INPUT_ERRORS };
