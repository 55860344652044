import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';

const getColumns = createAsyncThunk(
  `${SLICE_NAME.columns}/getColumns`,
  async (parameters, { rejectWithValue }) => {
    const {
      dataset,
      table,
      column,
      isColumnEqual,
      sortBy,
      order,
      page,
      limit,
      types = [],
    } = parameters;

    const params = new URLSearchParams();
    params.append('dataset', dataset);
    params.append('table', table);
    params.append('column', column);
    params.append('isColumnEqual', isColumnEqual);
    params.append('sortBy', sortBy);
    params.append('order', order);
    params.append('page', page);
    params.append('limit', limit);
    for (const type of types) {
      params.append('types', type);
    }

    const response = await fetcherGet(QUERY_TYPES.tableColumns, params);
    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const getUniqueColumns = createAsyncThunk(
  `${SLICE_NAME.columns}/getUniqueColumns`,
  async (parameters, { rejectWithValue }) => {
    const { dataset = '', table = '', types = [] } = parameters;

    const params = new URLSearchParams();
    params.append('dataset', dataset);
    params.append('table', table);
    for (const type of types) {
      params.append('types', type);
    }

    const response = await fetcherGet(QUERY_TYPES.uniqueColumns, params);

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const updateDesc = createAsyncThunk(
  `${SLICE_NAME.columns}/updateDesc`,
  async (parameters, { rejectWithValue }) => {
    const { uuid, desc } = parameters;
    const res = await fetcherPost(`/api/v1/table-columns/${uuid}`, {
      value: desc,
    });

    if (res.success) {
      return parameters;
    }

    return rejectWithValue();
  }
);

export { getColumns, getUniqueColumns, updateDesc };
