import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelinesSubtypesData = (
  startDate,
  endDate,
  pipelineType,
  enabled = true
) => {
  const { data } = useQuery(
    [QUERY_TYPES.jobPipelineSubtypes, startDate, endDate, pipelineType],
    ({ queryKey }) => {
      const [url, start, end, pipelineType] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
        pipelineType,
      });
    },
    { enabled }
  );

  const pipelineSubtypesData = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { pipelineSubtypesData };
};

export { usePipelinesSubtypesData };
