import { useEffect, useQuery, useState } from 'hooks/hooks.js';
import moment from 'moment';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';
import { QUERY_TYPES } from 'constants/constants.js';

const useJobVolume = (
  jobHash,
  metricType,
  startDate,
  endDate,
  enabled = true
) => {
  const [volumeData, setVolumeData] = useState([]);

  const { data, isFetching: isLoadingVolumeData } = useQuery(
    [QUERY_TYPES.jobVolume, jobHash, metricType, startDate, endDate],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
        from: moment(convertLocalToUTCDate(startDate)).utc().format(),
        to: moment(convertLocalToUTCDate(endDate)).utc().format(),
      });
    },
    {
      enabled: Boolean(
        jobHash && metricType && startDate && endDate && enabled
      ),
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (data?.values) {
      setVolumeData(data.values);
    }
  }, [data?.values]);

  return { volumeData, isLoadingVolumeData };
};

export { useJobVolume };
