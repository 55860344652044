import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Checkbox, FormControlLabel, FormControl } from '@mui/material';
import { ReactComponent as CheckedIcon } from 'assets/img/icons/checkIcons/check-icon.svg';
import { ReactComponent as UncheckedIcon } from 'assets/img/icons/checkIcons/uncheck-icon.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiFormControlLabel-root': {
      marginLeft: '-10px',
    },
    '& .MuiFormControlLabel-label': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
    '& .MuiCheckbox-root': {
      marginRight: 22,
      padding: 0,
      transform: 'translateX(50%)',
    },
  },
}));

const CheckboxWithLabel = ({
  isChecked,
  handleChange,
  label,
  id = '',
  isDefaultChecked = false,
  className = '',
}) => {
  const classes = useStyles();

  return (
    <FormControl className={clsx(classes.container, className)}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={(event) => handleChange(event.target.checked)}
            icon={<UncheckedIcon width={20} height={20} />}
            checkedIcon={<CheckedIcon width={20} height={20} />}
            id={id}
          />
        }
        label={label}
        defaultChecked={isDefaultChecked}
      />
    </FormControl>
  );
};

export { CheckboxWithLabel };
