import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { TextareaWithLineNumbers } from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  requiredElement: {
    color: theme.palette.error.main,
  },
  queryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  textBlock: {
    padding: 12,
    borderRadius: 4,
    backgroundColor: (props) =>
      props.isWhiteTextBlock
        ? theme.palette.common.white
        : theme.palette.secondary.light,
  },
}));

const SqlBlock = ({
  textInfo = 'Example row filter rule: discount_pct > 50',
  isWhiteTextBlock = true,
  value,
  onChangeSqlData,
}) => {
  const classes = useStyles({ isWhiteTextBlock });

  return (
    <div className={classes.queryContainer}>
      <div className='txt-grey-13-500'>
        Provide a SQL expression that evaluates to boolean true (PASS) or false
        (FAIL)
        <span className={classes.requiredElement}>*</span>
      </div>

      <div className={clsx(classes.textBlock, 'txt-mainDark-13-500')}>
        {textInfo}
      </div>

      <TextareaWithLineNumbers
        value={value}
        onChangeValue={onChangeSqlData}
        numberOfLines={10}
        name='sqlTextarea'
        placeholder=''
      />
    </div>
  );
};

export { SqlBlock };
