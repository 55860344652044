import React from 'react';
import { Box, Table, TableBody, TableContainer, Paper } from '@mui/material';
import { useStyles } from './Users.styles.js';
import { UsersTableHead, UsersColumnDetails } from './components/components.js';
import { Divider } from 'Components/components.js';

const headCells = [
  { id: 'NAME', label: 'Name', style: { minWidth: 300 } },
  { id: 'ACTIVE', label: 'Last active', style: { minWidth: 300 } },
  { id: 'ROLE', label: 'Role', style: { minWidth: 300 } },
  { id: 'icon', label: '', style: { minWidth: 40 } },
];

const UsersTable = ({ data, handleRemove, isOwner }) => {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <Box>
        <Paper sx={{ borderRadius: 4.5, boxShadow: 'none' }}>
          <TableContainer sx={{ padding: 6 }}>
            <div className='txt-mainDark-20-700'>Members</div>
            <Divider />
            <Table>
              <UsersTableHead headCells={headCells} />
              <TableBody className={classes.tableBody}>
                {data.map((item, index) => {
                  return (
                    <UsersColumnDetails
                      key={index + item.email}
                      columnInfo={item}
                      handleRemove={handleRemove}
                      isOwner={isOwner}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
};

export { UsersTable };
