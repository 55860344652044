const SCHEDULE_TYPE = {
  ON_DEMAND: 'On-demand',
  REPEAT: 'Repeat',
};

const SCHEDULE_OPTIONS = [
  { value: SCHEDULE_TYPE.REPEAT, label: SCHEDULE_TYPE.REPEAT },
  { value: SCHEDULE_TYPE.ON_DEMAND, label: SCHEDULE_TYPE.ON_DEMAND },
];

export { SCHEDULE_OPTIONS, SCHEDULE_TYPE };
