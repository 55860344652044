import React, { createContext } from 'react';
import { useContext, useState } from 'hooks/hooks.js';

const SignUpContext = createContext(null);
const SignUpContextProvider = ({ children }) => {
  const [signUpInfo, setSignUpInfo] = useState(null);
  const [signUpError, setSignUpError] = useState(null);

  return (
    <SignUpContext.Provider
      value={{ signUpInfo, setSignUpInfo, signUpError, setSignUpError }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

const useSignUpInfo = () => useContext(SignUpContext);

export { SignUpContext, SignUpContextProvider, useSignUpInfo };
