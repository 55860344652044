import React from 'react';
import { useBooleanToggle, useRef } from 'hooks/hooks.js';
import Widget from 'Components/Widget/Widget.js';
import { Schema } from './Schema.js';
import { getWidgetLabel } from 'utils/helpers/helpers.js';

const SchemaWidget = ({ tableInfo }) => {
  const schemaRef = useRef(null);
  const { schema } = getWidgetLabel(tableInfo?.type);

  const {
    booleanState: isShowButton,
    handleChangeBooleanState: handleChangeButton,
  } = useBooleanToggle();

  const {
    booleanState: existingDivider,
    handleChangeBooleanState: handleChangeExistingDivider,
  } = useBooleanToggle(true);

  const handleClickSchemaButton = () => {
    schemaRef.current.openSchemaModal();
  };

  return (
    <Widget
      label={schema.title}
      secondaryLabel={schema.subtitle}
      initialSettings={{ size: 'L', height: 'auto' }}
      isShowButton={isShowButton}
      onWidgetButtonClick={handleClickSchemaButton}
      existingDivider={existingDivider}
    >
      <Schema
        {...tableInfo}
        ref={schemaRef}
        toggleExpandButton={handleChangeButton}
        handleChangeDivider={handleChangeExistingDivider}
      />
    </Widget>
  );
};

export { SchemaWidget };
