import { useEffect, useQuery, useState, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const MILLISECONDS_IN_SECONDS = 1000;

const useJobFrequency = (jobHash, metricType, enabled = true) => {
  const [frequency, setFrequency] = useState([]);

  const { data, isFetching: isLoadingFrequency } = useQuery(
    [QUERY_TYPES.jobFrequency, jobHash, metricType],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
      });
    },
    { enabled: Boolean(jobHash && metricType && enabled) }
  );

  useEffect(() => {
    if (data?.value) {
      setFrequency(data.value);
    }
  }, [data?.value]);

  const frequencyMilliseconds = useMemo(() => {
    return frequency * MILLISECONDS_IN_SECONDS || null;
  }, [frequency]);

  return { frequencyMilliseconds, frequency, isLoadingFrequency };
};

export { useJobFrequency };
