const AppRoutes = {
  // pages
  Login: { path: '/login' },
  SignUp: { path: '/sign-up' },
  Deployment: { path: '/deployment' },
  DeploymentAddProject: { path: '/deployment-add-project' },
  DeploymentChooseProject: { path: '/deployment-choose-project' },
  Integrations: { path: '/integrations' },
  Monitors: { path: '/monitors' },
  Incidents: { path: '/incidents' },
  Dashboard: { path: '/' },
  Lineage: { path: '/lineage' },
  Dictionary: { path: '/dictionary' },
  DataQuality: { path: '/data-quality' },
  DataQuality_scanDetails: { path: '/data-quality/:scanId' },
  DataQuality_createScan: { path: '/data-quality/create-scan' },
  Admin: { path: '/admin' },
  Users: { path: '/users' },
  Pipelines: { path: '/pipelines' },
  Any: { path: '*' },

  // docs
  DocsOverview: { path: '/documentation/overview' },
  DocsDownload: { path: '/documentation/download' },
  DocsQuickStart: { path: '/documentation/quick-start' },
  DocsLicense: { path: '/documentation/license' },
  DocsFolderStructure: { path: '/documentation/folder-structure' },
  DocsBuild: { path: '/documentation/build-tools' },
  DocsChangelog: { path: '/documentation/changelog' },

  // components
  Accordions: { path: '/components/accordions' },
  Alerts: { path: '/components/alerts' },
  Badges: { path: '/components/badges' },
  Widgets: { path: '/widgets' },
  Breadcrumbs: { path: '/components/breadcrumbs' },
  Buttons: { path: '/components/buttons' },
  Forms: { path: '/components/forms' },
  Modals: { path: '/components/modals' },
  Navs: { path: '/components/navs' },
  Navbars: { path: '/components/navbars' },
  Pagination: { path: '/components/pagination' },
  Popovers: { path: '/components/popovers' },
  Progress: { path: '/components/progress' },
  Tables: { path: '/components/tables' },
  Tabs: { path: '/components/tabs' },
  Tooltips: { path: '/components/tooltips' },
  Toasts: { path: '/components/toasts' },
  WidgetsComponent: { path: '/components/widgets' },
};

export { AppRoutes };
