import { Buffer } from 'buffer';

const getImageByXMLSvg = (xml) => {
  const buf = Buffer.from(xml);
  const svg64 = buf.toString('base64');
  const b64Start = 'data:image/svg+xml;base64,';

  const image64 = b64Start + svg64;
  const img = new Image();
  img.onload = function () {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    context.drawImage(img, 0, 0);
  };
  img.src = image64;

  return img;
};

export { getImageByXMLSvg };
