import React from 'react';
import clsx from 'clsx';
import { Alert as MuiAlert } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ALERT_SEVERITY } from 'constants/constants.js';
import { ReactComponent as InfoIcon } from 'assets/img/icons/ic-24-info.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: (props) => (props.withTopMargin ? 12 : 0),
    '& .MuiAlert-root': {
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
    },
    '& .MuiAlert-standardError': {
      color: theme.palette.error.main,
      '& svg path': {
        fill: theme.palette.error.main,
      },
    },
    '& .MuiAlert-standardInfo': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiAlert-icon': {
      marginRight: theme.spacing(2),
    },
  },
}));

const Alert = ({
  severity = ALERT_SEVERITY.error,
  className = '',
  alertComponent = null,
  withTopMargin = true,
}) => {
  const classes = useStyles({ withTopMargin });

  return (
    <>
      {alertComponent !== null && (
        <div className={clsx(classes.container, className)}>
          <MuiAlert severity={severity} icon={<InfoIcon />}>
            {alertComponent}
          </MuiAlert>
        </div>
      )}
    </>
  );
};

export { Alert };
