import React from 'react';
import { Select } from 'Components/components.js';
import {
  CONTROL_ELEMENTS_DATA,
  DIMENSION_OPTIONS,
} from '../../libs/constants/constants.js';

const DimensionSelect = ({ value, onChange }) => {
  const { dimensionSelect } = CONTROL_ELEMENTS_DATA;

  return (
    <Select
      options={DIMENSION_OPTIONS}
      value={value || ''}
      onChange={onChange}
      label={dimensionSelect.label}
      id={dimensionSelect.id}
      isRequired={dimensionSelect.isRequired}
    />
  );
};

export { DimensionSelect };
