import { useState } from 'hooks/hooks.js';

const useSelectedElements = (data = []) => {
  const [selected, setSelected] = useState([]);

  const clearSelected = () => setSelected([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((item) => item);
      setSelected(newSelected);
      return;
    }
    clearSelected();
  };

  const handleClickElement = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (value) => selected.indexOf(value) !== -1;

  return {
    selected,
    clearSelected,
    handleSelectAllClick,
    isSelected,
    handleClickElement,
  };
};

export { useSelectedElements };
