const getJiraIntegrationUrl = () => {
  const link = encodeURI('https://app.mastheadata.com/integrations?item=jira');
  return (
    `https://auth.atlassian.com/authorize?audience=api.atlassian.com&` +
    `client_id=jt4ZBRxvchprKhuo5JQaW4j53DXxPYfv&` +
    `scope=write%3Ajira-work%20read%3Ajira-work%20offline_access%20read%3Ajira-user&` +
    `redirect_uri=${link}&` +
    `response_type=code&` +
    `prompt=consent`
  );
};

export { getJiraIntegrationUrl };
