export const columnNameId = 'column';
export const ruleTypeId = 'typeLabel';
export const dimensionId = 'dimensionLabel';
export const parametersId = 'PARAMETERS';
export const thresholdId = 'threshold';
export const editCellId = 'EDIT';

export const ColumnName = {
  id: columnNameId,
  label: 'Column',
  sort: true,
};

export const RuleType = {
  id: ruleTypeId,
  label: 'Rule type',
  sort: true,
};

export const Dimension = {
  id: dimensionId,
  label: 'Dimension',
  sort: true,
};

export const Parameters = {
  id: parametersId,
  label: 'Parameters',
  sort: false,
};

export const Threshold = {
  id: thresholdId,
  label: 'Threshold',
  sort: true,
};

export const EditCell = {
  id: editCellId,
  label: '',
  sort: false,
};
