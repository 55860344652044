import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { LookerSearchOptions } from 'Pages/Dictionary/libs/enums/enums.js';
import {
  GeneralSearch,
  SearchDropdown,
  TableDatasetSearch,
} from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    gap: 0,
    '& .tableSearch': {
      margin: 0,
      flexGrow: 1,
    },
  },
}));

const LookerSearch = ({
  customSearchOptions = [],
  dashboardNameSearchOptions = [],
  handleDashboardNameSearch = () => {},
  isDashboardNameSearchOptionsLoading = false,
  handleDashboardIdSearch = () => {},
  lookNameSearchOptions = [],
  handleLookNameSearch = () => {},
  isLookNameSearchOptionsLoading = false,
  handleLookIdSearch = () => {},
  dashboardElementSearchOptions = [],
  handleDashboardElementSearch = () => {},
  isDashboardElementSearchOptionsLoading = false,
  handleUpstreamChange = () => {},
  handleUpstreamSearch = () => {},
  defaultUpstreamDataset = '',
  defaultUpstreamTable = '',
  defaultUpstreamSearchValue = '',
}) => {
  const classes = useStyles();
  const searchOptions = customSearchOptions.length
    ? customSearchOptions
    : Object.values(LookerSearchOptions);
  const isSearchByUpstream = useMemo(() => {
    return (
      !!defaultUpstreamDataset ||
      !!defaultUpstreamTable ||
      !!defaultUpstreamSearchValue
    );
  }, [
    defaultUpstreamDataset,
    defaultUpstreamTable,
    defaultUpstreamSearchValue,
  ]);
  const [selectSearchOptions, setSelectSearchOptions] = useState(
    isSearchByUpstream ? LookerSearchOptions.UPSTREAM : searchOptions[0]
  );

  const handleChangeSearchSelectedOption = (value) => {
    handleDashboardNameSearch('');
    handleDashboardIdSearch('');
    handleLookNameSearch('');
    handleLookIdSearch('');
    handleDashboardElementSearch('');
    handleUpstreamChange(null);
    handleUpstreamSearch('');
    setSelectSearchOptions(value);
  };

  const renderSearchField = () => {
    switch (selectSearchOptions) {
      case LookerSearchOptions.DASHBOARD_ID:
        return (
          <GeneralSearch
            options={[]}
            onSearch={handleDashboardIdSearch}
            onSelect={handleDashboardIdSearch}
            className='tableSearch'
            placeholderName='Type dashboard id...'
            searchWithDropdown={true}
            withSelect={false}
            searchKey={LookerSearchOptions.DASHBOARD_ID}
          />
        );
      case LookerSearchOptions.LOOK_NAME:
        return (
          <GeneralSearch
            options={lookNameSearchOptions}
            onSearch={handleLookNameSearch}
            onSelect={handleLookNameSearch}
            isLoading={isLookNameSearchOptionsLoading}
            className='tableSearch'
            placeholderName='Start typing look name...'
            searchWithDropdown={true}
            searchKey={LookerSearchOptions.LOOK_NAME}
          />
        );
      case LookerSearchOptions.LOOK_ID:
        return (
          <GeneralSearch
            options={[]}
            onSearch={handleLookIdSearch}
            onSelect={handleLookIdSearch}
            className='tableSearch'
            placeholderName='Start typing look id...'
            searchWithDropdown={true}
            withSelect={false}
            searchKey={LookerSearchOptions.LOOK_ID}
          />
        );
      case LookerSearchOptions.DASHBOARD_ELEMENT:
        return (
          <GeneralSearch
            options={dashboardElementSearchOptions}
            onSearch={handleDashboardElementSearch}
            onSelect={handleDashboardElementSearch}
            isLoading={isDashboardElementSearchOptionsLoading}
            className='tableSearch'
            placeholderName='Start typing dashboard element name...'
            searchWithDropdown={true}
            searchKey={LookerSearchOptions.DASHBOARD_ELEMENT}
          />
        );
      case LookerSearchOptions.UPSTREAM:
        return (
          <TableDatasetSearch
            onTableChange={handleUpstreamChange}
            onSearch={handleUpstreamSearch}
            defaultDataset={defaultUpstreamDataset}
            defaultTable={defaultUpstreamTable}
            defaultSearchValue={defaultUpstreamSearchValue}
            className='tableSearch'
            placeholderName='Start typing upstream table name...'
            searchWithDropdown={true}
            withDatasets={false}
          />
        );
      default:
        return (
          <GeneralSearch
            options={dashboardNameSearchOptions}
            onSearch={handleDashboardNameSearch}
            onSelect={handleDashboardNameSearch}
            isLoading={isDashboardNameSearchOptionsLoading}
            className='tableSearch'
            placeholderName='Start typing dashboard name...'
            searchWithDropdown={true}
            searchKey={LookerSearchOptions.DASHBOARD_NAME}
          />
        );
    }
  };

  return (
    <div className={classes.container}>
      <SearchDropdown
        options={searchOptions}
        selectedOption={selectSearchOptions}
        onOptionClick={handleChangeSearchSelectedOption}
      />
      {renderSearchField()}
    </div>
  );
};

export { LookerSearch };
