import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useModal } from 'context/context.js';
import { Button, Divider } from 'Components/components.js';
import { ReactComponent as CheckIcon } from 'assets/img/icons/dashboard-icons/checkIcon.svg';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 474,
    minHeight: 170,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.common.white,
    borderRadius: 16,
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'end',
    '& .button': {
      width: 140,
    },
  },
}));

const AddProjectModal = ({ projectName }) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const closeModal = () => {
    setModal(null);
  };

  return (
    <div className={classes.modalWrapper}>
      <div className='p-3 pb-2'>
        <CheckIcon />
      </div>
      <div className='txt-mainDark-16-700 p-3 pb-0 pt-0'>New Project Added</div>

      <Divider />

      <div className='txt-mainDark-13-500 p-3 pt-0'>
        You have successfully added {projectName} project. We train our system
        to detect incidents and anomalies. We will notify you when the training
        is completed.
      </div>

      <div className={clsx(classes.modalButton, 'm-3 mt-0')}>
        <Button
          text='Done'
          className='button'
          onClick={closeModal}
          fullWidth={false}
        />
      </div>
    </div>
  );
};

export { AddProjectModal };
