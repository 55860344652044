import { useState, useCallback } from 'hooks/hooks.js';
import { v4 as uuid } from 'uuid';

const getDefaultValues = (values) => {
  if (!values.length) {
    return [{ id: uuid(), value: '' }];
  }

  return values.map((item) => ({ id: uuid(), value: item }));
};

const useDynamicInputs = (values = []) => {
  const [inputFields, setInputFields] = useState(getDefaultValues(values));

  const handleChangeInput = useCallback(
    (id, event) => {
      const newInputFields = inputFields.map((inputField) => {
        if (id === inputField.id) {
          inputField.value = event.target.value;
        }
        return inputField;
      });

      setInputFields(newInputFields);
    },
    [inputFields]
  );

  const handleAddFields = useCallback(() => {
    setInputFields([...inputFields, { id: uuid(), value: '' }]);
  }, [inputFields]);

  const handleRemoveFields = useCallback(
    (id) => {
      const values = [...inputFields];

      values.splice(
        values.findIndex((value) => value.id === id),
        1
      );

      setInputFields(values);
    },
    [inputFields]
  );

  return {
    inputFields,
    handleChangeInput,
    handleAddFields,
    handleRemoveFields,
  };
};

export { useDynamicInputs };
