import { signInWithPopup } from 'firebase/auth';
import {
  auth,
  googleAuthProvider,
  dataPlexCreator,
  amplEvent,
} from 'service/services.js';
import { fetcherPost } from 'utils/utils.js';
import { ALERT_SEVERITY, QUERY_TYPES } from 'constants/constants.js';

const CONNECT_EVENT = 'Dataplex -> Click Connect button -';
const SUCCESS_MESSAGE = 'Dataplex has been connected';
const ERROR_MESSAGE = 'Got an error while adding Dataplex connection';
const PERMISSIONS_MESSAGE =
  'You do not have sufficient permissions on GCP project';
const ERROR_CODE = '403';

const connectDataPlex = (user, queryClient, setMessage, changeLoading) => {
  googleAuthProvider.addScope('https://www.googleapis.com/auth/pubsub');
  googleAuthProvider.addScope(
    'https://www.googleapis.com/auth/service.management'
  );
  googleAuthProvider.addScope('https://www.googleapis.com/auth/logging.admin');
  googleAuthProvider.addScope(
    'https://www.googleapis.com/auth/cloudplatformprojects'
  );
  googleAuthProvider.addScope('https://www.googleapis.com/auth/iam');
  signInWithPopup(auth, googleAuthProvider).then((result) => {
    changeLoading(true);
    dataPlexCreator
      .create(user.info.currentProject, result._tokenResponse.oauthAccessToken)
      .then(() => {
        fetcherPost(QUERY_TYPES.dataPlex, {})
          .then((response) => {
            if (response?.success) {
              setMessage(SUCCESS_MESSAGE, ALERT_SEVERITY.success);
              amplEvent(`${CONNECT_EVENT} success`);
            } else {
              setMessage(ERROR_MESSAGE);
              amplEvent(`${CONNECT_EVENT} error 1`);
            }
            queryClient.invalidateQueries(QUERY_TYPES.dataPlex);
          })
          .catch((e) => {
            setMessage(ERROR_MESSAGE);
            amplEvent(`${CONNECT_EVENT} error 2. message: ${e.message}`);
          })
          .finally(() => {
            changeLoading(false);
          });
      })
      .catch((e) => {
        const isNotEnoughRights = e.message.includes(ERROR_CODE);
        setMessage(isNotEnoughRights ? PERMISSIONS_MESSAGE : ERROR_MESSAGE);
        changeLoading(false);
        amplEvent(`${CONNECT_EVENT} error 3. message: ${e.message}`);
      });
  });
};

export { connectDataPlex };
