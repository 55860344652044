import { createSlice } from '@reduxjs/toolkit';
import { DATA_STATUS, SLICE_NAME } from 'constants/constants.js';
import { getTables, updateTableDesc, updateTableAlertType } from './actions.js';

const initialState = {
  tablesData: [],
  tablesDataStatus: DATA_STATUS.idle,
  tablesNumber: 0,
  updateAlertTypeStatus: DATA_STATUS.idle,
};

export const tablesSlice = createSlice({
  name: SLICE_NAME.tables,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTables.pending, (state) => {
      state.tablesDataStatus = DATA_STATUS.pending;
    });
    builder.addCase(getTables.fulfilled, (state, action) => {
      state.tablesData = action.payload.values;
      state.tablesDataStatus = DATA_STATUS.fulfilled;
      state.tablesNumber = action.payload.pagination.total;
    });
    builder.addCase(getTables.rejected, (state) => {
      state.tablesDataStatus = DATA_STATUS.rejected;
    });

    builder.addCase(updateTableDesc.fulfilled, (state, action) => {
      state.tablesData = state.tablesData.map((item) => {
        if (
          item.dataset === action.payload.dataset &&
          item.table === action.payload.table
        ) {
          return { ...item, description: action.payload.desc };
        }
        return item;
      });
    });

    builder.addCase(updateTableAlertType.pending, (state) => {
      state.updateAlertTypeStatus = DATA_STATUS.pending;
    });
    builder.addCase(updateTableAlertType.fulfilled, (state, action) => {
      state.updateAlertTypeStatus = DATA_STATUS.fulfilled;
      const updatedUuids = action.payload.selected.map((item) => item.uuid);
      state.tablesData = state.tablesData.map((item) => {
        if (updatedUuids.includes(item.uuid)) {
          return { ...item, alertType: action.payload.selectedAlertType };
        }
        return item;
      });
    });
    builder.addCase(updateTableAlertType.rejected, (state) => {
      state.updateAlertTypeStatus = DATA_STATUS.rejected;
    });
  },
});

export default tablesSlice.reducer;
