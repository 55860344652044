import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  wrapper: {
    position: 'relative',
  },

  loader: {
    height: 32,
    width: 32,

    '&::after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      width: 12,
      height: 12,
      backgroundColor: (props) => {
        if (Boolean(props.isWhiteLoader)) {
          return theme.palette.common.white;
        }
        return theme.palette.primary.main;
      },
      borderRadius: '50%',
      animation:
        '$loader1 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite',
    },

    '& span': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      margin: 'auto',
      animation:
        '$loader2 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite',

      '&::before, &::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        height: 12,
        width: 12,
        backgroundColor: (props) =>
          props.isWhiteLoader
            ? theme.palette.common.white
            : theme.palette.primary.main,
        borderRadius: '50%',
        animation:
          '$loader3 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite',
      },

      '&::after': {
        animation:
          '$loader4 2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite',
      },
    },
  },

  '@keyframes loader1': {
    '0%': { transform: 'scale(0)', opacity: 0 },
    '50%': { transform: 'scale(1)', opacity: 1 },
    '100%': { transform: 'scale(0)', opacity: 0 },
  },

  '@keyframes loader2': {
    '0%': { transform: 'rotate(0deg)' },
    '50%': { transform: 'rotate(180deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },

  '@keyframes loader3': {
    '0%': { transform: 'translate3d(0, 0, 0) scale(1)' },
    '50%': { transform: 'translate3d(-16px, 0, 0) scale(.5)' },
    '100%': { transform: 'translate3d(0, 0, 0) scale(1)' },
  },

  '@keyframes loader4': {
    '0%': { transform: 'translate3d(0, 0, 0) scale(1)' },
    '50%': { transform: 'translate3d(16px, 0, 0) scale(.5)' },
    '100%': { transform: 'translate3d(0, 0, 0) scale(1)' },
  },
}));

const Loader = ({ isWhiteLoader = false }) => {
  const classes = useStyles({ isWhiteLoader });

  return (
    <div className={classes.wrapper}>
      <div className={classes.loader}>
        <span></span>
      </div>
    </div>
  );
};

const PositionLoader = ({ isWhiteLoader = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Loader isWhiteLoader={isWhiteLoader} />
    </div>
  );
};

export { Loader, PositionLoader };
