import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useStyles } from 'Pages/Pipelines/GlobalPipeline.styles.js';
import { EmptySearchData } from 'Pages/Pipelines/components/components.js';
import { PipelineTableHead } from './PipelineTableHead.js';
import { PipelineTableRows } from './PipelineTableRows.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { generateEmptyResultMessage } from 'Pages/Pipelines/libs/helpers/helpers.js';
import {
  SEARCH_EMAIL,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_TABLE,
  SELECTED_TAGS,
} from 'Pages/Pipelines/libs/constants/constants.js';

const PipelineTable = ({
  destinationTables = [],
  isFetchingDestinationTables,
  startDate,
  endDate,
  searchValues,
}) => {
  const classes = useStyles();
  const emptyResultMessage =
    !isFetchingDestinationTables && !destinationTables.length
      ? generateEmptyResultMessage(searchValues)
      : '';

  return (
    <TableContainer sx={{ padding: 0 }}>
      <Table>
        <PipelineTableHead />
        <TableBody className={classes.tableBody}>
          {emptyResultMessage.length ? (
            <EmptySearchData colSpan={9} searchType={emptyResultMessage} />
          ) : (
            destinationTables.length > 0 &&
            destinationTables.map((destinationTable) => {
              return (
                <PipelineTableRows
                  key={getFullTableName(
                    destinationTable.table,
                    destinationTable.dataset
                  )}
                  destinationTable={destinationTable}
                  startDate={startDate}
                  endDate={endDate}
                  searchValue={searchValues[SEARCH_TABLE]}
                  selectedTable={searchValues[SELECTED_TABLE]}
                  principalEmail={searchValues[SELECTED_EMAIL]}
                  searchPrincipalEmail={searchValues[SEARCH_EMAIL]}
                  jobTagNames={searchValues[SELECTED_TAGS]}
                />
              );
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { PipelineTable };
