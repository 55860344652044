import { useQuery, useState, useEffect } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const usePipelineQuery = (jobHash) => {
  const [query, setQuery] = useState('');

  const { data, isFetching } = useQuery(
    [QUERY_TYPES.jobQuery, jobHash],
    ({ queryKey }) => {
      const [url, jobHash] = queryKey;
      return fetcherGet(url, { jobHash });
    }
  );

  useEffect(() => {
    if (data?.value) {
      setQuery(data.value);
    }
  }, [data?.value]);

  return { query, isFetching };
};

export { usePipelineQuery };
