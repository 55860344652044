const EMPTY_DATA_INFO = {
  emptyTableData:
    'It was last updated long before you install Masthead. We are unable to provide any insights here.',
  emptyTableAnomalies:
    'It was last updated long before you install Masthead. We are unable to provide data health metrics to inactive tables.',
  emptyTableLineage:
    'There is no lineage for this table, because the table does not have any interactions inside Google BigQuery.',
};

const EXISTING_DATA_TYPES = {
  volume: 'volume',
  freshness: 'freshness',
  pipelineErrors: 'pipelineErrors',
  reports: 'reports',
  lineage: 'lineage',
};

export { EMPTY_DATA_INFO, EXISTING_DATA_TYPES };
