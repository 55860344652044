import React from 'react';
import { useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useModal } from 'context/context.js';
import {
  Alert,
  Button,
  BUTTON_VARIANT,
  Divider,
  PositionLoader,
} from 'Components/components.js';
import { validateEmail } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { errors } from '../libs/enums/enums.js';
import { ReactComponent as CloseIcon } from 'assets/img/icons/closeIcon.svg';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 474,
    minHeight: 170,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 16,
    background: theme.palette.common.white,
    borderRadius: 16,
  },
  modalContainer: {
    marginBottom: 16,
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 8,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiSelect-select:focus': {
      background: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root': {
      minHeight: 64,
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& .MuiAutocomplete-input': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
    '& .MuiChip-outlined': {
      padding: '5px 8px',
      borderColor: theme.palette.divider,
      borderRadius: 4,
    },
    '& .MuiChip-label': {
      paddingLeft: 0,
    },
  },
  buttonsContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    alignItems: 'center',
  },
}));

const InviteUserModal = ({ inviteUser, existingEmails, setIsInvite }) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const closeModal = () => setModal(null);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const eventButton = 'Users -> Click - Invite (success)';

  const handleChange = (value) => {
    const newValues = [];
    for (const item of value) {
      const newItem = item.replace(/\s\s+/g, ' ');
      for (const email of newItem.split(' ')) {
        if (!validateEmail(email)) {
          onSetError(errors.INVALID_EMAIL);
          continue;
        }

        if (existingEmails.includes(email)) {
          onSetError(errors.USER_EXISTS);
          continue;
        }
        newValues.push(email);
      }
    }

    setInviteEmails(newValues);
  };

  const onSetError = (value) => {
    setError(value);
    setTimeout(() => setError(null), 5000);
  };

  const onAgreeClick = () => {
    const newItem = inputValue.replace(/\s\s+/g, ' ');
    for (const email of newItem.split(' ')) {
      if (email.length && !validateEmail(email)) {
        onSetError(errors.INVALID_EMAIL);
        return;
      }
      if (email.length && existingEmails.includes(email)) {
        onSetError(errors.USER_EXISTS);
        return;
      }
      if (email.length) {
        inviteEmails.push(email);
        amplEvent(eventButton);
      }
      if (!inviteEmails.length) {
        onSetError(errors.EMPTY_FIELD);
        return;
      }
    }

    setIsLoading(true);
    const promises = [];
    for (const newUser of inviteEmails) {
      promises.push(inviteUser(newUser));
    }
    Promise.all(promises)
      .then(() => {
        setIsInvite();
        closeModal();
      })
      .catch(() => {
        onSetError(errors.SIMPLE);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={classes.modalWrapper}>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <>
          <div className='txt-mainDark-16-700'>Invite Team Members</div>

          <Divider />

          <div className={classes.modalContainer}>
            <div className='txt-grey-13-500' style={{ marginBottom: 12 }}>
              Email
            </div>
            <Autocomplete
              multiple
              value={inviteEmails}
              options={[]}
              freeSolo
              onChange={(e, value) => handleChange(value)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant='outlined'
                    label={option}
                    {...getTagProps({ index })}
                    deleteIcon={<CloseIcon />}
                  />
                ))
              }
              renderInput={(params) => {
                setInputValue(params.inputProps?.value || '');
                return <TextField {...params} />;
              }}
            />
          </div>

          <Alert alertComponent={error} className='mb-3' />

          <div className={classes.buttonsContainer}>
            <Button
              text='Cancel'
              variant={BUTTON_VARIANT.text}
              onClick={closeModal}
              fullWidth={false}
            />
            <Button
              text='Send invite'
              onClick={onAgreeClick}
              fullWidth={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { InviteUserModal };
