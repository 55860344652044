import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  divider: {
    '&:after': {
      display: 'block',
      height: 1,
      width: '100%',
      background: theme.palette.divider,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
      content: '""',
    },
  },
  wrapper: {
    display: 'flex',
    flexWrap: 'no-wrap',
  },
  dividerLine: {
    marginTop: 8,
    flexGrow: 1,
    content: '""',
    display: 'block',
    width: '100%',
    top: 12,
    height: 1,
    background: theme.palette.divider,
  },
  dividerText: {
    color: '#627D98',
    padding: '0 8px',
    flexShrink: 0,
    fontSize: 13,
    textAlign: 'center',
  },
}));

const Divider = ({ text = '', className = '' }) => {
  const classes = useStyles();
  return text ? (
    <div className={clsx(className, classes.wrapper)}>
      <span className={classes.dividerLine} />
      <span className={classes.dividerText}>{text}</span>
      <span className={classes.dividerLine} />
    </div>
  ) : (
    <div className={clsx(className, classes.divider)} />
  );
};

export { Divider };
