import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../IncidentCard.styles.js';
import { Typography, TYPOGRAPHY_COLOR } from 'Components/components.js';
import theme from 'theme.js';

const MetaDataItem = ({
  label = '',
  isBold = false,
  isLargeGap = false,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Typography
        color={TYPOGRAPHY_COLOR.textSecondary}
        className={clsx(classes.colLabel, isLargeGap && 'isLarge')}
      >
        {label}
      </Typography>

      <Typography
        sx={{
          fontWeight: isBold
            ? theme.typography.fontWeightBold
            : theme.typography.fontWeightMedium,
        }}
      >
        {children}
      </Typography>
    </div>
  );
};

export { MetaDataItem };
