import { TABLE_TYPES } from 'constants/constants.js';

const getTableTypeKey = (dataset, tableType) => `${dataset}-${tableType}`;
const getTableKey = (dataset, tableType, table) =>
  `${dataset}-${tableType}-${table}`;
const getLoadMoreKey = (dataset, tableType, page) =>
  `${dataset}-${tableType}-loadMore-${page}`;
const getTableFolderKey = (dataset, tableType, folderMatch) =>
  `${dataset}-${tableType}-folder-${folderMatch}`;

const tableTypeLabel = (tableType) => {
  switch (tableType) {
    case TABLE_TYPES.dbTable:
      return 'tables';
    case TABLE_TYPES.view:
      return 'views';
    case TABLE_TYPES.external:
      return 'externals';
    default:
      return 'undefined';
  }
};

const datasetObject = (dataset, children) => {
  return {
    key: dataset,
    title: dataset,
    children: children,
    isDataset: true,
  };
};

const tableTypeObject = (dataset, tableType, num) => {
  return {
    key: getTableTypeKey(dataset, tableType),
    title: `${tableTypeLabel(tableType)} [${num}]`,
    children: [],
    dataset: dataset,
    type: tableType,
    total: num,
    page: 1,
  };
};

const tableObject = (
  dataset,
  tableType,
  table,
  uuid,
  alertType,
  isPartition,
  isPreloaded = false
) => {
  const obj = {
    key: getTableKey(dataset, tableType, table),
    title: table,
    isLeaf: true,
    dataset: dataset,
    type: tableType,
    table: table,
    uuid: uuid,
    alertType: alertType,
    isPartition: isPartition,
  };
  if (isPreloaded) {
    obj.isPreloaded = true;
  }
  return obj;
};

const folderObjectTitle = (folderMatch, num) => {
  return `${folderMatch} [${num}]`;
};

const folderObject = (dataset, tableType, folderMatch) => {
  return {
    key: getTableFolderKey(dataset, tableType, folderMatch),
    title: folderObjectTitle(folderMatch, 0),
    children: [],
    folderMatch: folderMatch,
    isFolder: true,
    className: 'isFolder',
  };
};

const loadMoreObject = (dataset, tableType, nextPage) => {
  return {
    key: getLoadMoreKey(dataset, tableType, nextPage),
    title: 'load more...',
    dataset: dataset,
    type: tableType,
    page: nextPage,
    isLoadMore: true,
    className: 'isLoadMore',
  };
};

const treeDataPrepare = (datasets) => {
  return datasets.map((datasetItem) => {
    const children = [];
    if (datasetItem.numTables) {
      children.push(
        tableTypeObject(
          datasetItem.dataset,
          TABLE_TYPES.dbTable,
          datasetItem.numTables
        )
      );
    }
    if (datasetItem.numViews) {
      children.push(
        tableTypeObject(
          datasetItem.dataset,
          TABLE_TYPES.view,
          datasetItem.numViews
        )
      );
    }
    if (datasetItem.numExternals) {
      children.push(
        tableTypeObject(
          datasetItem.dataset,
          TABLE_TYPES.external,
          datasetItem.numExternals
        )
      );
    }
    return datasetObject(datasetItem.dataset, children);
  });
};

const getFolderMatch = (table) => {
  if (!table) {
    return;
  }
  const match = table.match(/(.+_)20\d{6}$/);
  return !match || match.length < 2 ? null : match[1];
};

const findByKey = (children, key) => {
  for (let i = 0; i < children.length; i++) {
    const child = children[i];
    if (child.key === key) {
      return true;
    } else if (child?.children?.length) {
      const result = findByKey(child.children, key);
      if (result) {
        return true;
      }
    }
  }
  return false;
};

const popIsLoadMoreItem = (children) => {
  if (!children.length) {
    return;
  }
  if (children.at(-1)?.isLoadMore) {
    return children.pop();
  }
  return;
};

const popIsPreloadedItem = (tableTypeItem, children) => {
  if (!children.length || !tableTypeItem.hasPreloadedItem) {
    return;
  }
  if (children.at(-1)?.isPreloaded) {
    children.pop();
  } else {
    const folderItem = children.findLast(
      (child) => child?.isFolder && child.children.at(-1)?.isPreloaded
    );
    if (folderItem) {
      folderItem.children.pop();
    }
  }
};

const addChildTableItem = (children, tableItem) => {
  const folderMatch = getFolderMatch(tableItem.table);
  if (folderMatch) {
    let folderItem;
    const folderKey = getTableFolderKey(
      tableItem.dataset,
      tableItem.type,
      folderMatch
    );
    const index = children.findIndex((item) => item.key === folderKey);
    if (index === -1) {
      folderItem = folderObject(tableItem.dataset, tableItem.type, folderMatch);
      children.push(folderItem);
    } else {
      folderItem = children[index];
    }
    folderItem.children.push(tableItem);
    folderItem.title = folderObjectTitle(
      folderMatch,
      folderItem.children.length
    );
  } else {
    children.push(tableItem);
  }
};

const prepareChildTables = (dataset, tableType, children, fetchedTables) => {
  return fetchedTables.reduce((newChildren, fetchedTable) => {
    addChildTableItem(
      newChildren,
      tableObject(
        dataset,
        tableType,
        fetchedTable.table,
        fetchedTable.uuid,
        fetchedTable.alertType,
        fetchedTable.isPartition
      )
    );
    return newChildren;
  }, children);
};

const addPreloadedTableItem = (tableTypeItem, selectTable, children) => {
  if (
    !selectTable ||
    selectTable.dataset !== tableTypeItem.dataset ||
    selectTable.type !== tableTypeItem.type
  ) {
    return false;
  }

  const selectedTableKey = getTableKey(
    selectTable.dataset,
    selectTable.type,
    selectTable.table
  );
  if (findByKey(children, selectedTableKey)) {
    return false;
  }

  addChildTableItem(
    children,
    tableObject(
      selectTable.dataset,
      selectTable.type,
      selectTable.table,
      selectTable.uuid,
      selectTable.alertType,
      selectTable.isPartition,
      true
    )
  );

  return true;
};

const treeDataAddTables = (
  treeData,
  dataset,
  tableType,
  fetchedTables,
  limit,
  selectTable
) => {
  return treeData.map((datasetItem) => {
    if (datasetItem.key !== dataset) {
      return datasetItem;
    }
    return {
      ...datasetItem,
      children: datasetItem.children.map((tableTypeItem) => {
        if (tableTypeItem.key !== getTableTypeKey(dataset, tableType)) {
          return tableTypeItem;
        }

        let children = tableTypeItem.children;

        popIsLoadMoreItem(children);
        popIsPreloadedItem(tableTypeItem, children);

        children = prepareChildTables(
          dataset,
          tableType,
          children,
          fetchedTables
        );

        const hasPreloadedItem = addPreloadedTableItem(
          tableTypeItem,
          selectTable,
          children
        );

        const nextPage = tableTypeItem.page + 1;
        const hasLoadMore = tableTypeItem.total > tableTypeItem.page * limit;
        if (hasLoadMore) {
          children.push(loadMoreObject(dataset, tableType, nextPage));
        }

        return {
          ...tableTypeItem,
          children: children,
          page: nextPage,
          hasPreloadedItem: hasPreloadedItem,
        };
      }),
    };
  });
};

/** add or change preloaded tree item  */
const treeDataSelectTable = (treeData, selectTable) => {
  return treeData.map((datasetItem) => {
    if (datasetItem.key !== selectTable.dataset) {
      return datasetItem;
    }
    return {
      ...datasetItem,
      children: datasetItem.children.map((tableTypeItem) => {
        const selectTableTypeKey = getTableTypeKey(
          selectTable.dataset,
          selectTable.type
        );
        if (
          tableTypeItem.key !== selectTableTypeKey ||
          !tableTypeItem.children.length
        ) {
          return tableTypeItem;
        }

        const children = tableTypeItem.children;
        const loadMoreItem = popIsLoadMoreItem(children);

        popIsPreloadedItem(tableTypeItem, children);

        const hasPreloadedItem = addPreloadedTableItem(
          tableTypeItem,
          selectTable,
          children
        );

        if (loadMoreItem) {
          children.push(loadMoreItem);
        }

        return {
          ...tableTypeItem,
          children: children,
          hasPreloadedItem: hasPreloadedItem,
        };
      }),
    };
  });
};

const getTableInfoFromNode = (node, currentProject) => ({
  project: currentProject,
  uuid: node.uuid,
  dataset: node.dataset,
  table: node.table,
  type: node.type,
  isPartition: node.isPartition,
  alertType: node.alertType,
});

export {
  getTableKey,
  getTableFolderKey,
  getTableTypeKey,
  getFolderMatch,
  treeDataAddTables,
  treeDataPrepare,
  treeDataSelectTable,
  getTableInfoFromNode,
};
