import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    width: 588,
    padding: 26,
    marginBottom: 24,
    backgroundColor: theme.palette.secondary.light,
    borderRadius: 16,
    '& .formBlock': {
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
  },
}));
