import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  resizer: {
    position: 'absolute',
    height: '100%',
    minHeight: '100vh',
    width: 10,
    right: 0,
    top: 0,
    borderRight: `1px solid ${theme.palette.divider}`,
    cursor: 'ew-resize',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 4,
  },
  resizerDivider: {
    position: 'fixed',
    top: '50vh',
    height: 30,
    width: 3,
    borderRadius: 10,
    background: theme.palette.divider,
  },
  treeDivider: {
    position: 'fixed',
    height: '100vh',
    width: 1,
    background: theme.palette.divider,
    left: (props) => props.left,
  },
  treeTitle: {
    paddingLeft: 24,
    paddingTop: 36,
    display: 'flex',
  },
  treeContainer: {
    position: 'fixed',
    left: (props) => props.left + 1,
    width: 320,
    height: '100vh',
    backgroundColor: theme.palette.common.white,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: 3,
    transition: theme.transitions.create(['left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    '& .rc-tree': {
      border: 0,
      paddingLeft: 24,
      paddingTop: 16,
      paddingRight: 1.5,
      '& .rc-tree-list-holder-inner': {
        alignItems: 'flex-start',
      },
      '&-list-scrollbar': {
        '&-thumb': {
          width: '6px !important',
          height: '30px !important',
          backgroundColor: `${theme.palette.divider} !important`,
          borderRadius: '10px !important',
        },
      },
      '& .rc-tree-treenode': {
        display: 'flex',
        alignItems: 'center',
        height: (props) => props.itemFullHeight,
        paddingBottom: (props) => props.itemFullHeight - props.itemHeight,
        lineHeight: (props) => `${props.itemHeight}px`,

        '& .rc-tree-switcher': {
          position: 'relative',
          zIndex: 1,
          width: 20,
          paddingLeft: 12,
          marginRight: 0,
          verticalAlign: 'initial',
          backgroundColor: 'initial',
          backgroundImage: 'initial',
          '& svg': {
            position: 'relative',
          },
          '&.rc-tree-switcher_close svg': {
            top: -1,
            left: 2,
          },
          '&.rc-tree-switcher_open svg': {
            top: -0.5,
          },
        },

        '& .rc-tree-node-content-wrapper': {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          paddingLeft: 12,
          paddingRight: 12,
          color: theme.palette.common.mainDarkText,
          borderRadius: 4,
          '& .rc-tree-iconEle': {
            display: 'block',
            width: 20,
            height: 20,
            marginRight: 5,
          },
          '& .rc-tree-title': {
            display: 'block',
            fontSize: 13,
            fontWeight: 500,
          },
          '& .MuiCircularProgress-root': {
            position: 'relative',
            top: 3,
            left: 3,
            color: theme.palette.primary.main,
          },
        },
        '&.rc-tree-treenode-switcher-open .rc-tree-node-content-wrapper': {
          left: -20,
          paddingLeft: 28,
        },
        '&.rc-tree-treenode-switcher-close .rc-tree-node-content-wrapper': {
          left: -20,
          paddingLeft: 28,
        },

        '&.rc-tree-treenode-selected': {
          '& .rc-tree-switcher': {
            '& svg path': {
              fill: theme.palette.primary.main,
            },
          },
          '& .rc-tree-node-content-wrapper': {
            boxShadow: 'initial',
            opacity: 'initial',
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.secondary.main,
            '& .rc-tree-iconEle': {
              '& svg path': {
                fill: theme.palette.primary.main,
              },
            },
          },
        },

        '&.isFolder': {
          '& .rc-tree-switcher': {
            left: 26,
          },
          '& .rc-tree-node-content-wrapper': {
            left: 0,
            paddingLeft: 38,
            '& .rc-tree-iconEle': {
              display: 'none',
            },
          },
        },

        '&.isLoadMore': {
          '& .rc-tree-switcher': {
            display: 'none',
          },
          '& .rc-tree-node-content-wrapper': {
            left: 20,
            paddingLeft: 12,
            color: theme.palette.primary.main,
            '& .rc-tree-iconEle': {
              '& svg path': {
                fill: theme.palette.primary.main,
              },
            },
          },
        },

        '& .rc-tree-node-content-wrapper:hover': {
          color: theme.palette.primary.main,
          '& .rc-tree-iconEle': {
            '& svg path': {
              fill: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 5,
    },
  },
}));

export { useStyles };
