export const columnsTypeId = 'columnsType';
export const columnNameId = 'COLUMN';
export const descriptionId = 'description';
export const datasetId = 'DATASET';

export const headType = {
  id: columnsTypeId,
  label: '',
  sort: false,
};
export const headColumnName = {
  id: columnNameId,
  label: 'Columns name',
  sort: true,
};
export const headDescription = {
  id: descriptionId,
  label: 'Description',
};
export const headDataset = {
  id: datasetId,
  label: 'Table',
  sort: false,
};
