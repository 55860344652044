import React from 'react';
import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useStyles } from 'Pages/Integrations/Integrations.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { fetcherDelete } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { amplEvent } from 'service/services.js';

const DISCONNECT_EVENT = 'DataPlex -> Click Disconnect button - success';

const DataplexItem = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const { mutateAsync: cancelDataPlex } = useMutation(
    () => fetcherDelete(QUERY_TYPES.dataPlex),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_TYPES.dataPlex),
    }
  );

  const onDisconnect = () => {
    cancelDataPlex();
    amplEvent(DISCONNECT_EVENT);
  };

  return (
    <div className={classes.cardItem}>
      <Button
        text='Disconnect'
        variant={BUTTON_VARIANT.text}
        onClick={onDisconnect}
        fullWidth={false}
        className='p-0'
      />
    </div>
  );
};

export { DataplexItem };
