import React, { createContext } from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMutation,
  useQuery,
  useQueryClient,
  useLocation,
  useMemo,
} from 'hooks/hooks.js';
import {
  fetcherDelete,
  fetcherGet,
  fetcherPost,
  fetcherPut,
} from 'utils/utils.js';
import { useConfiguration, useModal, useUserInfo } from 'context/context.js';
import {
  CostsBanner,
  OnboardingBanner,
  DataplexReconnectBanner,
} from 'Components/components.js';
import {
  COST_BANNER,
  ONBOARDING_BANNER,
  DATAPLEX_RECONNECT,
  QUERY_TYPES,
  BANNERS_TYPE,
  PRIORITY_TABLES,
} from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';

const BannerContext = createContext(null);

const getBannerByType = (bannerType) => {
  switch (bannerType) {
    case BANNERS_TYPE.onboarding:
      return ONBOARDING_BANNER;
    case BANNERS_TYPE.cost:
      return COST_BANNER;
    case BANNERS_TYPE.dataPlex:
      return DATAPLEX_RECONNECT;
    case BANNERS_TYPE.priorityTables:
      return PRIORITY_TABLES;
    default:
      return null;
  }
};

const BannerContextProvider = ({ children }) => {
  const { user, currentProject } = useUserInfo();
  const location = useLocation();
  const { isPrivateRoute, isDataplexApiDisabled } = useConfiguration();
  const queryClient = useQueryClient();
  const { setModal, Modal } = useModal();
  const [currentBanners, setCurrentBanners] = useState([]);

  const isDataQualityPage = useMemo(() => {
    return location.pathname.includes(AppRoutes.DataQuality.path);
  }, [location.pathname]);

  const { data: banners } = useQuery(
    [QUERY_TYPES.banners],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );

  const { data: bqBillingData } = useQuery(
    [QUERY_TYPES.bqBillingData],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    },
    { enabled: !!user && isPrivateRoute }
  );
  const { mutateAsync: deleteBanner } = useMutation(
    (bannerType) => fetcherDelete(`/api/v1/banners/${bannerType}`, {}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_TYPES.banners);
      },
    }
  );

  const { mutateAsync: createBanner } = useMutation(
    (bannerType) => fetcherPost(`/api/v1/banners`, { type: bannerType }),
    {
      onSuccess: (data) => {
        if (data?.value) {
          queryClient.invalidateQueries(QUERY_TYPES.banners);
        }
      },
    }
  );

  const { mutateAsync: updateBanner } = useMutation(
    (bannerType) => fetcherPut(`/api/v1/banners/${bannerType}`, {}),
    {
      onSuccess: (data) => {
        if (data?.value) {
          queryClient.invalidateQueries(QUERY_TYPES.banners);
        }
      },
    }
  );

  const handleCloseBanner = useCallback(
    (bannerType) => {
      return () => {
        setCurrentBanners((prev) =>
          prev.filter((banner) => banner.type !== bannerType)
        );
        updateBanner(bannerType);
      };
    },
    [updateBanner]
  );

  const createCostsBanner = useCallback(() => {
    setModal(() => (
      <CostsBanner onClose={handleCloseBanner(BANNERS_TYPE.cost)} />
    ));

    setCurrentBanners((prev) =>
      prev.filter((banner) => banner.type !== BANNERS_TYPE.cost)
    );
  }, [handleCloseBanner, setModal]);

  const createOnboardingBanner = useCallback(() => {
    setModal(() => (
      <OnboardingBanner onClose={handleCloseBanner(BANNERS_TYPE.onboarding)} />
    ));

    setCurrentBanners((prev) =>
      prev.filter((banner) => banner.type !== BANNERS_TYPE.onboarding)
    );
  }, [handleCloseBanner, setModal]);

  const createDataplexReconnectBanner = useCallback(() => {
    setModal(() => (
      <DataplexReconnectBanner
        onClose={handleCloseBanner(BANNERS_TYPE.dataPlex)}
        user={user}
        currentProject={currentProject}
      />
    ));

    setCurrentBanners((prev) =>
      prev.filter((banner) => banner.type !== BANNERS_TYPE.dataPlex)
    );
  }, [handleCloseBanner, setModal, user]);

  const createPriorityTablesBanner = useCallback(() => {
    setCurrentBanners((prev) =>
      prev.filter((banner) => banner.type !== BANNERS_TYPE.priorityTables)
    );
  }, []);

  useEffect(() => {
    if (currentBanners.length && Modal === null) {
      const banner = currentBanners.at(0);
      switch (banner.type) {
        case BANNERS_TYPE.priorityTables:
          createPriorityTablesBanner();
          return;
        case BANNERS_TYPE.cost:
          createCostsBanner();
          return;
        case BANNERS_TYPE.onboarding:
          createOnboardingBanner();
          return;
        case BANNERS_TYPE.dataPlex:
          createDataplexReconnectBanner();
          return;
        default:
          return;
      }
    }
  }, [
    Modal,
    createCostsBanner,
    createDataplexReconnectBanner,
    createOnboardingBanner,
    currentBanners,
    createPriorityTablesBanner,
  ]);

  const isShowBanner = useCallback((banner) => {
    const bannerOptions = getBannerByType(banner.type);
    const lastViewTimeMs = banner.lastViewTime * 1000;

    return (
      banner.viewsNumber < bannerOptions.maxViewsNumber &&
      Date.now() > lastViewTimeMs + bannerOptions.periodBetweenViewsMs
    );
  }, []);

  useEffect(() => {
    if (banners?.values) {
      const banner = banners.values.find(
        (item) => item.type === BANNERS_TYPE.onboarding
      );

      if (!banner) {
        createBanner(BANNERS_TYPE.onboarding);
      }

      if (banner && isShowBanner(banner)) {
        setCurrentBanners((prev) => [...new Set([...prev, banner])]);
      } else {
        setCurrentBanners((prev) =>
          prev.filter((banner) => banner.type !== BANNERS_TYPE.onboarding)
        );
      }
    }
  }, [banners?.values, createBanner, isShowBanner]);

  useEffect(() => {
    if (banners?.values) {
      const banner = banners.values.find(
        (item) => item.type === BANNERS_TYPE.priorityTables
      );

      if (!banner) {
        createBanner(BANNERS_TYPE.priorityTables);
      }

      if (banner && isShowBanner(banner)) {
        setCurrentBanners((prev) => [...new Set([...prev, banner])]);
      } else {
        setCurrentBanners((prev) =>
          prev.filter((banner) => banner.type !== BANNERS_TYPE.priorityTables)
        );
      }
    }
  }, [banners?.values, createBanner, isShowBanner]);

  useEffect(() => {
    if (banners?.values && isDataplexApiDisabled && !isDataQualityPage) {
      const banner = banners.values.find(
        (item) => item.type === BANNERS_TYPE.dataPlex
      );

      if (!banner) {
        createBanner(BANNERS_TYPE.dataPlex);
      }

      if (banner && isShowBanner(banner)) {
        setCurrentBanners((prev) => [...new Set([...prev, banner])]);
      } else {
        setCurrentBanners((prev) =>
          prev.filter((banner) => banner.type !== BANNERS_TYPE.dataPlex)
        );
      }
    }
  }, [banners?.values, createBanner, isShowBanner, isDataplexApiDisabled]);

  useEffect(() => {
    if (bqBillingData?.values && banners?.values && currentProject) {
      const banner = banners.values.find(
        (item) => item.type === BANNERS_TYPE.cost
      );
      const projectBillingData = bqBillingData.values.find(
        (item) => user.info.currentProject === item.project
      );
      if (!projectBillingData && !banner) {
        createBanner(BANNERS_TYPE.cost);
      }
      if (!projectBillingData && banner && isShowBanner(banner)) {
        setCurrentBanners((prev) => [...new Set([...prev, banner])]);
      } else {
        setCurrentBanners((prev) =>
          prev.filter((banner) => banner.type !== BANNERS_TYPE.cost)
        );
      }
      if (projectBillingData && banner) {
        deleteBanner(BANNERS_TYPE.cost);
      }
    }
  }, [
    bqBillingData,
    createBanner,
    deleteBanner,
    currentProject,
    banners,
    isShowBanner,
  ]);

  return (
    <BannerContext.Provider
      value={{ handleCloseBanner, isShowBanner, banners: banners?.values }}
    >
      {children}
    </BannerContext.Provider>
  );
};

const useBanner = () => useContext(BannerContext);

export { BannerContext, BannerContextProvider, useBanner };
