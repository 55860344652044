import React, { createContext } from 'react';
import { useContext, useState } from 'hooks/hooks.js';

const ProjectTreeContext = createContext(null);
const ProjectTreeContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectTable, setSelectTable] = useState(null);
  const [onSelectTable, setOnSelectTable] = useState(null);
  const [selectDataset, setSelectDataset] = useState(null);
  const [onSelectDataset, setOnSelectDataset] = useState(null);

  return (
    <ProjectTreeContext.Provider
      value={{
        isOpen,
        setIsOpen,
        selectTable,
        setSelectTable,
        onSelectTable,
        setOnSelectTable,
        selectDataset,
        setSelectDataset,
        onSelectDataset,
        setOnSelectDataset,
      }}
    >
      {children}
    </ProjectTreeContext.Provider>
  );
};

const useProjectTree = () => useContext(ProjectTreeContext);

export { ProjectTreeContext, ProjectTreeContextProvider, useProjectTree };
