import React from 'react';
import theme from 'theme.js';
import { INPUT_LABEL } from './constants.js';

const INPUT_VALUE_ERRORS = {
  limitCharacters: 'You have reached your maximum limit of characters allowed',
  nameCompanyRequired: `The fields "${INPUT_LABEL.name}" and "${INPUT_LABEL.company}" are required`,
  nameRequired: `The field "${INPUT_LABEL.name}" is required`,
  companyRequired: `The field "${INPUT_LABEL.company}" is required`,
  addGcp: `Add ${INPUT_LABEL.projectId}`,
  chooseGcp: 'Choose GCP Project',
  notEnoughPermissions: (
    <>
      <div>Missing resources or insufficient permissions.</div>
      <span>Please refer to&nbsp;</span>
      <a
        href='https://docs.mastheadata.com/masthead-agent/saas-deployment/saas-manual-resource-creation-google-cloud'
        target='_blank'
        rel='noreferrer'
        className='link'
        style={{ color: theme.palette.error.main }}
      >
        Deployment Guidelines
      </a>
      <span>
        &nbsp;for instructions on creating the required resources or adjusting
        Service Account permissions.
      </span>
    </>
  ),
};

export { INPUT_VALUE_ERRORS };
