import { useState } from 'hooks/hooks.js';
import { SORT_ORDER } from './constants/contants.js';

const useSort = (orderByProps) => {
  const [order, setOrder] = useState(SORT_ORDER.asc);
  const [orderBy, setOrderBy] = useState(orderByProps);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === SORT_ORDER.asc;
    setOrder(isAsc ? SORT_ORDER.desc : SORT_ORDER.asc);
    setOrderBy(property);
  };

  return { order, orderBy, handleRequestSort };
};

export { useSort };
