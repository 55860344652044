import React, { createContext } from 'react';
import { useContext, useState } from 'hooks/hooks.js';
import ModalBase from './ModalBase.js';

const ModalContext = createContext({});

const ModalContextProvider = ({ children }) => {
  const [Modal, setModal] = useState(null);

  return (
    <ModalContext.Provider value={{ setModal, Modal }}>
      <>
        {Modal && <ModalBase>{Modal}</ModalBase>}
        {children}
      </>
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { ModalContextProvider, useModal };
