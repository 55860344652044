import React from 'react';
import { Highlight } from 'Components/components.js';

const highlightText = (string, searchTable = null, searchValue = '') => {
  return (
    <Highlight
      searchTable={searchTable}
      searchValue={searchValue}
      value={string}
    />
  );
};

export { highlightText };
