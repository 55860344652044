import React from 'react';
import { useCallback, useState, useEffect, useQuery } from 'hooks/hooks.js';
import { useStyles } from '../IncidentCard.styles.js';
import { Button, BUTTON_COLOR } from 'Components/components.js';
import JiraIssueModal from 'Components/Jira/JiraIssueModal.js';
import { getJiraIntegrationUrl } from 'Pages/Integrations/libs/helpers/helpers.js';
import { useConfiguration, useModal } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { ReactComponent as JiraIcon } from 'assets/img/icons/jiraIcon.svg';

const JiraButton = ({
  groupIncident,
  currentProject,
  tableInfo,
  isCurrentProjectDemo,
  incidentLink,
}) => {
  const classes = useStyles();
  const { integrations } = useConfiguration();
  const { setModal } = useModal();
  const [jiraIssue, setJiraIssue] = useState(null);

  const { data } = useQuery(
    [
      QUERY_TYPES.jiraIssue,
      groupIncident.incidentId,
      groupIncident.incidentType,
      groupIncident.tableUuid,
    ],
    ({ queryKey }) => {
      const [url, incidentId, incidentType, tableUuid] = queryKey;
      const params = { incidentId, incidentType };
      if (tableUuid) {
        params['datasetUuid'] = tableUuid;
      }
      return fetcherGet(url, params);
    },
    { enabled: !!integrations.jira }
  );

  useEffect(() => {
    if (data?.value) {
      setJiraIssue(data?.value);
    }
  }, [data?.value]);

  const handleChangeJiraIssue = (issue) => {
    setJiraIssue(issue);
  };

  const onPushJiraIssue = useCallback(() => {
    return setModal(() => (
      <JiraIssueModal
        groupIncident={groupIncident}
        gcpProject={currentProject}
        tableInfo={tableInfo}
        copyLink={incidentLink}
        onPush={handleChangeJiraIssue}
      />
    ));
  }, [currentProject, groupIncident, incidentLink, setModal, tableInfo]);

  const toJiraIssue = (issueKey) => {
    window.open(
      `https://${integrations.jira.companyName}.atlassian.net/browse/${issueKey}`,
      '_blank'
    );
  };

  if (isCurrentProjectDemo) {
    return null;
  }

  return (
    <>
      {!integrations.jira ? (
        <Button
          onClick={() => (window.location = getJiraIntegrationUrl())}
          text='Push to Jira'
          startIcon={<JiraIcon />}
          color={BUTTON_COLOR.secondary}
          className='cardButton'
        />
      ) : jiraIssue ? (
        <button
          className='btn btn-light'
          onClick={() => toJiraIssue(jiraIssue.key)}
        >
          <JiraIcon style={{ marginRight: 8 }} />
          <>
            <span className={classes.jiraStatus}>{jiraIssue.key}. </span>
            {jiraIssue.status}
          </>
        </button>
      ) : (
        <Button
          onClick={onPushJiraIssue}
          text='Push to Jira'
          startIcon={<JiraIcon />}
          color={BUTTON_COLOR.secondary}
          className='cardButton'
        />
      )}
    </>
  );
};

export { JiraButton };
