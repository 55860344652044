import React from 'react';
import { useState, useCallback } from 'hooks/hooks.js';
import { Input } from 'Components/components.js';
import { numberInput } from 'utils/helpers/helpers.js';
import {
  CONTROL_ELEMENTS_DATA,
  INPUT_ERRORS,
} from '../../libs/constants/constants.js';

const MAX_CUSTOM_SIZE = 100;
const MIN_CUSTOM_SIZE = 1;

const ThresholdInput = ({ defaultValue = MAX_CUSTOM_SIZE, onChange }) => {
  const [value, setValue] = useState(defaultValue.toString());
  const [error, setError] = useState(null);
  const { thresholdInput } = CONTROL_ELEMENTS_DATA;

  const handleChangeValue = useCallback(
    (newValue) => {
      if (
        (newValue.length && !numberInput(newValue)) ||
        +newValue > MAX_CUSTOM_SIZE ||
        +newValue < MIN_CUSTOM_SIZE
      ) {
        setError(INPUT_ERRORS.invalidThresholdValue);
      } else {
        setError(null);
        onChange(newValue);
      }

      setValue(newValue);
    },
    [onChange]
  );

  return (
    <Input
      value={value}
      onChange={handleChangeValue}
      label={thresholdInput.label}
      id={thresholdInput.id}
      isRequired={thresholdInput.isRequired}
      helperText={thresholdInput.helperText}
      errorComponent={error}
    />
  );
};

export { ThresholdInput };
