import G6 from '@antv/g6';

const getMaxTextLength = (string, maxWidth, fontSize) => {
  const ellipsis = '...';
  const ellipsisLength = G6.Util.getTextSize(ellipsis, fontSize)[0];
  let currentWidth = 0;
  let result = string;
  const pattern = new RegExp('[\u4E00-\u9FA5]+');
  string.split('').forEach((letter, i) => {
    if (currentWidth > maxWidth - ellipsisLength) {
      return;
    }
    if (pattern.test(letter)) {
      currentWidth += fontSize;
    } else {
      currentWidth += G6.Util.getLetterWidth(letter, fontSize);
    }
    if (currentWidth > maxWidth - ellipsisLength) {
      result = `${string.substring(0, i)}${ellipsis}`;
    }
  });
  return result;
};

export { getMaxTextLength };
