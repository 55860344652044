import React from 'react';
import {
  useQueryClient,
  useQuery,
  useMemo,
  useCallback,
  useState,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image, Card, Col } from 'react-bootstrap';
import { useStyles } from '../Integrations.styles.js';
import {
  useConfiguration,
  useMessages,
  useModal,
  useUserInfo,
} from 'context/context.js';
import { Button, BUTTON_COLOR, LoadingButton } from 'Components/components.js';
import {
  connectDataPlex,
  connectDataForm,
  getJiraIntegrationUrl,
} from '../libs/helpers/helpers.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import {
  INTEGRATIONS,
  REDIRECT_PAGERDUTY_URL,
} from '../libs/constants/constants.js';
import { LookerModal } from './components.js';
import {
  SlackItem,
  JiraItem,
  LookerItem,
  DataplexItem,
  DataformItem,
  PagerDutyItem,
} from './IntegrationItems/items.js';
import ConnectedIcon from 'assets/img/icons/connectedIcon.svg';

const IntegrationItem = ({
  isConnected,
  Icon,
  name,
  title,
  unavailable,
  desc,
}) => {
  const { integrations } = useConfiguration();
  const { user } = useUserInfo();
  const classes = useStyles();
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const queryClient = useQueryClient();
  const [isLoadingDataplexConnection, setIsLoadingDataplexConnection] =
    useState(false);
  const [isLoadingDataformConnection, setIsLoadingDataformConnection] =
    useState(false);

  const { data: slackUrl } = useQuery(
    [QUERY_TYPES.slackSettings],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const { data: basePagerDutyUrl } = useQuery(
    [QUERY_TYPES.pagerDutySettings],
    ({ queryKey }) => {
      const [url] = queryKey;
      return fetcherGet(url);
    }
  );

  const isShowConnectSlackButton = useMemo(() => {
    return name === INTEGRATIONS.slack && slackUrl?.value?.connectionUrl;
  }, [name, slackUrl?.value?.connectionUrl]);

  const isShowConnectPagerDutyButton = useMemo(() => {
    return (
      name === INTEGRATIONS.pagerduty && basePagerDutyUrl?.value?.connectionUrl
    );
  }, [name, basePagerDutyUrl?.value?.connectionUrl]);

  const pagerDutyUrl = useMemo(() => {
    if (basePagerDutyUrl?.value) {
      const url = new URL(basePagerDutyUrl.value?.connectionUrl);
      url.searchParams.set('redirect_url', REDIRECT_PAGERDUTY_URL);

      return url.toString();
    }

    return null;
  }, [basePagerDutyUrl?.value]);

  const openLookerModal = () => {
    setModal(() => <LookerModal />);
  };

  const handleChangeIsLoadingDataplexConnection = useCallback((value) => {
    setIsLoadingDataplexConnection(value);
  }, []);

  const handleChangeIsLoadingDataformConnection = useCallback((value) => {
    setIsLoadingDataformConnection(value);
  }, []);

  const onDataplexConnectionClick = useCallback(() => {
    connectDataPlex(
      user,
      queryClient,
      setMessage,
      handleChangeIsLoadingDataplexConnection
    );
  }, [handleChangeIsLoadingDataplexConnection, queryClient, setMessage, user]);

  const onDataformConnectionClick = useCallback(() => {
    connectDataForm(
      user,
      queryClient,
      setMessage,
      handleChangeIsLoadingDataformConnection
    );
  }, []);

  const ViewByType =
    {
      slack: SlackItem,
      jira: JiraItem,
      looker: LookerItem,
      dataplex: DataplexItem,
      dataform: DataformItem,
      pagerduty: PagerDutyItem,
    }[name] || null;

  return (
    <Col xs={12} md='auto' style={{ marginBottom: 20 }}>
      <Card border='white'>
        <Card.Body className={classes.card}>
          <div>
            <div className={classes.logoContainer}>
              <Image src={Icon} className='logoImg' alt='company logo' />
              <div>
                {isConnected && (
                  <div className={classes.chipContainer}>
                    <img src={ConnectedIcon} alt='icon' />
                    <div className={classes.chip}>Connected</div>
                  </div>
                )}
                {!isConnected && unavailable && (
                  <span
                    style={{ padding: 4 }}
                    className={clsx(classes.chip, 'disabled')}
                  >
                    Coming Soon
                  </span>
                )}
              </div>
            </div>
            <div className='txt-mainDark-20-700'>{title}</div>
            <p className='txt-grey-13-500 my-1'>{desc}</p>
          </div>

          {ViewByType && isConnected && (
            <ViewByType {...integrations?.[name]} />
          )}

          {!isConnected && !unavailable && (
            <div>
              {isShowConnectSlackButton && (
                <Button
                  text='Connect'
                  color={BUTTON_COLOR.secondary}
                  onClick={() =>
                    (window.location = slackUrl.value.connectionUrl)
                  }
                />
              )}

              {name === INTEGRATIONS.jira && (
                <Button
                  text='Connect'
                  color={BUTTON_COLOR.secondary}
                  onClick={() => (window.location = getJiraIntegrationUrl())}
                />
              )}

              {isShowConnectPagerDutyButton && (
                <Button
                  text='Connect'
                  color={BUTTON_COLOR.secondary}
                  onClick={() => (window.location = pagerDutyUrl)}
                />
              )}

              {name === INTEGRATIONS.looker && (
                <Button
                  text='Connect to project'
                  color={BUTTON_COLOR.secondary}
                  onClick={openLookerModal}
                />
              )}

              {name === INTEGRATIONS.dataplex && (
                <LoadingButton
                  isLoading={isLoadingDataplexConnection}
                  onClick={onDataplexConnectionClick}
                  children='Connect to project'
                  fullWidth={true}
                />
              )}

              {name === INTEGRATIONS.dataform && (
                <LoadingButton
                  isLoading={isLoadingDataformConnection}
                  onClick={onDataformConnectionClick}
                  children='Connect to project'
                  fullWidth={true}
                />
              )}
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export { IntegrationItem };
