import React from 'react';
import { useCallback, useQueryClient, useState } from 'hooks/hooks.js';
import { Image } from 'react-bootstrap';
import { useStyles } from './DataplexReconnectBanner.style.js';
import {
  Button,
  BUTTON_COLOR,
  BUTTON_VARIANT,
  LoadingButton,
} from 'Components/components.js';
import { useMessages, useModal } from 'context/context.js';
import { connectDataPlex } from 'Pages/Integrations/libs/helpers/helpers.js';
import { getGcpDataplexApiLink } from 'utils/helpers/helpers.js';
import ExampleImage from 'assets/img/banners/dataplex-banner.png';

const DataplexReconnectBanner = ({ onClose, user, currentProject }) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { setMessage } = useMessages();
  const queryClient = useQueryClient();
  const [isLoadingConnection, setIsLoadingConnection] = useState(false);

  const handleChangeLoading = useCallback((value) => {
    setIsLoadingConnection(value);
  }, []);

  const closeModal = useCallback(() => {
    onClose();
    setModal(null);
  }, [onClose, setModal]);

  const onReconnectButtonClick = useCallback(() => {
    connectDataPlex(user, queryClient, setMessage, handleChangeLoading);
  }, [handleChangeLoading, queryClient, setMessage, user]);

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.rightSide}>
        <div className={classes.textWrapper}>
          <div className='txt-mainDark-24-700 mb-4'>
            Additional Setup Required for Dataplex
          </div>
          <div className='txt-mainDark-13-500'>
            Please ensure you have the necessary permissions and that Dataplex
            API is enabled in {currentProject}.{' '}
            <a
              href={getGcpDataplexApiLink(currentProject)}
              target='_blank'
              rel='noreferrer'
              className={classes.link}
            >
              Check Dataplex settings
            </a>{' '}
            and then, click 'Reconnect' to re-establish the connection.
          </div>
        </div>

        <div className={classes.buttonContainer}>
          <Button
            text='Not now'
            variant={BUTTON_VARIANT.text}
            onClick={closeModal}
            fullWidth={false}
          />

          <LoadingButton
            children='Reconnect'
            onClick={onReconnectButtonClick}
            fullWidth={false}
            color={BUTTON_COLOR.primary}
            isLoading={isLoadingConnection}
          />
        </div>
      </div>

      <div className={classes.leftSide}>
        <Image src={ExampleImage} alt='DataQuality' className={classes.image} />
      </div>
    </div>
  );
};

export { DataplexReconnectBanner };
