export const nameId = 'NAME';
export const lookerId = 'LOOKER_ID';
export const typeId = 'TYPE';
export const alertStatusId = 'ALERT_TYPE';
export const dashboardElementId = 'DASHBOARD_ELEMENT';
export const upstreamTablesId = 'UPSTREAM_TABLES';
export const createdAtId = 'CREATED_AT';
export const updatedAtId = 'UPDATED_AT';

const Name = {
  id: nameId,
  label: 'Name',
  sort: true,
};
const Id = {
  id: lookerId,
  label: 'Id',
  sort: true,
};
const alertStatus = {
  id: alertStatusId,
  label: 'Status',
  sort: true,
};
const Type = {
  id: typeId,
  label: 'Type',
  sort: true,
};
const DashboardElement = {
  id: dashboardElementId,
  label: 'Dashboard element',
  sort: false,
};
const UpstreamTables = {
  id: upstreamTablesId,
  label: 'Upstream',
  sort: false,
};
const CreatedAt = {
  id: createdAtId,
  label: 'Created at',
  sort: true,
};
const UpdatedAt = {
  id: updatedAtId,
  label: 'Updated at',
  sort: true,
};

export {
  Name,
  Id,
  alertStatus,
  Type,
  DashboardElement,
  UpstreamTables,
  CreatedAt,
  UpdatedAt,
};
