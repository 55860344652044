import { useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useGetIncidents = (startDate, endDate) => {
  return useQuery(
    [QUERY_TYPES.incidents, startDate, endDate],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    { enabled: !!startDate && !!endDate, staleTime: 3 * 60 * 1000 }
  );
};

export { useGetIncidents };
