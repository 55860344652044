import React from 'react';
import {
  useState,
  useDispatch,
  useEffect,
  useMemo,
  usePagination,
  useSelector,
  useCallback,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  GeneralSearch,
  Pagination,
  PositionLoader,
} from 'Components/components.js';
import { DATA_STATUS, ORDER_TYPES } from 'constants/constants.js';
import { getColumnTypeIcon } from 'utils/helpers/helpers.js';
import { getColumns, getUniqueColumns } from 'slices/actions.js';
import { columnNameId } from 'Components/Columns/HeadCellsEnum.js';
import { ReactComponent as ArrowRightIcon } from 'assets/img/icons/sidebarIcons/arrowRight.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: 400,
    height: '100%',
    padding: '16px 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflow: 'scroll',
  },
  columnsContainer: {
    flexGrow: 3,
  },
  columnContainer: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

const ColumnNameBlock = ({ selectedColumn, onColumnClick, table, dataset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [column, setColumn] = useState('');
  const [isColumnEqual, setIsColumnEqual] = useState(false);

  const existingPagination = useMemo(() => {
    return totalNumber > rowsPerPage;
  }, [totalNumber, rowsPerPage]);

  const {
    columns,
    columnsNumber,
    columnsStatus,
    uniqueColumns,
    uniqueColumnsStatus,
  } = useSelector((state) => ({
    columns: state.columns.columns,
    columnsNumber: state.columns.columnsNumber,
    columnsStatus: state.columns.columnsStatus,
    uniqueColumns: state.columns.uniqueColumns,
    uniqueColumnsStatus: state.columns.uniqueColumnsStatus,
  }));

  const isLoadingColumns = useMemo(() => {
    return (
      columnsStatus === DATA_STATUS.pending ||
      uniqueColumnsStatus === DATA_STATUS.pending
    );
  }, [columnsStatus, uniqueColumnsStatus]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getColumns({
          dataset,
          table,
          column,
          isColumnEqual,
          order: ORDER_TYPES.asc,
          sortBy: columnNameId,
          page: page + 1,
          limit: rowsPerPage,
        })
      );
    }
  }, [page, rowsPerPage, dispatch, column, isColumnEqual, table, dataset]);

  useEffect(() => {
    if (table && dataset) {
      dispatch(
        getUniqueColumns({
          dataset,
          table,
        })
      );
    }
  }, [dispatch, table, dataset]);

  useEffect(() => {
    if (columnsNumber) {
      setTotalNumber(columnsNumber);
    }
  }, [columnsNumber]);

  const searchOptions = useMemo(() => {
    return uniqueColumns.map((column) => {
      return { label: column, value: column };
    });
  }, [uniqueColumns]);

  const handleColumnSearch = useCallback((value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleColumnSelect = useCallback((value) => {
    handleChangePage({}, 0);
    setColumn(value || '');
    setIsColumnEqual(!!value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <div className='txt-grey-13-700 uppercase'>Column name</div>
        <GeneralSearch
          options={searchOptions}
          onSearch={handleColumnSearch}
          onSelect={handleColumnSelect}
          isLoading={isLoadingColumns}
          className='tableSearch'
          placeholderName='Start typing column name...'
        />

        <div className={classes.columnsContainer}>
          {isLoadingColumns ? (
            <PositionLoader />
          ) : (
            columns.map((column) => {
              const isSelectedColumn = column.name === selectedColumn?.name;

              return (
                <div
                  key={column.uuid}
                  className={classes.columnContainer}
                  onClick={() => onColumnClick(column)}
                >
                  <div className={classes.column}>
                    <img
                      src={getColumnTypeIcon(column.type)}
                      alt={`${column.type} column`}
                    />
                    <div
                      className={clsx(
                        'txt-mainDark-13-500',
                        isSelectedColumn && 'txt-mainDark-13-700'
                      )}
                    >
                      {column.name}
                    </div>
                  </div>

                  {isSelectedColumn && <ArrowRightIcon />}
                </div>
              );
            })
          )}
        </div>
      </div>

      {existingPagination && (
        <Pagination
          count={totalNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};

export { ColumnNameBlock };
