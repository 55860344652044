import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  useEffect,
  useState,
  useHistory,
  useLocation,
  useCallback,
} from 'hooks/hooks.js';
import axios from 'axios';
import clsx from 'clsx';
import { useStyles } from './Deployment.js';
import { useSignUpInfo, useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherPost } from 'utils/utils.js';
import { AUTH_ERRORS, SEARCH_PARAMS } from 'constants/constants.js';
import {
  INPUT_VALIDATION,
  INPUT_VALUE_ERRORS,
} from './libs/constants/constants.js';
import { auth, signUpAmplEvent } from 'service/services.js';
import {
  SetUpProject,
  InputProject,
  SetUpLoading,
  CheckPermissionsLoading,
} from './components/components.js';
import { useGetExistingProjects } from './libs/hooks/hooks.js';
import googleLogo from 'assets/img/googleIconBgd.svg';

const CreateFormComponent = ({
  projectValue,
  handleChangeProject,
  onConnectClick,
}) => {
  const classes = useStyles();

  return (
    <div className='form'>
      <div className='formBlock'>
        <InputProject
          projectValue={projectValue}
          onProjectValueChange={handleChangeProject}
        />
      </div>

      <button
        className={clsx(classes.googleBtn, 'formButton btn-my')}
        onClick={onConnectClick}
      >
        <img src={googleLogo} alt='google login' />
        <span>Check permissions and connect</span>
      </button>
    </div>
  );
};

const CreateTextBlockComponent = () => {
  return (
    <div>
      Let’s connect your Google Cloud project, that you want to observe. Ensure
      that you
      <br />
      have created the necessary resources within your project to allow Masthead
      to
      <br />
      monitor your data assets and pipelines.&nbsp;
      <a
        href='https://docs.mastheadata.com/masthead-agent/saas-deployment/saas-manual-resource-creation-google-cloud'
        target='_blank'
        rel='noreferrer'
        className='link'
      >
        Read Deployment Guidelines
      </a>
    </div>
  );
};

const DeploymentAddProject = () => {
  const { signUpInfo, setSignUpInfo, setSignUpError } = useSignUpInfo();
  const { setUser, setAuthError } = useUserInfo();
  const history = useHistory();
  const [checkPermissionsLoading, setCheckPermissionsLoading] = useState(false);
  const [setUpLoading, setSetUpLoading] = useState(false);
  const [projectValue, setProjectValue] = useState('');
  const [error, setError] = useState(null);
  const ampEvent = 'Deployment (Add GCP Project ID)';
  const eventButton = `${ampEvent} -> Click - Check permissions and connect`;
  const location = useLocation();

  useEffect(
    () =>
      signUpAmplEvent(
        `${ampEvent}`,
        signUpInfo?.accessToken,
        signUpInfo?.name,
        signUpInfo?.company,
        signUpInfo?.companyUseCase
      ),
    []
  );

  const handleChange = useCallback((value) => {
    if (value.length === INPUT_VALIDATION.maxLength) {
      setError(INPUT_VALUE_ERRORS.limitCharacters);
    } else {
      setError(null);
    }

    setProjectValue(value.replace(' ', ''));
  }, []);

  const token = signUpInfo?.accessToken;
  const { existingProjects, error: existingProjectsError } =
    useGetExistingProjects(token);

  useEffect(() => {
    if (existingProjectsError !== null) {
      setError(existingProjectsError);
    } else {
      setError(null);
    }
  }, [existingProjectsError]);

  if (!signUpInfo) {
    const searchParams = new URLSearchParams(location.search);
    const marketplaceId = searchParams.get('marketplaceId');

    const queryString = marketplaceId ? `?marketplaceId=${marketplaceId}` : '';
    return <Redirect to={`${AppRoutes.SignUp.path}${queryString}`} />;
  }

  const saveValue = () => {
    if (!projectValue) {
      setError(INPUT_VALUE_ERRORS.addGcp);
      return;
    }
    if (existingProjects.includes(projectValue)) {
      setError(AUTH_ERRORS.EXISTING_PROJECT);
      return;
    }
    setError(null);
    setCheckPermissionsLoading(true);
    signUpInfo.project = projectValue;

    axios
      .get(`/openApi/v1/project/${projectValue}/status`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        let success = true;
        for (const property in data) {
          if (!data[property]) {
            success = false;
            break;
          }
        }
        if (success) {
          setSetUpLoading(true);
          setCheckPermissionsLoading(false);
          fetcherPost('/sign-up', {
            adminEmail: signUpInfo.user.email,
            project: signUpInfo.project,
            name: signUpInfo.name,
            company: signUpInfo.company,
            companyRole: signUpInfo.companyRole,
            companyUseCase: signUpInfo.companyUseCase,
            marketplaceAccountId: signUpInfo.marketplaceAccountId,
          })
            .then((data) => {
              if (data?.value) {
                setSetUpLoading(false);
                const user = signUpInfo.user;
                user.info = data.value;
                setUser(user);
                signUpAmplEvent(
                  `${eventButton} (success)`,
                  signUpInfo.accessToken,
                  signUpInfo.name,
                  signUpInfo.company,
                  signUpInfo.companyUseCase
                );
                auth.updateCurrentUser(user).then();
                history.push(
                  `${AppRoutes.Dashboard.path}?${SEARCH_PARAMS.CREATE_PROJECT}=success`
                );
              } else {
                setSignUpError(AUTH_ERRORS.STH_ELSE);
                signUpAmplEvent(
                  `${eventButton} (error_1)`,
                  signUpInfo.accessToken,
                  signUpInfo.name,
                  signUpInfo.company,
                  signUpInfo.companyUseCase
                );
              }
            })
            .catch((e) => {
              setSignUpError(AUTH_ERRORS.STH_ELSE);
              signUpAmplEvent(
                `${eventButton} (error_2 - ${e.message})`,
                signUpInfo.accessToken,
                signUpInfo.name,
                signUpInfo.company,
                signUpInfo.companyUseCase
              );
            })
            .finally(() => {
              setSetUpLoading(false);
              setSignUpInfo(null);
            });
        } else {
          setCheckPermissionsLoading(false);
          setError(INPUT_VALUE_ERRORS.notEnoughPermissions);
          setProjectValue('');
          signUpAmplEvent(
            `${eventButton} (error_3 - Missing resources or insufficient permissions)`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
          return;
        }
      })
      .catch((e) => {
        setSignUpError(e.message);
        setCheckPermissionsLoading(false);
        setSetUpLoading(false);
        setSignUpInfo(null);
        setAuthError(null);
        signUpAmplEvent(
          `${eventButton} (error_4 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      });
  };

  if (checkPermissionsLoading) {
    return <CheckPermissionsLoading />;
  }
  if (setUpLoading) {
    return <SetUpLoading />;
  }

  return (
    <SetUpProject
      error={error}
      title='Add Google Cloud Project'
      FormComponent={CreateFormComponent}
      TextBlockComponent={CreateTextBlockComponent}
      handleChangeProject={handleChange}
      onConnectClick={saveValue}
      projectValue={projectValue}
    />
  );
};

export default DeploymentAddProject;
