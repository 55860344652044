import React from 'react';
import { useCallback, useHistory } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Image } from 'react-bootstrap';
import { ownStyles } from './fourth-slider.style.js';
import { baseStyles } from '../base.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { AppRoutes } from 'app-routes.js';
import { useModal } from 'context/context.js';
import MassageJiraImg from 'assets/img/banners/massage-jira.svg';
import Slack from 'assets/img/slack.svg';
import Jira from 'assets/img/jira.svg';

const FourthSlider = ({ onClose }) => {
  const classes = baseStyles();
  const ownClasses = ownStyles();
  const history = useHistory();
  const { setModal } = useModal();

  const closeModal = useCallback(() => {
    onClose();
    setModal(null);
  }, [onClose, setModal]);

  const navigateIntegrationPage = useCallback(() => {
    onClose();
    history.push(AppRoutes.Integrations.path);
    closeModal();
  }, [closeModal, history, onClose]);

  return (
    <div key='slide4' className={classes.wrapper}>
      <div className={classes.rightSide}>
        <div className={classes.textWrapper}>
          <div className='txt-mainDark-24-700 mb-4'>
            Stay on top of data health
          </div>
          <div className='txt-mainDark-13-500 mb-3'>
            We alerts you about data issues in real time. Keep your whole team
            in the loop!
          </div>

          <div className={ownClasses.integrationContainer}>
            <div className={ownClasses.integrationItem}>
              <Image src={Slack} alt='Jira' style={{ height: 24 }} />
              <div className='txt-mainDark-13-700'>Slack</div>
            </div>

            <Button
              text='Connect'
              variant={BUTTON_VARIANT.text}
              onClick={navigateIntegrationPage}
              fullWidth={false}
              className='p-1'
            />
          </div>

          <div className={ownClasses.integrationContainer}>
            <div className={ownClasses.integrationItem}>
              <Image src={Jira} alt='Jira' style={{ height: 24 }} />
              <div className='txt-mainDark-13-700'>Jira</div>
            </div>

            <Button
              text='Connect'
              variant={BUTTON_VARIANT.text}
              onClick={navigateIntegrationPage}
              fullWidth={false}
              className='p-1'
            />
          </div>
        </div>
      </div>

      <div className={clsx(classes.leftSide, ownClasses.leftSideOwn)}>
        <Image
          src={MassageJiraImg}
          alt='onboarding banner'
          className='banner-img'
        />
      </div>
    </div>
  );
};

export { FourthSlider };
