const LookerSearchOptions = {
  DASHBOARD_NAME: 'Dashboard name',
  DASHBOARD_ID: 'Dashboard id',
  LOOK_NAME: 'Look name',
  LOOK_ID: 'Look id',
  DASHBOARD_ELEMENT: 'Dashboard element',
  UPSTREAM: 'Upstream tables',
};

export { LookerSearchOptions };
