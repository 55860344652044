import React from 'react';
import { useMutation, useQueryClient } from 'hooks/hooks.js';
import { useStyles } from 'Pages/Integrations/Integrations.styles.js';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { amplEvent } from 'service/services.js';

const DISCONNECT_EVENT = 'Slack -> Click Disconnect button ->';

const SlackItem = ({ name }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const { mutateAsync: cancelSlack } = useMutation(
    () => fetcherPost('/api/v1/cancel-slack'),
    {
      onSettled: () => queryClient.invalidateQueries(QUERY_TYPES.slack),
    }
  );

  const onDisconnectSlack = () => {
    cancelSlack()
      .then(() => amplEvent(`${DISCONNECT_EVENT} success`))
      .catch(() => amplEvent(`${DISCONNECT_EVENT} error`));
  };

  return (
    <div className={classes.cardItem}>
      <div className={classes.cardItemInfo}>
        <span className='txt-grey-13-500'>WorkSpace:</span>
        <span className='txt-mainDark-13-500'>{name}</span>
      </div>

      <Button
        text='Disconnect'
        variant={BUTTON_VARIANT.text}
        onClick={onDisconnectSlack}
        fullWidth={false}
        className='p-0'
      />
    </div>
  );
};

export { SlackItem };
