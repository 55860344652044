import React from 'react';
import moment from 'moment/moment';
import { TableCell, TableRow } from '@mui/material';
import { DATE_FORMAT } from 'constants/constants.js';
import {
  getFullTableName,
  bytesToSize,
  highlightText,
  millisecondsToTimeSlots,
} from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE, PIPELINE_SUBTYPE_LABELS } from 'utils/constants.js';
import { OverflownText, RightSideModal } from 'Components/components.js';
import { CostLabel, JobTagsTableCell } from '../components.js';
import QueryModalBody from './QueryModalBody/QueryModalBody.js';
import { useModal } from 'context/context.js';
import { PipelineTableCellStyles } from 'Pages/Pipelines/libs/enums/enums.js';

const {
  DESTINATION_TABLE_NAME_WIDTH,
  PRINCIPAL_EMAIL_WIDTH,
  RESERVATION_WIDTH,
} = PipelineTableCellStyles;

const GAP = 20;
const SOURCE_TABLE_WIDTH = DESTINATION_TABLE_NAME_WIDTH - GAP;

const SourceTableRow = ({
  source,
  destinationTable,
  isJobTypeCellExists,
  selectedTable,
  searchValue,
}) => {
  const {
    jobHash,
    tables,
    sourceUri,
    destinationUri,
    principalEmail,
    jobTags,
    frequency,
    paymentType,
    avgBytes,
    avgSlotsMs,
    avgCost,
    costs,
    lastActivity,
    pipelineSubtype,
    sourceProject,
    reservation,
  } = source;
  const { setModal } = useModal();

  const avgData =
    paymentType === BQ_BILLING_TYPE.ON_DEMANDS
      ? bytesToSize(avgBytes)
      : millisecondsToTimeSlots(avgSlotsMs);

  const openQueryModal = () => {
    setModal(() => (
      <RightSideModal
        modalWidth={820}
        title='Source Query'
        subtitle={`Pipeline execution email: ${principalEmail}`}
        ModalBodyComponent={QueryModalBody}
        jobHash={jobHash}
        jobTags={jobTags}
        destinationTable={destinationTable}
        sourceTables={tables}
        sourceUri={sourceUri}
        destinationUri={destinationUri}
        pipeline={{
          pipelineType: destinationTable?.pipelineType,
          sourceProject,
        }}
      />
    ));
  };

  return (
    <TableRow onClick={openQueryModal}>
      <TableCell>
        <div style={{ display: 'flex', gap: GAP }}>
          <div>
            {tables.map((table, i) => {
              const tableName = getFullTableName(table.table, table.dataset);

              return (
                <OverflownText
                  title={tableName}
                  maxWidth={SOURCE_TABLE_WIDTH}
                  key={tableName + i}
                >
                  {highlightText(tableName, selectedTable, searchValue)}
                  <br />
                </OverflownText>
              );
            })}
            {Boolean(sourceUri) && (
              <OverflownText title={sourceUri} maxWidth={SOURCE_TABLE_WIDTH}>
                {highlightText(sourceUri)}
              </OverflownText>
            )}
          </div>
        </div>
      </TableCell>

      <TableCell>
        <OverflownText title={principalEmail} maxWidth={PRINCIPAL_EMAIL_WIDTH}>
          {principalEmail}
        </OverflownText>
      </TableCell>

      <JobTagsTableCell jobTags={jobTags} />

      <TableCell align='left' className='nowrapText'>
        {reservation?.length ? (
          <OverflownText title={reservation} maxWidth={RESERVATION_WIDTH}>
            {reservation}
          </OverflownText>
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>

      {isJobTypeCellExists && (
        <TableCell>
          {PIPELINE_SUBTYPE_LABELS[pipelineSubtype] || 'N/A'}
        </TableCell>
      )}

      <TableCell align='right' className='nowrapText'>
        {frequency}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {lastActivity ? (
          moment(lastActivity).utc().format(DATE_FORMAT.monthYearTime)
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {avgData.length ? (
          avgData
        ) : (
          <span className='txt-grey-13-500'>N/A</span>
        )}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        {avgCost ? <CostLabel value={avgCost} /> : '-'}
      </TableCell>
      <TableCell align='right' className='nowrapText'>
        <CostLabel value={costs} />
      </TableCell>
    </TableRow>
  );
};

export default SourceTableRow;
