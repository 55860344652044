import clsx from 'clsx';
import { useGetIsAnomalyTable } from 'hooks/hooks.js';
import { Tooltip } from 'Components/components.js';
import { getFullTableName } from 'utils/helpers/helpers.js';

const DictionaryLookerUpstreamTable = ({
  item,
  maxItemLength,
  onMonitors,
  highlightUpstream,
  startDate,
  endDate,
}) => {
  const fullTableName = getFullTableName(item.table, item.dataset);
  const isLongTableName = fullTableName.length > maxItemLength;
  const shortTableName = isLongTableName
    ? `${fullTableName.substring(0, maxItemLength)}...`
    : fullTableName;
  const tooltipTitle = isLongTableName ? fullTableName : '';

  const { data: isAnomalyTable } = useGetIsAnomalyTable(
    item.dataset,
    item.table,
    startDate,
    endDate
  );

  return (
    <Tooltip title={tooltipTitle}>
      <div
        className={clsx(isAnomalyTable && 'txt-red-13-500')}
        onClick={() => onMonitors(item.dataset, item.table)}
      >
        {highlightUpstream(shortTableName)}
      </div>
    </Tooltip>
  );
};

export { DictionaryLookerUpstreamTable };
