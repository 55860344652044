import React from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Loader } from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  wrapperContent: {
    gap: 40,
    '&.headerContainer': {
      borderRadius: 16,
    },
  },
}));

const CheckPermissionsLoading = () => {
  const classesLoading = useStyles();

  return (
    <div className={clsx(classesLoading.wrapperContent, 'headerContainer')}>
      <h1 className='txt-mainDark-32-700 textWhiteColor'>
        Almost done!
        <br />
        We are checking permissions
      </h1>
      <div className='txt-mainDark-13-500 textWhiteColor'>
        It can take a few minutes
      </div>
      <Loader isWhiteLoader={true} />
    </div>
  );
};

export { CheckPermissionsLoading };
