import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';

const getProjectTreeDatasets = createAsyncThunk(
  `${SLICE_NAME.projectTree}/getProjectTreeDatasets`,
  async (_, { rejectWithValue }) => {
    const response = await fetcherGet(QUERY_TYPES.projectTreeDatasets);
    if (response.values) {
      return response;
    }
    return rejectWithValue();
  }
);

const getProjectTreeTables = createAsyncThunk(
  `${SLICE_NAME.projectTree}/getProjectTreeTables`,
  async (parameters, { rejectWithValue }) => {
    const { dataset, tableType, page, limit } = parameters;

    const params = new URLSearchParams();
    params.append('dataset', dataset);
    params.append('tableType', tableType);
    params.append('page', page);
    params.append('limit', limit);

    const response = await fetcherGet(QUERY_TYPES.projectTreeTables, params);
    if (response.values) {
      return response;
    }
    return rejectWithValue();
  }
);

const selectProjectTreeTable = createAction(
  `${SLICE_NAME.projectTree}/selectProjectTreeTable`
);

export { getProjectTreeDatasets, getProjectTreeTables, selectProjectTreeTable };
