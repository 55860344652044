import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';

const getProjects = createAsyncThunk(
  `${SLICE_NAME.projects}/getProjects`,
  async (_, { rejectWithValue }) => {
    const response = await fetcherGet(QUERY_TYPES.projects);

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

export { getProjects };
