import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  widget: {
    position: 'relative',
    minHeight: '100%',
    padding: theme.spacing(6),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(4),
  },

  emptyData: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  flexRawContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    '&.smallGap': {
      gap: theme.spacing(1),
    },
  },

  lineStats: {
    height: 6,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),

    display: 'flex',
    gap: theme.spacing(2),
  },

  line: {
    height: '100%',
    cursor: 'pointer',
    '&:first-child:last-child': {
      borderRadius: '100px',
    },
    '&:first-child': {
      borderRadius: '100px 0 0 100px',
    },
    '&:last-child': {
      borderRadius: '0 100px 100px 0',
    },
  },

  tooltip: {
    '& .title': {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  },

  cardsContainer: {},

  card: {
    minWidth: 206,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    backgroundColor: theme.palette.secondary.light,
    borderRadius: theme.spacing(2),
  },

  marker: {
    width: 6,
    height: 6,
    borderRadius: '50%',
  },
}));
