import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    height: 62,
  },
  inviteButton: {
    width: 120,
  },
  companyInfoContainer: {
    display: 'flex',
    gap: 8,
  },
  mainContainer: {
    display: 'flex',
    flexGrow: 3,
    backgroundColor: theme.palette.common.white,
    borderRadius: 12,
  },
}));
