import { createSlice } from '@reduxjs/toolkit';
import { DATA_STATUS, SLICE_NAME } from 'constants/constants.js';
import {
  getDataScans,
  getCurrentDataScan,
  getDataScanJobs,
  getDataScanRules,
  removeDataScan,
  clearRemoveDataScanStatus,
  runDataScan,
  createDataScan,
  updateDataScan,
  updateDataScanRules,
  clearUpdateDataScanRulesStatus,
  clearUpdateDataScanStatus,
  clearCreateDataScanStatus,
} from './actions.js';

const initialState = {
  dataScansStatus: DATA_STATUS.idle,
  dataScans: [],
  dataScansNumber: 0,
  dataScanStatus: DATA_STATUS.idle,
  dataScan: null,
  dataScanJobsStatus: DATA_STATUS.idle,
  dataScanJobs: [],
  dataScanRulesStatus: DATA_STATUS.idle,
  dataScanRules: [],
  dataScanRulesNumber: 0,
  removeDataScanStatus: DATA_STATUS.idle,
  createDataScanStatus: DATA_STATUS.idle,
  updateDataScanStatus: DATA_STATUS.idle,
  updateDataScanRulesStatus: DATA_STATUS.idle,
  dataScanRunStatus: DATA_STATUS.idle,
};

export const dataScanSlice = createSlice({
  name: SLICE_NAME.dataScan,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataScans.pending, (state) => {
      state.dataScansStatus = DATA_STATUS.pending;
    });
    builder.addCase(getDataScans.fulfilled, (state, { payload }) => {
      state.dataScans = payload.values;
      state.dataScansNumber = payload.pagination.total;
      state.dataScansStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getDataScans.rejected, (state) => {
      state.dataScansStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getCurrentDataScan.pending, (state) => {
      state.dataScanStatus = DATA_STATUS.pending;
    });
    builder.addCase(getCurrentDataScan.fulfilled, (state, { payload }) => {
      state.dataScan = payload.value;
      state.dataScanStatus = DATA_STATUS.fulfilled;

      state.dataScanRules = [];
      state.dataScanRulesNumber = 0;
      state.dataScanRulesStatus = DATA_STATUS.idle;

      state.dataScanJobs = [];
      state.dataScanJobsStatus = DATA_STATUS.idle;
    });
    builder.addCase(getCurrentDataScan.rejected, (state) => {
      state.dataScanStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getDataScanJobs.pending, (state) => {
      state.dataScanRunStatus = DATA_STATUS.idle;
      state.dataScanJobsStatus = DATA_STATUS.pending;
    });
    builder.addCase(getDataScanJobs.fulfilled, (state, { payload }) => {
      state.dataScanRunStatus = DATA_STATUS.idle;
      state.dataScanJobs = payload.values;
      state.dataScanJobsStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getDataScanJobs.rejected, (state) => {
      state.dataScanJobsStatus = DATA_STATUS.rejected;
    });

    builder.addCase(getDataScanRules.pending, (state) => {
      state.dataScanRulesStatus = DATA_STATUS.pending;
    });
    builder.addCase(getDataScanRules.fulfilled, (state, { payload }) => {
      state.dataScanRules = payload.values;
      state.dataScanRulesNumber = payload.pagination.total;
      state.dataScanRulesStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(getDataScanRules.rejected, (state) => {
      state.dataScanRulesStatus = DATA_STATUS.rejected;
    });

    builder.addCase(removeDataScan.pending, (state) => {
      state.removeDataScanStatus = DATA_STATUS.pending;
    });
    builder.addCase(removeDataScan.fulfilled, (state, { payload }) => {
      state.dataScans.splice(
        state.dataScans.findIndex(
          (item) =>
            item.location === payload.location &&
            item.dataPlexId === payload.dataPlexId
        ),
        1
      );

      state.dataScansNumber = state.dataScansNumber - 1;
      state.removeDataScanStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(removeDataScan.rejected, (state) => {
      state.removeDataScanStatus = DATA_STATUS.rejected;
    });
    builder.addCase(clearRemoveDataScanStatus.fulfilled, (state) => {
      state.removeDataScanStatus = DATA_STATUS.idle;
    });

    builder.addCase(runDataScan.fulfilled, (state) => {
      state.dataScanRunStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(runDataScan.rejected, (state) => {
      state.dataScanRunStatus = DATA_STATUS.rejected;
    });

    builder.addCase(createDataScan.pending, (state) => {
      state.createDataScanStatus = DATA_STATUS.pending;
    });
    builder.addCase(createDataScan.fulfilled, (state, { payload }) => {
      state.dataScans.push(payload.value);
      state.createDataScanStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(createDataScan.rejected, (state) => {
      state.createDataScanStatus = DATA_STATUS.rejected;
    });
    builder.addCase(clearCreateDataScanStatus.fulfilled, (state) => {
      state.createDataScanStatus = DATA_STATUS.idle;
    });

    builder.addCase(updateDataScan.pending, (state) => {
      state.updateDataScanStatus = DATA_STATUS.pending;
    });
    builder.addCase(updateDataScan.fulfilled, (state, { payload }) => {
      state.dataScans.splice(
        state.dataScans.findIndex(
          (dataScan) => payload.value.id === dataScan.id
        ),
        1,
        payload.value
      );
      state.updateDataScanStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(updateDataScan.rejected, (state) => {
      state.updateDataScanStatus = DATA_STATUS.rejected;
    });
    builder.addCase(clearUpdateDataScanStatus.fulfilled, (state) => {
      state.updateDataScanStatus = DATA_STATUS.idle;
    });

    builder.addCase(updateDataScanRules.pending, (state) => {
      state.updateDataScanRulesStatus = DATA_STATUS.pending;
    });
    builder.addCase(updateDataScanRules.fulfilled, (state) => {
      state.updateDataScanRulesStatus = DATA_STATUS.fulfilled;
    });
    builder.addCase(updateDataScanRules.rejected, (state) => {
      state.updateDataScanRulesStatus = DATA_STATUS.rejected;
    });
    builder.addCase(clearUpdateDataScanRulesStatus.fulfilled, (state) => {
      state.updateDataScanRulesStatus = DATA_STATUS.idle;
    });
  },
});

export default dataScanSlice.reducer;
