import { SEARCH_PARAMS } from 'constants/constants.js';

const getIncidentUrl = (currentProject, tab, incidentId, incidentType) => {
  return encodeURI(
    window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?${SEARCH_PARAMS.PROJECT}=${currentProject}` +
      `&${SEARCH_PARAMS.TAB}=${tab}` +
      `&${SEARCH_PARAMS.ID}=${incidentId}` +
      `&${SEARCH_PARAMS.TYPE}=${incidentType}`
  );
};

export { getIncidentUrl };
