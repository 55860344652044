import axios from 'axios';

const ACK_DEADLINE_SECONDS = 60;
const MESSAGE_RETENTION_DURATION = 3600; // 1h

const gcpPost = (url, token, data, params) =>
  axios
    .post(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const gcpPut = (url, token, data, params) =>
  axios
    .put(url, data, { params, headers: { Authorization: `Bearer ${token}` } })
    .then((res) => res.data);

const dataPlexCreator = {
  async createRole(project, token) {
    try {
      return await gcpPost(
        `https://iam.googleapis.com/v1/projects/${project}/roles`,
        token,
        {
          roleId: 'masthead_dataplex_locations',
          role: {
            title: 'masthead_dataplex_locations',
            description: 'Masthead DataPlex locations reader',
            includedPermissions: [
              'dataplex.locations.get',
              'dataplex.locations.list',
            ],
            stage: 'GA',
          },
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Role exist';
      }
      throw e;
    }
  },

  async addRoleToMastheadSA(project, token) {
    return await this.addRoles(
      project,
      token,
      'serviceAccount:masthead-dataplex@masthead-prod.iam.gserviceaccount.com',
      [
        'roles/pubsub.subscriber',
        'roles/bigquery.jobUser',
        'roles/dataplex.dataScanAdmin',
        'roles/dataplex.storageDataReader',
        `projects/${project}/roles/masthead_dataplex_locations`,
      ]
    );
  },

  async addRoles(project, token, serviceAccount, roles) {
    const iam = await this.readIAM(project, token);
    const bRoles = iam.bindings.map((b) => b.role);
    for (const role of roles) {
      if (!bRoles.includes(role)) {
        iam.bindings.push({
          role: role,
          members: [serviceAccount],
        });
      } else {
        const currentB = iam.bindings.find((b) => b.role === role);
        currentB.members = currentB.members.concat(serviceAccount);
      }
    }

    return await this.writeIAM(project, token, iam);
  },

  async readIAM(project, token) {
    return gcpPost(
      `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:getIamPolicy`,
      token,
      {
        options: {
          requestedPolicyVersion: 3,
        },
      }
    );
  },

  async writeIAM(
    project,
    token,
    policy,
    currentRequest = 1,
    countRequests = 20
  ) {
    try {
      return await gcpPost(
        `https://cloudresourcemanager.googleapis.com/v1/projects/${project}:setIamPolicy`,
        token,
        {
          policy: policy,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'IAM exist';
      }
      if (currentRequest <= countRequests) {
        return this.writeIAM(project, token, policy, currentRequest + 1);
      } else {
        throw e;
      }
    }
  },

  async createTopic(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/topics/masthead-dataplex-topic`,
        token,
        {}
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Topic exist';
      }
      throw e;
    }
  },

  async createSink(project, token) {
    try {
      const sink = await gcpPost(
        `https://logging.googleapis.com/v2/projects/${project}/sinks`,
        token,
        {
          name: 'masthead-dataplex-sink',
          destination: `pubsub.googleapis.com/projects/${project}/topics/masthead-dataplex-topic`,
          description: `Masthead DataPlex log sink`,
          filter: `jsonPayload.@type="type.googleapis.com/google.cloud.dataplex.v1.DataScanEvent" OR protoPayload.methodName="google.cloud.dataplex.v1.DataScanService.CreateDataScan" OR protoPayload.methodName="google.cloud.dataplex.v1.DataScanService.UpdateDataScan" OR protoPayload.methodName="google.cloud.dataplex.v1.DataScanService.DeleteDataScan" AND (severity="INFO" OR "NOTICE")`,
        }
      );

      await this.addRoles(project, token, sink.writerIdentity, [
        'roles/pubsub.publisher',
      ]);

      return sink;
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Sink exist';
      }
      throw e;
    }
  },

  async createSubscription(project, token) {
    try {
      return await gcpPut(
        `https://pubsub.googleapis.com/v1/projects/${project}/subscriptions/masthead-dataplex-subscription`,
        token,
        {
          topic: `projects/${project}/topics/masthead-dataplex-topic`,
          messageRetentionDuration: `${MESSAGE_RETENTION_DURATION}s`,
          ackDeadlineSeconds: ACK_DEADLINE_SECONDS,
        }
      );
    } catch (e) {
      if (e.message === 'Request failed with status code 409') {
        return 'Subscription exist';
      }
      throw e;
    }
  },

  async create(project, token) {
    const results = [];
    results.push(
      await this.createRole(project, token),
      await this.createTopic(project, token),
      await this.createSink(project, token),
      await this.createSubscription(project, token),
      await this.addRoleToMastheadSA(project, token)
    );

    return results;
  },
};

export { dataPlexCreator };
