import React from 'react';
import clsx from 'clsx';
import moment from 'moment/moment';
import { styled } from '@mui/styles';
import { TableRow, Avatar } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { RadioDropdown, Modal } from 'Components/components.js';
import { useStyles } from '../Users.styles.js';
import {
  USER_ACTION_COPY,
  USER_ACTION_REMOVE,
  USER_ACTIONS,
  USER_STATUS,
} from 'utils/constants.js';
import { useModal } from 'context/context.js';
import PointsIcon from 'assets/img/icons/pointsIcon.svg';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    padding: '28px 18px 12px 0',
    borderBottom: `1px solid #BEC5E7`,
  },
}));

const CreateModalRemoveBody = () => {
  return (
    <div className='txt-mainDark-13-500' style={{ textAlign: 'center' }}>
      User will no longer have access to Masthead Data
    </div>
  );
};

const UsersColumnDetails = ({ columnInfo, handleRemove, isOwner }) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const { email, lastActivity, name, picture, role, status } = columnInfo;
  const isPending = status === USER_STATUS.PENDING;

  const onFilterSelect = (event, email) => {
    if (event.value === USER_ACTION_COPY) {
      navigator.clipboard.writeText(email);
    }

    if (event.value === USER_ACTION_REMOVE) {
      setModal(() => (
        <Modal
          onAgree={() => handleRemove(email)}
          title={`Remove ${email}`}
          titlePosition='center'
          ModalBodyComponent={CreateModalRemoveBody}
          agreeButtonText='Remove'
        />
      ));
    }
  };

  return (
    <>
      <TableRow key={email}>
        <StyledTableCell className='tableCell'>
          <div className={classes.userInfo}>
            {isPending ? (
              <div className={classes.userAvatar} />
            ) : (
              <Avatar
                src={picture}
                variant='rounded'
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            )}

            <div>
              {!isPending && (
                <div>
                  {name.length
                    ? name
                    : email.split('@')[0][0].toUpperCase() +
                      email.split('@')[0].slice(1)}
                </div>
              )}
              <div
                className='txt-grey-13-500'
                style={{ color: isPending ? '#050C2E' : '' }}
              >
                {email}
              </div>
            </div>

            {isPending && (
              <div
                className={clsx(classes.pendingContainer, 'txt-grey-13-500')}
              >
                Pending
              </div>
            )}
          </div>
        </StyledTableCell>

        <StyledTableCell className='tableCell'>
          {lastActivity === 0 ? '-' : moment.unix(lastActivity).fromNow()}
        </StyledTableCell>

        <StyledTableCell className='tableCell'>
          {role.length ? role : '-'}
        </StyledTableCell>

        <StyledTableCell className='tableCell'>
          {isOwner && (
            <RadioDropdown
              options={USER_ACTIONS}
              current={{ icon: PointsIcon }}
              bottom={10}
              right={-23}
              onSelect={(event) => onFilterSelect(event, email)}
            />
          )}
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export { UsersColumnDetails };
