import React from 'react';
import {
  useMemo,
  useJobCostsBilling,
  useJobCostsBillingPrevious,
  useBqBillingData,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { useStyles } from './StatsWidget.styles.js';
import {
  Tooltip,
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import {
  getCostsDiffByPaymentType,
  getTotalPipelineDiff,
  numberFormat,
} from 'utils/helpers/helpers.js';
import { BQ_BILLING_TYPE, PAYMENT_TYPE_DATA } from 'utils/constants.js';
import { ReactComponent as SearchIcon } from 'assets/img/icons/search.svg';

const ResourceStatsWidget = ({ startDate, endDate, selectedTag = null }) => {
  const classes = useStyles();
  const { isWelcome } = useBqBillingData();
  const isAllowedRequest =
    !isWelcome && (selectedTag === null || selectedTag?.length > 0);

  const { billingJobCostsData } = useJobCostsBilling({
    startDate,
    endDate,
    jobTagName: selectedTag,
    crossProject: true,
    enabled: isAllowedRequest,
  });
  const { billingJobCostsDataPrevious } = useJobCostsBillingPrevious({
    startDate,
    endDate,
    jobTagName: selectedTag,
    crossProject: true,
    enabled: isAllowedRequest,
  });

  const totalAmount = useMemo(() => {
    return billingJobCostsData.reduce(
      (accumulator, { costs }) => accumulator + costs,
      0
    );
  }, [billingJobCostsData]);

  const totalAmountPrevious = useMemo(() => {
    return billingJobCostsDataPrevious.reduce(
      (accumulator, { costs }) => accumulator + costs,
      0
    );
  }, [billingJobCostsDataPrevious]);

  const totalAmountInfo = getTotalPipelineDiff(
    totalAmount,
    totalAmountPrevious
  );

  const statsCardData = useMemo(() => {
    return billingJobCostsData.map((item) => {
      const { paymentType, editionPlan, costs } = item;
      const diff = getCostsDiffByPaymentType(item, billingJobCostsDataPrevious);
      const lineStatWidth = `${((costs * 100) / totalAmount).toFixed(1)}%`;

      const cardName =
        paymentType === BQ_BILLING_TYPE.EDITIONS
          ? `${PAYMENT_TYPE_DATA[paymentType].title}: ${PAYMENT_TYPE_DATA[
              paymentType
            ].plans[editionPlan].title.toLowerCase()}`
          : PAYMENT_TYPE_DATA[paymentType].title;

      const colorType =
        paymentType === BQ_BILLING_TYPE.EDITIONS
          ? PAYMENT_TYPE_DATA[paymentType].plans[editionPlan].color
          : PAYMENT_TYPE_DATA[paymentType].color;

      return {
        name: `${cardName} - ${lineStatWidth}`,
        count: numberFormat(costs),
        color: colorType,
        diff,
        lineStatWidth,
        gridSize:
          billingJobCostsData.length === 2 || billingJobCostsData.length === 4
            ? 6
            : 4,
      };
    });
  }, [billingJobCostsData, billingJobCostsDataPrevious]);

  return (
    <section className={classes.widget}>
      {selectedTag?.length === 0 ? (
        <div className={classes.emptyData}>
          <SearchIcon />
          <p className='txt-grey-13-500'>Start searching to get summary</p>
        </div>
      ) : (
        <>
          <section>
            <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
              {selectedTag?.length > 0
                ? `Summary for ${selectedTag}`
                : 'Total compute resource distribution'}
            </Typography>

            <section className={classes.flexRawContainer}>
              <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
                ${numberFormat(totalAmount)}
              </Typography>

              {totalAmountInfo !== null && (
                <PercentStatsBlock data={totalAmountInfo} />
              )}
            </section>
          </section>

          <div className={classes.lineStats}>
            {statsCardData.map((item) => (
              <Tooltip
                key={item.name}
                title={
                  <div className={classes.tooltip}>
                    <div className='title'>
                      <p className='txt-mainDark-13-500'>{item.name}</p>
                    </div>

                    <p className='txt-mainDark-13-500'>Cost: ${item.count}</p>
                  </div>
                }
              >
                <div
                  className={classes.line}
                  style={{
                    backgroundColor: item.color,
                    width: item.lineStatWidth,
                  }}
                />
              </Tooltip>
            ))}
          </div>

          <section className={classes.cardsContainer}>
            <Grid container spacing={4}>
              {statsCardData.map((item) => (
                <Grid
                  item
                  xs={item.gridSize}
                  md={item.gridSize}
                  key={item.name}
                >
                  <StatsCard statsCardData={item} />
                </Grid>
              ))}
            </Grid>
          </section>
        </>
      )}
    </section>
  );
};

const StatsCard = ({ statsCardData }) => {
  const { name, count, color, diff } = statsCardData;
  const classes = useStyles();

  return (
    <div className={clsx(classes.card, 'flex-column align-items-start gap-0')}>
      <div className={clsx(classes.flexRawContainer, 'smallGap')}>
        <div className={classes.marker} style={{ backgroundColor: color }} />
        <Typography color={TYPOGRAPHY_COLOR.textSecondary}>{name}</Typography>
      </div>

      <section className={classes.flexRawContainer}>
        <Typography variant={TYPOGRAPHY_VARIANT.h5} component={'h5'}>
          ${count}
        </Typography>
        {diff !== null && <PercentStatsBlock data={diff} isWhite={true} />}
      </section>
    </div>
  );
};

export { ResourceStatsWidget };
