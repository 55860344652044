import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { COLUMN_TYPE } from 'constants/constants.js';
import {
  REGEX,
  UNIQUENESS,
  RANGE,
  RULE_TYPES,
  NON_NULL,
  SET,
  STATISTIC,
} from 'Pages/DataQuality/libs/constants/constants.js';
import { ReactComponent as ArrowRightIcon } from 'assets/img/icons/sidebarIcons/arrowRight.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 380,
    height: '100%',
    padding: '16px 32px 32px',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  ruleItem: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ruleItemInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },
}));

const RuleTypesBlock = ({
  selectedColumn,
  selectedRuleType,
  addedRuleTypes,
  onRuleClick,
}) => {
  const classes = useStyles();

  const validRuleTypes = useMemo(() => {
    switch (selectedColumn?.type) {
      case COLUMN_TYPE.string:
        return RULE_TYPES.filter(
          (type) =>
            type.value === NON_NULL ||
            type.value === SET ||
            type.value === REGEX ||
            type.value === UNIQUENESS
        );

      case COLUMN_TYPE.timestamp || COLUMN_TYPE.datetime:
        return RULE_TYPES.filter(
          (type) =>
            type.value === RANGE ||
            type.value === NON_NULL ||
            type.value === UNIQUENESS
        );

      case COLUMN_TYPE.integer ||
        COLUMN_TYPE.float ||
        COLUMN_TYPE.bytes ||
        COLUMN_TYPE.numeric ||
        COLUMN_TYPE.bignumeric ||
        COLUMN_TYPE.date ||
        COLUMN_TYPE.time:
        return RULE_TYPES.filter(
          (type) =>
            type.value === RANGE ||
            type.value === NON_NULL ||
            type.value === SET ||
            type.value === UNIQUENESS ||
            type.value === STATISTIC
        );

      case COLUMN_TYPE.boolean:
        return RULE_TYPES.filter(
          (type) => type.value === NON_NULL || type.value === SET
        );

      default:
        return [];
    }
  }, [selectedColumn?.type]);

  return (
    <div className={classes.container}>
      <div className='txt-grey-13-700 uppercase mb-3'>Rule types</div>
      <div>
        {!!selectedColumn &&
          validRuleTypes.map((item) => {
            const isSelectedRule = item.value === selectedRuleType?.value;
            const isAddedType = addedRuleTypes.find(
              (type) => type === item.value
            );

            return (
              <div
                key={item.value}
                onClick={() => onRuleClick(item)}
                className={clsx(
                  classes.ruleItem,
                  'txt-mainDark-13-500',
                  isSelectedRule && 'txt-mainDark-13-700'
                )}
              >
                {item.label}
                <div className={classes.ruleItemInfo}>
                  {isAddedType && (
                    <span
                      className={clsx(
                        'txt-grey-13-500',
                        isSelectedRule && 'txt-grey-13-700'
                      )}
                    >
                      Added
                    </span>
                  )}
                  {isSelectedRule && <ArrowRightIcon />}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { RuleTypesBlock };
