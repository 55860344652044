import moment from 'moment/moment';
import G6 from '@antv/g6';
import insertCss from 'insert-css';
import { GraphShapeNames, NodeTypes } from './enums/enums.js';
import { PIPELINE_TYPE_DATA } from 'utils/constants.js';
import theme from 'theme.js';

const animateCfg = { duration: 200, easing: 'easeCubic' };
insertCss(`
  .g6-legend-container {
    position: absolute !important;
    top: auto !important;
    bottom: 8px !important;
    right: 16px !important;
    left: auto !important;
  }

  .g6-minimap {
    display: inline !important;
  }

  .g6-minimap-container {
    display: inline !important;
    position: absolute !important;
    top: auto !important;
    bottom: 16px !important;
    left: 24px !important;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    background-color: ${theme.palette.common.white};
  }

  .g6-minimap-viewport {
    border: 2px solid #e2e2e2;
    outline: none !important;
  }

  .g6-component-toolbar {
    padding: 7px;
    background-color: ${theme.palette.common.white};
    border: 1px solid ${theme.palette.divider};
  }
`);

const newTooltip = () => {
  return new G6.Tooltip({
    offset: 10,
    shouldBegin: (e) => {
      const targetShape = e.target.get('name');
      return (
        targetShape === GraphShapeNames.TITLE_SHAPE ||
        targetShape.includes(GraphShapeNames.VISIBLE_TAG_NAME)
      );
    },
    getContent(e) {
      const hoveredNode = e.item.getModel();
      const targetShape = e.target.get('name');
      let tooltipText;

      if (targetShape.includes(GraphShapeNames.VISIBLE_TAG_NAME)) {
        const [shapeName, tagName] = targetShape.split('/');
        tooltipText = tagName;
      } else if (hoveredNode.nodeType === NodeTypes.PIPELINE) {
        tooltipText =
          PIPELINE_TYPE_DATA[hoveredNode.nodeTypeData.pipelineType]?.title;
      } else {
        tooltipText = hoveredNode.label || hoveredNode.id;
      }

      const tooltipEl = document.createElement('div');
      tooltipEl.innerHTML = `
        <div class="txt-mainDark-11-500">${tooltipText}</div>
      `;
      return tooltipEl;
    },
    itemTypes: ['node'],
  });
};

const newToolbar = (isRightShifted = true) => {
  return new G6.ToolBar({
    position: { x: isRightShifted ? 248 : 16, y: 16 },
    zoomSensitivity: 4,
    getContent: () => `
      <ul class='g6-component-toolbar'>
        <li code='zoomIn'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 17C18.6421 17 22 13.6421 22 9.5C22 5.35786 18.6421 2 14.5 2C10.3579 2 7 5.35786 7 9.5C7 11.2316 7.58685 12.8262 8.57252 14.0957C8.47136 14.1435 8.37656 14.2092 8.29289 14.2929L2.29289 20.2929C1.90237 20.6834 1.90237 21.3166 2.29289 21.7071C2.68342 22.0976 3.31658 22.0976 3.70711 21.7071L9.70711 15.7071C9.79077 15.6234 9.85651 15.5286 9.90432 15.4275C11.1738 16.4131 12.7684 17 14.5 17ZM14.5 4C17.5376 4 20 6.46243 20 9.5C20 12.5376 17.5376 15 14.5 15C11.4624 15 9 12.5376 9 9.5C9 6.46243 11.4624 4 14.5 4ZM11 9.5C11 10.0523 11.4477 10.5 12 10.5H17C17.5523 10.5 18 10.0523 18 9.5C18 8.94772 17.5523 8.5 17 8.5H12C11.4477 8.5 11 8.94772 11 9.5Z" fill="#050C2E"/>
          </svg>
        </li>
        
        <li code='zoomOut'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.5C22 13.6421 18.6421 17 14.5 17C12.7684 17 11.1738 16.4131 9.90432 15.4275C9.85651 15.5286 9.79077 15.6234 9.70711 15.7071L3.70711 21.7071C3.31658 22.0976 2.68342 22.0976 2.29289 21.7071C1.90237 21.3166 1.90237 20.6834 2.29289 20.2929L8.29289 14.2929C8.37656 14.2092 8.47136 14.1435 8.57252 14.0957C7.58685 12.8262 7 11.2316 7 9.5C7 5.35786 10.3579 2 14.5 2C18.6421 2 22 5.35786 22 9.5ZM20 9.5C20 6.46243 17.5376 4 14.5 4C11.4624 4 9 6.46243 9 9.5C9 12.5376 11.4624 15 14.5 15C17.5376 15 20 12.5376 20 9.5ZM15.5 7C15.5 6.44772 15.0523 6 14.5 6C13.9477 6 13.5 6.44772 13.5 7V8.5H12C11.4477 8.5 11 8.94772 11 9.5C11 10.0523 11.4477 10.5 12 10.5H13.5V12C13.5 12.5523 13.9477 13 14.5 13C15.0523 13 15.5 12.5523 15.5 12V10.5H17C17.5523 10.5 18 10.0523 18 9.5C18 8.94772 17.5523 8.5 17 8.5H15.5V7Z" fill="#050C2E"/>
          </svg>
        </li>
        
        <li code='autoZoom'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 8C6.88071 8 8 6.88071 8 5.5V3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3V5.5C6 5.77614 5.77614 6 5.5 6H3C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 3 8H5.5ZM18.5 8C17.1193 8 16 6.88071 16 5.5V3C16 2.44772 16.4477 2 17 2C17.5523 2 18 2.44772 18 3V5.5C18 5.77614 18.2239 6 18.5 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H18.5ZM8 18.5C8 17.1193 6.88071 16 5.5 16H3C2.44772 16 2 16.4477 2 17C2 17.5523 2.44772 18 3 18H5.5C5.77614 18 6 18.2239 6 18.5V21C6 21.5523 6.44772 22 7 22C7.55228 22 8 21.5523 8 21V18.5ZM18.5 16C17.1193 16 16 17.1193 16 18.5V21C16 21.5523 16.4477 22 17 22C17.5523 22 18 21.5523 18 21V18.5C18 18.2239 18.2239 18 18.5 18H21C21.5523 18 22 17.5523 22 17C22 16.4477 21.5523 16 21 16H18.5Z" fill="#050C2E"/>
          </svg>
        </li>
      </ul>
    `,
    handleClick: (code, graph) => {
      const zoomX = graph.cfg.width / 2 || 0;
      const zoomY = graph.cfg.height / 2 || 0;

      switch (code) {
        case 'zoomOut':
          graph.zoom(1.2, { x: zoomX, y: zoomY }, true, animateCfg);
          break;
        case 'zoomIn':
          graph.zoom(0.8, { x: zoomX, y: zoomY }, true, animateCfg);
          break;
        case 'autoZoom':
          graph.fitView(20, true, true, animateCfg);
          break;
        default:
          break;
      }
    },
  });
};

const LEGEND_DATA = {
  nodes: [
    {
      id: 'normalTable',
      label: '  No anomalies or errors detected',
      type: 'rect',
      size: [12, 12],
      style: {
        fill: theme.palette.primary.main,
        radius: 2,
      },
    },
    {
      id: 'anomalyTable',
      label: `  Data assets affected with errors
  or anomalies`,
      type: 'rect',
      size: [12, 12],
      style: {
        fill: theme.palette.error.main,
        radius: 2,
      },
    },
    {
      id: 'grayTable',
      label: `  Data assets affected with 
  upstream errors or anomalies`,
      type: 'rect',
      size: [12, 12],
      style: {
        fill: theme.palette.text.secondary,
        radius: 2,
      },
    },
  ],
};

const newLegend = (startDate, endDate) => {
  const title = `For the last 7 days, ${moment(startDate).format(
    'MMM DD'
  )} - ${moment(endDate).format('MMM DD')}`;

  return new G6.Legend({
    data: LEGEND_DATA,
    align: 'center',
    layout: 'vertical',
    vertiSep: 6,
    padding: 12,
    containerStyle: {
      width: 224,
      fill: theme.palette.common.white,
      lineWidth: 1,
      stroke: theme.palette.divider,
      radius: 4,
      strokeOpacity: true,
      opacity: true,
    },
    title,
    titleConfig: {
      position: 'left',
      offsetY: 8,
      offsetX: 0,
      style: {
        fontSize: 12,
        fontWeight: 600,
      },
    },
  });
};

const newMinimap = () => {
  return new G6.Minimap({
    size: [150, 114],
  });
};

export { newTooltip, newToolbar, newLegend, newMinimap };
