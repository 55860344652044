import React from 'react';
import clsx from 'clsx';
import {
  Switch,
  FormControl,
  FormControlLabel,
  styled,
  FormLabel,
  FormHelperText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme from 'theme.js';

const useStyles = makeStyles((theme) => ({
  container: {
    '& .MuiFormLabel-root': {
      marginBottom: 12,
      color: theme.palette.common.secondaryDark,
      fontFamily: theme.typography.fontFamily,
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 'initial',
      '&.Mui-focused': {
        color: theme.palette.common.secondaryDark,
      },
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
    '& .MuiTypography-root': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 13,
      lineHeight: '22px',
      color: theme.palette.common.mainDarkText,
    },
    '& .MuiFormHelperText-root': {
      margin: 0,
      marginTop: 12,
      color: theme.palette.common.secondaryDark,
      fontFamily: theme.typography.fontFamily,
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '22px',
      letterSpacing: 'initial',
      textAlign: (props) => props.helperTextAlign,
    },
  },
}));

const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 48,
  height: 28,
  marginRight: 8,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.1,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.primary.main,
      border: `6px solid ${theme.palette.common.white}`,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.common.white,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24,
    backgroundColor: theme.palette.divider,
    opacity: 1,
  },
}));

const SwitchWithLabel = ({
  isChecked,
  handleChange,
  label = '',
  id = '',
  formLabel = '',
  isRequired = false,
  isDisabled = false,
  isDefaultChecked = false,
  helperText = '',
  helperTextAlign = 'start',
  className = '',
}) => {
  const classes = useStyles({ helperTextAlign });

  return (
    <FormControl className={clsx(classes.container, className)}>
      {!!formLabel && <FormLabel>{formLabel}</FormLabel>}
      <FormControlLabel
        control={
          <StyledSwitch
            checked={isChecked}
            onChange={(event) => handleChange(event.target.checked)}
            theme={theme}
            id={id}
          />
        }
        label={label}
        disabled={isDisabled}
        required={isRequired}
        defaultChecked={isDefaultChecked}
      />

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export { SwitchWithLabel };
