import { endOfDay, startOfDay, subDays } from 'date-fns';

const convertLocalToUTCDate = (date) => {
  if (!date) {
    return date;
  }
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

/**
 * @param {Date} date1
 * @param {Date} date2
 */
const isSameDates = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

const defaultStartDate = () => {
  return startOfDay(subDays(new Date(), 6));
};

const defaultEndDate = () => {
  return endOfDay(new Date());
};

const getUnixDateByStr = (date) => {
  return new Date(date).getTime();
};

export {
  convertLocalToUTCDate,
  isSameDates,
  defaultStartDate,
  defaultEndDate,
  getUnixDateByStr,
};
