import {
  useHistory,
  useLocation,
  useMemo,
  useEffect,
  useMutation,
  useGetProjects,
} from 'hooks/hooks.js';
import { SEARCH_PARAMS } from 'constants/constants.js';
import { fetcherPost } from 'utils/utils.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import { useMessages, useUserInfo } from 'context/context.js';

const EVENT_BUTTON = 'Change project ->';

const useAutoChangeProject = () => {
  const history = useHistory();
  const location = useLocation();
  const { setMessage } = useMessages();
  const { currentProject } = useUserInfo();
  const { projects } = useGetProjects();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const projectFromUrl = useMemo(() => {
    return searchParams.get(SEARCH_PARAMS.PROJECT);
  }, [searchParams]);

  const isExistingProject = useMemo(() => {
    if (!projects.length) {
      return null;
    }
    return projects.includes(projectFromUrl);
  }, [projectFromUrl, projects]);

  const { mutateAsync: updateProject } = useMutation(
    (data) => fetcherPost('/api/v1/current-project', data),
    {
      onSuccess: (data) => {
        if (!data?.success) {
          window.location.replace(AppRoutes.Dashboard.path);
          setMessage('Got an error while changing current project');
          amplEvent(`${EVENT_BUTTON} error`);
        } else {
          window.location.reload();
          amplEvent(`${EVENT_BUTTON} from url -> success`);
        }
      },
    }
  );

  const changeProject = (project) => {
    if (project === currentProject) {
      return;
    }

    updateProject({ value: project }).then(() => {
      amplEvent(`${EVENT_BUTTON} ${project}`);
    });
  };

  useEffect(() => {
    if (isExistingProject !== null && projectFromUrl) {
      searchParams.delete(SEARCH_PARAMS.PROJECT);
      history.replace({
        search: searchParams.toString(),
      });
      if (isExistingProject) {
        changeProject(projectFromUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, isExistingProject, projectFromUrl, searchParams]);
};

export { useAutoChangeProject };
