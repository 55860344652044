import { useState, useCallback } from 'hooks/hooks.js';

const DEFAULT_ROWS_PER_PAGE = 10;

const usePagination = (
  scrollUp = true,
  pageDefault = 0,
  rowsPerPageDefault = DEFAULT_ROWS_PER_PAGE
) => {
  const [page, setPage] = useState(pageDefault);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageDefault);

  const handleChangePage = useCallback(
    (event, newPage) => {
      if (scrollUp) {
        window.scrollTo(0, 0);
      }
      setPage(newPage);
    },
    [scrollUp]
  );
  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }, []);

  return { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage };
};

export { usePagination };
