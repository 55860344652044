import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { CostsStatsWidget, StatsWidget } from 'Components/components.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { AppRoutes } from 'app-routes.js';
import {
  PipelineTabs,
  DEAD_END_TABLES,
  DEAD_END_WIDGET_TIP,
} from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(6),
  },

  largeStatsWidget: {
    flexGrow: 2,
  },
}));

const CostsStats = ({
  costsStats,
  isCostsStatsFetching,
  costsStatsPrevious,
  deadEndTablesStats,
  startDate,
  endDate,
  className = '',
}) => {
  const classes = useStyles();

  const deadEndWidgetData = useMemo(() => {
    return {
      name: 'Dead-end tables',
      mainInfo: deadEndTablesStats?.total || 0,
      subname: 'Cost optimisation est.',
      info: `$${numberFormat(deadEndTablesStats?.costs)}` || '',
    };
  }, [deadEndTablesStats]);

  return (
    <div className={clsx(classes.container, className)}>
      <div className={classes.largeStatsWidget}>
        <CostsStatsWidget
          costsStats={costsStats}
          isLoadingStats={isCostsStatsFetching}
          costsStatsPrevious={costsStatsPrevious}
          startDate={startDate}
          endDate={endDate}
        />
      </div>

      {deadEndTablesStats !== null && (
        <StatsWidget
          statsInfo={deadEndWidgetData}
          pathname={AppRoutes.Pipelines.path}
          search={{ tab: PipelineTabs.COSTS }}
          state={{
            startDate,
            endDate,
            filterPipelineEfficiencyIds: [DEAD_END_TABLES],
          }}
          tooltipTip={DEAD_END_WIDGET_TIP}
        />
      )}
    </div>
  );
};

export { CostsStats };
