import React from 'react';
import clsx from 'clsx';
import { useState } from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  AsyncGeneralSearch,
  AsyncMultiSearch,
  SearchDropdown,
  TableDatasetSearch,
} from 'Components/components.js';
import { PipelineTabSearchOptions } from 'Pages/Pipelines/libs/enums/enums.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { AMPL_PAGE_EVENT, QUERY_TYPES } from 'constants/constants.js';
import {
  SEARCH_EMAIL,
  SELECTED_TABLE,
  SEARCH_TABLE,
  SELECTED_EMAIL,
  SELECTED_TAGS,
  SELECTED_LABEL,
  SEARCH_LABEL,
  SELECTED_DESTINATION_TABLE,
  SEARCH_DESTINATION_TABLE,
} from 'Pages/Pipelines/libs/constants/constants.js';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    '& .searchInput': {
      flexGrow: 1,
    },
    '& .input': {
      flexGrow: 1,
    },
  },
}));

const PAGE_EVENT = `${AMPL_PAGE_EVENT.pipeline} ->`;

const getCurrentSearchType = (values) => {
  for (const searchKey in values) {
    const searchValue = values[searchKey];
    if (
      (searchValue && !Array.isArray(searchValue)) ||
      (Array.isArray(searchValue) && searchValue.length > 0)
    ) {
      switch (searchKey) {
        case SELECTED_EMAIL:
        case SEARCH_EMAIL:
          return PipelineTabSearchOptions.EMAIL;
        case SELECTED_TAGS:
          return PipelineTabSearchOptions.TAGS;
        case SELECTED_LABEL:
        case SEARCH_LABEL:
          return PipelineTabSearchOptions.LABELS;
        case SELECTED_DESTINATION_TABLE:
        case SEARCH_DESTINATION_TABLE:
          return PipelineTabSearchOptions.DESTINATION_TABLE;
        default:
          return PipelineTabSearchOptions.TABLE;
      }
    }
  }

  return PipelineTabSearchOptions.TABLE;
};

const Search = ({ searchValues, onChangeSearchValues }) => {
  const classes = useStyles();
  const searchOptions = Object.values(PipelineTabSearchOptions);
  const [selectedSearch, setSelectedSearch] = useState(
    getCurrentSearchType(searchValues)
  );

  const handleChangeSelectedSearch = (value) => {
    setSelectedSearch(value);
    onChangeSearchValues(null, null);
  };

  const handleSearchStringValue = (searchKey, value) => {
    onChangeSearchValues(searchKey, value || '');

    if (value.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> ${value}`);
    }
  };

  const handleSearchMultiValue = (searchKey, value) => {
    onChangeSearchValues(searchKey, value);

    if (value.length) {
      amplEvent(`${PAGE_EVENT} ${searchKey} -> multi search`);
    }
  };

  const handleSelectObjectValue = (searchKey, value) => {
    onChangeSearchValues(searchKey, value);

    if (value) {
      amplEvent(
        `${PAGE_EVENT} ${searchKey} -> ${getFullTableName(
          value?.table,
          value?.dataset
        )}`
      );
    }
  };

  const handleAsyncLabelSearchGetLabelFromResponseItem = (value) => {
    return `${value.key} : ${value.value}`;
  };

  const handleAsyncLabelSearchGetValueFromResponseItem = (value) => {
    return value;
  };

  const renderSearchField = () => {
    switch (selectedSearch) {
      case PipelineTabSearchOptions.EMAIL:
        return (
          <AsyncGeneralSearch
            onSearch={(value) => handleSearchStringValue(SEARCH_EMAIL, value)}
            onSelect={(value) => handleSearchStringValue(SELECTED_EMAIL, value)}
            defaultSelectValue={searchValues[SELECTED_EMAIL]}
            defaultSearchValue={searchValues[SEARCH_EMAIL]}
            searchUrl={QUERY_TYPES.jobProjectPrincipalEmails}
            searchQueryParam={'searchPrincipalEmail'}
            className='input'
            placeholderName='Start typing principal email...'
            searchWithDropdown={true}
            searchKey={PipelineTabSearchOptions.EMAIL}
          />
        );
      case PipelineTabSearchOptions.TAGS:
        return (
          <AsyncMultiSearch
            onSelect={(value) => handleSearchMultiValue(SELECTED_TAGS, value)}
            defaultSelectValue={searchValues[SELECTED_TAGS]}
            searchUrl={QUERY_TYPES.jobProjectTagName}
            searchQueryParam={'searchJobTagName'}
            className='input'
            placeholderName='Start typing pipeline tag / label...'
            searchWithDropdown={true}
            searchKey={PipelineTabSearchOptions.TAGS}
          />
        );
      case PipelineTabSearchOptions.LABELS:
        return (
          <AsyncGeneralSearch
            onSearch={(value) => handleSearchStringValue(SEARCH_LABEL, value)}
            onSelect={(value) => handleSearchStringValue(SELECTED_LABEL, value)}
            defaultSelectValue={searchValues[SELECTED_LABEL]}
            defaultSearchValue={searchValues[SEARCH_LABEL]}
            searchUrl={QUERY_TYPES.projectLabels}
            searchQueryParam={'searchTableLabel'}
            className='input'
            placeholderName='Start typing label...'
            searchWithDropdown={true}
            searchKey={PipelineTabSearchOptions.LABELS}
            getLabelFromResponseItem={
              handleAsyncLabelSearchGetLabelFromResponseItem
            }
            getValueFromResponseItem={
              handleAsyncLabelSearchGetValueFromResponseItem
            }
          />
        );
      case PipelineTabSearchOptions.DESTINATION_TABLE:
        return (
          <TableDatasetSearch
            onTableChange={(value) =>
              handleSelectObjectValue(SELECTED_DESTINATION_TABLE, value)
            }
            onSearch={(value) =>
              handleSearchStringValue(SEARCH_DESTINATION_TABLE, value)
            }
            defaultDataset={
              searchValues[SELECTED_DESTINATION_TABLE]?.dataset || ''
            }
            defaultTable={searchValues[SELECTED_DESTINATION_TABLE]?.table || ''}
            defaultSearchValue={searchValues[SEARCH_DESTINATION_TABLE]}
            className='input'
            placeholderName='Start typing destination table name...'
            searchWithDropdown={true}
            disableDatasets={true}
            searchKey={PipelineTabSearchOptions.DESTINATION_TABLE}
          />
        );
      default:
        return (
          <TableDatasetSearch
            onTableChange={(value) =>
              handleSelectObjectValue(SELECTED_TABLE, value)
            }
            onSearch={(value) => handleSearchStringValue(SEARCH_TABLE, value)}
            defaultDataset={searchValues[SELECTED_TABLE]?.dataset || ''}
            defaultTable={searchValues[SELECTED_TABLE]?.table || ''}
            defaultSearchValue={searchValues[SEARCH_TABLE]}
            className='input'
            placeholderName='Start typing table name...'
            searchWithDropdown={true}
            disableDatasets={true}
            searchKey={PipelineTabSearchOptions.TABLE}
          />
        );
    }
  };

  return (
    <div className={clsx(classes.container, 'searchInput')}>
      <SearchDropdown
        options={searchOptions}
        selectedOption={selectedSearch}
        onOptionClick={handleChangeSelectedSearch}
      />
      {renderSearchField()}
    </div>
  );
};

export { Search };
