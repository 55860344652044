import axios from 'axios';
import { auth } from 'service/services.js';

// const BASE_URL = 'http://localhost:8080';
// const BASE_URL = 'https://metadata-dot-gothic-sequence-307320.uc.r.appspot.com';
const BASE_URL = 'https://metadata.mastheadata.com';
const API_PREFIX = '/api/v';
const OPEN_API_PREFIX = '/openApi/v';
const ADMIN_PREFIX = '/admin/';

axios.defaults.baseURL = BASE_URL;

axios.interceptors.request.use(async (req) => {
  if (
    (req.url.startsWith(API_PREFIX) ||
      req.url.startsWith(OPEN_API_PREFIX) ||
      req.url.startsWith(ADMIN_PREFIX)) &&
    req.headers.Authorization === undefined &&
    auth?.currentUser
  ) {
    const token = await auth.currentUser.getIdToken();
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});
