import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';
import {
  DASHBOARD_TYPE,
  LOOK_TYPE,
} from 'Pages/Dictionary/components/DictionaryLooker/enums/enums.js';

const getLookerDictionary = createAsyncThunk(
  `${SLICE_NAME.looker}/getLookerDictionary`,
  async (parameters, { rejectWithValue }) => {
    const {
      dataset,
      table,
      searchTable,
      searchDashboardElementName,
      searchDashboardId,
      searchDashboardName,
      searchLookId,
      searchLookName,
      type,
      lookerAlerts,
      sortBy,
      order,
      page,
      limit,
    } = parameters;

    const params = new URLSearchParams();
    params.append('dataset', dataset);
    params.append('table', table);
    params.append('searchTable', searchTable);
    params.append('searchDashboardElementName', searchDashboardElementName);
    params.append('searchDashboardId', searchDashboardId);
    params.append('searchDashboardName', searchDashboardName);
    params.append('searchLookId', searchLookId);
    params.append('searchLookName', searchLookName);
    params.append('type', type);
    params.append('sortBy', sortBy);
    params.append('order', order);
    params.append('page', page);
    params.append('limit', limit);
    for (const lookerAlert of lookerAlerts) {
      params.append('lookerAlertTypes', lookerAlert);
    }

    const response = await fetcherGet(QUERY_TYPES.lookerDictionary, params);
    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const getUniqueDashboards = createAsyncThunk(
  `${SLICE_NAME.looker}/getUniqueDashboards`,
  async (_, { rejectWithValue }) => {
    const response = await fetcherGet(QUERY_TYPES.uniqueDashboards);

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const getUniqueLooks = createAsyncThunk(
  `${SLICE_NAME.looker}/getUniqueLooks`,
  async (_, { rejectWithValue }) => {
    const response = await fetcherGet(QUERY_TYPES.uniqueLooks);

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const getUniqueDashboardElements = createAsyncThunk(
  `${SLICE_NAME.looker}/getUniqueDashboardElements`,
  async (_, { rejectWithValue }) => {
    const response = await fetcherGet(QUERY_TYPES.uniqueDashboardElements);

    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const changeAlertType = createAsyncThunk(
  `${SLICE_NAME.looker}/changeAlertType`,
  async (parameters, { rejectWithValue }) => {
    const { selected, selectedAlertType } = parameters;
    const selectedLooks = selected.filter((item) => item.type === LOOK_TYPE);
    const selectedDashboards = selected.filter(
      (item) => item.type === DASHBOARD_TYPE
    );
    const lookIds = selectedLooks.map((item) => item.id);
    const dashboardIds = selectedDashboards.map((item) => item.id);

    const res = await fetcherPost(QUERY_TYPES.lookerAlert, {
      dashboardIds,
      lookIds,
      alertType: selectedAlertType,
    });

    if (res.success) {
      return parameters;
    }

    return rejectWithValue();
  }
);

export {
  getLookerDictionary,
  getUniqueDashboards,
  getUniqueLooks,
  getUniqueDashboardElements,
  changeAlertType,
};
