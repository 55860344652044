import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    width: 800,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    background: theme.palette.secondary.light,
    borderRadius: 16,
  },
  rightSide: {
    width: 400,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px 24px 24px',
  },
  textWrapper: {
    textAlign: 'start',
  },
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  leftSide: {
    width: 440,
    height: 400,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background:
      'linear-gradient(129.36deg, #877CF6 0%, #4965F5 58.62%, #0000D2 117.73%)',
    borderRadius: '0 16px 16px 0',
  },
  image: {
    width: 384,
    position: 'absolute',
    right: 0,
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'end',
    alignItems: 'center',
  },
}));
