import { subDays, endOfDay, startOfDay, startOfHour } from 'date-fns';

const HOURS_TO_MILLISECONDS = 1000 * 60 * 60;

const RUNNING_ACTION_STATUS = 'RUNNING';
const RUNNING_ACTION_LABEL = 'Running';
const FINISHED_ACTION_STATUS = 'FINISHED';
const FINISHED_ACTION_LABEL = 'Finished';

const PIPELINE_STATUS_FILTER_OPTIONS = [
  { value: '', label: 'All statuses' },
  { value: RUNNING_ACTION_STATUS, label: RUNNING_ACTION_LABEL },
  { value: FINISHED_ACTION_STATUS, label: FINISHED_ACTION_LABEL },
];

const PIPELINE_TIME_PERIOD_OPTIONS = [
  {
    value: {
      start: startOfHour(
        new Date(new Date().getTime() - HOURS_TO_MILLISECONDS)
      ),
      end: endOfDay(new Date()),
    },
    label: 'Last 1 hour',
  },
  {
    value: {
      start: startOfHour(
        new Date(new Date().getTime() - 12 * HOURS_TO_MILLISECONDS)
      ),
      end: endOfDay(new Date()),
    },
    label: 'Last 12 hours',
  },
  {
    value: {
      start: startOfHour(
        new Date(new Date().getTime() - 24 * HOURS_TO_MILLISECONDS)
      ),
      end: endOfDay(new Date()),
    },
    label: 'Last 1 day',
  },
  {
    value: {
      start: startOfDay(subDays(new Date(), 6)),
      end: endOfDay(new Date()),
    },
    label: 'Last 7 days',
  },
];

const BILLED_BYTES_METRIC = 'BILLED_BYTES';
const SLOTS_MS_METRIC = 'SLOTS_MS';
const DURATION_METRIC = 'DURATION';

const JOB_METRIC_TYPES = {
  bytes: BILLED_BYTES_METRIC,
  slots: SLOTS_MS_METRIC,
  duration: DURATION_METRIC,
};

const DEAD_END_TABLES = 'DEAD_END';

const COST_EFFICIENCY_FILTER_OPTIONS = [
  { value: '', label: 'All cost efficiency' },
  { value: DEAD_END_TABLES, label: 'Dead-end tables' },
];

export {
  PIPELINE_STATUS_FILTER_OPTIONS,
  PIPELINE_TIME_PERIOD_OPTIONS,
  RUNNING_ACTION_STATUS,
  FINISHED_ACTION_STATUS,
  RUNNING_ACTION_LABEL,
  FINISHED_ACTION_LABEL,
  JOB_METRIC_TYPES,
  COST_EFFICIENCY_FILTER_OPTIONS,
  DEAD_END_TABLES,
};
