import { makeStyles } from '@mui/styles';

export const baseStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    borderRadius: 16,
  },
  rightSide: {
    width: 400,
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '50px 24px 24px',
  },
  textWrapper: {
    textAlign: 'start',
    '& li': {
      listStyleType: 'disc',
    },
  },
  leftSide: {
    width: 400,
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 16px 16px 0',
    background:
      'linear-gradient(129.36deg, #877CF6 0%, #4965F5 58.62%, #0000D2 117.73%)',
  },
}));
