const INITIAL_TECHNOLOGY_OPTION = {
  value: '',
  label: 'All technologies',
};

export const INCIDENT_TYPE_FILTER = 'incidentType';
export const PIPELINE_INCIDENT_TYPE_FILTER = 'pipelineIncidentType';
export const TABLE_ALERT_FILTER = 'tableAlert';
export const LOOKER_ALERT_FILTER = 'lookerAlert';
export const INCIDENT_STATUS_FILTER = 'incidentStatus';
export const INCIDENT_STATUS_SHORT_FILTER = 'incidentStatusShort';
export const TECHNOLOGY_FILTER = 'technology';

const BigQueryTabDropdowns = [
  INCIDENT_TYPE_FILTER,
  TABLE_ALERT_FILTER,
  INCIDENT_STATUS_FILTER,
];

const LookerTabDropdowns = [
  INCIDENT_TYPE_FILTER,
  TABLE_ALERT_FILTER,
  LOOKER_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
];

const PipelineTabDropdowns = [
  // PIPELINE_INCIDENT_TYPE_FILTER,
  TABLE_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  TECHNOLOGY_FILTER,
];

export {
  BigQueryTabDropdowns,
  LookerTabDropdowns,
  PipelineTabDropdowns,
  INITIAL_TECHNOLOGY_OPTION,
};
