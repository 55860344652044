import { useMemo, useQuery } from 'hooks/hooks.js';
import moment from 'moment/moment';
import { QUERY_TYPES } from 'constants/constants.js';
import { fetcherGet } from 'utils/utils.js';
import { convertLocalToUTCDate } from 'utils/helpers/helpers.js';

const useCostsStats = (startDate, endDate, enabled = true) => {
  const { isFetching: isCostsStatsFetching, data } = useQuery(
    [QUERY_TYPES.jobProjectCost, startDate, endDate],
    ({ queryKey }) => {
      const [url, start, end] = queryKey;
      return fetcherGet(url, {
        from: moment(convertLocalToUTCDate(start)).utc().format(),
        to: moment(convertLocalToUTCDate(end)).utc().format(),
      });
    },
    { enabled }
  );

  const costsStats = useMemo(() => {
    return data?.values || [];
  }, [data?.values]);

  return { costsStats, isCostsStatsFetching };
};

export { useCostsStats };
