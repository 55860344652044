import { useRef, useState, useEffect, useMemo } from 'hooks/hooks.js';
import clsx from 'clsx';
import { Tooltip } from 'Components/components.js';

const OverflownText = ({
  title,
  children,
  maxWidth,
  className = '',
  ...props
}) => {
  const ref = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (ref.current !== null) {
      setIsOverflown(ref.current.scrollWidth > maxWidth);
    }
  }, [maxWidth]);

  const tooltipTitle = useMemo(() => {
    return isOverflown ? title : '';
  }, [isOverflown, title]);

  return (
    <Tooltip title={tooltipTitle}>
      <div
        ref={ref}
        className={clsx('textOverflow', className)}
        style={{ maxWidth }}
        {...props}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export { OverflownText };
