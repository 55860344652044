import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  modalBodyContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    height: '100%',
    margin: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflow: 'scroll',
  },
  tableContainer: {
    flexGrow: 3,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 12,
    backgroundColor: theme.palette.common.white,
    '& .tableItem': {
      padding: 16,
      borderBottom: `1px solid ${theme.palette.divider}`,
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  buttonContainer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));
