const bytesToSize = (bytes = 0, useBinaryUnits = true, decimals = 3) => {
  const base = useBinaryUnits ? 1024 : 1000;
  const units = useBinaryUnits
    ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(base));
  return bytes === 0
    ? 0
    : `${(bytes / Math.pow(base, i)).toFixed(decimals)} ${units[i]}`;
};

export { bytesToSize };
