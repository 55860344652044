import React from 'react';
import {
  useCallback,
  useDynamicInputs,
  useMemo,
  useState,
} from 'hooks/hooks.js';
import { makeStyles } from '@mui/styles';
import {
  Button,
  BUTTON_VARIANT,
  DynamicInputs,
  Input,
  Select,
  SwitchWithLabel,
} from 'Components/components.js';
import {
  DescriptionInput,
  DimensionSelect,
  MinMaxValue,
  ThresholdInput,
} from '../control-elements/controlElements.js';
import {
  CONTROL_ELEMENTS_DATA,
  NON_NULL,
  RANGE,
  REGEX,
  SET,
  STATISTIC,
  COLUMN_STATISTIC_OPTIONS,
  UNIQUENESS,
  RULE_TYPES,
  SQL,
  SQL_TEXT_INFO,
} from 'Pages/DataQuality/libs/constants/constants.js';
import { SqlBlock } from 'Pages/DataQuality/components/components.js';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    margin: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    overflow: 'scroll',
  },
  helperText: {
    textAlign: 'end',
  },
  buttonContainer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));

const getDefaultThreshold = (threshold) => {
  return threshold * 100;
};

const getDefaultMinMax = (ruleData) => {
  if (ruleData.type !== RANGE && ruleData.type !== STATISTIC) {
    return null;
  }

  return {
    minValue: ruleData.minValue,
    maxValue: ruleData.maxValue,
    strictMinChecked: ruleData.strictMinEnabled,
    strictMaxChecked: ruleData.strictMaxEnabled,
    error: null,
  };
};

const getDefaultColumnStatistic = (ruleData) => {
  if (ruleData.type !== STATISTIC) {
    return COLUMN_STATISTIC_OPTIONS[0];
  }

  return (
    COLUMN_STATISTIC_OPTIONS.find(
      (option) => option.value === ruleData.statistic
    ) || { value: ruleData.statistic, label: ruleData.statistic }
  );
};

const EditRuleModalBody = ({ closeModal, saveRule = () => {}, rule }) => {
  const classes = useStyles();

  const [dimensionValue, setDimensionValue] = useState({
    value: rule.dimension,
    label: rule.dimension,
  });
  const [description, setDescription] = useState(rule.description);
  const [thresholdValue, setThresholdValue] = useState(
    getDefaultThreshold(rule.threshold)
  );
  const [isIgnoreNulls, setIsIgnoreNulls] = useState(Boolean(rule.ignoreNull));
  const [regexValue, setRegexValue] = useState(rule.data.regex || '');
  const [minMaxData, setMinMaxData] = useState(getDefaultMinMax(rule.data));
  const [columnStatistic, setColumnStatistic] = useState(
    getDefaultColumnStatistic(rule.data)
  );
  const [sqlValue, setSqlValue] = useState(rule.data.sqlExpression || '');

  const { columnStatisticSelect } = CONTROL_ELEMENTS_DATA;
  const { ignoreNullsSwitch, regexInput } = CONTROL_ELEMENTS_DATA;

  const {
    inputFields,
    handleChangeInput,
    handleAddFields,
    handleRemoveFields,
  } = useDynamicInputs(rule.data.values || []);

  const isDisabledSaveButton = useMemo(() => {
    return (
      !dimensionValue ||
      !thresholdValue ||
      (rule.data.type === REGEX && !regexValue) ||
      (rule.data.type === RANGE &&
        (!minMaxData?.minValue || minMaxData?.error)) ||
      (rule.data.type === STATISTIC &&
        (!minMaxData?.minValue || minMaxData?.error))
    );
  }, [
    dimensionValue,
    minMaxData?.error,
    minMaxData?.minValue,
    regexValue,
    rule.data.type,
    thresholdValue,
  ]);

  const handleChangeDimensionValue = useCallback((newValue) => {
    setDimensionValue(newValue);
  }, []);

  const handleChangeThresholdValue = useCallback((newValue) => {
    setThresholdValue(newValue);
  }, []);

  const handleChangeDescription = useCallback((newValue) => {
    setDescription(newValue);
  }, []);

  const handleChangeSwitch = useCallback((value) => {
    setIsIgnoreNulls(value);
  }, []);

  const handleChangeRegexValue = useCallback((newValue) => {
    setRegexValue(newValue);
  }, []);

  const handleChangeColumnStatistic = useCallback((newValue) => {
    setColumnStatistic(newValue);
  }, []);

  const handleChangeMinMaxData = useCallback((newValue) => {
    setMinMaxData(newValue);
  }, []);

  const handleChangeSql = useCallback((newValue) => {
    setSqlValue(newValue);
  }, []);

  const newRuleData = useMemo(() => {
    if (!rule.data.type) {
      return {};
    }

    const data = {
      type: rule.data.type,
    };
    switch (rule.data.type) {
      case RANGE:
        data.minValue = minMaxData?.minValue;
        data.maxValue = minMaxData?.maxValue;
        data.strictMinEnabled = minMaxData?.strictMinChecked;
        data.strictMaxEnabled = minMaxData?.strictMaxChecked;
        break;
      case SET:
        data.values = inputFields.map((item) => item.value);
        break;
      case STATISTIC:
        data.statistic = columnStatistic.value;
        data.minValue = minMaxData?.minValue;
        data.maxValue = minMaxData?.maxValue;
        data.strictMinEnabled = minMaxData?.strictMinChecked;
        data.strictMaxEnabled = minMaxData?.strictMaxChecked;
        break;
      case REGEX:
        data.regex = regexValue;
        break;
      case SQL:
        data.sqlExpression = sqlValue;
        break;
      default:
        break;
    }
    return data;
  }, [
    rule.data.type,
    minMaxData,
    inputFields,
    columnStatistic,
    regexValue,
    sqlValue,
  ]);

  const onSaveButtonClick = useCallback(() => {
    saveRule({
      ...rule,
      isEdit: true,
      ignoreNull: isIgnoreNulls,
      dimension: dimensionValue?.value || '',
      dimensionLabel: dimensionValue?.label || '',
      threshold: +thresholdValue / 100,
      description,
      typeLabel:
        RULE_TYPES.find((item) => item.value === newRuleData.type)?.label ||
        newRuleData.type,
      data: newRuleData,
    });
    closeModal();
  }, [
    rule,
    saveRule,
    isIgnoreNulls,
    dimensionValue?.value,
    dimensionValue?.label,
    thresholdValue,
    description,
    newRuleData,
    closeModal,
  ]);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <div>
          <div>
            <span className='txt-grey-13-500'>Column name</span>{' '}
            <span className='txt-mainDark-13-500'>{rule.column}</span>
          </div>

          <div>
            <span className='txt-grey-13-500'>Rule type</span>{' '}
            <span className='txt-mainDark-13-500'>{rule.data.type}</span>
          </div>
        </div>

        <DimensionSelect
          value={dimensionValue}
          onChange={handleChangeDimensionValue}
        />
        <DescriptionInput
          value={description}
          onChange={handleChangeDescription}
        />

        {rule.data.type === REGEX && (
          <Input
            value={regexValue}
            onChange={handleChangeRegexValue}
            label={regexInput.label}
            id={regexInput.id}
            isRequired={regexInput.isRequired}
          />
        )}

        {(rule.data.type === RANGE ||
          rule.data.type === SET ||
          rule.data.type === UNIQUENESS) && (
          <SwitchWithLabel
            isChecked={isIgnoreNulls}
            handleChange={handleChangeSwitch}
            label={ignoreNullsSwitch.label}
            id={ignoreNullsSwitch.id}
          />
        )}

        {(rule.data.type === NON_NULL ||
          rule.data.type === SET ||
          rule.data.type === RANGE ||
          rule.data.type === SQL ||
          rule.data.type === UNIQUENESS) && (
          <ThresholdInput
            defaultValue={thresholdValue}
            onChange={handleChangeThresholdValue}
          />
        )}

        {rule.data.type === SET && (
          <DynamicInputs
            inputFields={inputFields}
            handleChangeInput={handleChangeInput}
            handleRemoveFields={handleRemoveFields}
            handleAddFields={handleAddFields}
            dynamicInputLabel='Value'
          />
        )}

        {rule.data.type === STATISTIC && (
          <Select
            value={columnStatistic}
            onChange={handleChangeColumnStatistic}
            options={COLUMN_STATISTIC_OPTIONS}
            label={columnStatisticSelect.label}
            id={columnStatisticSelect.id}
          />
        )}

        {(rule.data.type === STATISTIC || rule.data.type === RANGE) && (
          <MinMaxValue
            changeMinMaxData={handleChangeMinMaxData}
            minMaxData={minMaxData}
          />
        )}

        {rule.data.type === SQL && (
          <SqlBlock
            textInfo={SQL_TEXT_INFO}
            value={sqlValue}
            onChangeSqlData={handleChangeSql}
          />
        )}
      </div>

      <div className={classes.buttonContainer}>
        <Button
          text='Save'
          fullWidth={false}
          className='button'
          onClick={onSaveButtonClick}
          isDisabled={isDisabledSaveButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { EditRuleModalBody };
