import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetcherGet, fetcherPost } from 'utils/utils.js';
import { QUERY_TYPES, SLICE_NAME } from 'constants/constants.js';

const getTables = createAsyncThunk(
  `${SLICE_NAME.tables}/getTables`,
  async (parameters, { rejectWithValue }) => {
    const {
      dataset,
      table,
      searchTable,
      alertTypes,
      datasetTypes,
      sortBy,
      order,
      page,
      limit,
      onlyRecommendPriority,
    } = parameters;

    const params = new URLSearchParams();
    params.append('dataset', dataset);
    params.append('table', table);
    params.append('searchTable', searchTable);
    for (const alertType of alertTypes) {
      params.append('alertTypes', alertType);
    }
    for (const datasetType of datasetTypes) {
      params.append('datasetTypes', datasetType);
    }
    params.append('sortBy', sortBy);
    params.append('order', order);
    params.append('page', page);
    params.append('limit', limit);
    onlyRecommendPriority && params.append('onlyRecommendPriority', true);

    const response = await fetcherGet(QUERY_TYPES.tables, params);
    if (response.values) {
      return response;
    }

    return rejectWithValue();
  }
);

const updateTableDesc = createAsyncThunk(
  `${SLICE_NAME.tables}/updateTableDesc`,
  async (parameters, { rejectWithValue }) => {
    const { dataset, table, desc } = parameters;

    const response = await fetcherPost(
      `/api/v1/tables?dataset=${dataset}&table=${table}`,
      { value: desc }
    );
    if (response.success) {
      return parameters;
    }

    return rejectWithValue();
  }
);

const updateTableAlertType = createAsyncThunk(
  `${SLICE_NAME.tables}/updateTableAlertType`,
  async (parameters, { rejectWithValue }) => {
    const { selected, selectedAlertType } = parameters;

    const tables = selected.map((item) => {
      return { dataset: item.dataset, table: item.table };
    });

    const response = await fetcherPost(QUERY_TYPES.tablesAlerts, {
      type: selectedAlertType,
      tables,
    });
    if (response.success) {
      return parameters;
    }

    return rejectWithValue();
  }
);

export { getTables, updateTableDesc, updateTableAlertType };
