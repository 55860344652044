import React from 'react';
import {
  useMemo,
  usePagination,
  useState,
  useEffect,
  useHistory,
  useCallback,
  useDispatch,
  useSelector,
} from 'hooks/hooks.js';
import { Table, TableBody } from '@mui/material';
import { useStyles } from './DataQuality.styles.js';
import {
  Button,
  BUTTON_COLOR,
  PageHeader,
  Pagination,
  PositionLoader,
  TableHeadWithCheckbox,
  Toolbar,
} from 'Components/components.js';
import {
  ScanName,
  LastRun,
  TableName,
  Schedule,
  Edit,
} from './libs/enums/enums.js';
import {
  DataQualitySearch,
  DataQualityTable,
  DataQualityEmpty,
} from './components/components.js';
import { amplEvent } from 'service/services.js';
import {
  AMPL_PAGE_EVENT,
  DATA_STATUS,
  ORDER_TYPES,
} from 'constants/constants.js';
import { AppRoutes } from 'app-routes.js';
import {
  getDataScans,
  clearRemoveDataScanStatus,
  clearCreateDataScanStatus,
  updateDataScan,
  clearUpdateDataScanStatus,
} from 'slices/actions.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { useMessages } from 'context/context.js';
import { getUpdateRequestDTOFromDataScan } from './libs/mappers/data-scan-request-mapper.js';
import { ReactComponent as RefreshIcon } from 'assets/img/icons/refresh-blue.svg';

const HEAD_CELLS = [ScanName, LastRun, TableName, Schedule, Edit];
const PAGE_EVENT = `${AMPL_PAGE_EVENT.dataQuality} -> `;
const START_PAGE_NUMBER = 0;

const DataQuality = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMessage } = useMessages();
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const [toolbarText, setToolbarText] = useState('');
  const [order, setOrder] = useState(ORDER_TYPES.asc);
  const [sortBy, setSortBy] = useState(ScanName.id);
  const [searchValue, setSearchValue] = useState('');
  const [searchTable, setSearchTable] = useState(null);
  const [searchScanId, setSearchScanId] = useState('');

  useEffect(() => amplEvent(AMPL_PAGE_EVENT.dataQuality), []);

  const {
    dataScans,
    dataScansNumber,
    dataScansStatus,
    removeDataScanStatus,
    updateDataScanStatus,
    createDataScanStatus,
  } = useSelector((state) => ({
    dataScans: state.dataScans.dataScans,
    dataScansNumber: state.dataScans.dataScansNumber,
    dataScansStatus: state.dataScans.dataScansStatus,
    removeDataScanStatus: state.dataScans.removeDataScanStatus,
    updateDataScanStatus: state.dataScans.updateDataScanStatus,
    createDataScanStatus: state.dataScans.createDataScanStatus,
  }));

  const isLoadingScans = useMemo(() => {
    return (
      dataScansStatus === DATA_STATUS.pending ||
      removeDataScanStatus === DATA_STATUS.pending ||
      updateDataScanStatus === DATA_STATUS.pending
    );
  }, [dataScansStatus, removeDataScanStatus, updateDataScanStatus]);

  useEffect(() => {
    dispatch(
      getDataScans({
        limit: rowsPerPage,
        page: page + 1,
        sortBy,
        order,
        dataset: searchTable?.dataset || '',
        table: searchTable?.table || '',
        search: searchValue,
        dataPlexId: searchScanId,
      })
    );
  }, [
    dispatch,
    order,
    page,
    rowsPerPage,
    searchScanId,
    searchTable?.dataset,
    searchTable?.table,
    searchValue,
    sortBy,
  ]);

  const isShowDataScansTable = useMemo(() => {
    return dataScansStatus === DATA_STATUS.fulfilled && dataScansNumber > 0;
  }, [dataScansNumber, dataScansStatus]);

  useEffect(() => {
    if (removeDataScanStatus === DATA_STATUS.fulfilled) {
      dispatch(clearRemoveDataScanStatus());
      setToolbarText('Data scan successfully deleted');
      setTimeout(() => {
        setToolbarText('');
      }, 3000);
    }

    if (removeDataScanStatus === DATA_STATUS.rejected) {
      dispatch(clearRemoveDataScanStatus());
      setToolbarText('Something went wrong, try again');
      setTimeout(() => {
        setToolbarText('');
      }, 3000);
    }
  }, [dispatch, removeDataScanStatus]);

  useEffect(() => {
    if (createDataScanStatus === DATA_STATUS.fulfilled) {
      dispatch(clearCreateDataScanStatus());
      setToolbarText('Data scan successfully created');
      setTimeout(() => {
        setToolbarText('');
      }, 3000);
    }
  }, [dispatch, createDataScanStatus]);

  const onRefreshClick = useCallback(() => {
    handleChangePage({}, 0);

    if (page === START_PAGE_NUMBER) {
      dispatch(
        getDataScans({
          limit: rowsPerPage,
          page: page + 1,
          sortBy,
          order,
          dataset: searchTable?.dataset || '',
          table: searchTable?.table || '',
          search: searchValue,
          dataPlexId: searchScanId,
        })
      );
    }
  }, [
    dispatch,
    handleChangePage,
    order,
    page,
    rowsPerPage,
    searchScanId,
    searchTable?.dataset,
    searchTable?.table,
    searchValue,
    sortBy,
  ]);

  const navigateToCreateScanPage = useCallback(() => {
    history.push(AppRoutes.DataQuality_createScan.path);
  }, [history]);

  const handleRequestSort = useCallback(
    (property) => {
      const isAsc = sortBy === property && order === ORDER_TYPES.asc;
      handleChangePage({}, 0);
      setOrder(isAsc ? ORDER_TYPES.desc : ORDER_TYPES.asc);
      setSortBy(property);
    },
    [handleChangePage, order, sortBy]
  );

  const handleScanIdSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchScanId(value || '');

      if (value.length) {
        amplEvent(`${PAGE_EVENT} search by scan id -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const handleTableChange = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchTable(value);
      setSearchValue('');

      if (value) {
        amplEvent(
          `${PAGE_EVENT} search by table -> ${getFullTableName(
            value?.table,
            value?.dataset
          )}`
        );
      }
    },
    [handleChangePage]
  );

  const handleTableSearch = useCallback(
    (value) => {
      handleChangePage({}, 0);
      setSearchValue(value);
      setSearchTable(null);

      if (value.length) {
        amplEvent(`${PAGE_EVENT} search by table -> ${value}`);
      }
    },
    [handleChangePage]
  );

  const updateDataScanRequest = useCallback(
    (updatedDataScan) => {
      const updateDataScanRequest =
        getUpdateRequestDTOFromDataScan(updatedDataScan);
      dispatch(
        updateDataScan({
          location: updatedDataScan.location,
          dataPlexId: updatedDataScan.dataPlexId,
          updateDataScanRequest,
        })
      ).then((action) => {
        dispatch(clearUpdateDataScanStatus());
        if (action.meta.requestStatus === DATA_STATUS.fulfilled) {
          setToolbarText('Changes saved');
          setTimeout(() => {
            setToolbarText('');
          }, 3000);
        }
        if (action.meta.requestStatus === DATA_STATUS.rejected) {
          setMessage('Something went wrong, try again');
        }
      });
    },
    [dispatch, setMessage]
  );

  return (
    <>
      <PageHeader hasDateRangePicker={false}>Data Quality</PageHeader>

      <div className={classes.flexContainer}>
        <div className={classes.mainContainer}>
          {isLoadingScans ? (
            <PositionLoader />
          ) : isShowDataScansTable ? (
            <>
              <div className={classes.searchContainer}>
                <DataQualitySearch
                  handleScanIdSearch={handleScanIdSearch}
                  handleTableChange={handleTableChange}
                  handleTableSearch={handleTableSearch}
                  defaultDataset={searchTable?.dataset || ''}
                  defaultTable={searchTable?.table || ''}
                  defaultTableSearchValue={searchValue}
                  className='searchInput'
                />

                <div className={classes.buttonContainer}>
                  <Button
                    onClick={onRefreshClick}
                    color={BUTTON_COLOR.secondary}
                    text='Refresh'
                    fullWidth={false}
                    startIcon={<RefreshIcon />}
                  />

                  <Button
                    text='Create scan'
                    onClick={navigateToCreateScanPage}
                    fullWidth={false}
                  />
                </div>
              </div>

              <div className={classes.tableContainer}>
                <Toolbar actionText={toolbarText} />
                <Table>
                  <TableHeadWithCheckbox
                    onRequestSort={handleRequestSort}
                    headCells={HEAD_CELLS}
                    selectedColumn={false}
                  />
                  <TableBody>
                    {dataScans.map((scan) => {
                      return (
                        <DataQualityTable
                          key={`${scan.dataPlexId}/${scan.id}`}
                          rowData={scan}
                          saveScanEditing={updateDataScanRequest}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </>
          ) : (
            <DataQualityEmpty />
          )}
        </div>

        <Pagination
          count={dataScansNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    </>
  );
};

export default DataQuality;
