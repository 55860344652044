import React, { useEffect } from 'react';
import {
  useCallback,
  useState,
  useDynamicInputs,
  useMemo,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { v4 as uuid } from 'uuid';
import {
  Button,
  BUTTON_VARIANT,
  DynamicInputs,
  Input,
  Select,
  SwitchWithLabel,
} from 'Components/components.js';
import {
  CONTROL_ELEMENTS_DATA,
  NON_NULL,
  RANGE,
  REGEX,
  SET,
  STATISTIC,
  COLUMN_STATISTIC_OPTIONS,
  UNIQUENESS,
  RULE_TYPES,
  DIMENSION_OPTIONS,
  COMPLETENESS_DIMENSION,
  UNIQUENESS_DIMENSION,
  VALIDITY_DIMENSION,
} from 'Pages/DataQuality/libs/constants/constants.js';
import {
  DescriptionInput,
  DimensionSelect,
  MinMaxValue,
  ThresholdInput,
} from 'Pages/DataQuality/components/components';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 600,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    padding: '16px 32px',
  },
  contentContainer: {
    padding: '0 32px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flexGrow: 3,
    overflow: 'scroll',
  },
  buttonContainer: {
    padding: 32,
    display: 'flex',
    gap: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
    '& .button': {
      padding: '12px 48px',
    },
  },
}));

const MAX_PERCENT = 100;

const getDefaultDimensionValue = (ruleType) => {
  switch (ruleType) {
    case NON_NULL:
      return DIMENSION_OPTIONS.find(
        (item) => item.value === COMPLETENESS_DIMENSION
      );
    case UNIQUENESS:
      return DIMENSION_OPTIONS.find(
        (item) => item.value === UNIQUENESS_DIMENSION
      );
    default:
      return DIMENSION_OPTIONS.find(
        (item) => item.value === VALIDITY_DIMENSION
      );
  }
};

const EditRuleBlock = ({
  selectedColumn,
  selectedRuleType,
  closeModal,
  addNewRule,
  onSaveRules = () => {},
  isDisabledSaveRules = false,
}) => {
  const classes = useStyles();
  const [dimensionValue, setDimensionValue] = useState(
    getDefaultDimensionValue(selectedRuleType?.value)
  );
  const [description, setDescription] = useState('');
  const [isIgnoreNulls, setIsIgnoreNulls] = useState(false);
  const [thresholdValue, setThresholdValue] = useState(MAX_PERCENT.toString());
  const [regexValue, setRegexValue] = useState('');
  const [minMaxData, setMinMaxData] = useState(null);
  const [columnStatistic, setColumnStatistic] = useState(
    COLUMN_STATISTIC_OPTIONS[0]
  );

  const { columnStatisticSelect } = CONTROL_ELEMENTS_DATA;

  const {
    inputFields,
    handleChangeInput,
    handleAddFields,
    handleRemoveFields,
  } = useDynamicInputs();

  const newRuleData = useMemo(() => {
    if (!selectedRuleType?.value) {
      return {};
    }

    const data = {
      type: selectedRuleType.value,
    };
    switch (selectedRuleType.value) {
      case RANGE:
        data.minValue = minMaxData?.minValue;
        data.maxValue = minMaxData?.maxValue;
        data.strictMinEnabled = minMaxData?.strictMinChecked;
        data.strictMaxEnabled = minMaxData?.strictMaxChecked;
        break;
      case SET:
        data.values = inputFields.map((item) => item.value);
        break;
      case STATISTIC:
        data.statistic = columnStatistic.value;
        data.minValue = minMaxData?.minValue;
        data.maxValue = minMaxData?.maxValue;
        data.strictMinEnabled = minMaxData?.strictMinChecked;
        data.strictMaxEnabled = minMaxData?.strictMaxChecked;
        break;
      case REGEX:
        data.regex = regexValue;
        break;
      default:
        break;
    }
    return data;
  }, [
    selectedRuleType?.value,
    minMaxData,
    inputFields,
    columnStatistic,
    regexValue,
  ]);

  const newRule = useMemo(() => {
    return {
      id: uuid(),
      isNew: true,
      dimension: dimensionValue?.value || '',
      dimensionLabel: dimensionValue?.label || '',
      threshold: +thresholdValue / MAX_PERCENT,
      column: selectedColumn?.name || '',
      ignoreNull: isIgnoreNulls,
      description,
      typeLabel:
        RULE_TYPES.find((item) => item.value === newRuleData.type)?.label ||
        newRuleData.type,
      data: newRuleData,
    };
  }, [
    description,
    dimensionValue?.label,
    dimensionValue?.value,
    selectedColumn?.name,
    thresholdValue,
    newRuleData,
    isIgnoreNulls,
  ]);

  const { ignoreNullsSwitch, regexInput } = CONTROL_ELEMENTS_DATA;

  const isDisabledAddButton = useMemo(() => {
    return (
      !dimensionValue ||
      !thresholdValue ||
      (selectedRuleType?.value === REGEX && !regexValue) ||
      (selectedRuleType?.value === RANGE &&
        (!minMaxData?.minValue || minMaxData?.error)) ||
      (selectedRuleType?.value === STATISTIC &&
        (!minMaxData?.minValue || minMaxData?.error))
    );
  }, [
    dimensionValue,
    minMaxData?.error,
    minMaxData?.minValue,
    regexValue,
    selectedRuleType?.value,
    thresholdValue,
  ]);

  const handleChangeDimensionValue = useCallback((newValue) => {
    setDimensionValue(newValue);
  }, []);

  const handleChangeDescription = useCallback((newValue) => {
    setDescription(newValue);
  }, []);

  const handleChangeSwitch = useCallback((value) => {
    setIsIgnoreNulls(value);
  }, []);

  const handleChangeThresholdValue = useCallback((newValue) => {
    setThresholdValue(newValue);
  }, []);

  const handleChangeRegexValue = useCallback((newValue) => {
    setRegexValue(newValue);
  }, []);

  const handleChangeColumnStatistic = useCallback((newValue) => {
    setColumnStatistic(newValue);
  }, []);

  const handleChangeMinMaxData = useCallback((newValue) => {
    setMinMaxData(newValue);
  }, []);

  const clearFields = useCallback(() => {
    handleChangeDimensionValue(
      getDefaultDimensionValue(selectedRuleType?.value)
    );
    handleChangeThresholdValue(MAX_PERCENT.toString());
    handleChangeRegexValue('');
    handleChangeColumnStatistic(COLUMN_STATISTIC_OPTIONS[0]);
    handleChangeSwitch(false);
    handleChangeDescription('');
    handleChangeMinMaxData(null);
  }, [
    handleChangeDimensionValue,
    selectedRuleType?.value,
    handleChangeThresholdValue,
    handleChangeRegexValue,
    handleChangeColumnStatistic,
    handleChangeSwitch,
    handleChangeDescription,
    handleChangeMinMaxData,
  ]);

  useEffect(() => {
    clearFields();
  }, [clearFields, selectedRuleType?.value]);

  const onAddButtonClick = useCallback(() => {
    addNewRule(newRule);
    clearFields();
    closeModal();
  }, [addNewRule, newRule, clearFields, closeModal]);

  // const onSaveButtonClick = useCallback(() => {
  //   onSaveRules();
  //   clearFields();
  //   closeModal();
  // }, [onSaveRules, clearFields, closeModal]);

  return (
    <div className={classes.container}>
      <div className={clsx(classes.title, 'txt-grey-13-700 uppercase')}>
        Edit rule
      </div>

      <div className={classes.contentContainer}>
        {!!selectedRuleType && (
          <>
            <DimensionSelect
              value={dimensionValue}
              onChange={handleChangeDimensionValue}
            />

            <DescriptionInput
              value={description}
              onChange={handleChangeDescription}
            />

            {selectedRuleType.value === REGEX && (
              <Input
                value={regexValue}
                onChange={handleChangeRegexValue}
                label={regexInput.label}
                id={regexInput.id}
                isRequired={regexInput.isRequired}
              />
            )}

            {(selectedRuleType.value === RANGE ||
              selectedRuleType.value === SET ||
              selectedRuleType.value === UNIQUENESS) && (
              <SwitchWithLabel
                isChecked={isIgnoreNulls}
                handleChange={handleChangeSwitch}
                label={ignoreNullsSwitch.label}
                id={ignoreNullsSwitch.id}
              />
            )}

            {(selectedRuleType.value === NON_NULL ||
              selectedRuleType.value === SET ||
              selectedRuleType.value === RANGE ||
              selectedRuleType.value === UNIQUENESS) && (
              <ThresholdInput onChange={handleChangeThresholdValue} />
            )}

            {selectedRuleType.value === SET && (
              <DynamicInputs
                inputFields={inputFields}
                handleChangeInput={handleChangeInput}
                handleRemoveFields={handleRemoveFields}
                handleAddFields={handleAddFields}
                dynamicInputLabel='Value'
              />
            )}

            {selectedRuleType.value === STATISTIC && (
              <Select
                value={columnStatistic}
                onChange={handleChangeColumnStatistic}
                options={COLUMN_STATISTIC_OPTIONS}
                label={columnStatisticSelect.label}
                id={columnStatisticSelect.id}
              />
            )}

            {(selectedRuleType.value === STATISTIC ||
              selectedRuleType.value === RANGE) && (
              <MinMaxValue
                changeMinMaxData={handleChangeMinMaxData}
                minMaxData={minMaxData}
              />
            )}
          </>
        )}
      </div>

      <div className={classes.buttonContainer}>
        {/* <Button
          text='Save'
          fullWidth={false}
          className='button'
          onClick={onSaveButtonClick}
          isDisabled={isDisabledSaveRules}
        /> */}
        <Button
          text='Add'
          fullWidth={false}
          className='button'
          onClick={onAddButtonClick}
          isDisabled={isDisabledAddButton}
        />
        <Button
          variant={BUTTON_VARIANT.text}
          text='Cancel'
          fullWidth={false}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};

export { EditRuleBlock };
