const millisecondsToTimeSlots = (milliseconds, decimals = 1) => {
  const seconds = ((milliseconds / 1000) % 60).toFixed(decimals);
  const minutes = ((milliseconds / (1000 * 60)) % 60).toFixed(decimals);
  const hours = (milliseconds / (1000 * 60 * 60)).toFixed(decimals);

  return hours > 0
    ? `${hours} slot hours`
    : minutes > 0
    ? `${minutes} slot min`
    : seconds > 0
    ? `${seconds} slot sec`
    : `${milliseconds} slot ms`;
};

export { millisecondsToTimeSlots };
