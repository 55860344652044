import { useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useModal } from 'context/context.js';
import {
  Button,
  BUTTON_VARIANT,
  Divider,
  PositionLoader,
} from 'Components/components.js';

const useStyles = makeStyles((theme) => ({
  modalWrapper: {
    width: 474,
    minHeight: 170,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: theme.palette.common.white,
    borderRadius: 16,
  },
  header: {
    padding: '16px 16px 0',
  },
  modalDesc: {
    padding: '0 16px 16px',
  },
  modalBtns: {
    display: 'flex',
    gap: 10,
    padding: '0 16px 16px',
    justifyContent: 'end',
    alignItems: 'center',
    '& .agreeButton': {
      padding: '12px 24px',
    },
  },
}));

const Modal = ({
  onAgree,
  title = '',
  titlePosition = 'left',
  ModalBodyComponent,
  agreeButtonText,
  isDisabledSaveButton = false,
  className = '',
  ...props
}) => {
  const classes = useStyles();
  const { setModal } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const closeModal = () => setModal(null);

  const onAgreeClick = () => {
    const callback = onAgree();
    if (callback !== undefined && typeof callback.finally === 'function') {
      setIsLoading(true);
      callback.finally(() => {
        setIsLoading(false);
        closeModal();
      });
    } else {
      closeModal();
    }
  };

  return (
    <div className={clsx(classes.modalWrapper, className)}>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <>
          {title.length > 0 && (
            <>
              <div
                className={clsx(classes.header, 'txt-mainDark-16-700')}
                style={{ textAlign: titlePosition }}
              >
                {title}
              </div>
              <Divider />
            </>
          )}

          <div className={classes.modalDesc}>
            <ModalBodyComponent {...props} />
          </div>

          <div className={classes.modalBtns}>
            <Button
              variant={BUTTON_VARIANT.text}
              text='Cancel'
              onClick={closeModal}
              fullWidth={false}
            />
            <Button
              onClick={onAgreeClick}
              text={agreeButtonText}
              fullWidth={false}
              className='agreeButton'
              isDisabled={isDisabledSaveButton}
            />
          </div>
        </>
      )}
    </div>
  );
};

export { Modal };
